import React, { useContext, useState, useLayoutEffect, useRef, useEffect } from "react";
import "../../pages/user-dashboard/styles/user-index.css";
import AppContext from "../../context/app-context";
import ChefDashBoardEngine from "./engine/engine";
import ChefDashboardSideNav from "./navigator/nav";
import { useParams } from "react-router-dom";
import ChefDashboradContext from "./context/chef-index-context";
import AlertModal from "../../components/alert-modal/alert-modal";

const ChefDashboardIndex = function () {
  const [securityCheckded, setSecurityChecked] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);

  const [message, setMessage] = useState("");

  const { setShowFooter, usersData, loggedIn, msgCount, notCount, popup } = useContext(AppContext);

  const [dashboardFunctions, setDashboardFunctions] = useState({});

  const engineRef = useRef(null);
  const { chefId } = useParams();
  const navRef = useRef(null);

  useEffect(() => {
    document.title = "iKooK - Chef Dashboard";
    document.location.hash = "dashboard";
  }, []);

  useLayoutEffect(() => {
    setShowFooter(false);
  }, []);

  useLayoutEffect(() => {
    if (usersData?.role !== "chef") {
      setSecurityChecked(false);
    }

    if (!loggedIn || loggedIn === "none") return setSecurityChecked(false);

    if (!localStorage.getItem("ikooktoken")) return setSecurityChecked(false);

    if (!localStorage.getItem("ikookusername")) return setSecurityChecked(false);

    if (localStorage.getItem("ikookid") !== chefId) return setSecurityChecked(false);

    if (!usersData?.verified) {
      popup("Please verify your email to access this page");
      setTimeout(() => {
        document.location.href = "/";
      }, 4000);
      return setSecurityChecked(false);
    }

    setSecurityChecked(true);
  }, [loggedIn, usersData]);

  useEffect(() => {
    const isMobile = navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500;
    if (isMobile) {
      setMessage(
        "Hi there, we recommend requesting desktop site on your browser or viewing on your PC for a better experience."
      );
      setShowAlertModal(true);
    }
  }, []);

  if (!securityCheckded) return;

  return (
    <ChefDashboradContext.Provider
      value={{ changeAppScreen: navRef?.current?.changeAppScreen, dashboardFunctions }}
    >
      <div className="user-dashboard-cont media-chef-dashboard-cont">
        <ChefDashboardSideNav
          ref={(ref) => (navRef.current = ref)}
          changeScreen={engineRef.current?.changeScreen}
        />

        <ChefDashBoardEngine
          msgCount={msgCount}
          notCount={notCount}
          dashboardFunctions={dashboardFunctions}
          setDashboardFunctions={setDashboardFunctions}
          ref={(ref) => (engineRef.current = ref)}
          changeAppScreen={navRef?.current?.changeAppScreen}
        />

        <AlertModal
          display={showAlertModal}
          setShowAlertModal={setShowAlertModal}
          message={message}
        />
      </div>
    </ChefDashboradContext.Provider>
  );
};

export default ChefDashboardIndex;
