import React from "react";
import "../../styles/index.css";
import "./lightening-list.css";

const LighteningList = function ({ title, content }) {
  return (
    <div className="l-cont">
      <div className="l-lightening-title">
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="big yellowText boldText l-title">{title}</p>
      </div>

      <p
        dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }}
        className="small lighteneing-text"
      ></p>
    </div>
  );
};

export default LighteningList;
