import board from "./assets/board.svg";

const HostHeading = () => {
  return (
    <div className="hwt-heading-container">
      <div className="heading-wrapper">
        <div style={{ position: "relative" }}>
          <div className="background-box"></div>
          <p className="how-it-works-heading">How it works</p>
        </div>
        <p className="how-it-works-intro">
          Our platform connects you with top chefs, who bring their expertise and delicious menus
          right to your doorstep. Simply follow these steps and enjoy a unique dining experience,
          made just for you!
        </p>
      </div>
      <div className="relative-container-image">
        <img className="board-img" src={board} />
        <div className="board-background"></div>
      </div>
    </div>
  );
};

export default HostHeading;
