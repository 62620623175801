import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "../components/error";
import Home from "../pages/home/home";
import ExploreSetMenus from "../pages/explore-menus/explore";
import HowItWorks from "../pages/How-It-Works/how-it-works";
import UserDashBoardIndex from "../pages/user-dashboard/user-index";
import ChefDashboardIndex from "../pages/chef-dashboard/chef-index";
import ExploreUsers from "../pages/users/users-page";
import UserDetails from "../pages/user-page/user-page";
import ExploreMealPrepChefs from "../pages/meal-prep-explore/meal-prep-explore";
import ExploreLargeEventsChefs from "../pages/large-event-explore/large-event-explore";
import TermsAndCondition from "../pages/terms-and-condition/terms-and-condition";
import PrivacyPolicy from "../pages/privacy-policy/privacy-policy";
import AboutUs from "../pages/about-us/about-us";
import MenuPage from "../pages/menu-page/menu-page";
import CustomBookingSequence from "../pages/custom-bookings/cb";
import { MealPrepMenuPage } from "../pages/meal-prep-menu-page/meal-prep-menu-page";
import HowItWorksUser from "../pages/how-it-works-user/how-it-works";
import DirectProposals from "../pages/direct-proposal/direct-proposal";
import Cities from "../pages/cities/cities";

//blog yet to be done
import IndividualBlog from "../components/Homeblogcard/individualBlog/individualBlog";
import Blogs from "../components/Homeblogcard";
import HomeNew from "../pages/home/home_new/HomeNew";
import WaitingList from "../pages/home/home_new/waiting_list/WaitingList";
import ResetPassword from "../pages/home/home_new/resetpassword";
import PrivacyPolicyNew from "../pages/home/home_new/privacy_policy2";
import ExternalReviewChef from "../pages/home/home_new/review_chef/ExternalReviewChef";
import TermlyPolicyViewer from "../pages/sla/SlaWebsite";
import SlaNew from "../pages/home/home_new/sla";
import EulaPage from "../pages/home/home_new/eula";

const AllRoutes = function () {
  return (
    <Routes>
      <Route
        path="/:modal?"
        element={
          <Suspense fallback={<ErrorPage />}>
            {/* <HomeNew /> */}
            <WaitingList />
          </Suspense>
        }
      ></Route>
      <Route
        path="/newHome/:modal?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <Home />
          </Suspense>
        }
      ></Route>

      <Route
        path="/explore/:query?/:filterdate?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ExploreSetMenus />
          </Suspense>
        }
      ></Route>

      <Route
        path="/waitingList"
        element={
          <Suspense fallback={<ErrorPage />}>
            <WaitingList />
          </Suspense>
        }
      ></Route>

      <Route
        path="/how-it-works/chef"
        element={
          <Suspense fallback={<ErrorPage />}>
            <HowItWorks />
          </Suspense>
        }
      ></Route>

      <Route
        path="/how-it-works/user/:type?/:city?/:country?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <HowItWorksUser />
          </Suspense>
        }
      ></Route>

      <Route
        path="/cb/:query?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <CustomBookingSequence />
          </Suspense>
        }
      ></Route>

      <Route
        path="/menu/:id?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <MenuPage />
          </Suspense>
        }
      ></Route>

      <Route
        path="/meal-prep/order/:id?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <MealPrepMenuPage />
          </Suspense>
        }
      ></Route>

      <Route
        path="/user/:id?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <UserDetails />
          </Suspense>
        }
      ></Route>

      <Route
        path="/dashboard/user/:userId?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <UserDashBoardIndex />
          </Suspense>
        }
      ></Route>

      <Route
        path="/dashboard/chef/:chefId?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ChefDashboardIndex />
          </Suspense>
        }
      ></Route>

      <Route
        path="/users/:query?/:filterdate?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ExploreUsers />
          </Suspense>
        }
      ></Route>

      <Route
        path="/meal-prep/chefs/:query?/:filterdate?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ExploreMealPrepChefs />
          </Suspense>
        }
      ></Route>

      <Route
        path="/large-event/chefs/:query?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ExploreLargeEventsChefs />
          </Suspense>
        }
      ></Route>

      <Route
        path="/tac"
        element={
          <Suspense fallback={<ErrorPage />}>
            <TermsAndCondition />
          </Suspense>
        }
      ></Route>

      <Route
        path="/policy"
        element={
          <Suspense fallback={<ErrorPage />}>
            <PrivacyPolicyNew />
          </Suspense>
        }
      ></Route>

      <Route
        path="/about"
        element={
          <Suspense fallback={<ErrorPage />}>
            <AboutUs />
          </Suspense>
        }
      ></Route>

      <Route
        path="/direct-proposals/:bookingID"
        element={
          <Suspense fallback={<ErrorPage />}>
            <DirectProposals />
          </Suspense>
        }
      ></Route>

      {/* Blog yet to be done */}
      <Route
        path="/blog"
        element={
          <Suspense fallback={<ErrorPage />}>
            <Blogs />
          </Suspense>
        }
      ></Route>

      <Route
        path="booking/review"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ExternalReviewChef />
          </Suspense>
        }
      ></Route>

      <Route
        path="/reset-password"
        element={
          <Suspense fallback={<ErrorPage />}>
            <ResetPassword />
          </Suspense>
        }
      ></Route>

      <Route
        path="/legal/sla"
        element={
          <Suspense fallback={<ErrorPage />}>
            <SlaNew />
          </Suspense>
        }
      ></Route>
      <Route
        path="/legal/eula"
        element={
          <Suspense fallback={<ErrorPage />}>
            <EulaPage />
          </Suspense>
        }
      ></Route>

      <Route
        path="/blog/:slug"
        element={
          <Suspense fallback={<ErrorPage />}>
            <IndividualBlog />
          </Suspense>
        }
      ></Route>

      <Route
        path="/ikook-cities/:countryy?"
        element={
          <Suspense fallback={<ErrorPage />}>
            <Cities />
          </Suspense>
        }
      ></Route>
    </Routes>
  );
};

export default AllRoutes;
