import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import { MDBRadio, MDBBtn, MDBInput } from "mdb-react-ui-kit";
import CalendarCard from "../../../components/calender-card/calendar-card";
import AppContext from "../../../context/app-context";

const CdCalendar = function ({ display }) {
  const [activeAvailInputs, setSctiveAvailInputs] = useState("");

  const [onShowCalendarComps, setOnShowCalendarComp] = useState([
    <p key={0} className="small">
      Loading, please wait...
    </p>,
  ]);

  const [stateData, setStateData] = useState([]);

  const [searchDate, setSearchDate] = useState("");

  const [dateDate, setDateDate] = useState("");

  const [rangeFromDate, setRangeFromDate] = useState("");
  const [rangeToDate, setRangeToDate] = useState("");

  const { usersData, backendServer, setIsLoading, apiKey, popup, pyBackendServer } =
    useContext(AppContext);

  useEffect(() => {
    getBookings();
  }, [usersData]);

  async function getBookings() {
    try {
      setIsLoading(true);

      const realBookings = await Promise.all(
        usersData?.chef_details?.chef_bookings?.map(async (e) => {
          const { data } = await (
            await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get/${e}`, {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            })
          ).json();

          return data;
        })
      );

      const comp = realBookings.map((e, i) => {
        if (e?.status === "pending") return <CalendarCard data={e} key={i} type={e.booking_type} />;
        else return;
      });

      if (comp.length === 0 || realBookings.filter((ell) => ell.status === "pending").length === 0)
        setOnShowCalendarComp(
          <div className="flex-column for-no-bookings">
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">NO UPCOMING SCHEDULE</p>
            <p className="small">Tip: Create a Menu to start getting bookings!</p>
          </div>
        );
      else {
        setOnShowCalendarComp(comp);
        setStateData(realBookings.filter((e) => e?.status === "pending"));
      }

      setIsLoading(false);
    } catch {
      popup("Something went wrong...");
      setIsLoading(false);
    }
  }

  function searchForData(theDate) {
    if (!theDate) return;

    if (stateData.length === 0) return popup("Sorry, you have no bookings yet");

    const filteredData = stateData.filter((e) => {
      return e.startDate.split("+")[0].trim() === theDate;
    });

    const comp = filteredData.map((e, i) => (
      <CalendarCard data={e} key={i} type={e.booking_type} />
    ));

    if (comp.length === 0)
      setOnShowCalendarComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">No Bookings For That Date</p>
        </div>
      );
    else {
      setOnShowCalendarComp(comp);
    }
  }

  async function submitAvailabilityToPythonServer(availabilityType) {
    try {
      if (availabilityType === "date") {
        if (!dateDate) return popup("Date required");

        setIsLoading(true);

        const returned = await (
          await fetch(`${pyBackendServer}/accounts/chef/unavailability/set?type=single`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ chef: usersData._id, date: dateDate }),
          })
        ).json();

        if (returned.message !== "Successful") throw new Error();

        setIsLoading(false);

        setDateDate("");

        popup("You will not recieve bookings on this date!");
      }

      if (availabilityType === "range") {
        if (!rangeFromDate || !rangeToDate) return popup("Fill in all dates");

        setIsLoading(true);

        await fetch(`${pyBackendServer}/accounts/chef/unavailability/set?type=duration`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ chef: usersData._id, from: rangeFromDate, to: rangeToDate }),
        });

        setIsLoading(false);

        setRangeFromDate("");

        setRangeToDate("");

        popup("You will not recieve bookings on these days!");
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <div className="flex-row ud-dash-header">
        <p className="big alignTextLeft removemargin">Set Unavailability</p>

        <div
          className="flex-row align-row-left"
          style={{ gap: 10, width: "70%", justifyContent: "flex-end", paddingRight: 50 }}
        >
          <MDBInput
            value={searchDate}
            label="Search Date"
            onChange={(e) => {
              searchForData(e.target.value);
              setSearchDate(e.target.value);
            }}
            className="in avail-input"
            type="date"
          />

          <button
            onClick={() => {
              setSearchDate("");
              getBookings();
            }}
            className="themeBtn ud-btns xsmall"
          >
            REFRESH
          </button>
        </div>
      </div>

      <div className="flex-row align-row-left a-little-gap" style={{ marginBottom: 20 }}>
        <MDBRadio
          onClick={() => setSctiveAvailInputs("date")}
          defaultChecked={activeAvailInputs === "date" && true}
          name="inlineRadio"
          id="inlineRadio1"
          value="option1"
          label="Single Date"
          inline
        />

        <MDBRadio
          onClick={() => setSctiveAvailInputs("range")}
          defaultChecked={activeAvailInputs === "range" && true}
          name="inlineRadio"
          id="inlineRadio1"
          value="option2"
          label="Period of Time"
          inline
        />
      </div>

      {activeAvailInputs === "date" && (
        <div className="flex-row align-row-left a-little-gap" style={{ marginBottom: 40 }}>
          <div
            className="flex-column align-column-left"
            style={{ marginBottom: 20, width: "250px" }}
          >
            <p>Date</p>

            <input
              value={dateDate}
              onChange={(e) => {
                setDateDate(e.target.value);
              }}
              className="in avail-input"
              type="date"
            />
          </div>

          <MDBBtn onClick={() => submitAvailabilityToPythonServer("date")} color="#ffd93b">
            ADD
          </MDBBtn>
        </div>
      )}

      {activeAvailInputs === "range" && (
        <div className="flex-row align-row-left a-little-gap" style={{ marginBottom: 40 }}>
          <div
            className="flex-column align-column-left"
            style={{ marginBottom: 20, width: "250px" }}
          >
            <p>From</p>

            <input
              value={rangeFromDate}
              onChange={(e) => {
                setRangeFromDate(e.target.value);
              }}
              className="in avail-input"
              type="date"
            />
          </div>

          <div
            className="flex-column align-column-left"
            style={{ marginBottom: 20, width: "250px" }}
          >
            <p>To</p>

            <input
              value={rangeToDate}
              onChange={(e) => {
                setRangeToDate(e.target.value);
              }}
              className="in avail-input"
              type="date"
            />
          </div>

          <MDBBtn onClick={() => submitAvailabilityToPythonServer("range")} color="#ffd93b">
            ADD
          </MDBBtn>
        </div>
      )}

      <div className="flex-row ud-dash-header" style={{ marginTop: activeAvailInputs ? -20 : 30 }}>
        <p className="big alignTextLeft removemargin">Upcoming Schedule</p>

        {/* <button onClick={() => {

        }} className="themeBtn ud-btns">Hide</button> */}
      </div>

      <div className="ud-active-booking-cont">{onShowCalendarComps}</div>
    </div>
  );
};

export default CdCalendar;
