import React, { useState, useContext, useEffect } from "react";
import "../../pages/menu-page/menu-page.css";
import "../../styles/index.css";
import "../../pages/home/home.css";
import { GoVerified, GoLocation } from "react-icons/go";
import { VscUnverified } from "react-icons/vsc";
import FaqCard from "../faq-card/faq-card";
import AppContext from "../../context/app-context";
import "../../pages/user-page/user-page.css";

const UserDetailsChefInfo = function ({ activeNav, data }) {
  const [faqComp, setFaqComp] = useState([]);
  const [image, setImage] = useState("/images/user_placeholder.png");

  const { getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    if (!data) return;
    (async function () {
      setImage(await getSignedAwsUrl(data?.photo, "users"));
    })();

    const comp = data?.chef_details?.chef_faq?.map((e, i) => {
      return <FaqCard key={i} data={e} />;
    });

    setFaqComp(comp);
  }, [data]);

  if (activeNav !== "chef") return;

  if (!data) return;

  return (
    <div className="box-1-in">
      <div className="flex-column align-column-left">
        <h6 className="blackText">About {data?.username}</h6>
      </div>

      <div className="flex-row align-row-left upp-abt-chef">
        <img
          style={{ width: 300, height: 300, borderRadius: 300 }}
          src={image ? image : "/images/user_placeholder.png"}
          alt={data?.username}
        />

        <div className="review-card">
          <p className="big blackText alignTextLeft">
            {data?.username ? data?.username : ""}{" "}
            {data?.chef_details?.chef_verification_details?.is_documents_verified ? (
              <GoVerified color="green" size={15} style={{ marginTop: -10 }} />
            ) : (
              <VscUnverified color="red" size={15} style={{ marginTop: -10 }} />
            )}
          </p>

          <p className="yellowText small alignTextLeft">
            {" "}
            <GoLocation color="#FCC01C" /> &nbsp; {data?.city}, {data?.country}
          </p>

          <p className="small alignTextLeft" style={{ textAlign: "left" }}>
            {data?.chef_details?.bio}
          </p>
        </div>
      </div>

      <div
        className="flex-row align-row-left upp-extra-chef-details"
        style={{ gap: 15, width: "100%" }}
      >
        <div className="chef-details-boxes">
          <h6 className="alignTextLeft">Chef Experience</h6>

          <p className="tablesmall alignTextLeft removemargin">{data?.chef_details?.experience}</p>
        </div>

        <div className="chef-details-boxes">
          <h6 className="alignTextLeft">Cooking Style</h6>

          <p className="tablesmall alignTextLeft removemargin">
            {data?.chef_details?.style_cooking}
          </p>
        </div>
      </div>

      <div className="menu-page-nav-cont">
        <p className={`big blackText boldText`}>FAQ</p>
      </div>

      <div className="flex-row align-row-left upp-feq-list" style={{ gap: 20 }}>
        {faqComp}
      </div>
    </div>
  );
};

export default UserDetailsChefInfo;
