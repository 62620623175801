import React, { useState, useEffect, useContext } from "react";
import "../menu-page/menu-page.css";
import "../../styles/index.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import { AiTwotoneStar } from "react-icons/ai";
import MealPrepMenuDetails from "../../components/meal-prep-menu-page-comp/menu-page-menu-details";
import MealPrepMenuDetailsReviews from "../../components/meal-prep-menu-page-comp/reviews";
import MealPrepMenuDetailsChefInfo from "../../components/meal-prep-menu-page-comp/chef-info";
import { MDBTextArea } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import AppContext from "../../context/app-context";
import PaystackHook from "../../payment/paystack-hook";
import "./meal-prep-menu-page.css";
import socket from "../../socket/socket";
import StripeModal from "../../components/stripe-modal.jsx/stripe-modal";
import country from "../../context/country";

let menuDetailObjs = [];

const MealPrepMenuPage = function () {
  const [numberOfWeeksWarning, setNumberOfWeeksWarning] = useState("");
  const [addressWarning, setAddressWarning] = useState("");
  const [experienceWarning, setExperienceWarning] = useState("");
  const [eventDateWarning, setEventDateWarning] = useState("");
  const [eventTimeWarning, setEventTimeWarning] = useState("");
  const [numberOfGuestsWarning, setNumberOfGuestsWarning] = useState("");
  const [deliveryTypeWarning, setDeliveryTypeWarning] = useState("");
  const [chefAppearanceWarning, setChefAppereanceWarning] = useState("");
  const [menuWarning, setMenuWarning] = useState("");
  const [budgetPerGuestsWarning, setBudgetPerGuestsWarning] = useState("");
  const [menuThereInWarning, setMenuThereInWarning] = useState("");

  const [fullAddress, setFullAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [theUser, setTheUser] = useState({});
  const [activeNav, setActiveNav] = useState("menu");
  const [allTheUsersMenus, setAllTheUsersMenu] = useState([]);
  const [fromPrice, setFromPrice] = useState(0);
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");
  const [disableBookingBtn, setDisableBookingBtn] = useState(true);
  const [numberOfWeeks, setNumberofWeeks] = useState("");

  const [totalPerPerson, setTotalPerson] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPerWeek, setTotalPerWeek] = useState(0);
  const taxes = 0;

  const [selectedDietary, setSelectedDietary] = useState([]);
  const [messageToChef, setMessageToChef] = useState("");
  const [menusSelected, setMenusSelected] = useState([]);
  const [numberOfKids, setnumberOfKids] = useState("");
  const [numberOfAdults, setnumberOfAdults] = useState("");
  const [numberOfTeens, setnumberOfTeens] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [budgetPerPerson, setBudgetPerPerson] = useState("");
  const [experience, setExperience] = useState("");
  const [deliveryType, setDeliverType] = useState("");
  const [delieryDays, setDeliveryDays] = useState([]);

  const [menuBreakDownComps, setMenuBreakDownComps] = useState([]);
  const [menuListBreakDown, setMenuListBreakDown] = useState([]);

  const [showStripeModal, setShowStripeModal] = useState(false);

  const {
    backendServer,
    apiKey,
    setIsLoading,
    popup,
    getSignedAwsUrl,
    calculateChefRating,
    usersData,
    convertAmount,
    formatNumber,
    handleChefInactiveState,
  } = useContext(AppContext);

  const { id } = useParams();

  useEffect(() => {
    if (!theUser?.username) return;
    document.title = `iKooK - Chef ${theUser?.username || ""}`;
  }, [theUser]);

  useEffect(() => {
    calculateCost();

    if (
      fullAddress === "" ||
      city === "" ||
      menuDetailObjs.length === 0 ||
      eventStartDate === "" ||
      eventEndDate === "" ||
      eventTime === "" ||
      experience === "" ||
      deliveryType === "" ||
      delieryDays.length === 0 ||
      Number(numberOfWeeks) < 0 ||
      Number(numberOfAdults) + Number(numberOfTeens) + Number(numberOfKids) < 1 ||
      numberOfWeeks === "" ||
      Number(numberOfAdults) < 0 ||
      Number(numberOfTeens) < 0 ||
      Number(numberOfKids) < 0 ||
      Number(budgetPerPerson) < 0 ||
      !verifyTheDaysAndMenuTherein()
    )
      return setDisableBookingBtn(true);
    else return setDisableBookingBtn(false);
  });

  useEffect(() => {
    if (numberOfWeeks && Number(numberOfWeeks) >= 1) setNumberOfWeeksWarning("");
    if (fullAddress && city) setAddressWarning("");
    if (experience) setExperienceWarning("");
    if (eventStartDate && eventEndDate) setEventDateWarning("");
    if (eventTime) setEventTimeWarning("");
    if (
      Number(numberOfAdults) + Number(numberOfTeens) + Number(numberOfKids) >= 1 &&
      Number(numberOfAdults) >= 0 &&
      Number(numberOfTeens) >= 0 &&
      Number(numberOfKids) >= 0
    )
      setNumberOfGuestsWarning("");
    if (deliveryType) setDeliveryTypeWarning("");
    if (delieryDays.length > 0) setChefAppereanceWarning("");
    if (menuDetailObjs.length > 0) setMenuWarning("");
    if (Number(budgetPerPerson) >= 0) setBudgetPerGuestsWarning("");
    if (verifyTheDaysAndMenuTherein()) setMenuThereInWarning("");
  });

  useEffect(() => {
    setMenuBreakDownComps([
      <div key={1}>
        <p className="midsmall blackText alignTextLeft">- A {numberOfWeeks}-week Meal Prep order</p>

        <p className="midsmall blackText alignTextLeft">
          - {delieryDays.length} Day(s) in a week{" "}
          {delieryDays.length > 0 && `(${delieryDays.join(", ")})`}
        </p>

        <p className="midsmall blackText alignTextLeft">
          - {menuDetailObjs.length} Menu(s) per week selected
        </p>
      </div>,
    ]);

    const arrOfTheMenuName = [];

    menuDetailObjs.forEach((e) => {
      if (arrOfTheMenuName.includes(e.description)) return;
      else arrOfTheMenuName.push(e.description);
    });

    setMenuListBreakDown(
      arrOfTheMenuName.map((e, i) => (
        <p key={i} className="midsmall blackText alignTextLeft">
          {e}
        </p>
      ))
    );
  }, [menusSelected, delieryDays, numberOfWeeks]);

  function pseudoValidate() {
    if (!numberOfWeeks || Number(numberOfWeeks) < 1) {
      document.getElementById("numberOfWeeksWarningRef").scrollIntoView({ behavior: "smooth" });
      return setNumberOfWeeksWarning("number of weeks is missing or invalid");
    }

    if (!fullAddress || !city) {
      document.getElementById("addressWarningRef").scrollIntoView({ behavior: "smooth" });
      return setAddressWarning("address incomplete");
    }

    if (!experience) {
      document.getElementById("experienceWarningRef").scrollIntoView({ behavior: "smooth" });
      return setExperienceWarning("we need your experience preference");
    }

    if (!eventStartDate || !eventEndDate) {
      document.getElementById("eventDateWarningRef").scrollIntoView({ behavior: "smooth" });
      return setEventDateWarning("we need both the start and end dates");
    }

    if (!eventTime) {
      document.getElementById("eventTimeWarningRef").scrollIntoView({ behavior: "smooth" });
      return setEventTimeWarning("set an event start time");
    }

    if (
      Number(numberOfAdults) + Number(numberOfTeens) + Number(numberOfKids) < 1 ||
      Number(numberOfTeens) < 0 ||
      Number(numberOfKids) < 0 ||
      Number(numberOfAdults) < 0
    ) {
      document.getElementById("numberOfGuestsWarningRef").scrollIntoView({ behavior: "smooth" });
      return setNumberOfGuestsWarning("you must have at least 1 guest");
    }

    if (!deliveryType) {
      document.getElementById("deliveryTypeWarningRef").scrollIntoView({ behavior: "smooth" });
      return setDeliveryTypeWarning("set a delivery type");
    }

    if (delieryDays.length === 0) {
      document.getElementById("chefAppearanceWarningRef").scrollIntoView({ behavior: "smooth" });
      return setChefAppereanceWarning("select at least one appearance day");
    }

    if (menuDetailObjs.length === 0) {
      document.getElementById("menuWarningRef").scrollIntoView({ behavior: "smooth" });
      return setMenuWarning("select at least one menu (breakfast/lunch/dinner)");
    }

    if (Number(budgetPerPerson) < 0) {
      document.getElementById("budgetPerPerson").scrollIntoView({ behavior: "smooth" });
      return setBudgetPerGuestsWarning("invalid budget per guest");
    }

    if (!verifyTheDaysAndMenuTherein()) {
      document.getElementById("menuWarningRef").scrollIntoView({ behavior: "smooth" });
      return setMenuThereInWarning("select at least one menu for each day you choose");
    }
  }

  function verifyTheDaysAndMenuTherein() {
    if (menuDetailObjs.length === 0) return false;

    let go = true;

    delieryDays.forEach((e) => {
      if (!menuDetailObjs.find((el) => el?.day === e)) go = false;
    });

    return go;
  }

  function calculateCost() {
    const totalAmountOfGuests =
      Number(numberOfAdults) + Number(numberOfTeens) + Number(numberOfKids);

    let totalMenuDetailsAmount = 0;

    menuDetailObjs.forEach((e) => {
      totalMenuDetailsAmount = totalMenuDetailsAmount + e.price;
    });

    let totalAmoutToPay = totalMenuDetailsAmount * totalAmountOfGuests * numberOfWeeks + taxes;

    setTotalPerson(totalAmoutToPay / totalAmountOfGuests || 0);
    setTotal(totalAmoutToPay || 0);
    setTotalPerWeek(totalAmoutToPay / Number(numberOfWeeks) || 0);
  }

  const renderMenuDetails = async () => {
    if (delieryDays.length > 0) return document.location.reload();
    setActiveNav("menu");
  };

  const renderReviews = async () => {
    setActiveNav("reviews");
  };

  const renderChefDetails = async () => {
    setActiveNav("chef");
  };

  useEffect(() => {
    (async function () {
      try {
        const { data } = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?id=${id}`, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        setTheUser(data);

        const servicesFilteredData = data?.chef_details?.chef_menus?.filter(
          (e) => e.currency === localStorage.getItem("edition") && e.status === "approved"
        );

        setAllTheUsersMenu(servicesFilteredData);

        setChefImage(await getSignedAwsUrl(data?.photo, "users"));
      } catch {
        popup("Error Fetching Chef");
      }
    })();
  }, []);

  useEffect(() => {
    if (allTheUsersMenus.length === 0) return;

    let extracts = [];

    allTheUsersMenus.forEach((e) => {
      extracts = [...extracts, e?.menuPrice];
    });

    setFromPrice(smallestArrayValue(extracts) || 0);
  }, [allTheUsersMenus]);

  function smallestArrayValue(array) {
    if (array.length === 0) return 0;

    let smallest = array[0];

    array.forEach((e) => {
      if (e < smallest) smallest = e;
    });

    return smallest;
  }

  const smartSelectedDiary = (e) => {
    if (selectedDietary.find((el) => el === e.target.textContent)) {
      const newArr = selectedDietary.filter((el) => {
        return el !== e.target.textContent;
      });
      setSelectedDietary(newArr);
    } else {
      setSelectedDietary([...selectedDietary, e.target.textContent]);
    }

    e.target.classList.toggle("drbtn-active");
  };

  async function sendBooking(paymentreference) {
    setIsLoading(true);
    try {
      const obj = {
        user: usersData?._id,
        chef: theUser?._id,
        booking_type: "meal-prep",
        number_of_guest: Number(numberOfAdults) + Number(numberOfTeens) + Number(numberOfKids),
        dietary_restrictions: selectedDietary,
        location: {
          coordinates: [],
          addressString: `${fullAddress}, ${city}. ${country}. ${
            postalCode ? postalCode + "." : ""
          }`,
        },
        number_of_children: Number(numberOfKids),
        number_of_teens: Number(numberOfTeens),
        number_of_adults: Number(numberOfAdults),
        startDate: `${eventStartDate} + ${eventTime}`,
        endDate: `${eventEndDate}`,
        amount: total,
        booking_date: Date.now(),
        addons: [...menuDetailObjs],
        other_info: messageToChef,
        paymentRef: paymentreference,
        budget_per_person: budgetPerPerson,
        meal_prep_option: experience,
        delivery_type: deliveryType,
        delivery_days: delieryDays,
        number_of_weeks: numberOfWeeks,
        currency: theUser?.country === "UK" ? "GBP" : theUser?.country === "Canada" ? "CAD" : "NGN",
      };

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/create`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify(obj),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      socket?.emit(
        "smartNotification",
        "booking",
        `You have a new Booking from ${usersData?.first_name} ${usersData?.last_name}!`,
        theUser?.username
      );

      popup("Your booking has been created. Redirecting to your dashboard");

      setTimeout(() => {
        document.location.href = `/dashboard/${usersData.role}/${usersData._id}`;
      }, 1500);
    } catch (err) {
      popup("We are having trouble creating your Booking. Please contact support.");
      setIsLoading(false);
    }
  }

  const sortOutMenuSelected = (theMenu) => {
    if (menuDetailObjs.find((e) => e.unique_id === theMenu.unique_id)) {
      const newArr = menuDetailObjs.filter((e) => {
        return e.unique_id !== theMenu.unique_id;
      });

      menuDetailObjs = [...newArr];

      setMenusSelected([]);
    } else {
      menuDetailObjs.push(theMenu);

      setMenusSelected([]);
    }
  };

  const removeMenusByDay = (day) => {
    const newArr = menuDetailObjs.filter((e) => {
      return e.day !== day;
    });

    menuDetailObjs = [...newArr];

    setMenusSelected([]);
  };

  const successPayment = (reference) => {
    sendBooking(reference);
  };

  const closedPayment = () => {
    console.log("");
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: usersData.email,
    amount: total * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_76e5521f96453545d74e2d8b9753ac2e6ff5a1fc",
  };

  const componentProps = {
    ...config,
    text: "Book Now",
    onSuccess: (reference) => successPayment(reference),
    onClose: closedPayment,
  };

  if (!theUser) return;

  if (theUser?._id && !theUser?.is_active) return handleChefInactiveState();

  return (
    <div className="menu-page-cont">
      <div className="menu-page-menu-details meal-prep-intro mppp-meal-prep-intro">
        <div className="flex-column adjust-each-menu-details mppp-adjust-each-menu-details">
          <h4 className="small blackText boldText mppp-menu-name">
            Book {theUser?.username ? theUser?.username : ""} for Meal Prep
          </h4>

          <p className="small blackText">{theUser?.chef_details?.languages?.join(" | ")}</p>
        </div>

        <div className="flex-column adjust-each-menu-details mppp-adjust-each-menu-details">
          <p className="blackText small">Starting from</p>

          <h4 className="blackText">{convertAmount(fromPrice, theUser?.country)}</h4>
        </div>

        <div className="flex-row adjust-each-menu-details mppp-adjust-each-menu-details-edit">
          <div>
            <h5 className="big removemargin blackText boldText mppp-menu-name">
              {theUser?.username ? theUser?.username : ""}
            </h5>
            <p className="small removemargin">
              &nbsp;
              <span className="blackText">
                {<AiTwotoneStar color="#fcc01c" />}
                {calculateChefRating(theUser.chef_details?.reviews)} (
                {theUser.chef_details?.reviews.length} Reviews)
              </span>
            </p>
          </div>

          <img
            className="menu-profile-image-circle"
            src={chefImage ? chefImage : "/images/user_placeholder.png"}
            alt="iKooK Chef"
          />
        </div>
      </div>

      <div className="menu-page-main-cont mppp-menu-page-main-cont">
        <div className="box-1 mppp-box-1">
          <div className="menu-page-nav-cont mppp-menu-page-nav-cont">
            <p
              onClick={renderMenuDetails}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "menu" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Details
            </p>

            <p
              onClick={renderReviews}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "reviews" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Reviews
            </p>

            <p
              onClick={renderChefDetails}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "chef" ? "each-menu-page-nav-active" : ""
              }`}
            >
              About Chef
            </p>
          </div>

          <MealPrepMenuDetails
            setNumberofWeeks={setNumberofWeeks}
            setDeliveryDays={setDeliveryDays}
            delieryDays={delieryDays}
            setDeliverType={setDeliverType}
            setExperience={setExperience}
            setBudgetPerPerson={setBudgetPerPerson}
            setEventEndDate={setEventEndDate}
            setEventStartDate={setEventStartDate}
            setEventTime={setEventTime}
            setnumberOfAdults={setnumberOfAdults}
            setnumberOfKids={setnumberOfKids}
            setnumberOfTeens={setnumberOfTeens}
            setMenusSelected={setMenusSelected}
            allTheUsersMenus={allTheUsersMenus}
            theUser={theUser}
            activeNav={activeNav}
            fullAddress={fullAddress}
            city={city}
            postalCode={postalCode}
            setFullAddress={setFullAddress}
            setCity={setCity}
            setPostalCode={setPostalCode}
            sortOutMenuSelected={sortOutMenuSelected}
            numberOfWeeksWarning={numberOfWeeksWarning}
            addressWarning={addressWarning}
            experienceWarning={experienceWarning}
            eventDateWarning={eventDateWarning}
            eventTimeWarning={eventTimeWarning}
            numberOfGuestsWarning={numberOfGuestsWarning}
            deliveryTypeWarning={deliveryTypeWarning}
            chefAppearanceWarning={chefAppearanceWarning}
            menuWarning={menuWarning}
            budgetPerGuestsWarning={budgetPerGuestsWarning}
            menuThereInWarning={menuThereInWarning}
            removeMenusByDay={removeMenusByDay}
            numberOfKids={numberOfKids}
            numberOfAdults={numberOfAdults}
            numberOfTeens={numberOfTeens}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            eventTime={eventTime}
            budgetPerPerson={budgetPerPerson}
            numberOfWeeks={numberOfWeeks}
          />

          <MealPrepMenuDetailsChefInfo theUser={theUser} activeNav={activeNav} />

          <MealPrepMenuDetailsReviews theUser={theUser} activeNav={activeNav} />
        </div>

        <div className="box-2 mppp-box-2">
          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText`}>Booking Details</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Meal Prep Start Date</p>

              <p className="small blackText removemargin">{eventStartDate || ""}</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Guests</p>

              {Number(numberOfAdults) + Number(numberOfTeens) + Number(numberOfKids) >= 1 &&
                Number(numberOfAdults) >= 0 &&
                Number(numberOfTeens) >= 0 &&
                Number(numberOfKids) >= 0 && (
                  <p className="midsmall blackText removemargin alignTextLeft">
                    {numberOfAdults || 0} Adult(s) - {numberOfTeens || 0} Teen(s) -{" "}
                    {numberOfKids || 0} Kid(s).
                  </p>
                )}
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText bllackText`}>Menu(s)</p>
            </div>

            <div className="dietray-cont">
              <div className="flex-column align-column-left">{menuListBreakDown}</div>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText bllackText`}>Dietary Restrictions</p>
            </div>

            <div className="dietray-cont">
              <button onClick={smartSelectedDiary} className="drbtn">
                Vegetarian
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                Gluten free
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                No Shellfish
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                No Nuts
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                Dairy free
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                Halal
              </button>

              <button
                onClick={(e) => {
                  e.target.classList.toggle("drbtn-active");
                  selectedDietary.length = 0;
                }}
                className="drbtn"
              >
                None
              </button>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont" style={{ marginBottom: 10 }}>
              <p className={`small boldText bllackText`}>Message Chef</p>
            </div>

            <div className="dietray-cont">
              <MDBTextArea
                value={messageToChef}
                onChange={(el) => {
                  setMessageToChef(el.target.value);
                }}
                maxLength={400}
                cols={23}
                rows={8}
                label="Your Message"
              />
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText bllackText`}>Order Breakdown</p>
            </div>

            <div className="dietray-cont">
              <div className="flex-column align-column-left">{menuBreakDownComps}</div>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText bllackText`}>
                Order Details (
                {theUser?.country === "UK" ? "GBP" : theUser?.country === "Canada" ? "CAD" : "NGN"})
              </p>
            </div>

            <div className="flex-column align-column-left">
              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Total per person</p>

                <p className="small removemargin alignTextLeft">
                  {formatNumber(totalPerPerson) || 0}
                </p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Total per week</p>

                <p className="small removemargin alignTextLeft">
                  {formatNumber(totalPerWeek) || 0}
                </p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft boldText">Total</p>

                <p className="small removemargin alignTextLeft boldText">
                  {formatNumber(total) || 0}
                </p>
              </div>
            </div>
          </div>

          {usersData._id === theUser?._id ? (
            ""
          ) : (
            <PaystackHook
              config={componentProps}
              disabled={disableBookingBtn}
              paymentOnSuccess={successPayment}
              paymentClosed={closedPayment}
              setShowStripeModal={setShowStripeModal}
              currency={
                theUser?.country === "UK" ? "GBP" : theUser?.country === "Canada" ? "CAD" : "NGN"
              }
              pseudoValidate={pseudoValidate}
            />
          )}
        </div>
      </div>

      <ForPageActions />

      <StripeModal
        rawAmount={convertAmount(
          total,
          theUser?.country === "UK" ? "GBP" : theUser?.country === "Canada" ? "CAD" : "NGN"
        )}
        amount={total * 100}
        display={showStripeModal}
        setShowStripeModal={setShowStripeModal}
        sendBooking={sendBooking}
        currency={theUser?.country === "UK" ? "GBP" : theUser?.country === "Canada" ? "CAD" : "NGN"}
      />
    </div>
  );
};

export { MealPrepMenuPage, menuDetailObjs };
