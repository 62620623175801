import React, { useState, useRef, useContext } from "react";
import "./chefjoin.css";
import "../../styles/index.css";
import LoginModal from "../../components/auth/login/login-modal";
import AppContext from "../../context/app-context";

const Intro = () => {
  const [showLogin, setShowLogin] = useState(false);

  const loginModal = useRef(null);
  const { popup, loggedIn, edition } = useContext(AppContext);

  return (
    <div className="ch-i">
      <div className="ch-i-container">
        <div className="ch-i-top">
          <div className="ch-i-left">
            <div className="ch-i-left-wrapper">
              <div className="ch-i-title">
                <h1>Register with iKooK today</h1>
              </div>
              <div className="ch-i-desc">
                <p>
                  Join the fastest growing private chef network and explore Menus and Private Chefs
                  for your mini and large events!
                </p>
              </div>
              <div
                onClick={() => {
                  if (loggedIn) popup("You are logged In!");
                  loginModal?.current?.showLoginScreen("signup");
                  loginModal?.current?.setActiveSignupScreen("user");
                  setShowLogin(true);
                }}
                className="ch-i-links"
              >
                <button className="themeBtn">Register Now</button>
              </div>
            </div>
          </div>
          <div className="ch-i-right">
            <div className="ch-i-right-wrapper">
              <img
                src={`${
                  edition === "NGN"
                    ? "/images/Picture1A.png"
                    : "/images/happy-male-chef-cook-restaurant-kitchen-768x512.jpeg"
                }`}
                alt="ikook-chef"
                className="ch-i-img"
              />
            </div>
          </div>
        </div>
        {/* <div className='ch-i-bottom'>
                    <div className='ch-i-imgbox'>
                        <img src={Picture1B} alt='ikook-chef' className='ch-i-img' />
                    </div>
                    <div className='ch-i-bbox'>
                        <p>Rating</p>
                        <h1>5.0</h1>
                    </div>
                    <div className='ch-i-imgbox1'>
                        <img src={Picture1C} alt='ikook-chef' className='ch-i-img' />
                    </div>
                </div> */}
      </div>

      <LoginModal ref={loginModal} display={showLogin} setShowLogin={setShowLogin} />
    </div>
  );
};

export default Intro;
