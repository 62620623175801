import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import "./individualBlog.css";
import apiCall from "../functions/apiCall";
import IndividualHeader from "./individualHeader";
import Comments from "../comments/comments";
import AppContext from "../../../context/app-context";
import { Helmet } from "react-helmet";

const IndividualBlog = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState({});
  const [blogInfo, setBlogInfo] = useState({});

  const { loggedIn, usersData } = useContext(AppContext);

  useEffect(() => {
    const getBlog = async () => {
      try {
        const { data, filteredBlogData } = await apiCall(slug);
        setBlog(data);
        setBlogInfo(filteredBlogData);
      } catch (error) {
        throw error;
      }
    };
    getBlog();
  }, [loggedIn, usersData]);

  return (
    <>
      {blog?.data?.content && (
        <div className="individual-blog-container">
          <Helmet>
            <title>{blog?.data?.title ?? "Blogs"}</title>
            <meta name="title" content={blog?.data?.title ?? "Blogs"} />
            <meta
              name="description"
              content="Discover the new, easy way to
book a private chef"
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://ikook.co.uk/" />
            <meta property="og:title" content={blog?.data?.title ?? "Blogs"} />
            <meta
              property="og:description"
              content={blog?.data?.excerpt ?? "Blogs"}
            />
            <meta
              property="og:image"
              content={
                blog?.data?.picture ??
                "https://firebasestorage.googleapis.com/v0/b/trepa-mobile.appspot.com/o/StaticImages%2Fmetadata%2FScreenshot%202023-12-27%20173730.png?alt=media&token=282cdb91-a8e9-4443-b0fe-43d8d57fe4da"
              }
            />

            {/* <!-- Twitter --> */}
            <meta
              property="twitter:card"
              content={blog?.data?.title ?? "Blogs"}
            />
            <meta
              property="twitter:url"
              content={
                blog?.data?.picture ??
                "https://firebasestorage.googleapis.com/v0/b/trepa-mobile.appspot.com/o/StaticImages%2Fmetadata%2FScreenshot%202023-12-27%20173730.png?alt=media&token=282cdb91-a8e9-4443-b0fe-43d8d57fe4da"
              }
            />
            <meta
              property="twitter:title"
              content={blog?.data?.title ?? "Blogs"}
            />
            <meta
              property="twitter:description"
              content={blog?.data?.excerpt ?? "Blogs"}
            />
            <meta
              property="twitter:image"
              content={
                blog?.data?.picture ??
                "https://firebasestorage.googleapis.com/v0/b/trepa-mobile.appspot.com/o/StaticImages%2Fmetadata%2FScreenshot%202023-12-27%20173730.png?alt=media&token=282cdb91-a8e9-4443-b0fe-43d8d57fe4da"
              }
            />
          </Helmet>
          <IndividualHeader blog={blog?.data} blogInfo={blogInfo} />
          <h2 className="individual-blog-title">{blog?.data?.title}</h2>
          <div>{parse(`${blog?.data?.content}`)}</div>
          {blogInfo?.blog_tag?.length > 0 && (
            <div className="tags-container">
              <p style={{ width: "fit-content" }}>Tags: </p>
              {blogInfo?.blog_tag?.map((tag, i) => (
                <p className="tag" key={i}>
                  {tag?.name}
                </p>
              ))}
            </div>
          )}

          <Comments
            comments={blog?.data?.comments.filter((item) => item.approved)}
            id={blog?.data?.id}
          />
        </div>
      )}
    </>
  );
};

export default IndividualBlog;
