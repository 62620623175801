import React, { useState, useContext, useEffect } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../menu-page/menu-page.css";
import CMMenuDetails from "./child-components/create-menu/cm-details";
import CMImages from "./child-components/create-menu/cm-images";
import CMMAndP from "./child-components/create-menu/cm-menus";
import CMFinishCreateMenu from "./child-components/create-menu/cm-finish";
import AppContext from "../../../context/app-context";
import DessertUpload from "./child-components/create-menu/upload-cards/dessert";
import SideUpload from "./child-components/create-menu/upload-cards/side";
import MainUpload from "./child-components/create-menu/upload-cards/main";
import StarterUpload from "./child-components/create-menu/upload-cards/starter";

let boundariesRaw = {
  min_starter: {
    value: "",
    additional_charge: "",
  },
  min_main: {
    value: "",
    additional_charge: "",
  },
  min_dessert: {
    value: "",
    additional_charge: "",
  },
  min_side: {
    value: "",
    additional_charge: "",
  },
};

const dishDataBackup = {
  starterData: [],
  mainData: [],
  dessertData: [],
  sideData: [],
};

const CdCreateMenu = function ({ onView, setOnView }) {
  const [whatsWrong, setWhatsWrong] = useState("");

  const [activeNav, setActiveNav] = useState("details");

  const [hideSubmitBtn, setHideSubmitBtn] = useState(true);

  const [menuName, setMenuName] = useState("");
  const [menuPrice, setMenuPrice] = useState("");
  const [minimumNumberOfGuests, setMinimumNumberOfGuests] = useState("");
  const [menuDescription, setMenuDesciption] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [cuisineType, setCuisineType] = useState([]);

  const [starterData, setStarterData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [dessertData, setDessertData] = useState([]);
  const [sideData, setSideData] = useState([]);

  const [starterComps, setstarterComps] = useState([
    <StarterUpload key={0} setStarterData={setStarterData} starterData={starterData} />,
  ]);
  const [mainComps, setmainComps] = useState([
    <MainUpload key={0} mainData={mainData} setMainData={setMainData} />,
  ]);
  const [sideComps, setsideComps] = useState([
    <SideUpload key={0} sideData={sideData} setSideData={setSideData} />,
  ]);
  const [dessertComps, setdessertComps] = useState([
    <DessertUpload key={0} dessertData={dessertData} setDessertData={setDessertData} />,
  ]);
  const [_boundaries, setBoundaries] = useState({});

  const [menuImages, setMenuImages] = useState([]);

  const { backendServer, apiKey, popup, usersData, actOnProgressIsLoading } =
    useContext(AppContext);

  useEffect(() => {
    if (
      !menuName ||
      !menuPrice ||
      !menuDescription ||
      Number(menuPrice) < 1 ||
      menuImages.length < 5 ||
      (starterData.length === 0 &&
        mainData.length === 0 &&
        dessertData.length === 0 &&
        sideData.length === 0)
    )
      setHideSubmitBtn(true);
    else setHideSubmitBtn(false);

    if (boundariesRaw.min_side.value && Number(boundariesRaw.min_side.value) < 1)
      setHideSubmitBtn(true);

    if (
      boundariesRaw.min_side.additional_charge &&
      Number(boundariesRaw.min_side.additional_charge) < 1
    )
      setHideSubmitBtn(true);

    if (boundariesRaw.min_main.value && Number(boundariesRaw.min_main.value) < 1)
      setHideSubmitBtn(true);

    if (
      boundariesRaw.min_main.additional_charge &&
      Number(boundariesRaw.min_main.additional_charge) < 1
    )
      setHideSubmitBtn(true);

    if (boundariesRaw.min_dessert.value && Number(boundariesRaw.min_dessert.value) < 1)
      setHideSubmitBtn(true);

    if (
      boundariesRaw.min_dessert.additional_charge &&
      Number(boundariesRaw.min_dessert.additional_charge) < 1
    )
      setHideSubmitBtn(true);

    if (boundariesRaw.min_starter.value && Number(boundariesRaw.min_starter.value) < 1)
      setHideSubmitBtn(true);

    if (
      boundariesRaw.min_starter.additional_charge &&
      Number(boundariesRaw.min_starter.additional_charge) < 1
    )
      setHideSubmitBtn(true);

    if (boundariesRaw.min_side.value && sideData.length < boundariesRaw.min_side.value)
      setHideSubmitBtn(true);

    if (boundariesRaw.min_main.value && mainData.length < boundariesRaw.min_main.value)
      setHideSubmitBtn(true);

    if (boundariesRaw.min_dessert.value && dessertData.length < boundariesRaw.min_dessert.value)
      setHideSubmitBtn(true);

    if (boundariesRaw.min_starter.value && starterData.length < boundariesRaw.min_starter.value)
      setHideSubmitBtn(true);
  });

  useEffect(() => {
    if (!menuName) return setWhatsWrong("Please set a name for your menu");

    if (!menuPrice || Number(menuPrice) < 1)
      return setWhatsWrong(
        "Invalid or no price per person price set. Note: Price must be greater than or equal to one."
      );

    if (!menuDescription) return setWhatsWrong("Set a description for your menu");

    if (
      starterData.length === 0 &&
      mainData.length === 0 &&
      dessertData.length === 0 &&
      sideData.length === 0
    )
      return setWhatsWrong(
        "Please add at least one starter, or one main, or one dessert, or  one side dish..."
      );

    if (menuImages.length < 5) return setWhatsWrong("Please add at least 5 images to your menu");

    if (Number(boundariesRaw.min_starter.value) < 0)
      return setWhatsWrong("You have an invalid minimum starter value");

    if (Number(boundariesRaw.min_starter.additional_charge) < 0)
      return setWhatsWrong("You have an invalid  starter additional charge value");

    if (Number(boundariesRaw.min_main.value) < 0)
      return setWhatsWrong("You have an invalid minimum main value");

    if (Number(boundariesRaw.min_main.additional_charge) < 0)
      return setWhatsWrong("You have an invalid additional main charge value");

    if (Number(boundariesRaw.min_dessert.value) < 0)
      return setWhatsWrong("You have an invalid minimum dessert value");

    if (Number(boundariesRaw.min_dessert.additional_charge) < 0)
      return setWhatsWrong("You have an invalid  dessert additional charge value");

    if (Number(boundariesRaw.min_side.value) < 0)
      return setWhatsWrong("You have an invalid minimum side value");

    if (Number(boundariesRaw.min_side.additional_charge) < 0)
      return setWhatsWrong("You have an invalid additional side charge value");

    if (boundariesRaw.min_starter.value && starterData.length < boundariesRaw.min_starter.value)
      return setWhatsWrong(
        `You have a minimum starter dish of ${boundariesRaw.min_starter.value} and only ${starterData.length} starter dish saved`
      );

    if (boundariesRaw.min_main.value && mainData.length < boundariesRaw.min_main.value)
      return setWhatsWrong(
        `You have a minimum main dish of ${boundariesRaw.min_main.value} and only ${mainData.length} main dish saved`
      );

    if (boundariesRaw.min_dessert.value && dessertData.length < boundariesRaw.min_dessert.value)
      return setWhatsWrong(
        `You have a minimum dessert dish of ${boundariesRaw.min_dessert.value} and only ${dessertData.length} dessert dish set`
      );

    if (boundariesRaw.min_side.value && sideData.length < boundariesRaw.min_side.value)
      return setWhatsWrong(
        `You have a minimum side dish of ${boundariesRaw.min_side.value} and only ${sideData.length} side dish set`
      );
    else return setWhatsWrong("");
  });

  async function submitMenu() {
    try {
      // const obj = {
      //   chefID: usersData._id,
      //   menuName: menuName,
      //   description: menuDescription,
      //   price_per_person: menuPrice,
      //   searchtags: searchTags.join(","),
      //   cuisineTags: cuisineType.join(","),
      //   eventTags: eventTags.join(","),
      //   images: menuImages,
      //   starterData: starterData,
      //   mainData: mainData,
      //   sideData: sideData,
      //   dessertData: dessertData,
      //   boundaries: boundariesRaw
      // }
      // return console.log(obj)

      popup("Please wait, don't reload this page...");

      actOnProgressIsLoading(true, 4, "Uploading Menu Details...");

      //initial menu upload

      const formBasicDetails = new FormData();

      formBasicDetails.append("chefID", usersData._id);
      formBasicDetails.append("menuName", menuName);

      if (cuisineType.length >= 1) formBasicDetails.append("cuisineTags", cuisineType.join(","));

      if (searchTags.length >= 1) formBasicDetails.append("searchtags", searchTags.join(","));

      if (eventTags.length >= 1) formBasicDetails.append("eventTags", eventTags.join(","));

      formBasicDetails.append("description", menuDescription);
      formBasicDetails.append("menuPrice", menuPrice);

      if (minimumNumberOfGuests)
        formBasicDetails.append("minimum_number_of_guest", minimumNumberOfGuests);

      formBasicDetails.append(
        "currency",
        usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
      );
      formBasicDetails.append("images", menuImages[0]);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/add`, {
          method: "POST",
          credentials: "include",
          headers: {
            enctype: "multipart/form-data",
            token: localStorage.getItem("ikooktoken"),
          },
          body: formBasicDetails,
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const menuID = returned.data._id;

      actOnProgressIsLoading(true, 19, "Uploading Menu Images");

      //upload remaining images

      await new Promise(async (resolve, _reject) => {
        if (menuImages[1]) {
          const form = new FormData();
          form.append("image", menuImages[1]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 22, "Uploading Menu Images");
        }
        if (menuImages[2]) {
          const form = new FormData();
          form.append("image", menuImages[2]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 24, "Uploading Menu Images");
        }
        if (menuImages[3]) {
          const form = new FormData();
          form.append("image", menuImages[3]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 25, "Uploading Menu Images");
        }
        if (menuImages[4]) {
          const form = new FormData();
          form.append("image", menuImages[4]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 26, "Uploading Menu Images");
        }
        if (menuImages[5]) {
          const form = new FormData();
          form.append("image", menuImages[5]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 27, "Uploading Menu Images");
        }
        if (menuImages[6]) {
          const form = new FormData();
          form.append("image", menuImages[6]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 29, "Uploading Menu Images");
        }
        if (menuImages[7]) {
          const form = new FormData();
          form.append("image", menuImages[7]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 30, "Uploading Menu Images");
        }
        if (menuImages[8]) {
          const form = new FormData();
          form.append("image", menuImages[8]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 31, "Uploading Menu Images");
        }
        if (menuImages[9]) {
          const form = new FormData();
          form.append("image", menuImages[9]);
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          );
          actOnProgressIsLoading(true, 32, "Uploading Menu Images");
        }
        resolve();
      });

      //starter data
      if (starterData.length > 0) {
        actOnProgressIsLoading(true, 33, "Uploading Starter Menu...");
        const returnedStarter = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/v2/${menuID}/starter`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("ikooktoken"),
              },
              body: JSON.stringify(starterData),
            }
          )
        ).json();

        if (returnedStarter.status === "Internal server error")
          throw new Error(returnedStarter.message);
      }

      //main data
      if (mainData.length > 0) {
        actOnProgressIsLoading(true, 54, "Uploading Main Menu...");
        const returnedMain = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/v2/${menuID}/main`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("ikooktoken"),
              },
              body: JSON.stringify(mainData),
            }
          )
        ).json();

        if (returnedMain.status === "Internal server error") throw new Error(returnedMain.message);
      }

      //dessert data
      if (dessertData.length > 0) {
        actOnProgressIsLoading(true, 76, "Uploading Dessert Menu...");
        const returnedDessert = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/v2/${menuID}/dessert`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("ikooktoken"),
              },
              body: JSON.stringify(dessertData),
            }
          )
        ).json();

        if (returnedDessert.status === "Internal server error")
          throw new Error(returnedDessert.message);
      }

      //side data
      if (sideData.length > 0) {
        actOnProgressIsLoading(true, 84, "Uploading Side Menu...");
        const returnedSide = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/v2/${menuID}/side`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("ikooktoken"),
              },
              body: JSON.stringify(sideData),
            }
          )
        ).json();

        if (returnedSide.status === "Internal server error") throw new Error(returnedSide.message);
      }

      actOnProgressIsLoading(true, 90, "Almost done. Uploading Minor Configurations...");
      //boundaries
      const returnedBoundaries = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/v2/${menuID}/boundaries`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify(boundariesRaw),
          }
        )
      ).json();

      if (returnedBoundaries.status === "Internal server error")
        throw new Error(returnedBoundaries.message);

      actOnProgressIsLoading(true, 100, "All done.");

      popup("Congratulations, your menu has been uploaded!");
      setHideSubmitBtn(true);

      setTimeout(() => {
        document.location.reload();
      }, 2000);
    } catch (err) {
      popup("Oooops something went wrong, please try again later");
      setTimeout(() => {
        document.location.reload();
      }, 2000);
    }
  }

  if (onView !== "create menu") return;

  return (
    <div className="cd-box-1-settings" style={{ overflow: "hidden" }}>
      <div
        className="flex-row ud-dash-header"
        style={{
          justifyContent: "space-between",
          marginTop: -20,
          paddingRight: 25,
          marginBottom: 0,
        }}
      >
        <p className="big alignTextLeft removemargin">New Menu</p>

        <button id="create_chef" onClick={() => setOnView("menu")} className="themeBtn ud-btns">
          Exit
        </button>
      </div>

      <div className="menu-page-nav-cont">
        <p
          onClick={() => {
            setActiveNav("details");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "details" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Details
        </p>

        <p
          onClick={() => {
            setActiveNav("m&p");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "m&p" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Menus & Prices
        </p>

        <p
          onClick={() => {
            setActiveNav("images");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "images" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Menu Images (Minimum of 5 Images)
        </p>

        <p
          onClick={() => {
            setActiveNav("finish");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "finish" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Finish Upload
        </p>
      </div>

      <CMMenuDetails
        menuName={menuName}
        menuPrice={menuPrice}
        menuDescription={menuDescription}
        eventTags={eventTags}
        searchTags={searchTags}
        cuisineType={cuisineType}
        setMenuName={setMenuName}
        setMenuPrice={setMenuPrice}
        setMenuDesciption={setMenuDesciption}
        setEventTags={setEventTags}
        setCuisineType={setCuisineType}
        setSearchTags={setSearchTags}
        activeNav={activeNav}
        minimumNumberOfGuests={minimumNumberOfGuests}
        setMinimumNumberOfGuests={setMinimumNumberOfGuests}
      />

      <CMMAndP
        starterData={starterData}
        setStarterData={setStarterData}
        mainData={mainData}
        setMainData={setMainData}
        dessertData={dessertData}
        setDessertData={setDessertData}
        sideData={sideData}
        setSideData={setSideData}
        activeNav={activeNav}
        setBoundaries={setBoundaries}
        boundariesRaw={boundariesRaw}
        starterComps={starterComps}
        setstarterComps={setstarterComps}
        mainComps={mainComps}
        setmainComps={setmainComps}
        sideComps={sideComps}
        setsideComps={setsideComps}
        dessertComps={dessertComps}
        setdessertComps={setdessertComps}
      />

      <CMImages menuImages={menuImages} setMenuImages={setMenuImages} activeNav={activeNav} />

      <CMFinishCreateMenu
        hideSubmitBtn={hideSubmitBtn}
        activeNav={activeNav}
        submitMenu={submitMenu}
        whatsWrong={whatsWrong}
      />
    </div>
  );
};

export { CdCreateMenu, boundariesRaw, dishDataBackup };
