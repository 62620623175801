import React, { useState, useEffect, useContext } from "react";
import "../../styles/user-index.css";
import "../../../../styles/index.css";
// import { AiTwotoneStar } from "react-icons/ai";
import AddReviewModal from "../../../../components/add-a-review-modal/modal-add-a-review";
import AppContext from "../../../../context/app-context";

const TableReviewCard = function ({ data, username }) {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [chefID, setChefID] = useState("");
  const [chefName, setChefName] = useState("");

  const { emitRealDate, popup } = useContext(AppContext);

  useEffect(() => {
    setChefID(data?.chef);
    setChefName(username);
  }, [data]);

  return (
    <>
      <div className="flex-row align-row-left table-booking-card no-hover-color review-header review-table-card">
        <p className="tablesmall alignTextLeft media-hide" style={{ width: "25%" }}>
          {data?._id}
        </p>

        <p className="tablesmall alignTextLeft" style={{ width: "25%" }}>
          {username}
        </p>

        <p className="tablesmall alignTextLeft" style={{ width: "25%" }}>
          {emitRealDate(data?.booking_date)}
        </p>

        <div className="xsmall alignTextLeft" style={{ width: "25%", marginTop: -17 }}>
          <button
            onClick={() => {
              if (data.status !== "completed") return popup(`This is a ${data.status} booking.`);
              setShowReviewModal(true);
            }}
            className="themeBtn responsiveBtn media-review-btn"
          >
            Write Review
          </button>

          {/* {reviewed
            ?
            <div className="rating-row">
              <AiTwotoneStar size={20} color={rating > 0 ? "yellow" : "grey"} />
              <AiTwotoneStar size={20} color={rating > 1 ? "yellow" : "grey"} />
              <AiTwotoneStar size={20} color={rating > 2 ? "yellow" : "grey"} />
              <AiTwotoneStar size={20} color={rating > 3 ? "yellow" : "grey"} />
              <AiTwotoneStar size={20} color={rating > 4 ? "yellow" : "grey"} />
            </div>
            :
            <button onClick={() => { setShowReviewModal(true) }} className="themeBtn responsiveBtn">Write Review</button>
          } */}
        </div>
      </div>

      <AddReviewModal
        data={data}
        chefName={chefName}
        chefID={chefID}
        display={showReviewModal}
        setShowReviewModal={setShowReviewModal}
      />
    </>
  );
};

export default TableReviewCard;
