import React from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageNine = function ({ currPage, setCurrPage }) {
  if (currPage !== 9) return;

  return (
    <div className="pages-cont" data-page="9">
      <img style={{ width: 80 }} src="/images/kitchen.png" alt="iKook chef" />

      <h4>Now, tell us about kitchen configuration</h4>

      <p className="small">We only need a few details 😎</p>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={false}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageNine;
