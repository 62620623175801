import React, { useState } from 'react';

const IkookStarRating = ({ onRatingChange }) => {
  const [rating, setRating] = useState(0);

  const handleClick = (index) => {
    setRating(index + 1);
    onRatingChange(index + 1);
  };

  const renderStar = (index) => {
    return (
      <svg
        key={index}
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => handleClick(index)}
        style={{ cursor: 'pointer' }}
      >
        <path
          d={index < rating
            ? "M11.5734 1.39057C12.0224 0.00860596 13.9776 0.00861001 14.4266 1.39058L16.2555 7.01925C16.4563 7.63729 17.0322 8.05573 17.682 8.05573H23.6004C25.0535 8.05573 25.6576 9.91515 24.4821 10.7693L19.694 14.248C19.1683 14.6299 18.9483 15.307 19.1491 15.925L20.978 21.5537C21.427 22.9357 19.8453 24.0848 18.6697 23.2307L13.8817 19.752C13.3559 19.3701 12.6441 19.3701 12.1183 19.752L7.33028 23.2307C6.15471 24.0849 4.57299 22.9357 5.02202 21.5537L6.85089 15.925C7.0517 15.307 6.83171 14.6299 6.30598 14.248L1.51794 10.7693C0.34237 9.91515 0.946536 8.05573 2.39962 8.05573H8.31796C8.9678 8.05573 9.54374 7.63729 9.74455 7.01925L11.5734 1.39057Z"
            : "M6.89377 13.439L6.30598 14.248L6.89377 13.4389L2.10573 9.96023C1.71387 9.67554 1.91525 9.05573 2.39962 9.05573H8.31796C9.40103 9.05573 10.3609 8.35833 10.6956 7.32827L12.5245 1.69959C12.6741 1.23894 13.3258 1.23893 13.4755 1.69959L15.3044 7.32827C15.6391 8.35833 16.599 9.05573 17.682 9.05573H23.6004C24.0847 9.05573 24.2861 9.67553 23.8943 9.96024L19.1062 13.439C18.23 14.0756 17.8634 15.204 18.1981 16.234L20.0269 21.8627C20.1766 22.3234 19.6494 22.7064 19.2575 22.4217L14.4695 18.943C13.5932 18.3064 12.4068 18.3064 11.5305 18.943L6.7425 22.4217C6.35065 22.7064 5.8234 22.3234 5.97308 21.8627L7.80194 16.234C8.13663 15.204 7.76999 14.0756 6.89377 13.439Z"
          }
          stroke={index < rating ? "none" : "#FCC01C"}
          fill={index < rating ? "#FCC01C" : "none"}
          strokeWidth="2"
        />
      </svg>
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      {[...Array(5)].map((_, index) => renderStar(index))}
    </div>
  );
};

export default IkookStarRating;
