import React from "react";
import "./communication-guideline.css";
import "../../styles/index.css";
import { TbAlertOctagon } from "react-icons/tb";
import "../lightening-list/lightening-list.css";

const CommunicationGuideLines = () => {
  return (
    <div className="communicatoionGuidelineCont">
      <div
        className="flex-row align-row-left"
        style={{ justifyContent: "flex-start", gap: 15, padding: 0 }}
      >
        <TbAlertOctagon color="#f8d134" size={30} />

        <p style={{ marginTop: 15 }} className="small boldText">
          Communcation Guidelines
        </p>
      </div>

      <div className="l-lightening-title" style={{ marginLeft: 40, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">
          As stated in our{" "}
          <span>
            <a href="/tac" target="_blank" title="iKooK Terms of Service">
              terms of service
            </a>
          </span>
          , sharing of personal contact details, email and/or phone number, is not allowed.
        </p>
      </div>

      <div className="l-lightening-title" style={{ marginLeft: 40, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">Keep communications professional.</p>
      </div>
    </div>
  );
};

export default CommunicationGuideLines;
