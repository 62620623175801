import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import CDChatBox from "../components/chef-chat-box";
import AppContext from "../../../context/app-context";
import CDChatHistoryCard from "../components/chef-history-card";
import socket from "../../../socket/socket";
import CommunicationGuideLines from "../../../components/communication-guideline/communication-guideline";

let whereMsgAre = document.querySelector("#chef-dashboard-where-messages-are");

const CdMessages = function ({ display }) {
  const [revealChatHistory, setRevealChatHistory] = useState(true);
  const [activeChat, setActiveChat] = useState(null);

  const [chatHistoryComps, setChatHistoryComps] = useState([]);

  const { usersData, backgroundUpdateUsersData } = useContext(AppContext);

  function mediaShowMessages() {
    whereMsgAre = document.querySelector("#chef-dashboard-where-messages-are");

    document.querySelector("#chef-dashboard-history-cont")?.classList?.add("media-hide");
    whereMsgAre?.classList?.remove("media-hide");
  }

  function mediaShowHistory() {
    whereMsgAre = document.querySelector("#chef-dashboard-where-messages-are");

    document.querySelector("#chef-dashboard-history-cont")?.classList?.remove("media-hide");
    whereMsgAre.classList?.add("media-hide");
  }

  function renderChatHistory() {
    const initArr = usersData?.rooms?.sort((a, b) => {
      if (
        a?.messages[a?.messages?.length - 1]?.createdAt >
        b?.messages[b?.messages?.length - 1]?.createdAt
      )
        return -1;
      else return 1;
    });

    const comp = initArr.map((e, i) => (
      <CDChatHistoryCard
        mediaShowMessages={mediaShowMessages}
        setActiveChat={setActiveChat}
        key={i}
        data={e}
        unread={usersData.unReadRooms.find((el) => el === e._id)}
      />
    ));

    setChatHistoryComps(comp);
  }

  useEffect(() => {
    if (!display) setActiveChat(null);
  }, [display]);

  useEffect(() => {
    renderChatHistory();
  }, [usersData]);

  useEffect(() => {
    socket.removeAllListeners("notifyAppend");

    socket?.on("notifyAppend", async (_type, _message, toId, _messageObj) => {
      if (activeChat?.firstUser !== toId && activeChat?.secondUser !== toId)
        backgroundUpdateUsersData();

      if (!activeChat) backgroundUpdateUsersData();
    });
  }, [activeChat]);

  if (!display) return;

  return (
    <div className="ud-messages-cont media-ud-messages-cont">
      <div className="ud-chat-history-cont" id="chef-dashboard-history-cont">
        {/* <div className="ud-in-cont" style={{ width: "100%", padding: 10 }}>

          <input style={{ width: "100%" }} className="ud-in" type="text" placeholder="Search User" />

          <BiSearch size={25} style={{ marginLeft: -45, zIndex: 1000, opacity: 0.7, cursor: "pointer" }} />

        </div> */}

        <div className="flex-row ud-history-header">
          <p className="small alignTextLeft removemargin">All Messages</p>

          {revealChatHistory ? (
            <AiOutlineCaretDown
              className="cursor"
              onClick={() => setRevealChatHistory(!revealChatHistory)}
              size={15}
            />
          ) : (
            <AiOutlineCaretUp
              className="cursor"
              onClick={() => setRevealChatHistory(!revealChatHistory)}
              size={15}
            />
          )}
        </div>

        <div
          className={`${revealChatHistory ? "" : "hide"} ${
            navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500
              ? "media-chat-history-itself-cont"
              : ""
          }`}
        >
          {chatHistoryComps}
        </div>
      </div>

      {activeChat ? (
        <CDChatBox
          mediaShowHistory={mediaShowHistory}
          mediaShowMessages={mediaShowMessages}
          whereMsgAre={whereMsgAre}
          activeChat={activeChat}
        />
      ) : (
        <div
          className="is-empty-cont media-hide"
          style={{
            width: "70%",
            height: "460px",
          }}
        >
          <img src="/images/no-booking.png" alt="ikook" style={{ width: 70, height: 90 }} />

          <p className="small boldText">Real Time Messaging</p>

          <p className="small">Your Chat Appear Here!</p>

          <CommunicationGuideLines />
        </div>
      )}
    </div>
  );
};

export default CdMessages;
