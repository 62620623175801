import React, { useState } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../menu-page/menu-page.css";
import FAQ from "../../How-It-Works/FAQ";
import { questions } from "../../How-It-Works/questionsList";
import SupportModal from "../../../components/support-modal/support-modal";

const CdSupport = function ({ display }) {
  const [showSupportModal, setShowSupportModal] = useState(false);

  if (!display) return;

  return (
    <div className="cd-box-1-settings">
      <div onClick={() => setShowSupportModal(true)} className="flex-column help-div cursor">
        <div style={{ width: "100%", textAlign: "left" }}>
          <p className="border-bottom small boldText">SEND US A MESSAGE</p>
        </div>

        <div className="flex-row align-row-left flex-gap">
          <img src="/images/hd-support.png" alt="ikook support" style={{ width: 50, height: 50 }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <p className="small boldText removemargin">iKooK Support</p>
            <p className="small greyText removemargin">Hi there, how can I help you today?</p>
          </div>
        </div>
      </div>

      <div style={{ width: "90%", paddingBottom: 40, marginTop: 20 }}>
        {questions.map((question, i) => {
          if (i >= 8) return;
          return <FAQ key={question.id} {...question} />;
        })}
      </div>

      <SupportModal display={showSupportModal} setShowSupportModal={setShowSupportModal} />
    </div>
  );
};

export default CdSupport;
