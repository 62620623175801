import React, { useState, useEffect, useContext } from "react";
import "../../../menu-page/menu-page.css";
import "../../../../styles/index.css";
import { GoLocation } from "react-icons/go";
import AppContext from "../../../../context/app-context";

const BookingPageUserDetails = function ({ activeNav, data }) {
  const [theUserImage, setTheUserImage] = useState("/images/user_placeholder.png");
  const [theUser, setTheUser] = useState("");

  const { emitRealDate, getSignedAwsUrl, backendServer, apiKey, setIsLoading } =
    useContext(AppContext);

  useEffect(() => {
    (async () => {
      if (!data) return;

      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data.user._id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setTheUser(returned.data);

      if (data.user.photo) setTheUserImage(await getSignedAwsUrl(data?.user?.photo, "users"));
    })();
  }, [data]);

  if (activeNav !== "profile") return;

  return (
    <div className="box-1-in">
      <div className="menu-page-nav-cont">
        <p className={`big blackText boldText`}>About {data?.user?.first_name}</p>
      </div>

      <div className="flex-row align-row-left" style={{ alignItems: "flex-start", gap: 10 }}>
        <img
          style={{ width: 150, height: 150, borderRadius: 150 }}
          src={theUserImage}
          alt={data?.user?.first_name}
        />

        <div className="review-card">
          <p className="big blackText">{data?.user?.first_name + ` ${data?.user?.last_name}`}</p>

          <p className="yellowText small">
            {" "}
            <GoLocation color="#FCC01C" /> &nbsp; {theUser?.geo_location?.addressString}
          </p>

          <p className="xsmall">Joined: {emitRealDate(theUser?.date_joined)}</p>

          <p className="xsmall">Last Active: {emitRealDate(theUser.last_login)}</p>

          <p className="xsmall">Stat: {theUser?.user_bookings?.length} Booking(s)</p>
        </div>
      </div>
    </div>
  );
};

export default BookingPageUserDetails;
