import React from "react";
import "../communication-guideline/communication-guideline.css";
import "../../styles/index.css";
import { TbAlertOctagon } from "react-icons/tb";
import "../lightening-list/lightening-list.css";

const ChefRequirements = () => {
  return (
    <div
      className="communicatoionGuidelineCont"
      style={{ marginBottom: 30, marginLeft: 0, width: "100%" }}
    >
      <div
        className="flex-row align-row-left"
        style={{ justifyContent: "flex-start", gap: 15, padding: 0 }}
      >
        <TbAlertOctagon color="#f8d134" size={30} />

        <p style={{ marginTop: 15 }} className="small boldText">
          CHEF REQUIREMENTS
        </p>
      </div>

      <p className="small alignTextLeft cmgl-text">
        Note: We require you to have the following as a minimum requirement before applying:
      </p>

      <div className="l-lightening-title" style={{ marginLeft: 20, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">A Valid ID</p>
      </div>

      <div className="l-lightening-title" style={{ marginLeft: 20, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">
          Certified Level 2 food hygiene certification
        </p>
      </div>

      <p className="small alignTextLeft cmgl-text">
        It would help if you are friendly and professional. Our reputation is built on the
        reliability of our professionals, and we guarantee our clients your attendance. We'll need
        you to be punctual, reliable, and trustworthy.
      </p>
    </div>
  );
};

export default ChefRequirements;
