import React from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../styles/index.css";
import "../../../../../components/auth/login/login-modal.css";
import { MDBBtn } from "mdb-react-ui-kit";
import { TbAlertOctagon } from "react-icons/tb";

const CMFinishCreateMenu = function ({ activeNav, hideSubmitBtn, submitMenu, whatsWrong }) {
  if (activeNav !== "finish") return;

  return (
    <div className="cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll" }}>
      <p className="small boldText removemargin" style={{ fontStyle: "italic" }}>
        NOTE: iKooK will deduct 15% commission on the total amount of each booking.
      </p>

      <p className="small removemargin alignTextLeft">
        Price includes: Your time, Travel Expenses, The Cooking, The ingredients, The Service and
        The cleaning.
      </p>

      <p className="small">
        By clicking submit, you agree to our{" "}
        <span>
          <a href="/tac" target="_blank">
            Terms of Service.
          </a>
        </span>
      </p>

      <MDBBtn
        disabled={hideSubmitBtn}
        style={{ width: "90%", cursor: "pointer", backgroundColor: "#fcc01c", color: "black" }}
        onClick={submitMenu}
      >
        Submit Menu
      </MDBBtn>

      {whatsWrong && (
        <p className="small yellowText alignTextLeft" style={{ textAlign: "left" }}>
          <TbAlertOctagon color="#fcc01c" size={20} /> {whatsWrong}
        </p>
      )}
    </div>
  );
};

export default CMFinishCreateMenu;
