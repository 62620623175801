import React, { useState, useContext, useRef, useEffect } from "react";
import "./cb.css";
import "../../styles/index.css";
import CustomBookingNavIndicators from "./nav";
import PageOne from "./pages/page-1";
import { AiFillBackward } from "react-icons/ai";
import PageTwo from "./pages/page-2";
import PageThree from "./pages/page-3";
import PageFour from "./pages/page-4";
import PageFive from "./pages/page-5";
import PageSix from "./pages/page-6";
import PageSeven from "./pages/page-7";
import PageEight from "./pages/page-8";
import PageNine from "./pages/page-9";
import PageTen from "./pages/page-10";
import PageEleven from "./pages/page-11";
import PageTwelve from "./pages/page-12";
import PageThirteen from "./pages/page-13";
import PageFourteen from "./pages/page-14";
import PageFifteen from "./pages/page-15";
import PageSixteen from "./pages/page-16";
import AppContext from "../../context/app-context";
import { useParams } from "react-router-dom";
import country from "../../context/country";

const CustomBookingSequence = function () {
  const { query } = useParams();

  const [currPage, setCurrPage] = useState(3);

  const [fetchLocation, setFetchLocation] = useState("");

  const [bookingType, setBookingTypeMain] = useState([]);

  const setBookingType = (text) => {
    if (bookingType.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = bookingType.filter((item) => item !== text);
      setBookingTypeMain(updatedList);
      console.log(`Text "${text}" removed from the list. ${bookingType}`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...bookingType, text];
      setBookingTypeMain(updatedList);
      console.log(bookingType);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  const [mealPrepOption, setMealPrepOption] = useState("");

  const [eventType, setEventTypeMain] = useState([]);

  const setEventType = (text) => {
    if (eventType.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = eventType.filter((item) => item !== text);
      setEventTypeMain(updatedList);
      console.log(`Text "${text}" removed from the list.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...eventType, text];
      setEventTypeMain(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  const [numOfChildren, setNumOfChildren] = useState("0");

  const [numOfTeens, setNumOfTeens] = useState("0");

  const [numOfAdult, setNumOfAdult] = useState("0");

  const [meal, setMealMain] = useState([]);
  const setMeal = (text) => {
    if (meal.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = meal.filter((item) => item !== text);
      setMealMain(updatedList);
      console.log(`Text "${text}" removed from the list. ${meal}`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...meal, text];
      setMealMain(updatedList);
      console.log(`Text "${text}" added to the list.`);
      console.log(meal);
    }
  };

  const [cuisine, setCuisine2] = useState([]);
  const setCuisine = (text) => {
    if (cuisine.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = cuisine.filter((item) => item !== text);
      setCuisine2(updatedList);
      console.log(`Text "${text}" removed from the list ${cuisine}.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...cuisine, text];
      setCuisine2(updatedList);
      console.log(cuisine);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  const [stoveType, setStoveType2] = useState([]);
  const setStoveType = (text) => {
    if (stoveType.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = stoveType.filter((item) => item !== text);
      setStoveType2(updatedList);
      console.log(`Text "${text}" removed from the list. ${stoveType}`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...stoveType, text];
      setStoveType2(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  const [numberOfStoves, setNumberOfStoves] = useState("");

  const [haveOven, setHaveOven] = useState("");

  const [eventName, setEventName] = useState("");

  const [eventDate, setEventDate] = useState("");

  const [eventTime, setEventTime] = useState(``);

  const [eventEndDate, setEventEndDate] = useState("");

  const [eventEndTime, setEventEndTime] = useState(``);

  const [dietary, setDietary] = useState([]);

  const [dietaryMessage, setDietaryMessage] = useState("");

  const [messageToChef, setMessageToChef] = useState("");

  const [customBookingCity, setCustomBookingCity] = useState("");

  const top = useRef(null);

  const { backendServer, apiKey, setIsLoading, popup, usersData } =
    useContext(AppContext);

  const submitBooking = async function () {
    if (
      !fetchLocation ||
      !eventDate ||
      !eventTime ||
      !eventName ||
      haveOven === "" ||
      !numberOfStoves ||
      !stoveType ||
      !cuisine ||
      !meal ||
      !numOfAdult ||
      !numOfTeens ||
      !numOfChildren ||
      !eventType ||
      !mealPrepOption ||
      !bookingType
    )
      return popup("Something Went Wrong");

    setIsLoading(true);

    try {
      const obj = {
        user: usersData._id,
        booking_type: "custom-booking",
        custom_booking_type_selected: bookingType.join(", "),
        number_of_guest:
          Number(numOfAdult) + Number(numOfTeens) + Number(numOfChildren),
        cuisines: cuisine.map((e) => ({
          name: e,
        })),
        dietary_restrictions: [...dietary],
        location: {
          addressString: fetchLocation,
        },
        number_of_children: Number(numOfChildren),
        number_of_teens: Number(numOfTeens),
        number_of_adults: Number(numOfAdult),
        eventDetailsForLargeEvents: {
          eventType: eventType.join(", "),
          eventName: eventName,
        },
        meal_prep_option: mealPrepOption,
        other_info: messageToChef,
        stoveType: stoveType.join(", "),
        haveOven: haveOven,
        meal: meal,
        startDate: `${eventDate} + ${eventTime}`,
        endDate: ` ${eventEndTime}`,
        numberOfStove: numberOfStoves,
        dietary_restrictions_message: dietaryMessage,
        custom_booking_country: country,
        custom_booking_city: customBookingCity,
      };

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/create`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify(obj),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error")
        throw new Error(returned.message);

      setCurrPage(17);

      setTimeout(() => {
        document.location.href = `/dashboard/${usersData.role}/${usersData._id}`;
      }, 2000);

      setIsLoading(false);
    } catch (err) {
      popup("We are having trouble creating your custom booking.");
      setIsLoading(false);
    }
  };

  function goToTop() {
    top.current.scrollIntoView({
      behaviour: "smooth",
    });
  }

  useEffect(() => {
    goToTop();
    if (currPage == 2) {
      setCurrPage(3);
    }
  }, [currPage]);

  useEffect(() => {
    if (
      document.location.pathname === "/cb/le" ||
      document.location.pathname === "/cb/le/"
    )
      document.title = "iKooK - Large Events";
    if (
      document.location.pathname === "/cb" ||
      document.location.pathname === "/cb/"
    )
      document.title = "iKooK - Custom Bookings";
  }, []);

  return (
    <div className="cb-cont min-h-screen">
      <div ref={top} style={{ height: 10 }}></div>

      <CustomBookingNavIndicators page={currPage} />

      <div className="cb-back-cont">
        <button
          onClick={() => {
            if (currPage === 1) return;
            if (currPage === 3) return;
            else setCurrPage(currPage - 1);
          }}
          className="themeBtn cb-back"
        >
          <AiFillBackward />
        </button>
      </div>

      {/* <PageOne currPage={currPage} setCurrPage={setCurrPage} /> */}

      <PageThree
        query={query}
        setBookingType={setBookingType}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageFour
        setMealPrepOption={setMealPrepOption}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageFive
        setEventType={setEventType}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageSix
        setNumOfChildren={setNumOfChildren}
        setNumOfAdult={setNumOfAdult}
        setNumOfTeens={setNumOfTeens}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageSeven
        setMeal={setMeal}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageEight
        setCuisine={setCuisine}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageNine currPage={currPage} setCurrPage={setCurrPage} />

      <PageTen
        setStoveType={setStoveType}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageEleven
        setNumberOfStoves={setNumberOfStoves}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageTwelve
        setHaveOven={setHaveOven}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageThirteen
        setEventEndTime={setEventEndTime}
        setEventTime={setEventTime}
        setEventName={setEventName}
        setEventDate={setEventDate}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      <PageFourteen
        setDietaryMessage={setDietaryMessage}
        setDietary={setDietary}
        currPage={currPage}
        setCurrPage={setCurrPage}
      />

      {currPage === 15 ? (
        <PageTwo
          setFetchLocation={setFetchLocation}
          currPage={currPage}
          setCurrPage={setCurrPage}
          setCustomBookingCity={setCustomBookingCity}
        />
      ) : (
        ""
      )}

      {currPage == 16 && (
        <PageFifteen
          submitBooking={submitBooking}
          setMessageToChef={setMessageToChef}
          currPage={currPage}
          setCurrPage={setCurrPage}
        />
      )}

      {currPage == 17 && (
        <PageSixteen currPage={currPage} setCurrPage={setCurrPage} />
      )}
    </div>
  );
};

export default CustomBookingSequence;
