import React, { useEffect, useContext } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../styles/index.css";
import DessertUpload from "./upload-cards/dessert";
import SideUpload from "./upload-cards/side";
import MainUpload from "./upload-cards/main";
import StarterUpload from "./upload-cards/starter";
import CreateMenuPrompt from "../../../../../components/create-menu-prompt/create-menu-prompt";
import { MDBInput } from "mdb-react-ui-kit";
import {
  MenuAdditionalChargeWhatIsThis,
  MenuBounderyNumberOfMenuWhatIsThis,
} from "../../../../../components/what-is-this-collections/what-is-this-collections";
import { boundariesRaw } from "../../../components/chef-create-menu";
import AppContext from "../../../../../context/app-context";

const CMMAndP = function ({
  activeNav,
  starterData,
  setStarterData,
  mainData,
  setMainData,
  dessertData,
  setDessertData,
  sideData,
  setSideData,
  setBoundaries,
  starterComps,
  setstarterComps,
  mainComps,
  setmainComps,
  sideComps,
  setsideComps,
  dessertComps,
  setdessertComps,
}) {
  const { usersData } = useContext(AppContext);

  useEffect(() => {
    if (starterData.length !== 0)
      setstarterComps(
        starterData.map((e, i) => (
          <StarterUpload
            key={i}
            setStarterData={setStarterData}
            starterData={starterData}
            data={e}
          />
        ))
      );

    if (mainData.length !== 0)
      setmainComps(
        mainData.map((e, i) => (
          <MainUpload key={i} setMainData={setMainData} mainData={mainData} data={e} />
        ))
      );

    if (dessertData.length !== 0)
      setdessertComps(
        dessertData.map((e, i) => (
          <DessertUpload
            key={i}
            setDessertData={setDessertData}
            dessertData={dessertData}
            data={e}
          />
        ))
      );

    if (sideData.length !== 0)
      setsideComps(
        sideData.map((e, i) => (
          <SideUpload key={i} setSideData={setSideData} sideData={sideData} data={e} />
        ))
      );
  }, [activeNav]);

  if (activeNav !== "m&p") return;

  return (
    <div className="cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll" }}>
      <CreateMenuPrompt />

      {starterComps}

      <button
        onClick={() =>
          setstarterComps([
            ...starterComps,
            <StarterUpload
              key={starterComps.length}
              setStarterData={setStarterData}
              starterData={starterData}
            />,
          ])
        }
        style={{ padding: 10 }}
        className={`themeBtn small ${starterComps.length >= 5 ? "hide" : ""}`}
      >
        Add Another Starter
      </button>

      <div className="flex-row align-row-left flex-gap">
        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Starter"} />
          <MDBInput
            value={boundariesRaw?.min_starter?.value}
            onChange={(e) => {
              boundariesRaw.min_starter.value = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Minimum Number of Starter"
            type="number"
            min="1"
          />
        </div>

        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuAdditionalChargeWhatIsThis
            title={`Additional Starter Charge (${
              usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
            })`}
          />
          <MDBInput
            value={boundariesRaw?.min_starter?.additional_charge}
            onChange={(e) => {
              boundariesRaw.min_starter.additional_charge = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Additional Starter Charge"
            type="number"
            min="1"
          />
        </div>
      </div>

      {mainComps}

      <button
        onClick={() =>
          setmainComps([
            ...mainComps,
            <MainUpload key={mainComps.length} mainData={mainData} setMainData={setMainData} />,
          ])
        }
        style={{ padding: 10 }}
        className={`themeBtn small ${mainComps.length >= 5 ? "hide" : ""}`}
      >
        Add Another Main
      </button>

      <div className="flex-row align-row-left flex-gap">
        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Main"} />
          <MDBInput
            value={boundariesRaw.min_main.value}
            onChange={(e) => {
              boundariesRaw.min_main.value = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Minimum Number of Main"
            type="number"
            min="1"
          />
        </div>

        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuAdditionalChargeWhatIsThis
            title={`Additional Main Charge (${
              usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
            })`}
          />
          <MDBInput
            value={boundariesRaw.min_main.additional_charge}
            onChange={(e) => {
              boundariesRaw.min_main.additional_charge = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Additional Main Charge"
            type="number"
            min="1"
          />
        </div>
      </div>

      {dessertComps}

      <button
        onClick={() =>
          setdessertComps([
            ...dessertComps,
            <DessertUpload
              key={dessertComps.length}
              dessertData={dessertData}
              setDessertData={setDessertData}
            />,
          ])
        }
        style={{ padding: 10 }}
        className={`themeBtn small ${dessertComps.length >= 5 ? "hide" : ""}`}
      >
        Add Another Dessert
      </button>

      <div className="flex-row align-row-left flex-gap">
        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Dessert"} />
          <MDBInput
            value={boundariesRaw?.min_dessert?.value}
            onChange={(e) => {
              boundariesRaw.min_dessert.value = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Minimum Number of Dessert"
            type="number"
            min="1"
          />
        </div>

        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuAdditionalChargeWhatIsThis
            title={`Additional Dessert Charge (${
              usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
            })`}
          />
          <MDBInput
            value={boundariesRaw?.min_dessert?.additional_charge}
            onChange={(e) => {
              boundariesRaw.min_dessert.additional_charge = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Additional Dessert Charge"
            type="number"
            min="1"
          />
        </div>
      </div>

      {sideComps}

      <button
        onClick={() =>
          setsideComps([
            ...sideComps,
            <SideUpload key={sideComps.length} sideData={sideData} setSideData={setSideData} />,
          ])
        }
        style={{ padding: 10 }}
        className={`themeBtn small ${sideComps.length >= 5 ? "hide" : ""}`}
      >
        Add Another Side
      </button>

      <div className="flex-row align-row-left flex-gap">
        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Side"} />
          <MDBInput
            value={boundariesRaw?.min_side?.value}
            onChange={(e) => {
              boundariesRaw.min_side.value = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Minimum Number of Side"
            type="number"
            min="1"
          />
        </div>

        <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
          <MenuAdditionalChargeWhatIsThis
            title={`Additional Side Charge (${
              usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
            })`}
          />
          <MDBInput
            value={boundariesRaw?.min_side?.additional_charge}
            onChange={(e) => {
              boundariesRaw.min_side.additional_charge = e.target.value;
              setBoundaries({});
            }}
            style={{ width: "335px" }}
            className="ac-inputs"
            label="Additional Side Charge"
            type="number"
            min="1"
          />
        </div>
      </div>
    </div>
  );
};

export default CMMAndP;
