const ukreviews = [
  {
    id: 1,
    name: "Richard F.",
    ratings: 5,
    review: `What a beautiful experience! I never loved pasta, but now after eating the chef's 3 course meal (paradise in italy), I've changed my mind. It was worth it.`,
  },
  {
    id: 2,
    name: "Serena",
    ratings: 4,
    review: `Chef Errol was great. He was friendly, efficient & just really made the experience enjoyable from start to finish, so I would definitely recommend him!`,
  },
  {
    id: 3,
    name: "Tanesha",
    ratings: 5,
    review: `The food and service was excellent. I was very impressed, I'd totally recommend Ikook. Excellent way to find professional chefs you need for your occassion.`,
  },
  {
    id: 4,
    name: "Samantha D.",
    ratings: 4,
    review: `Very good service and quick work. Thank you team Ikook; the chef was so good with the cooking, and all my guests loved the food. Would love to make more bookings.`,
  },
  {
    id: 5,
    name: "Israel Essien",
    ratings: 5,
    review: `Ikook sure gives you the best private cooking experience one could ever imagine. I am giving them 4-stars
        because I expect them to expand to other parts of the world.`,
  },
];

const ngreviews = [
  {
    id: 1,
    name: "Temilade I",
    ratings: 5,
    review: `The chef was professional, taking into account my dietary preferences. It was a lovely experience, I’m definitely trying it again.`,
  },
  {
    id: 2,
    name: "Rasheed ",
    ratings: 4,
    review: `Impressive service, The attention to detail and the presentation of each dish was good.I had a great time with my friends.`,
  },
  {
    id: 3,
    name: "Gloria E",
    ratings: 5,
    review: `The booking process was smooth and the chef didn’t disappoint, He prepared such a wonderful meal. Thank you iKooK.`,
  },
  {
    id: 4,
    name: "Ada",
    ratings: 4,
    review: `The chef I hired was an absolute delight, and the meal he prepared exceeded my expectations.Flavors and Tastes were outstanding.`,
  },
  {
    id: 5,
    name: "Joshua O",
    ratings: 5,
    review: `I decided to try ikook even though I had doubts but it turned out to be worth it. The chef prepared a four course meal that left all my guests impressed.`,
  },
];

export { ukreviews, ngreviews };
