import React, { useState, useEffect, useContext } from "react";
import "./menu-page.css";
import "../../styles/index.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import {
  AiTwotoneStar,
  AiOutlineCaretUp,
  AiOutlineCaretDown,
  AiFillEye,
  AiOutlineEye,
} from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import MenuDetails from "../../components/menu-page-comp/menu-page-menu-details.jsx";
import MenuDetailsChefInfo from "../../components/menu-page-comp/chef-info";
import MenuDetailsReviews from "../../components/menu-page-comp/reviews";
import AddOnCard from "../../components/add-on-card/add-on-card";
import { MDBTextArea } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import AppContext from "../../context/app-context";
import PaystackHook from "../../payment/paystack-hook";
import socket from "../../socket/socket";
import StripeModal from "../../components/stripe-modal.jsx/stripe-modal";
import { usePlacesWidget } from "react-google-autocomplete";
import restrictCountry from "../../context/country-restrictions";
import Carousel from "../../components/menu-page-comp/carousel/carousel";

let menuDetailObjs = [];
let addOnDetailsObjs = [];
let bookingDetailsRaw = {
  location: "",
  date: "",
  time: "",
  guests: {
    adult: "0",
    teens: "0",
    kids: "0",
  },
  city: "",
  address: "",
  postal_code: "",
};

const MenuPage = function () {
  const [mainImages, setMainImages] = useState([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [openEye, setOpenEye] = useState(false);

  const [platterWarning, setPlatterWarning] = useState("");
  const [menuDetailsWarning, setMenuDetailsWarning] = useState("");
  const [locationWarning, setLocationWarning] = useState("");
  const [dateWarning, setDateWarning] = useState("");
  const [timeWarning, setTimeWarning] = useState("");
  const [guestsWarning, setGuestsWarning] = useState("");

  //dropdowns states
  const [revealDietary, setRevealDietary] = useState(false);
  const [revealGuestsSettings, setRevealGuestsSettings] = useState(false);
  const [revealLocationSettings, setRevealLocationSetting] = useState(false);

  const [activeNav, setActiveNav] = useState("menu");
  const [menuData, setMenuData] = useState(null);
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");
  const [menuDetailsSelected, setMenuDetailsSelected] = useState([]);
  const [_addOnSelected, setSddOnSelected] = useState([]);
  const [pTagMenus, setPTagsMenu] = useState([]);
  const [pTagAddons, setPTagsAddOns] = useState([]);
  const [addOnComps, setAddonComps] = useState([]);

  const [thePlatterSelected, setThePlatterSelected] = useState("");

  const [messageToChef, setMessageToChef] = useState("");

  const [selectedDietary, setSelectedDietary] = useState([]);

  const [showStripeModal, setShowStripeModal] = useState(false);

  const [_stateBookingDetails, setStateBookingDetails] = useState({});

  const {
    backendServer,
    apiKey,
    setIsLoading,
    popup,
    getSignedAwsUrl,
    calculateChefRating,
    usersData,
    convertAmount,
    edition,
    googleMapsApiKey,
    pyBackendServer,
    formatNumber,
    handleChefInactiveState,
  } = useContext(AppContext);

  const { id } = useParams();

  let country = `${edition === "NGN" ? "Nigeria" : edition === "GBP" ? "UK" : "Canada"}`;

  //amount calculation
  const [totalPerPerson, setTotalPerPerson] = useState(0);
  const [total, setTotal] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [totalMinusTax, setTotalMinusTax] = useState(0);

  const [disableBookingBtn, setDisableBookingBtn] = useState(true);

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      bookingDetailsRaw.location = place?.formatted_address;
      setStateBookingDetails({ ...bookingDetailsRaw });

      bookingDetailsRaw.address = place?.formatted_address;
    },
    options: {
      componentRestrictions: { country: restrictCountry },
      types: ["address"],
    },
  });

  useEffect(() => {
    if (!menuData?.menuName) return;
    document.title = `iKooK - ${menuData?.menuName || ""}`;
  }, [menuData]);

  const finalizeAndCalculateOrderCost = () => {
    if (
      bookingDetailsRaw.date === "" ||
      bookingDetailsRaw.time === "" ||
      menuDetailObjs.length === 0 ||
      !thePlatterSelected ||
      Number(bookingDetailsRaw.guests.adult) +
        Number(bookingDetailsRaw.guests.teens) +
        Number(bookingDetailsRaw.guests.kids) <
        1 ||
      (menuData?.minimum_number_of_guest &&
        Number(bookingDetailsRaw.guests.adult) +
          Number(bookingDetailsRaw.guests.teens) +
          Number(bookingDetailsRaw.guests.kids) <
          Number(menuData?.minimum_number_of_guest)) ||
      bookingDetailsRaw.city === "" ||
      bookingDetailsRaw.address === "" ||
      Number(bookingDetailsRaw.guests.adult) < 0 ||
      Number(bookingDetailsRaw.guests.teens) < 0 ||
      Number(bookingDetailsRaw.guests.kids) < 0 ||
      (menuData?.boundaries?.min_starter?.value &&
        menuDetailObjs.filter((e) => e?.type === "Starter").length <
          menuData?.boundaries?.min_starter?.value) ||
      (menuData?.boundaries?.min_main?.value &&
        menuDetailObjs.filter((e) => e?.type === "Main").length <
          menuData?.boundaries?.min_main?.value) ||
      (menuData?.boundaries?.min_side?.value &&
        menuDetailObjs.filter((e) => e?.type === "Side").length <
          menuData?.boundaries?.min_side?.value) ||
      (menuData?.boundaries?.min_dessert?.value &&
        menuDetailObjs.filter((e) => e?.type === "Dessert").length <
          menuData?.boundaries?.min_dessert?.value) ||
      totalPerPerson < Number(menuData?.menuPrice)
    ) {
      setDisableBookingBtn(true);
    }

    const totalAmountOfGuests =
      Number(bookingDetailsRaw.guests.adult) +
      Number(bookingDetailsRaw.guests.teens) +
      Number(bookingDetailsRaw.guests.kids);

    let totalMenuDetailsAmount = menuData?.menuPrice;

    // no more menu details price abeg
    // menuDetailObjs.forEach((e) => {
    //   totalMenuDetailsAmount = totalMenuDetailsAmount + e.price
    // })

    let totalAddonService = 0;

    addOnDetailsObjs.forEach((e) => {
      totalAddonService = totalAddonService + e.price;
    });

    let tax = Math.ceil(
      (2.5 / 100) * ((totalMenuDetailsAmount + totalAddonService) * totalAmountOfGuests)
    );

    let totalAmoutToPay = (totalMenuDetailsAmount + totalAddonService) * totalAmountOfGuests + tax;

    let totalPerPesonn = totalMenuDetailsAmount + totalAddonService;

    if (
      menuData?.boundaries?.min_starter?.value &&
      menuData?.boundaries?.min_starter?.additional_charge &&
      menuDetailObjs.filter((e) => e?.type === "Starter").length >
        Number(menuData?.boundaries?.min_starter?.value)
    )
      totalAmoutToPay =
        totalAmoutToPay + Number(menuData?.boundaries?.min_starter?.additional_charge);

    if (
      menuData?.boundaries?.min_main?.value &&
      menuData?.boundaries?.min_main?.additional_charge &&
      menuDetailObjs.filter((e) => e?.type === "Main").length >
        Number(menuData?.boundaries?.min_main?.value)
    )
      totalAmoutToPay = totalAmoutToPay + Number(menuData?.boundaries?.min_main?.additional_charge);

    if (
      menuData?.boundaries?.min_dessert?.value &&
      menuData?.boundaries?.min_dessert?.additional_charge &&
      menuDetailObjs.filter((e) => e?.type === "Dessert").length >
        Number(menuData?.boundaries?.min_dessert?.value)
    )
      totalAmoutToPay =
        totalAmoutToPay + Number(menuData?.boundaries?.min_dessert?.additional_charge);

    if (
      menuData?.boundaries?.min_side?.value &&
      menuData?.boundaries?.min_side?.additional_charge &&
      menuDetailObjs.filter((e) => e?.type === "Side").length >
        Number(menuData?.boundaries?.min_side?.value)
    )
      totalAmoutToPay = totalAmoutToPay + Number(menuData?.boundaries?.min_side?.additional_charge);

    setTotalPerPerson(totalPerPesonn || 0);
    setTotal(totalAmoutToPay || 0);
    setPlatformFee(tax || 0);
    setTotalMinusTax(totalAmoutToPay - tax || 0);

    if (
      bookingDetailsRaw.date === "" ||
      bookingDetailsRaw.time === "" ||
      menuDetailObjs.length === 0 ||
      !thePlatterSelected ||
      Number(bookingDetailsRaw.guests.adult) +
        Number(bookingDetailsRaw.guests.teens) +
        Number(bookingDetailsRaw.guests.kids) <
        1 ||
      (menuData?.minimum_number_of_guest &&
        Number(bookingDetailsRaw.guests.adult) +
          Number(bookingDetailsRaw.guests.teens) +
          Number(bookingDetailsRaw.guests.kids) <
          Number(menuData?.minimum_number_of_guest)) ||
      bookingDetailsRaw.city === "" ||
      bookingDetailsRaw.address === "" ||
      Number(bookingDetailsRaw.guests.adult) < 0 ||
      Number(bookingDetailsRaw.guests.teens) < 0 ||
      Number(bookingDetailsRaw.guests.kids) < 0 ||
      (menuData?.boundaries?.min_starter?.value &&
        menuDetailObjs.filter((e) => e?.type === "Starter").length <
          menuData?.boundaries?.min_starter?.value) ||
      (menuData?.boundaries?.min_main?.value &&
        menuDetailObjs.filter((e) => e?.type === "Main").length <
          menuData?.boundaries?.min_main?.value) ||
      (menuData?.boundaries?.min_side?.value &&
        menuDetailObjs.filter((e) => e?.type === "Side").length <
          menuData?.boundaries?.min_side?.value) ||
      (menuData?.boundaries?.min_dessert?.value &&
        menuDetailObjs.filter((e) => e?.type === "Dessert").length <
          menuData?.boundaries?.min_dessert?.value) ||
      totalPerPerson < Number(menuData?.menuPrice)
    ) {
      setDisableBookingBtn(true);
    } else setDisableBookingBtn(false);
  };

  const fetchMenu = async function () {
    try {
      setIsLoading(true);
      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setMenuData(data);

      setIsLoading(false);

      setChefImage(await getSignedAwsUrl(data.chefID.photo, "users"));
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMenu();
    return () => setMenuData({});
  }, []);

  async function getAddons() {
    return setAddonComps([]);

    try {
      const data = await (
        await fetch(
          `${pyBackendServer}/accounts/addons/menu_addons/fetch?currency=${menuData?.currency}`
        )
      ).json();

      if (!data[0]?.id) throw new Error();

      const comp = data?.map((e, i) => {
        return (
          <AddOnCard
            finalizeAndCalculateOrderCost={finalizeAndCalculateOrderCost}
            addOnDetailsObjs={addOnDetailsObjs}
            setSddOnSelected={setSddOnSelected}
            key={i}
            data={e}
            setPTagsAddOns={setPTagsAddOns}
          />
        );
      });

      comp.reverse();

      setAddonComps(comp.slice(0, 3));
    } catch (err) {
      setAddonComps([]);
    }
  }

  useEffect(() => {
    if (menuData?.currency) getAddons();
  }, [menuData]);

  useEffect(() => {
    if (!menuData?.images) return;

    (async function () {
      const allimages = await Promise.all(
        menuData.images.map(async (e) => await getSignedAwsUrl(e, "menus"))
      );

      setMainImages([...allimages]);
    })();
  }, [menuData]);

  const renderMenuDetails = async () => {
    setActiveNav("menu");
  };

  const renderReviews = async () => {
    setActiveNav("reviews");
  };

  const renderChefDetails = async () => {
    setActiveNav("chef");
  };

  const smartSelectedDiary = (e) => {
    if (selectedDietary.find((el) => el === e.target.textContent)) {
      const newArr = selectedDietary.filter((el) => {
        return el !== e.target.textContent;
      });
      setSelectedDietary(newArr);
    } else {
      setSelectedDietary([...selectedDietary, e.target.textContent]);
    }

    e.target.classList.toggle("drbtn-active");
  };

  useEffect(() => {
    finalizeAndCalculateOrderCost();
  });

  async function sendBooking(paymentreference) {
    setIsLoading(true);
    try {
      const obj = {
        user: usersData?._id,
        menuID: menuData?._id,
        chef: menuData?.chefID._id,
        booking_type: "chef-at-home",
        number_of_guest:
          Number(bookingDetailsRaw?.guests?.adult) +
          Number(bookingDetailsRaw?.guests?.teens) +
          Number(bookingDetailsRaw?.guests?.kids),
        dietary_restrictions: selectedDietary,
        location: {
          coordinates: [],
          addressString: `${bookingDetailsRaw.address}, ${bookingDetailsRaw.city}. ${country}. ${
            bookingDetailsRaw.postal_code ? bookingDetailsRaw.postal_code + "." : ""
          }`,
        },
        number_of_children: Number(bookingDetailsRaw?.guests?.kids),
        number_of_teens: Number(bookingDetailsRaw?.guests?.teens),
        number_of_adults: Number(bookingDetailsRaw?.guests?.adult),
        startDate: `${bookingDetailsRaw?.date} + ${bookingDetailsRaw?.time}`,
        booking_option: thePlatterSelected,
        amount: totalMinusTax,
        booking_date: Date.now(),
        addons: [...menuDetailObjs, ...addOnDetailsObjs],
        other_info: messageToChef,
        paymentRef: paymentreference,
        currency: menuData?.currency,
      };

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/create`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify(obj),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      socket?.emit(
        "smartNotification",
        "booking",
        `You have a new Booking from ${usersData.first_name} ${usersData.last_name}!`,
        menuData?.chefID?.username
      );

      popup("Your booking has been created. Redirecting to your dashboard");

      setTimeout(() => {
        document.location.href = `/dashboard/${usersData.role}/${usersData._id}`;
      }, 1500);
    } catch (err) {
      popup("We are having trouble creating your Booking. Please contact support.");
      setIsLoading(false);
    }
  }

  const successPayment = (reference) => {
    sendBooking(reference);
  };

  const closedPayment = () => {
    console.log("");
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: usersData?.email,
    amount: total * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_76e5521f96453545d74e2d8b9753ac2e6ff5a1fc",
  };

  const componentProps = {
    ...config,
    text: "Book Now",
    onSuccess: (reference) => successPayment(reference),
    onClose: closedPayment,
  };

  useEffect(() => {
    if (thePlatterSelected) setPlatterWarning("");

    if (menuDetailObjs.length > 0) setMenuDetailsWarning("");

    if (bookingDetailsRaw.city && bookingDetailsRaw.address) setLocationWarning("");

    if (bookingDetailsRaw.date) setDateWarning("");

    if (bookingDetailsRaw.time) setTimeWarning("");

    if (
      !menuData?.minimum_number_of_guest &&
      Number(bookingDetailsRaw.guests.adult) +
        Number(bookingDetailsRaw.guests.teens) +
        Number(bookingDetailsRaw.guests.kids) >=
        1
    )
      setGuestsWarning("");

    if (
      menuData?.minimum_number_of_guest &&
      Number(bookingDetailsRaw.guests.adult) +
        Number(bookingDetailsRaw.guests.teens) +
        Number(bookingDetailsRaw.guests.kids) >=
        Number(menuData?.minimum_number_of_guest)
    )
      setGuestsWarning("");
  });

  function pseudoValidate() {
    if (!thePlatterSelected) {
      document.getElementById("platterWarningRef").scrollIntoView({ behavior: "smooth" });
      return setPlatterWarning("select a Menu type");
    }

    if (menuDetailObjs.length === 0) {
      document.getElementById("menuDetailsWarningRef").scrollIntoView({ behavior: "smooth" });
      return setMenuDetailsWarning("select from either the starter, main, side, or dessert menu");
    }

    if (bookingDetailsRaw.city === "" || bookingDetailsRaw.address === "") {
      document.getElementById("locationWarningRef").scrollIntoView({ behavior: "smooth" });
      return setLocationWarning("address incomplete");
    }

    if (bookingDetailsRaw.date === "") {
      document.getElementById("dateWarningRef").scrollIntoView({ behavior: "smooth" });
      return setDateWarning("set an event date");
    }

    if (bookingDetailsRaw.time === "") {
      document.getElementById("timeWarningRef").scrollIntoView({ behavior: "smooth" });
      return setTimeWarning("set an event time");
    }

    if (
      menuData?.minimum_number_of_guest &&
      Number(bookingDetailsRaw.guests.adult) +
        Number(bookingDetailsRaw.guests.teens) +
        Number(bookingDetailsRaw.guests.kids) <
        Number(menuData?.minimum_number_of_guest)
    ) {
      document.getElementById("guestsWarningRef").scrollIntoView({ behavior: "smooth" });
      return setGuestsWarning(`guests must be at least ${menuData?.minimum_number_of_guest}`);
    }

    if (
      !menuData?.minimum_number_of_guest &&
      Number(bookingDetailsRaw.guests.adult) +
        Number(bookingDetailsRaw.guests.teens) +
        Number(bookingDetailsRaw.guests.kids) <
        1
    ) {
      document.getElementById("guestsWarningRef").scrollIntoView({ behavior: "smooth" });
      return setGuestsWarning("guests must be at least one");
    }

    if (totalPerPerson < Number(menuData?.menuPrice))
      return popup(
        `The minimum price per person for this menu is ${convertAmount(
          menuData?.menuPrice,
          menuData?.currency
        )}`
      );

    if (
      menuData?.boundaries?.min_starter?.value &&
      menuDetailObjs.filter((e) => e?.type === "Starter").length <
        menuData?.boundaries?.min_starter?.value
    )
      return popup(`Minimum number of starter menu is ${menuData?.boundaries?.min_starter?.value}`);

    if (
      menuData?.boundaries?.min_main?.value &&
      menuDetailObjs.filter((e) => e?.type === "Main").length <
        menuData?.boundaries?.min_main?.value
    )
      return popup(`Minimum number of main menu is ${menuData?.boundaries?.min_main?.value}`);

    if (
      menuData?.boundaries?.min_side?.value &&
      menuDetailObjs.filter((e) => e?.type === "Side").length <
        menuData?.boundaries?.min_side?.value
    )
      return popup(`Minimum number of side menu is ${menuData?.boundaries?.min_side?.value}`);

    if (
      menuData?.boundaries?.min_dessert?.value &&
      menuDetailObjs.filter((e) => e?.type === "Dessert").length <
        menuData?.boundaries?.min_dessert?.value
    )
      return popup(`Minimum number of dessert menu is ${menuData?.boundaries?.min_dessert?.value}`);
  }

  if (menuData?.chefID && !menuData?.chefID?.is_active) return handleChefInactiveState();

  return (
    <div className="menu-page-cont">
      <button className="bck-bnt" onClick={() => window.close()}>
        <IoIosArrowBack /> Back
      </button>

      <div
        className="menu-page-image-big-cont"
        onMouseEnter={() => setOpenEye(true)}
        onMouseLeave={() => setOpenEye(false)}
      >
        <Carousel
          images={mainImages}
          menuData={menuData}
          showCarousel={showCarousel}
          setShowCarousel={setShowCarousel}
        />

        <div className="flex-row small vaimgs-cont">
          <p
            onClick={() => setShowCarousel(!showCarousel)}
            className="small whiteText removemargin"
            title="Expand Images"
          >
            {" "}
            {openEye ? (
              <AiOutlineEye style={{ marginTop: -2 }} size={20} color="white" />
            ) : (
              <AiFillEye style={{ marginTop: -2 }} size={20} color="white" />
            )}{" "}
            View all images
          </p>
        </div>
      </div>

      <div className="menu-page-menu-details mpp-menu-page-menu-details">
        <div className="flex-column adjust-each-menu-details mpp-adjust-each-menu-details">
          <h4 className="blackText mpp-menu-name">{menuData?.menuName}</h4>

          <p className="small blackText">{menuData?.cuisineTags?.join(" | ")}</p>
        </div>

        <div className="flex-column adjust-each-menu-details mpp-adjust-each-menu-details">
          <p className="blackText xsmall removemargin">Per Person</p>

          <h4 className="blackText removemargin">
            {convertAmount(menuData?.menuPrice, menuData?.currency)}
          </h4>

          <p className="blackText xsmall" style={{ textAlign: "right" }}>
            {(function () {
              let count = 0;
              if (menuData?.menustarter?.length > 0) count++;
              if (menuData?.menumain?.length > 0) count++;
              if (menuData?.menuside?.length > 0) count++;
              if (menuData?.menudessert?.length > 0) count++;
              return count;
            })() || "-"}{" "}
            Course
          </p>
        </div>

        <div className="flex-row adjust-each-menu-details mpp-adjust-each-menu-details-edit">
          <img
            className="menu-profile-image-circle mpp-menu-profile-image-circle"
            src={chefImage ? chefImage : "/images/user_placeholder.png"}
            alt=""
          />
          <div className="mpp-menu-details-p-cont">
            <h5 className="small blackText boldText">
              {menuData?.chefID?.username ? menuData?.chefID?.username : ""}
            </h5>
            <p className="small blackText">
              <span>
                <HiLocationMarker color="#fcc01c" size={15} />
                {menuData?.chefID?.city}
              </span>
              &nbsp; &nbsp;
              <span>
                {<AiTwotoneStar color="#fcc01c" />}
                {calculateChefRating(menuData?.chefID?.chef_details?.reviews)} (
                {menuData?.chefID?.chef_details?.reviews.length} Reviews)
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="menu-page-main-cont media-menu-page-main-cont">
        <div className="box-1 mpp-box-1">
          <div className="menu-page-nav-cont mpp-menu-page-nav-cont">
            <p
              onClick={renderMenuDetails}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "menu" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Menu
            </p>

            <p
              onClick={renderReviews}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "reviews" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Reviews
            </p>

            <p
              onClick={renderChefDetails}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "chef" ? "each-menu-page-nav-active" : ""
              }`}
            >
              About Chef
            </p>
          </div>

          <MenuDetails
            platterWarning={platterWarning}
            menuDetailsWarning={menuDetailsWarning}
            setThePlatterSelected={setThePlatterSelected}
            setPTagsMenu={setPTagsMenu}
            menuDetailObjs={menuDetailObjs}
            menuDetailsSelected={menuDetailsSelected}
            menuData={menuData}
            setMenuDetailsSelected={setMenuDetailsSelected}
            activeNav={activeNav}
          />

          <MenuDetailsChefInfo menuData={menuData} activeNav={activeNav} />

          <MenuDetailsReviews menuData={menuData} activeNav={activeNav} />
        </div>

        <div className="box-2 mpp-box-2">
          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText blackText`}>Booking Details</p>
            </div>

            <p id="locationWarningRef" className="small removemargin alignTextLeft">
              Event Location
            </p>

            <div className="flex-column align-column-left">
              <div
                className="flex-row booking-inputs in booking-inputs-init removemargin cursor"
                style={{
                  justifyContent: "space-between",
                  width: "90%",
                  marginBottom: 0,
                  borderWidth: 1,
                }}
                onClick={() => setRevealLocationSetting(!revealLocationSettings)}
              >
                <p className="small removemargin">Location Details</p>

                {revealLocationSettings ? (
                  <AiOutlineCaretUp className="cursor" size={15} />
                ) : (
                  <AiOutlineCaretDown className="cursor" size={15} />
                )}
              </div>

              <div
                className={`${!revealLocationSettings ? "menu-page-hide" : ""}`}
                style={{ width: "100%", marginTop: 10, marginBottom: -20 }}
              >
                <input
                  ref={ref}
                  onChange={(e) => {
                    setStateBookingDetails({ ...bookingDetailsRaw });
                    bookingDetailsRaw.address = e.target.value;
                  }}
                  className="booking-inputs in booking-inputs-init no-border force-yellow-border"
                  placeholder="Full Address"
                  type="text"
                  style={{
                    marginBottom: 10,
                    borderBottomColor: "#FCC01C",
                    borderBottomWidth: 1,
                    borderWidth: 1,
                  }}
                />

                <input
                  onChange={(e) => {
                    setStateBookingDetails({ ...bookingDetailsRaw });
                    bookingDetailsRaw.city = e.target.value;
                  }}
                  className="booking-inputs in booking-inputs-init no-border"
                  placeholder="City/State"
                  type="text"
                  style={{
                    marginBottom: 10,
                    borderBottomColor: "#FCC01C",
                    borderBottomWidth: 1,
                    borderWidth: 1,
                  }}
                />

                <input
                  onChange={(e) => {
                    setStateBookingDetails({ ...bookingDetailsRaw });
                    bookingDetailsRaw.postal_code = e.target.value;
                  }}
                  className="booking-inputs in booking-inputs-init no-border"
                  placeholder="Postal Code"
                  type="text"
                  style={{
                    marginBottom: 10,
                    borderBottomColor: "#FCC01C",
                    borderBottomWidth: 1,
                    borderWidth: 1,
                  }}
                />

                <select
                  onChange={(e) => {}}
                  className="booking-inputs in booking-inputs-init no-border"
                  defaultValue={`${
                    edition === "NGN" ? "Nigeria" : edition === "GBP" ? "UK" : "Canada"
                  }`}
                  disabled={true}
                  style={{ borderColor: "#FCC01C", borderBottomWidth: 1, borderWidth: 1 }}
                >
                  <option value="Nigeria">Nigeria</option>
                  <option value="UK">United Kingdom</option>
                  <option value="Canada">Canada</option>
                </select>
              </div>

              <p className="small redText removemargin alignTextLeft">{locationWarning}</p>
            </div>

            <p id="dateWarningRef" className="small removemargin alignTextLeft">
              Event Date
            </p>

            <input
              onChange={(e) => {
                bookingDetailsRaw.date = e.target.value;
                setStateBookingDetails({ ...bookingDetailsRaw });
              }}
              className="booking-inputs in booking-inputs-init removemargin"
              placeholder="Event Date"
              type="date"
              style={{ borderWidth: 1 }}
            />

            <p style={{ marginTop: -40 }} className="small redText removemargin alignTextLeft">
              {dateWarning}
            </p>

            <p id="timeWarningRef" className="small removemargin alignTextLeft">
              Event Time
            </p>

            <input
              onChange={(e) => {
                bookingDetailsRaw.time = e.target.value;
                setStateBookingDetails({ ...bookingDetailsRaw });
              }}
              className="booking-inputs in booking-inputs-init removemargin"
              placeholder="Event Time"
              type="time"
              style={{ borderWidth: 1 }}
            />

            <p style={{ marginTop: -40 }} className="small redText removemargin alignTextLeft">
              {timeWarning}
            </p>

            <p id="guestsWarningRef" className="small removemargin alignTextLeft">
              Guests
            </p>

            <div className="flex-column align-column-left">
              <div
                className="flex-row booking-inputs in booking-inputs-init removemargin cursor"
                style={{
                  justifyContent: "space-between",
                  width: "90%",
                  marginBottom: 0,
                  borderWidth: 1,
                }}
                onClick={() => setRevealGuestsSettings(!revealGuestsSettings)}
              >
                <p className="small removemargin">Number of Guests</p>

                {revealGuestsSettings ? (
                  <AiOutlineCaretUp className="cursor" size={15} />
                ) : (
                  <AiOutlineCaretDown className="cursor" size={15} />
                )}
              </div>

              <div
                className={`${!revealGuestsSettings ? "menu-page-hide" : ""}`}
                style={{ width: "100%", marginTop: 10 }}
              >
                <input
                  min="0"
                  onChange={(e) => {
                    bookingDetailsRaw.guests.kids = e.target.value;
                    setStateBookingDetails({ ...bookingDetailsRaw });
                  }}
                  className="booking-inputs in booking-inputs-init no-border"
                  placeholder="Number of Kids"
                  type="number"
                  style={{
                    marginBottom: 10,
                    borderBottomColor: "#FCC01C",
                    borderBottomWidth: 1,
                    borderWidth: 1,
                  }}
                />

                <input
                  min="0"
                  onChange={(e) => {
                    bookingDetailsRaw.guests.teens = e.target.value;
                    setStateBookingDetails({ ...bookingDetailsRaw });
                  }}
                  className="booking-inputs in booking-inputs-init no-border"
                  placeholder="Number of Teens"
                  type="number"
                  style={{
                    marginBottom: 10,
                    borderBottomColor: "#FCC01C",
                    borderBottomWidth: 1,
                    borderWidth: 1,
                  }}
                />

                <input
                  min="0"
                  onChange={(e) => {
                    bookingDetailsRaw.guests.adult = e.target.value;
                    setStateBookingDetails({ ...bookingDetailsRaw });
                  }}
                  className="booking-inputs in booking-inputs-init no-border"
                  placeholder="Number of Adults"
                  type="number"
                  style={{
                    marginBottom: 10,
                    borderBottomColor: "#FCC01C",
                    borderBottomWidth: 1,
                    borderWidth: 1,
                  }}
                />
              </div>

              <p className="small redText removemargin alignTextLeft">{guestsWarning}</p>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText blackText`}>Menu Details</p>
            </div>

            <div className="flex-column align-column-left">{pTagMenus}</div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText blackText`}>Add-On Services</p>
            </div>

            <div className="flex-column align-column-left">{pTagAddons}</div>
          </div>

          <div className="box-2-booking-details">
            <div
              className="menu-page-nav-cont flex-row cursor"
              style={{ alignItems: "center", justifyContent: "space-between" }}
              onClick={() => setRevealDietary(!revealDietary)}
            >
              <p className={`small boldText blackText removemargin`}>Dietary Restrictions</p>

              {revealDietary ? (
                <AiOutlineCaretUp className="cursor" size={15} />
              ) : (
                <AiOutlineCaretDown className="cursor" size={15} />
              )}
            </div>

            <div className={`dietray-cont ${!revealDietary ? "menu-page-hide" : ""}`}>
              <button onClick={smartSelectedDiary} className="drbtn">
                Vegetarian
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                Gluten free
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                No Shellfish
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                No Nuts
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                Dairy free
              </button>

              <button onClick={smartSelectedDiary} className="drbtn">
                Halal
              </button>

              <button
                onClick={(e) => {
                  e.target.classList.toggle("drbtn-active");
                  selectedDietary.length = 0;
                }}
                className="drbtn"
              >
                None
              </button>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont" style={{ marginBottom: 10 }}>
              <p className={`small boldText blackText`}>Message Chef</p>
            </div>

            <div className="dietray-cont">
              <MDBTextArea
                onChange={(el) => {
                  setMessageToChef(el.target.value);
                }}
                maxLength={400}
                cols={23}
                rows={8}
                label="Your Message"
              />
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText blackText`}>Order Details ({menuData?.currency})</p>
            </div>

            <div className="flex-column align-column-left">
              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Total per person</p>

                <p className="small removemargin alignTextLeft">
                  {formatNumber(totalPerPerson) || 0}
                </p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Platform Fee</p>

                <p className="small removemargin alignTextLeft">{formatNumber(platformFee) || 0}</p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Subtotal</p>

                <p className="small removemargin alignTextLeft">
                  {formatNumber(total - platformFee) || 0}
                </p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft boldText">Total</p>

                <p className="small removemargin alignTextLeft boldText">
                  {formatNumber(total) || 0}
                </p>
              </div>
            </div>
          </div>

          {usersData?._id === menuData?.chefID?._id ? (
            ""
          ) : (
            <PaystackHook
              config={componentProps}
              disabled={disableBookingBtn}
              paymentOnSuccess={successPayment}
              paymentClosed={closedPayment}
              setShowStripeModal={setShowStripeModal}
              currency={menuData?.currency}
              pseudoValidate={pseudoValidate}
            />
          )}
        </div>
      </div>

      {addOnComps?.length !== 0 ? (
        <>
          <h4 style={{ marginTop: 40, marginLeft: 40 }}>Order Add-Ons</h4>
          <div className="flex-row add-on-services-menu-page">{addOnComps}</div>
        </>
      ) : (
        ""
      )}

      <ForPageActions />

      <StripeModal
        currency={menuData?.currency}
        rawAmount={convertAmount(total, menuData?.currency)}
        amount={total * 100}
        display={showStripeModal}
        setShowStripeModal={setShowStripeModal}
        sendBooking={sendBooking}
      />
    </div>
  );
};

export default MenuPage;
