import React from "react";
import "../../pages/menu-page/menu-page.css";
import "../../styles/index.css";
import { AiTwotoneStar } from "react-icons/ai";
import "../../pages/home/home.css";

const ReviewCard = function ({ data }) {
  return (
    <div className="review-card" style={{ width: "250px", height: "250px" }}>
      <p className="small blackText removemargin boldText">{data.name}</p>

      <div className="rating-row">
        <AiTwotoneStar color={data.rating > 0 ? "yellow" : "grey"} />
        <AiTwotoneStar color={data.rating > 1 ? "yellow" : "grey"} />
        <AiTwotoneStar color={data.rating > 2 ? "yellow" : "grey"} />
        <AiTwotoneStar color={data.rating > 3 ? "yellow" : "grey"} />
        <AiTwotoneStar color={data.rating > 4 ? "yellow" : "grey"} />
      </div>

      <p className="small" style={{ textAlign: "left" }}>
        {data.text}
      </p>
    </div>
  );
};

export default ReviewCard;
