import React, { useContext, useState, forwardRef, useImperativeHandle, useEffect } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import DashboardInit from "../components/chef-dashboard-init";
import BookingDetails from "../components/booking-page";
import AppContext from "../../../context/app-context";
import DashCustomBookingDetails from "../components/dash-custom-booking";

const CdDashboard = forwardRef(function ({ display, setDashboardFunctions }, ref) {
  const [onView, setOnView] = useState("dashboard init");

  const [bookingData, setBookingDetailsData] = useState({});
  const [customBookingData, setCustomBookingData] = useState({});

  const { usersData, loggedIn } = useContext(AppContext);

  useEffect(() => {
    setDashboardFunctions({
      setOnView,
      setBookingDetailsData,
      setCustomBookingData,
    });
  }, [usersData, loggedIn]);

  useImperativeHandle(ref, () => {
    return {
      changeDashBoardScreen(screen) {
        setOnView(screen);
      },
    };
  });

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont media-chef-chef-dashboard-cont">
      <DashboardInit
        setCustomBookingData={setCustomBookingData}
        setBookingDetailsData={setBookingDetailsData}
        setOnView={setOnView}
        display={onView === "dashboard init" ? true : false}
      />

      <BookingDetails
        data={bookingData}
        setOnView={setOnView}
        display={onView === "chef at home" ? true : false}
      />

      <DashCustomBookingDetails
        data={customBookingData}
        setOnView={setOnView}
        display={onView === "custom booking" ? true : false}
      />
    </div>
  );
});

export default CdDashboard;
