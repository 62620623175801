import React from "react";
import { AiFillStar } from "react-icons/ai";
import "./styles/person.css";

const Person = ({ id, name, rating, review }) => {
  return (
    <article key={id} className="article flex flex-col text-center place-items-center place-content-center">
      <div className="revcard">
        {review}
        <div className="drpdw"></div>
      </div>

      <h4>{name}</h4>
      <div className="rat-row flex mx-auto">
        <AiFillStar className={rating >= 1 ? "r-yellow" : "r-white"} />
        <AiFillStar className={rating >= 2 ? "r-yellow" : "r-white"} />
        <AiFillStar className={rating >= 3 ? "r-yellow" : "r-white"} />
        <AiFillStar className={rating >= 4 ? "r-yellow" : "r-white"} />
        <AiFillStar className={rating >= 5 ? "r-yellow" : "r-white"} />
      </div>
    </article>
  );
};

export default Person;
