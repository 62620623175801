import React, { useState } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../menu-page/menu-page.css";
import "../../chef-dashboard/styles/chef-index.css";
import CustomBookingDetails from "../components/chef-custom-booking";
import ExploreCustomBookingInit from "../components/custom-booking-init";

const ExploreCustomBooking = function ({ display }) {
  const [customBookingData, setCustomBookingData] = useState({});
  const [onView, setOnView] = useState("explore-init");

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <CustomBookingDetails
        display={onView === "custom-booking-details" ? true : false}
        setOnView={setOnView}
        onView={onView}
        data={customBookingData}
      />

      <ExploreCustomBookingInit
        display={onView === "explore-init" ? true : false}
        setOnView={setOnView}
        onView={onView}
        setCustomBookingData={setCustomBookingData}
      />
    </div>
  );
};

export default ExploreCustomBooking;
