import React, { useContext, useEffect, useState } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../menu-page/menu-page.css";
import "../../../../../components/auth/login/login-modal.css";
import { MDBBtn } from "mdb-react-ui-kit";
import ChefFAQCard from "../../../../../components/chef-faq-card/chef-faq-card";
import AddAFAQModal from "../../../../../components/add-a-faq-modal/add-a-faq";
import AppContext from "../../../../../context/app-context";

const ChefFaq = function ({ activeNav }) {
  const [showFAQModal, setShowFaQModal] = useState(false);
  const [faqComp, setFaqComp] = useState("");

  const { usersData, popup } = useContext(AppContext);

  useEffect(() => {
    const comps = usersData.chef_details.chef_faq.map((e, i) => <ChefFAQCard data={e} key={i} />);

    comps.reverse();

    setFaqComp(comps);
  }, [usersData]);

  if (activeNav !== "faq") return;

  return (
    <div className="cd-box-1-in-acc media-chef-cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll", paddingTop: 20 }}>
      <div className="flex-row align-row-left">
        <MDBBtn
          onClick={() => {
            if (usersData?.chef_details?.chef_faq?.length > 10) return popup("Maximum FAQ Added");
            setShowFaQModal(true);
          }}
          color="#ffd93b"
          className="small themeBtn"
        >
          Add FAQ
        </MDBBtn>
      </div>

      <div className="flex-row align-row-left" style={{ gap: 15 }}>
        {faqComp}
      </div>

      <AddAFAQModal display={showFAQModal} setShowFaQModal={setShowFaQModal} />
    </div>
  );
};

export default ChefFaq;
