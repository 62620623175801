import React, {
  useState,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
  useEffect,
} from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBInput,
  MDBModalBody,
} from "mdb-react-ui-kit";
import "./login-modal.css";
import "../../../styles/index.css";
import AppContext from "../../../context/app-context";
import SignupModal from "./signup";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";

const LoginModal = forwardRef(({ display, setShowLogin, setShowForgotPassword }, ref) => {
  const [active, setActive] = useState("login");

  const loginEmailRef = useRef(null);
  const loginPassRef = useRef(null);

  const signupModal = useRef(null);

  const {
    backendServer,
    apiKey,
    setIsLoading,
    setLoggedIn,
    popup,
    setUsersData,
    getSignedAwsUrl,
    setUsersPhoto,
    loggedIn,
    setEdition,
    renderAdvancedPopUp,
  } = useContext(AppContext);

  useImperativeHandle(
    ref,
    () => {
      return {
        showLoginScreen(screen) {
          setActive(screen);
        },
        setActiveSignupScreen(screen) {
          signupModal?.current?.setActiveSignup(screen);
        },
      };
    },
    []
  );

  async function login() {
    if (!loginEmailRef.current.value || !loginPassRef.current.value) return;

    setIsLoading(true);

    try {
      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/login`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: loginEmailRef.current.value,
            loginPass: loginPassRef.current.value,
          }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      localStorage.setItem("ikookusername", returned.data.username);

      localStorage.setItem("ikooktoken", returned.data.token);

      const data = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get/${returned.data.username}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      localStorage.setItem("ikookid", data.data._id);

      setUsersData(data.data);

      if (data.data.photo) setUsersPhoto(await getSignedAwsUrl(data.data.photo, "users"));
      else setUsersPhoto("/images/user_placeholder.png");

      if (data?.data?.country === "UK") {
        localStorage.setItem("edition", "GBP");
        setEdition("GBP");
      } else if (data?.data?.country === "Canada") {
        localStorage.setItem("edition", "CAD");
        setEdition("CAD");
      } else if (data?.data?.country === "Nigeria") {
        localStorage.setItem("edition", "NGN");
        setEdition("NGN");
      }

      setIsLoading(false);
      setShowLogin(false);

      setLoggedIn(true);

      popup(`Welcome back, ${data.data.first_name}`);

      checkIfChefVerifiedDocuments(data?.data);

      localStorage.setItem("chefathome", "on");
      localStorage.setItem("mealprep", "on");
      localStorage.setItem("largeevent", "on");
    } catch (err) {
      if (err.message === "Password incorrect") popup(err.message);
      else if (err.message === "This user does not exist") popup(err.message);
      else if (err.message === "Account suspended. Please contact support.") popup(err.message);
      else popup("Something went wrong");
      setIsLoading(false);
    }
  }

  async function runAutoLogin() {
    setIsLoading(true);
    try {
      const data = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/user/get/${localStorage.getItem(
            "ikookusername"
          )}`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (!data.data.is_active) {
        localStorage.removeItem("ikookusername");
        localStorage.removeItem("ikooktoken");
        localStorage.removeItem("ikookid");
        localStorage.removeItem("edition");
        setLoggedIn(false);
        return popup("Account Suspended. Please contact support.");
      }

      setUsersData(data.data);

      if (data.data.photo) setUsersPhoto(await getSignedAwsUrl(data.data.photo, "users"));
      else setUsersPhoto("/images/user_placeholder.png");

      setShowLogin(false);

      setLoggedIn(true);

      setIsLoading(false);
    } catch (err) {
      popup("Login Failed. Please login again...");
      setIsLoading(false);
      setLoggedIn(false);
    }
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsLoading(true);

        const googleData = await (
          await fetch(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`
          )
        ).json();

        //then login
        const loginReturned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/login/google`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ email: googleData.email, googleID: googleData.id }),
            credentials: "include",
          })
        ).json();

        if (loginReturned.status === "Internal server error")
          throw new Error(loginReturned.message);

        localStorage.setItem("ikookusername", loginReturned.data.username);

        localStorage.setItem("ikooktoken", loginReturned.data.token);

        const data = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/user/get/${loginReturned.data.username}`,
            {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            }
          )
        ).json();

        localStorage.setItem("ikookid", data.data._id);

        setUsersData(data.data);

        if (data.data.photo) setUsersPhoto(await getSignedAwsUrl(data.data.photo, "users"));
        else setUsersPhoto("/images/user_placeholder.png");

        if (data?.data?.country === "UK") {
          localStorage.setItem("edition", "GBP");
          setEdition("GBP");
        } else if (data?.data?.country === "Canada") {
          localStorage.setItem("edition", "CAD");
          setEdition("CAD");
        } else {
          localStorage.setItem("edition", "NGN");
          setEdition("NGN");
        }

        localStorage.setItem("chefathome", "on");
        localStorage.setItem("mealprep", "on");
        localStorage.setItem("largeevent", "on");

        popup(`Welcome back, ${data.data.first_name}`);

        checkIfChefVerifiedDocuments(data?.data);

        setIsLoading(false);

        setShowLogin(false);

        setLoggedIn(true);
      } catch (err) {
        console.error(err);
        popup("Something went wrong");
        setIsLoading(false);
      }
    },
  });

  useLayoutEffect(() => {
    if (localStorage.getItem("ikooktoken")) runAutoLogin();
    else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && display) login();
    });

    return () => {
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Enter" && display) login();
      });
    };
  }, [display]);

  async function checkIfChefVerifiedDocuments(checkdata) {
    if (
      checkdata?.role === "chef" &&
      checkdata?.chef_details?.chef_verification_details?.documents?.length === 0 &&
      !checkdata?.chef_details?.chef_verification_details?.is_documents_verified &&
      checkdata?.verified
    ) {
      setTimeout(() => {
        renderAdvancedPopUp(
          true,
          true,
          "Go to Dashboard",
          () => {
            window.open(`/dashboard/${checkdata?.role}/${checkdata?._id}`, "_blank");
          },
          `Hi ${checkdata?.first_name}. We noticed you haven't submitted your chef verification documents yet. Head over to your dashboard's settings to attach your ID and Chef Certificate.`
        );
      }, 5000);
    }
  }

  if (loggedIn) return;
  if (!display && document.location.pathname === "/signup") return;
  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowLogin}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <div className="login-modal-option-cont">
                <div
                  onClick={() => setActive("login")}
                  className={
                    active === "login"
                      ? "login-modal-option login-modal-option-active"
                      : "login-modal-option"
                  }
                >
                  LOGIN
                </div>

                <div
                  onClick={() => setActive("signup")}
                  className={
                    active === "signup"
                      ? "login-modal-option login-modal-option-active"
                      : "login-modal-option"
                  }
                >
                  SIGN UP
                </div>
              </div>
            </MDBModalHeader>

            <MDBModalBody style={{ display: `${active === "login" ? "block" : "none"}` }}>
              <div className="signunwith-cont">
                <div
                  onClick={loginWithGoogle}
                  className="continue-with-google"
                  style={{ cursor: "pointer" }}
                >
                  <FcGoogle size={20} />

                  <p className="small removemargin">CONTINUE WITH GOOGLE</p>
                </div>
              </div>

              <form>
                <MDBInput
                  ref={loginEmailRef}
                  required={true}
                  className="login-inputs"
                  label="Your Email"
                  type="email"
                />

                <MDBInput
                  ref={loginPassRef}
                  required={true}
                  className="login-inputs"
                  label="Your Password"
                  type="password"
                />
              </form>

              <p
                onClick={() => {
                  setShowForgotPassword(true);
                  setShowLogin(false);
                }}
                className="small fbpassword"
              >
                Forgot Password?
              </p>

              <div className="loginbtn-cont">
                <MDBBtn
                  style={{
                    width: "80%",
                    cursor: "pointer",
                    backgroundColor: "#fcc01c",
                    color: "black",
                  }}
                  onClick={login}
                >
                  Log In
                </MDBBtn>
              </div>

              <p className="small">Copyright © 2024 iKooK. All Rights Reserved.</p>
            </MDBModalBody>

            <SignupModal
              setActive={setActive}
              ref={signupModal}
              setShowLogin={setShowLogin}
              display={active === "signup" ? true : false}
            />
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
});

export default LoginModal;
