import "./comments.css";

const IndividualComment = ({ comment, setReply }) => {
  const handleClick = () => {
    setReply({
      id: comment.id,
      name: comment.audience.full_name,
      audience: comment.audience.email,
    });
  };
  
  return (
    <div className="comment-container">
      <div className="content-container">
        <p className="commenter">{comment.audience.full_name}</p>
        <p style={{ marginBottom: "17px" }}>{comment.comment}</p>
      </div>
      <button id="comment_reply" className="reply-button" onClick={handleClick}>
        Reply
      </button>
      {comment?.replies?.length > 0 && (
        <div className="replies-container">
          {comment?.replies?.map((replies, i) => {
            if(replies.approved){
            return <div key={i} className="reply-container">
              {replies.approved && <p className="commenter">{replies.audience.full_name}</p>}
              <p>{replies.reply}</p>
            </div>
            }}
          )}
        </div>
      )}
    </div>
  );
};

export default IndividualComment;
