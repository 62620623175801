import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const PageThirteen = function ({
  currPage,
  setCurrPage,
  setEventTime,
  setEventName,
  setEventDate,
  setEventEndTime,
  setEventEndDate,
}) {
  const [deactivateBtn, setDeActivateBtn] = useState(true);

  const [date, setDate] = useState("");
  const [time, setTime] = useState(``);


  const [endTime, setEndTime] = useState(``);

  const [selectedTheme, setSeletedTheme] = useState("Select an Event Theme");

  useEffect(() => {
    if (time && date && selectedTheme !== "Select an Event Theme"  && endTime)
      setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [date, selectedTheme, time, endTime]);

  if (currPage !== 13) return;

  return (
    <div className="pages-cont" data-page="13">
      <h4>Event Details</h4>

      <MDBDropdown id="mdb-dropdown-custom" style={{ width: 350 }}>
        <MDBDropdownToggle id="mdb-dropdown-custom-in" color="light" style={{ width: 350 }}>
          {selectedTheme}
        </MDBDropdownToggle>
        <MDBDropdownMenu style={{ width: 350 }}>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Murder Mystery
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Coachella
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Game of Thrones
          </MDBDropdownItem>

          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Hollywood
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Bollywood
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Great Gatsby
          </MDBDropdownItem>

          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            20’s
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Pyjamas
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Street Food
          </MDBDropdownItem>

          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Carnival
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Arabian
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            007 Bond
          </MDBDropdownItem>

          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Grand Masked Ball
          </MDBDropdownItem>
          <MDBDropdownItem
            link
            onClick={(e) => {
              setSeletedTheme(e.target.textContent);
              setEventName(e.target.textContent);
            }}
          >
            Other
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>

      <MDBInput
        style={{ width: 350 }}
        onChange={(e) => {
          setDate(e.target.value);
          setEventDate(e.target.value);
        }}
        required={true}
        className="cb-inputs"
        label="Event Start Date"
        type="date"
      />

      <MDBInput
        style={{ width: 350 }}
        onChange={(e) => {
          setTime(e.target.value);
          setEventTime(e.target.value);
        }}
        required={true}
        className="cb-inputs active"
        label="Event Start Time"
        type="time"
        value={time}
      />

      

      <MDBInput
        style={{ width: 350 }}
        onChange={(e) => {
          setEndTime(e.target.value);
          setEventEndTime(e.target.value);
        }}
        required={true}
        className="cb-inputs active"
        label="Event End Time"
        type="time"
        value={endTime}
      />

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageThirteen;
