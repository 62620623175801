import React, { useEffect, useState } from "react";
import "./extensive-meal-prep-day.css";
import "../../../styles/index.css";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import EachOfInnerMenu from "./each-menu";

const ExtensiveMealPrepDay = ({
  day,
  allTheUsersMenus,
  setMenusSelected,
  sortOutMenuSelected,
  delieryDays,
}) => {
  const [revealAll, setRevealAll] = useState(false);
  const [revealBreakFast, setRevealBreakFast] = useState(false);
  const [revealLunch, setRevealLunch] = useState(false);
  const [revealDinner, setRevealDinner] = useState(false);

  const [extractedMenuAddons, setExtractedMenuAddons] = useState([]);

  useEffect(() => {
    let extracts = [];

    allTheUsersMenus.forEach((e) => {
      extracts = [
        ...extracts,
        ...e?.menustarter.map((el) => {
          el.price = el?.price || e?.menuPrice;
          return el;
        }),
        ...e?.menumain.map((el) => {
          el.price = el?.price || e?.menuPrice;
          return el;
        }),
        ...e?.menudessert.map((el) => {
          el.price = el?.price || e?.menuPrice;
          return el;
        }),
        ...e?.menuside.map((el) => {
          el.price = el?.price || e?.menuPrice;
          return el;
        }),
      ];
    });

    setExtractedMenuAddons(
      extracts.map((e, i) => (
        <EachOfInnerMenu
          setMenusSelected={setMenusSelected}
          key={i}
          data={e}
          currency={allTheUsersMenus[0].currency}
          day={day}
          sortOutMenuSelected={sortOutMenuSelected}
        />
      ))
    );
  }, [allTheUsersMenus, delieryDays]);

  return (
    <div
      className="extensive-outside-cont extensive-cursor"
      onClick={() => setRevealAll(!revealAll)}
    >
      <div className="the-day-title">
        <p className="small removemargin blackText">{day}</p>

        {revealAll ? (
          <BiUpArrow color="black" className="extensive-cursor" />
        ) : (
          <BiDownArrow color="black" className="extensive-cursor" />
        )}
      </div>

      {revealAll && (
        <>
          <div
            className="a-day extensive-cursor"
            onClick={(e) => {
              e.stopPropagation();
              setRevealBreakFast(!revealBreakFast);
            }}
          >
            <p className="small removemargin blackText">Breakfast</p>

            {revealBreakFast ? (
              <BiUpArrow color="black" className="extensive-cursor" />
            ) : (
              <BiDownArrow color="black" className="extensive-cursor" />
            )}
          </div>

          {revealBreakFast && (
            <div className="where-each-inner-menu-are" data-type="Breakfast">
              {extractedMenuAddons}
            </div>
          )}

          <div
            className="a-day extensive-cursor"
            onClick={(e) => {
              e.stopPropagation();
              setRevealLunch(!revealLunch);
            }}
          >
            <p className="small removemargin blackText">Lunch</p>

            {revealLunch ? (
              <BiUpArrow color="black" className="extensive-cursor" />
            ) : (
              <BiDownArrow color="black" className="extensive-cursor" />
            )}
          </div>

          {revealLunch && (
            <div className="where-each-inner-menu-are" data-type="Lunch">
              {extractedMenuAddons}
            </div>
          )}

          <div
            className="a-day extensive-cursor"
            onClick={(e) => {
              e.stopPropagation();
              setRevealDinner(!revealDinner);
            }}
          >
            <p className="small removemargin blackText">Dinner</p>

            {revealDinner ? (
              <BiUpArrow color="black" className="extensive-cursor" />
            ) : (
              <BiDownArrow color="black" className="extensive-cursor" />
            )}
          </div>

          {revealDinner && (
            <div className="where-each-inner-menu-are" data-type="Dinner">
              {extractedMenuAddons}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExtensiveMealPrepDay;
