import React, { useContext, useEffect, useState } from "react";
import "../menu-card-small/menu-card-small.css";
import "../../styles/index.css";
import "./user-card-meal-prep.css";
import { AiTwotoneStar } from "react-icons/ai";
import AppContext from "../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";
import { GiCookingPot } from "react-icons/gi";
import { MdDeliveryDining } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillCalendarDateFill } from "react-icons/bs";

const UserCardMealPrep = function ({ data }) {
  const [userImage, setUserImage] = useState("");
  const [rating, setRating] = useState(0);
  const [fromPrice, setFromPrice] = useState(0);
  const [menuCount, setMenuCount] = useState(0);

  const { getSignedAwsUrl, calculateChefRating, convertAmount, popup } = useContext(AppContext);

  useEffect(() => {
    setRating(calculateChefRating(data.chef_details.reviews));

    return () => setRating(0);
  }, [data]);

  useEffect(() => {
    (async function () {
      if (data.photo) setUserImage(await getSignedAwsUrl(data.photo, "users"));
      else setUserImage("/images/user_placeholder.png");
    })();

    getChefStartingPrice();

    return () => setUserImage("/images/user_placeholder.png");
  }, [data]);

  useEffect(() => {
    (async () => {
      if (data.chef_details.chef_menus.length === 0) return setMenuCount(0);
      setMenuCount(data.chef_details.chef_menus.filter((e) => e?.status === "approved").length);
    })();
  }, [data]);

  const smallestArrayValue = function (array) {
    if (array.length === 0) return 0;

    let smallest = array[0];

    array.forEach((e) => {
      if (e < smallest) smallest = e;
    });

    return smallest;
  };

  async function getChefStartingPrice() {
    if (data.chef_details.chef_menus.length === 0) return setFromPrice("0");

    let extracts = [];

    data.chef_details.chef_menus
      .filter((e) => e?.status === "approved")
      ?.forEach((e) => {
        extracts = [
          ...extracts,
          ...e?.menustarter?.map((e) => e.price),
          ...e?.menumain?.map((e) => e.price),
          ...e?.menudessert?.map((e) => e.price),
          ...e?.menuside?.map((e) => e.price),
        ];
      });

    setFromPrice(smallestArrayValue(extracts) || 0);
  }

  return (
    <div
      onClick={() => {
        if (menuCount === 0) return popup("This chef has no Menu...");
        window.open(`/meal-prep/order/${data._id}`, "_blank");
      }}
      className="l-user-card-small-cont"
    >
      {userImage ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <img
            style={{ width: 150, height: 150, borderRadius: 150 }}
            src={userImage}
            alt="iKook MealPrep"
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      )}

      <div className="l-user-roww" style={{ marginTop: 15 }}>
        <h6 className="alignTextLeft" style={{ marginBottom: 0, fontWeight: "bold" }}>
          {(data?.username).length < 15 ? data?.username : (data?.username).slice(0, 15) + `...`}
        </h6>

        <div className="menu-det-two" style={{ width: "fit-content" }}>
          <p className="small removemargin">
            <AiTwotoneStar color={rating > 0 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 1 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 2 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 3 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 4 ? "yellow" : "grey"} />
          </p>

          <p className="small removemargin">({data.chef_details.reviews.length} Reviews)</p>
        </div>
      </div>

      <div className="l-user-roww">
        <div className="l-user-roww-one">
          <div className="menu-det">
            <p className="small removemargin alignTextLeft">
              <HiLocationMarker color="#f8c808" /> {data?.city?.split(" ")[0]}
            </p>

            <p className="small removemargin alignTextLeft">
              <GiCookingPot color="#f8c808" /> {menuCount} {menuCount === 0 ? "" : "+"} Cuisines
            </p>
          </div>
        </div>

        <div className="l-user-roww-two">
          <div className="menu-det-two">
            <p className="small removemargin">
              <BsFillCalendarDateFill color="#f8c808" /> Weekly
            </p>

            <p className="small removemargin">
              <MdDeliveryDining color="#f8c808" /> Physical, Gourmet
            </p>
          </div>
        </div>
      </div>

      <div className="l-user-roww-two" style={{ justifyContent: "flex-end", width: "100%" }}>
        <div className="menu-det-two">
          <p className="xsmall removemargin">From</p>

          <p className="big">{convertAmount(fromPrice, data?.country)}</p>
        </div>
      </div>
    </div>
  );
};

export default UserCardMealPrep;
