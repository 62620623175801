import React, { useEffect, useState, useContext } from "react";
import "./booking-card.css";
import "../../styles/index.css";
import "../../components/menu-card-small/menu-card-small.css";
import "../../pages/user-dashboard/styles/user-index.css";
import AppContext from "../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";

const BookingCardCD = function ({ setOnView, type, data, setBookingDetailsData }) {
  const [menuImage, setMenuImage] = useState("");
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");
  const [menuName, setMenuName] = useState("");
  const [menuCurrency, setMenuCurrency] = useState("");

  const theBookingWithExtraDetails = { ...data };

  const { emitRealDate, getSignedAwsUrl, convertAmount } = useContext(AppContext);

  useEffect(() => {
    if (!data) return;

    (async function () {
      if (data?.chef?.photo) setChefImage(await getSignedAwsUrl(data?.chef?.photo, "users"));

      if (data?.booking_type === "chef-at-home") {
        setMenuName(data?.menuID?.menuName);

        setMenuImage(
          (await getSignedAwsUrl(data?.menuID?.images[0], "menus")) ||
            "images/Picture5B.96c7a9a22c3d1151edb2.png"
        );

        setMenuCurrency(data?.menuID?.currency);
      } else if (data?.booking_type === "meal-prep") {
        setMenuName(`Meal Prep with ${data?.user?.first_name}`);

        setMenuImage("/images/tac.png");

        setMenuCurrency(data?.currency);
      }
    })();

    return () => setMenuImage("");
  }, [data]);

  return (
    <div
      title={menuName}
      onClick={() => {
        setBookingDetailsData(theBookingWithExtraDetails);
        if (type === "chef-at-home") setOnView("chef at home");
        if (type === "large-event") setOnView("chef at home");
        if (type === "meal-prep") setOnView("chef at home");
      }}
      className="bk-card-small-cont"
    >
      <div style={{ width: "300px", height: "200px" }}>
        {menuImage ? (
          ""
        ) : (
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        )}
      </div>

      <div style={{ width: "300px", height: "200px" }}>
        {menuImage ? (
          <img
            className="menu-card-small-img adjust-image-bk"
            src={menuImage}
            alt={data?.menuID?.menuName}
          />
        ) : (
          ""
        )}
      </div>

      <div className="roww-bk">
        <h6 style={{ marginBottom: 0, fontWeight: "bold" }}>
          {menuName ? menuName.slice(0, 20) + `...` : ""}
        </h6>
        <img className="bk-card-profile-image" src={chefImage} alt={data?.chef?.first_name} />
      </div>

      <div className="roww-bk">
        <div className="roww-one">
          <div className="menu-det">
            <p className="xsmall removemargin alignTextLeft">
              {data?.number_of_adults} Adult(s). {data?.number_of_teens} Teen(s).{" "}
              {data?.number_of_children} Kid(s)
            </p>

            <p className="small removemargin yellowText">
              {" "}
              {convertAmount(data?.amount ? data?.amount : "0", menuCurrency)}
            </p>

            <p className="ts-btn-yellow">{type}</p>
          </div>
        </div>

        <div className="roww-two">
          <div className="menu-det-two">
            <p className="small removemargin">📅 {emitRealDate(data?.booking_date)}</p>

            <p className="small removemargin">
              🌍 {data?.location?.addressString?.slice(0, 15)}...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCardCD;
