import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
import "./explore.css";
import "../../styles/index.css";
import { AiOutlineSearch } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import MenuCardSmall from "../../components/menu-card-small/menu-card-small";
import "../../pages/home/home.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import AppContext from "../../context/app-context";
import { useParams } from "react-router-dom";
import "../user-dashboard/styles/user-index.css";
import { usePlacesWidget } from "react-google-autocomplete";
import country from "../../context/country";
import restrictCountry from "../../context/country-restrictions";
import { Pagination } from "@mui/material";

let unvailableChefsForTheFilteredDate = [];
let page = 0;

const ExploreSetMenus = function () {
  const [menusOnShow, setMenusOnShow] = useState([]);
  const [splittedArrWorked, setSplittedArrWorked] = useState([]);

  const [queryy, setQueryy] = useState("");

  let { query: comingFromHome, filterdate } = useParams();

  const { backendServer, apiKey, setIsLoading, popup, googleMapsApiKey, pyBackendServer } =
    useContext(AppContext);

  const topRef = useRef(null);
  const searchTag = useRef(null);

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setQueryy(place?.formatted_address);
    },
    options: {
      componentRestrictions: { country: restrictCountry },
    },
  });

  useEffect(() => {
    document.title = "iKooK - Explore Menus";
  }, []);

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }

  async function searchMenu(query, cfh) {
    try {
      setIsLoading(true);
      let url;

      if (query) {
        url = `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get?tag=${query}`;
      } else url = `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get`;

      let { data } = await (
        await fetch(url, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (queryy || cfh) {
        data = data.filter((e) => {
          const arr = e.chefID.chef_details.address.split(" ");

          if (
            arr.includes((queryy || cfh).split(",")[0].split(" ")[0]) ||
            arr.includes((queryy || cfh).split(",")[0].split(" ")[0] + ",") ||
            arr.includes(capitalizeEachWord((queryy || cfh).split(",")[0].split(" ")[0])) ||
            arr.includes(capitalizeEachWord((queryy || cfh).split(",")[0].split(" ")[0] + ",")) ||
            e.chefID.city === (queryy || cfh)
          )
            return e;
        });
      }

      const servicesFilteredData = data.filter(
        (e) =>
          e?.chefID?.availableForChefAtHome !== "no" &&
          !unvailableChefsForTheFilteredDate.find((el) => el === e?.chefID?._id) &&
          e?.chefID?.country === country &&
          e?.status === "approved"
      );

      const comp = servicesFilteredData.map((e, i) => {
        return <MenuCardSmall key={i} data={e} />;
      });

      if (!data || servicesFilteredData?.length === 0) handleEmptyState();
      else runPaginationOnComponents(comp, 20, setMenusOnShow, setSplittedArrWorked);

      setIsLoading(false);
    } catch (err) {
      popup("Menu not found");
      setIsLoading(false);
    }
  }

  async function fetchMenu(query, cfh) {
    try {
      setIsLoading(true);
      let url;

      if (!query) url = `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/advanced`;
      else {
        url = `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/advanced?${query}`;
      }

      let { data } = await (
        await fetch(url, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (queryy || cfh) {
        data = data.filter((e) => {
          const arr = e.chefID.chef_details.address.split(" ");

          if (
            arr.includes((queryy || cfh).split(",")[0].split(" ")[0]) ||
            arr.includes((queryy || cfh).split(",")[0].split(" ")[0] + ",") ||
            arr.includes(capitalizeEachWord((queryy || cfh).split(",")[0].split(" ")[0])) ||
            arr.includes(capitalizeEachWord((queryy || cfh).split(",")[0].split(" ")[0] + ",")) ||
            e.chefID.city === (queryy || cfh)
          )
            return e;
        });
      }

      const servicesFilteredData = data.filter(
        (e) =>
          e?.chefID?.availableForChefAtHome !== "no" &&
          !unvailableChefsForTheFilteredDate.find((el) => el === e?.chefID?._id) &&
          e?.chefID?.country === country &&
          e?.status === "approved"
      );

      let comp = servicesFilteredData.map((e, i) => {
        return <MenuCardSmall key={i} data={e} />;
      });

      if (comp.length === 0) handleEmptyState();
      else runPaginationOnComponents(comp, 20, setMenusOnShow, setSplittedArrWorked);

      setIsLoading(false);
    } catch (err) {
      popup("Ooooops, an Error Occured");
      setIsLoading(false);
    }
  }

  function runPaginationOnComponents(componentsArr, pageLimit, setOnShowState, saveSplittedState) {
    try {
      //ensure page variable is outside the component of this function

      page = 0;

      let firstStart = 0;
      let firstEnd = pageLimit;

      const splittedArr = [];

      for (let loop = 1; loop <= Math.ceil(componentsArr.length / pageLimit); loop++) {
        splittedArr.push([...componentsArr.slice(firstStart, firstEnd)]);

        firstStart = firstStart + pageLimit;
        firstEnd = firstEnd + pageLimit;
      }

      saveSplittedState(splittedArr);

      setOnShowState(splittedArr[0]);
    } catch {}
  }

  function navigate(command, theTopOfThepage, setOnShowState, theSplittedArr) {
    setOnShowState([]);

    theTopOfThepage?.current?.scrollIntoView({
      behaviour: "smooth",
    });

    page = command - 1;

    setOnShowState(theSplittedArr[page]);
  }

  function handleEmptyState() {
    page = 0;
    setSplittedArrWorked([]);
    setMenusOnShow(
      <div className="is-empty-cont" style={{ marginBottom: 40 }}>
        <img src="/images/no-booking.png" alt="ikook" />

        <p className="small boldText">No Menu Matches Your Query</p>

        <p className="small">Try another query</p>
      </div>
    );
  }

  useEffect(() => {
    (async function () {
      try {
        if (filterdate) {
          setIsLoading(true);

          const returned = await (
            await fetch(
              `${pyBackendServer}/accounts/chef/unavailability/get/date?date=${filterdate}`
            )
          ).json();

          unvailableChefsForTheFilteredDate = returned.data;
        }

        fetchMenu(null, comingFromHome);
      } catch {
        fetchMenu(null, comingFromHome);
      }
    })();
  }, []);

  useLayoutEffect(() => {
    if (comingFromHome) setQueryy(comingFromHome);
  }, []);

  return (
    <div className="explore-cont" style={{ paddingTop: 0 }}>
      <div
        ref={topRef}
        className="flex-row filter-and-rest"
        style={{ backgroundColor: "#fcc01c", marginBottom: 20 }}
      >
        <div className="lineafter explore-line-after explore-menu-count">
          <p className="small">{menusOnShow.length > 0 ? menusOnShow.length : 0} Menus Fetched</p>
        </div>

        <div className="lineafter explore-line-after" style={{ gap: 15 }}>
          <p className="small">Sort By:</p>

          <button
            onClick={() => {
              fetchMenu(`${country === "Nigeria" ? "menuPrice[lte]=1000" : "menuPrice[lte]=80"}`);
            }}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-price-btn"
          >
            <GiMoneyStack />
            &nbsp; Lower Price
          </button>

          <button
            onClick={() => {
              fetchMenu(`${country === "Nigeria" ? "menuPrice[gte]=1000" : "menuPrice[gte]=80"}`);
            }}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-cuisines-btn"
          >
            <GiMoneyStack />
            &nbsp; Higher Price
          </button>
        </div>

        <div className="lineafter explore-line-after" style={{ gap: 15 }}>
          <input ref={searchTag} className="explore-location in" placeholder="search; e.g. pizza" />

          <button
            onClick={(e) => {
              if (!searchTag?.current?.value) {
                fetchMenu();
                return;
              }
              searchMenu(searchTag.current.value);
            }}
            className="themeBtn home-search-explore whiteBg"
          >
            <AiOutlineSearch />
          </button>
        </div>

        <div className="lineafter explore-line-after" style={{ gap: 15, borderRightWidth: 0 }}>
          <input
            value={queryy}
            onChange={(e) => {
              setQueryy(e.target.value);
            }}
            ref={ref}
            className="explore-location in"
            placeholder={"Location; state/city"}
          />

          <button
            onClick={(e) => {
              if (!queryy) {
                fetchMenu();
                return;
              }
              searchMenu(null);
            }}
            className="themeBtn home-search-explore whiteBg"
          >
            <AiOutlineSearch />
          </button>
        </div>
      </div>

      <div className="our-services-large-explore where-explore-menus-are" style={{ gap: 30 }}>
        {menusOnShow}
      </div>

      <div className="flex-row">
        <Pagination
          page={page + 1}
          count={splittedArrWorked.length}
          variant="outlined"
          shape="rounded"
          onChange={(_event, page) => {
            navigate(page, topRef, setMenusOnShow, splittedArrWorked);
          }}
        />
      </div>

      <ForPageActions />
    </div>
  );
};

export default ExploreSetMenus;
