import React from "react";
import "../../styles/index.css";
import { MDBSpinner } from "mdb-react-ui-kit";

const Loading = function ({ show }) {
  return (
    <div className={`loading-cont ${show ? "" : "hide"}`}>
      <MDBSpinner size="lg" className="mx-2" color="warning">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    </div>
  );
};

export default Loading;
