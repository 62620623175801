import React, { useContext, useEffect, useState } from "react";
import "./menu-card-small.css";
import "../../styles/index.css";
import { AiOutlineAim, AiTwotoneStar } from "react-icons/ai";
import AppContext from "../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";

const MenuCardSmall = function ({ data }) {
  const [menuImage, setMenuImage] = useState("");
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");

  const { getSignedAwsUrl, calculateChefRating, convertAmount } = useContext(AppContext);

  const menu = { ...data };

  useEffect(() => {
    if (!data) return;
    (async function () {
      if (menu.chefID.photo) setChefImage(await getSignedAwsUrl(menu.chefID.photo, "users"));

      setMenuImage(await getSignedAwsUrl(menu.images[0], "menus"));
    })();

    return () => setMenuImage("");
  }, [data]);

  if (!data) return;

  return (
    <div
      onClick={() => window.open(`/menu/${data._id}`, "_blank")}
      className="menu-card-small-cont"
    >
      <div style={{ width: "300px", height: "250px" }}>
        {menuImage ? (
          ""
        ) : (
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        )}
      </div>

      <div style={{ width: "300px", height: "250px" }}>
        {menuImage ? (
          <img className="menu-card-small-img" src={menuImage} alt="iKooK Chef Menu" />
        ) : (
          ""
        )}
      </div>

      <div className="roww">
        <h6 className="blackText" style={{ marginBottom: 0, fontWeight: "bold" }}>
          {menu.menuName.length < 20 ? menu.menuName : menu.menuName.slice(0, 20) + "..."}{" "}
        </h6>

        <p className="removemargin blackText boldText small">
          {convertAmount(menu.menuPrice, menu.currency)}
        </p>
      </div>

      <div className="roww">
        <div className="roww-one">
          <img className="menu-img" src={chefImage} alt="iKooK Chef" />

          <div className="menu-det">
            <p className="small removemargin blackText">{menu.chefID.username}</p>

            <p className="small removemargin alignTextLeft">
              <AiOutlineAim color="#fcc01c" /> {menu.chefID.city.split(" ")[0]}
            </p>
          </div>
        </div>

        <div className="roww-two">
          <div className="menu-det-two">
            <p className="small removemargin">
              <AiTwotoneStar
                color={
                  calculateChefRating(menu?.chefID?.chef_details?.reviews) > 0 ? "yellow" : "grey"
                }
              />
              <AiTwotoneStar
                color={
                  calculateChefRating(menu?.chefID?.chef_details?.reviews) > 1 ? "yellow" : "grey"
                }
              />
              <AiTwotoneStar
                color={
                  calculateChefRating(menu?.chefID?.chef_details?.reviews) > 2 ? "yellow" : "grey"
                }
              />
              <AiTwotoneStar
                color={
                  calculateChefRating(menu?.chefID?.chef_details?.reviews) > 3 ? "yellow" : "grey"
                }
              />
              <AiTwotoneStar
                color={
                  calculateChefRating(menu?.chefID?.chef_details?.reviews) > 4 ? "yellow" : "grey"
                }
              />
            </p>

            <p className="small removemargin">
              ({menu.chefID.chef_details.reviews.length} Reviews)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCardSmall;
