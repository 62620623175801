import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import WithdrawalCard from "../components/child-components/withdrwal-card";
import "../../chef-dashboard/styles/chef-index.css";
import AppContext from "../../../context/app-context";

const CdRevenues = function ({ display }) {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [widthdrawn, setWithdrawn] = useState(0);
  const [total, setTotal] = useState(0);

  const [tableWithdrawalComp, setTableWithdrawalComp] = useState([]);

  const { usersData, popup, setIsLoading, apiKey, backendServer, formatNumber } =
    useContext(AppContext);

  let backendServerMock = "https://ikook-master-api.herokuapp.com";

  useEffect(() => {
    let currBalance = 0;

    usersData.withdrawals.forEach((e) => {
      if (e.status === "paid") return;
      currBalance = currBalance + e.amount;
    });

    let withdrn = 0;

    usersData.withdrawals.forEach((e) => {
      if (e.status !== "paid") return;
      withdrn = withdrn + e.amount;
    });

    setCurrentBalance(currBalance);
    setWithdrawn(withdrn);
    setTotal(currBalance + withdrn);

    const comp = usersData.withdrawals.map((e, i) => <WithdrawalCard key={i} data={e} />);

    if (comp.length === 0)
      return setTableWithdrawalComp(
        <div className="flex-column for-no-bookings">
          <p className="small boldText" style={{ marginTop: 50 }}>
            No pending revenues...
          </p>
        </div>
      );

    comp.reverse();

    setTableWithdrawalComp(comp);
  }, [usersData]);

  async function processWithdrawals() {
    if (
      usersData.withdrawals.filter((e) => {
        return e.status !== "paid";
      }).length === 0
    )
      return popup("You have no pending balance");

    try {
      setIsLoading(true);

      let totalAmaount = 0;

      await new Promise((resolve, _reject) => {
        usersData.withdrawals.forEach(async (e, i) => {
          try {
            if (e.status === "paid") return;

            const returned = await (
              await fetch(`${backendServerMock}/ikook/api/v1/${apiKey}/bookings/pay/${e._id}`, {
                credentials: "include",
                method: "PATCH",
                headers: {
                  token: localStorage.getItem("ikooktoken"),
                },
              })
            ).json();

            if (returned.status === "Internal server error") {
              backendServerMock = "";
              resolve();
            }

            totalAmaount = totalAmaount + e.amount;

            if (i === usersData.withdrawals.length - 1) resolve();
          } catch {
            backendServerMock = "";
            resolve();
          }
        });
      });

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/payments/create`, {
          credentials: "include",
          method: "POST",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify({
            userID: usersData._id,
            amount: totalAmaount,
            currency:
              usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN",
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error();

      setIsLoading(false);

      alert("Your withdrawal request has been sent");

      document.location.reload();
    } catch {
      popup("Something went wrong");
      alert("Please contact support to confirm status of your withdrawal request");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <div className="flex-row ud-dash-header" style={{ justifyContent: "space-around" }}>
        <div className="cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">
              Balance (
              {usersData?.country === "UK"
                ? "GBP"
                : usersData?.country === "Canada"
                ? "CAD"
                : "NGN"}
              )
            </p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(10, 20, 129, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-revenue.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin" style={{ marginTop: -10 }}>
            {currentBalance ? formatNumber(currentBalance) : 0}
          </p>
        </div>

        <div className="cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">
              Withdrawn (
              {usersData?.country === "UK"
                ? "GBP"
                : usersData?.country === "Canada"
                ? "CAD"
                : "NGN"}
              )
            </p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(10, 20, 129, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-revenue.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin" style={{ marginTop: -10 }}>
            {widthdrawn ? formatNumber(widthdrawn) : 0}
          </p>
        </div>

        <div className="cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">
              Total (
              {usersData?.country === "UK"
                ? "GBP"
                : usersData?.country === "Canada"
                ? "CAD"
                : "NGN"}
              )
            </p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(10, 20, 129, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-revenue.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin" style={{ marginTop: -10 }}>
            {total ? formatNumber(total) : 0}
          </p>
        </div>
      </div>

      <div className="flex-row ud-dash-header">
        <p className="big alignTextLeft removemargin">
          Withdrawal History (
          {usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"})
        </p>

        <button
          onClick={() => {
            if (!usersData?.chef_details?.bank_account?.account_number)
              return popup("Please update your bank information");
            else processWithdrawals();
          }}
          className="themeBtn ud-btns"
        >
          Withdraw Balance
        </button>
      </div>

      <div
        className="flex-row align-row-left"
        style={{ padding: "0px 40px 0px 40px", justifyContent: "flex-start", marginTop: 40 }}
      >
        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Payment ID
        </p>

        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Booking ID
        </p>

        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Amount
        </p>

        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Status
        </p>
      </div>

      {tableWithdrawalComp}
    </div>
  );
};

export default CdRevenues;
