import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageFour = function ({ currPage, setCurrPage, setMealPrepOption }) {
  const [selected, setSelected] = useState("");

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  useEffect(() => {
    if (selected) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  if (currPage !== 4) return;

  return (
    <div className="pages-cont" data-page="4">
      <h4>What do you need a Private Chef for?</h4>

      <div className="flex-row" style={{ gap: "40px" }}>
        <div
          onClick={() => {
            setSelected("ote");
            setMealPrepOption("one-time");
          }}
          className={`div-image-text-cb ${selected === "ote" ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/mp.png" alt="iKook Chef At Home" />
          <p className="small">One-Time Experience</p>
        </div>

        <div
          onClick={() => {
            setSelected("mo");
            setMealPrepOption("multiple");
          }}
          className={`div-image-text-cb ${selected === "mo" ? "active-nav-indicator" : ""}`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "50%",
            }}
          >
            <img style={{ width: 30 }} src="/images/mp.png" alt="iKook Meal Prep" />
            <img style={{ width: 30 }} src="/images/mp.png" alt="iKook Meal Prep" />
          </div>

          <p className="small">Multiple Occassions</p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageFour;
