import React, { useState, useContext, useEffect } from "react";
import "../modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../styles/index.css";
import "../../pages/user-dashboard/styles/user-index.css";
import "../auth/login/login-modal.css";
import "../../pages/chef-dashboard/styles/chef-index.css";
import AppContext from "../../context/app-context";
import "./propose.css";
import { theMenusSelected } from "./propose";
import { MDBSpinner } from "mdb-react-ui-kit";

const PMenuCard = ({ data, fixTheMenuSelected }) => {
  const [image, setImage] = useState("");
  const [active, setActive] = useState(false);

  const { getSignedAwsUrl, popup } = useContext(AppContext);

  useEffect(() => {
    (async function () {
      setImage(await getSignedAwsUrl(data?.images[0], "menus"));
    })();
  }, []);

  return (
    <div
      className={`p-a an-extra-menu-card ${active && "an-extra-menu-card-active"}`}
      onClick={() => {
        if (theMenusSelected.length >= 2) {
          if (!active) return popup("You can only select a maximum of 2 menus");
        }
        setActive(!active);
        fixTheMenuSelected(data?._id);
      }}
    >
      <div className="extra-menu-one">
        {image ? (
          <img style={{ width: 50, height: 50 }} src={image} alt={data?.description} />
        ) : (
          <MDBSpinner size="sm" className="mx-2" color="primary" />
        )}
        <p className="small removemargin alignTextLeft">{data?.menuName?.slice(0, 60)}...</p>
      </div>
    </div>
  );
};

export default PMenuCard;
