import React, { useContext, useEffect } from "react";
import "../../../pages/menu-page/menu-page.css";
import AppContext from "../../../context/app-context";
import "./carousel.css";
import { useState } from "react";
import FsLightbox from "fslightbox-react";

const Carousel = function ({ images, menuData, showCarousel, setShowCarousel }) {
  const [mainImageComp, setMainImageComp] = useState([]);
  const [gridComps, setGridComps] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);

  const { getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    if (images.length === 0) return;

    setMainImageComp(
      <img key={0} className="main-images-image main-image" src={images[0]} alt="ikook-menu" />
    );

    if (images.length > 4) {
      setGridComps(
        images?.slice(1, 5)?.map((e, i) => {
          return (
            <div className="each-grid-image-div" key={i}>
              <img className="main-images-image" src={e} alt="ikook-menu" />
            </div>
          );
        })
      );

      setCarouselImages(
        images.map((e) => <img src={e} alt="iKooK Menu" className="all-corousel-images" />)
      );
    } else {
      (async function () {
        const joinedArr = [...images];

        await new Promise((resolve) => {
          menuData.menustarter.forEach(async (e, i, arr) => {
            const str = await getSignedAwsUrl(e?.picture, "menus");
            joinedArr.push(str);
            if (i === arr.length - 1) resolve();
          });
        });

        await new Promise((resolve) => {
          menuData.menumain.forEach(async (e, i, arr) => {
            const str = await getSignedAwsUrl(e?.picture, "menus");
            joinedArr.push(str);
            if (i === arr.length - 1) resolve();
          });
        });

        await new Promise((resolve) => {
          menuData.menudessert.forEach(async (e, i, arr) => {
            const str = await getSignedAwsUrl(e?.picture, "menus");
            joinedArr.push(str);
            if (i === arr.length - 1) resolve();
          });
        });

        if (menuData?.menuside.length > 0) {
          await new Promise((resolve) => {
            menuData.menuside.forEach(async (e, i, arr) => {
              const str = await getSignedAwsUrl(e?.picture, "menus");
              joinedArr.push(str);
              if (i === arr.length - 1) resolve();
            });
          });
        }

        setGridComps(
          joinedArr?.slice(1, 5)?.map((e, i) => {
            return (
              <div className="each-grid-image-div" key={i}>
                <img className="main-images-image" src={e} alt="ikook-menu" />
              </div>
            );
          })
        );

        setCarouselImages(
          joinedArr
            ?.slice(1, 5)
            ?.map((e) => <img src={e} alt="iKooK Menu" className="all-corousel-images" />)
        );
      })();
    }
  }, [images, menuData]);

  useEffect(() => {
    document.querySelector(".carousel-grid")?.lastElementChild?.classList?.add("grid-no-hover");
  }, [images, gridComps]);

  return (
    <div className="carousels-cont">
      <div className="carousel-main" onClick={() => setShowCarousel(!showCarousel)}>
        {mainImageComp}
      </div>

      <div className="carousel-grid" onClick={() => setShowCarousel(!showCarousel)}>
        {gridComps}
      </div>

      <FsLightbox toggler={showCarousel} sources={carouselImages} />
    </div>
  );
};

export default Carousel;
