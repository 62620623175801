import React, { useContext, useEffect, useState } from "react";
import "../../styles/index.css";
import "./direct-proposal.css";
import { DirectProposalModal } from "../../components/direct-proposal-modal/direct-propose-modal";
import AppContext from "../../context/app-context";
import { useParams } from "react-router-dom";

const DirectProposals = function () {
  const [showPage, setShowPage] = useState(true);
  const [theBooking, setTheBooking] = useState(null);

  const { bookingID } = useParams();

  const { usersData, setIsLoading, popup, backendServer, apiKey, loggedIn } =
    useContext(AppContext);

  useEffect(() => {
    if (!localStorage.getItem("ikooktoken")) return popup("Please Login");
    fetchBooking();
  }, [bookingID, loggedIn, usersData]);

  async function fetchBooking() {
    if (!usersData?._id) return;

    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get/${bookingID}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (data?.status !== "pending") {
        popup("Sorry, this booking is no longer available");
        setShowPage(false);
        return setTimeout(() => {
          document.location.href = `/`;
        }, 2000);
      }

      if (data?.proposals?.find((e) => e?.chef_who_proposed_id === usersData?._id)) {
        popup("You have already submitted a proposal for this custom booking");
        setShowPage(false);
        return setTimeout(() => {
          document.location.href = `/`;
        }, 2000);
      } else {
        setTheBooking(data);
        setShowPage(true);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  if (!showPage) return;

  if (!theBooking) return;

  if (usersData?._id && usersData?.role !== "chef")
    return (
      <div className="flex-column for-no-bookings" style={{ marginTop: 50, marginBottom: 50 }}>
        <img src="/images/no-booking.png" alt="ikook" />
        <p className="small boldText">Sorry, only chefs can make proposals for this booking</p>
        <button onClick={() => window.open("/explore", "_blank")} className="themeBtn">
          Book A Chef
        </button>
      </div>
    );

  return (
    <div className="dp-cont">
      <DirectProposalModal
        data={theBooking}
        currency={
          usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
        }
      />
    </div>
  );
};

export default DirectProposals;
