import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import ReviewCard from "../../../components/review-card/review-card";
import AppContext from "../../../context/app-context";

const CdReviews = function ({ display }) {
  const [avgRating, setAvgRating] = useState(0);
  const [reviewsComp, setReviewsComp] = useState([]);

  const { usersData, calculateChefRating } = useContext(AppContext);

  useEffect(() => {
    setAvgRating(calculateChefRating(usersData?.chef_details?.reviews));

    const comp = usersData?.chef_details?.reviews.map((e, i) => <ReviewCard key={i} data={e} />);

    comp.reverse();

    setReviewsComp(comp);
  }, [usersData]);

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <div className="flex-row ud-dash-header" style={{ padding: "0px 40px 0px 40px" }}>
        <p className="big alignTextLeft removemargin">Your Reviews</p>
      </div>

      <div
        className="flex-row ud-dash-header align-row-left"
        style={{ justifyContent: "flex-start", paddingLeft: 30 }}
      >
        <div className="cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">Avg Rating</p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(10, 20, 129, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-rating.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin yellowText" style={{ marginTop: -10 }}>
            {avgRating}/5
          </p>

          <p className={`xsmall removemargin ${avgRating >= 3 ? "greenText" : "redText"}`}>
            {avgRating >= 3 ? "Great Work!" : "Do Better"}
          </p>
        </div>
      </div>

      <div className="flex-row align-row-left the-review-cont">{reviewsComp}</div>
    </div>
  );
};

export default CdReviews;
