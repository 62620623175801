import React, { useContext } from "react";
import "../../styles/user-index.css";
import "../../../../styles/index.css";
import AppContext from "../../../../context/app-context";
import UserDashBoardIndexContext from "../../context/user-index-context";

const TableBookingCard = function ({
  setOnView,
  data,
  setCustomBookingDetails,
  type,
  setBookingDetailsData,
}) {
  const { emitRealDate } = useContext(AppContext);
  const { changeAppScreen } = useContext(UserDashBoardIndexContext);

  if (!data) return;

  return (
    <div
      className="flex-row align-row-left table-booking-card ud-media-table-booking-card"
      style={{}}
      onClick={() => {
        if (type === "custom-booking") {
          setCustomBookingDetails(data);
          changeAppScreen("dashboard");
          setOnView("custom booking");
        } else {
          setBookingDetailsData(data);
          changeAppScreen("dashboard");
          setOnView("chef at home");
        }
      }}
    >
      <p style={{ width: "17%" }} className="tablesmall mobile-edit-table-ud">
        #{data._id}
      </p>

      <p style={{ width: "14%" }} className="tablesmall">
        {emitRealDate(data.booking_date)}
      </p>

      <p
        style={{ width: "14%" }}
        className={`tablesmall ${data.status === "cancelled" ? "redText" : "greenText"}`}
      >
        {data.status}
      </p>

      <p style={{ width: "20%" }} className="tablesmall">
        {data.eventDetailsForLargeEvents?.eventName
          ? data.eventDetailsForLargeEvents?.eventName?.slice(0, 20)
          : "nil"}
      </p>

      <p className="tablesmall ud-edit-booking-type">{data.booking_type}</p>

      <p style={{ width: "14%" }} className="tablesmall mobile-edit-table-ud">
        {data.number_of_guest}
      </p>
    </div>
  );
};

export default TableBookingCard;
