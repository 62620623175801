import React from "react";
import { MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";
import "../../styles/index.css";

export default function ProgressBarLoading({ display, value, whatAreYouDoing }) {
  if (!display) return;

  return (
    <div className="progress-loading-cont">
      <MDBProgress height="30" style={{ width: "80%" }}>
        <MDBProgressBar
          striped
          animated
          bgColor="warning"
          width={value}
          valuemin={0}
          valuemax={100}
        >
          {value}%
        </MDBProgressBar>
      </MDBProgress>

      <p className="small blackText boldText">{whatAreYouDoing}</p>
    </div>
  );
}
