import React from 'react';

const EulaPage = () => {
  return (
    <div className="container mx-auto p-8 text-start">
      <h1 className="text-4xl font-bold mb-8">End User License Agreement (EULA) for iKooK LTD Mobile App</h1>
      <p className="text-gray-700 mb-4">Effective Date: 26 June 2024</p>
      
      <section className="mb-8">
        <p>This End User License Agreement ("EULA") is a legal agreement between you ("User" or "You") and iKooK LTD ("Company", "We", "Our", or "Us"), a company incorporated in the United Kingdom, with its principal office located at D Frensham House, 4 Blakesley Avenue, London W5 2DW.</p>
        <p className="mt-4">By downloading, installing, or using the iKooK LTD mobile application ("App"), you agree to be bound by the terms of this EULA. If you do not agree to the terms of this EULA, do not download, install, or use the App.</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. License Grant</h2>
        <p>iKooK LTD grants you a limited, non-exclusive, non-transferable, revocable license to use the App for personal, non-commercial purposes in accordance with the terms of this EULA.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. App Description</h2>
        <p>The App connects chefs, caterers, cooks, nutritionists, dietitians, farmers, and other service providers ("Providers") to users for booking services on the platform. The App facilitates the connection and transaction between users and Providers but does not itself provide any food or nutrition services.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Restrictions</h2>
        <p>You agree not to:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Copy, modify, or create derivative works based on the App;</li>
          <li>Distribute, transfer, sublicense, lease, lend, or rent the App to any third party;</li>
          <li>Reverse engineer, decompile, or disassemble the App, except to the extent permitted by applicable law;</li>
          <li>Remove, alter, or obscure any proprietary notices on the App;</li>
          <li>Use the App for any illegal or unauthorized purpose.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. User Responsibilities</h2>
        <p>You are solely responsible for:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Ensuring that your use of the App complies with all applicable laws and regulations;</li>
          <li>All activities that occur under your account;</li>
          <li>Maintaining the confidentiality of your account login information;</li>
          <li>Any interactions and transactions between you and the Providers, including the quality, safety, or legality of the services provided;</li>
          <li>Adhering to the Prohibited Conduct section outlined below.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Prohibited Conduct</h2>
        <p>You agree not to:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Post, upload, or share any content that is obscene, offensive, defamatory, or otherwise objectionable;</li>
          <li>Harass, threaten, or abuse other users or Providers;</li>
          <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
          <li>Engage in any activity that disrupts or interferes with the App's functionality;</li>
          <li>Use the App to engage in any fraudulent or illegal activities.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Objectionable Content and Abusive Users</h2>
        <p>a. iKooK LTD reserves the right to remove any content that it deems objectionable or in violation of this EULA.</p>
        <p>b. Users found to be engaging in abusive behavior or violating the Prohibited Conduct section may have their accounts suspended or terminated at iKooK LTD's discretion.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. User-Generated Content</h2>
        <p>a. Users and Providers can upload documents, create content (including text and images), and provide feedback and suggestions through the App.</p>
        <p>b. By submitting any content to the App, you grant iKooK LTD a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with the App and iKooK LTD's business.</p>
        <p>c. You represent and warrant that you own or have the necessary rights and permissions to use and submit all content you provide and that such content does not violate any intellectual property rights, privacy rights, or any other rights of any third party.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Privacy and Data Collection</h2>
        <p>a. The App is available on Google Play Store and Apple App Store.</p>
        <p>b. iKooK LTD may collect certain personal information from users, including but not limited to email addresses, IDs, and other identifying information. Your use of the App is also governed by our Privacy Policy, which can be found at <a href="https://ikook.co.uk/policy" className="text-blue-500 underline">https://ikook.co.uk/policy</a>. By using the App, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Feedback and Suggestions</h2>
        <p>a. Users can provide feedback and suggestions for our App. By submitting feedback and suggestions, you agree that iKooK LTD is free to use them without any restriction or compensation to you.</p>
        <p>b. iKooK LTD may implement any feedback or suggestions received from users at its sole discretion.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Ownership</h2>
        <p>The App is licensed, not sold, to you. iKooK LTD retains all rights, title, and interest in and to the App, including all intellectual property rights. This EULA does not grant you any rights to trademarks or service marks of iKooK LTD.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">11. Updates</h2>
        <p>iKooK LTD may, from time to time, at its sole discretion, provide updates or modifications to the App. These updates may include bug fixes, new features, or enhancements. You agree that iKooK LTD has no obligation to provide any updates or to continue to provide or enable any particular features or functionality.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">12. Termination</h2>
        <p>This EULA is effective until terminated. Your rights under this EULA will terminate automatically without notice from iKooK LTD if you fail to comply with any term(s) of this EULA. Upon termination, you must cease all use of the App and delete all copies of the App in your possession or control.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">13. Disclaimer of Warranties</h2>
        <p>The App is provided "AS IS" and "AS AVAILABLE" without warranty of any kind. To the maximum extent permitted by applicable law, iKooK LTD disclaims all warranties, whether express, implied, or statutory, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. iKooK LTD does not warrant that the App will be uninterrupted or error-free, that defects will be corrected, or that the App is free of viruses or other harmful components.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">14. Limitation of Liability</h2>
        <p>To the maximum extent permitted by applicable law, in no event shall iKooK LTD be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Your use or inability to use the App;</li>
          <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
          <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our App by any third party;</li>
          <li>Any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the App, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not iKooK LTD has been advised of the possibility of such damages.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">15. Governing Law</h2>
        <p>This EULA shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law principles. Any legal action or proceeding arising under this EULA shall be brought exclusively in the courts located in London, England.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">16. Entire Agreement</h2>
        <p>This EULA constitutes the entire agreement between you and iKooK LTD regarding the App and supersedes all prior or contemporaneous understandings regarding such subject matter. No amendment to or modification of this EULA will be binding unless in writing and signed by iKooK LTD.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">17. Agreement to New Terms</h2>
        <p>During the app installation or first launch, you will be required to agree to the terms of this EULA. If you do not agree to these terms, you will not be able to use the App. By continuing to use the App after any modifications to this EULA, you agree to be bound by the revised terms.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">18. Contact Information</h2>
        <p>If you have any questions about this EULA, please contact us at:</p>
        <address>
          iKooK LTD<br />
          27 Old Gloucester Street,<br />
          London,<br />
          WC1N 3AX,<br />
          United Kingdom<br />
          Email: <a href="mailto:support@ikook.co.uk" className="text-blue-500 underline">support@ikook.co.uk</a>
        </address>
      </section>

      <section>
        <p>By downloading, installing, or using the App, you acknowledge that you have read, understood, and agree to be bound by this EULA.</p>
      </section>
    </div>
  );
};

export default EulaPage;
