import React, { useState, useContext, useEffect } from "react";
import "../../../../../menu-page/menu-page.css";
import "../../../../../../styles/index.css";
import { MDBBtn, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import AppContext from "../../../../../../context/app-context";
import { MenuDishesWhatIsThis } from "../../../../../../components/what-is-this-collections/what-is-this-collections";
import { dishDataBackup } from "../../../chef-create-menu";

const DessertUpload = function ({ setDessertData, data }) {
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [menuIngredients, setMenuIngredients] = useState("");

  const [disableAllbtn, setDisableAllBtn] = useState(false);

  const { popup } = useContext(AppContext);

  useEffect(() => {
    if (data?.description) {
      setMenuName(data.description);
      setMenuDescription(data.full_description);
      setMenuIngredients(data.ingredients || "");

      setDisableAllBtn(true);
    }
  }, []);

  function saveState() {
    if (menuName && menuDescription) {
      setDessertData([
        ...dishDataBackup.dessertData,
        {
          description: menuName,
          price: 0,
          full_description: menuDescription,
          ingredients: menuIngredients || "",
        },
      ]);
      dishDataBackup.dessertData = [
        ...dishDataBackup.dessertData,
        {
          description: menuName,
          price: 0,
          full_description: menuDescription,
          ingredients: menuIngredients || "",
        },
      ];
      setDisableAllBtn(true);
    } else popup("incomplete menu");
  }

  return (
    <div className="flex-row flex-gap align-row-left" style={{ alignItems: "flex-start" }}>
      <div className="menu-boxex-settings flex-col align-column-left">
        <h5 className="greyText">
          <MenuDishesWhatIsThis title={"DESSERT DISH"} />
        </h5>

        <MDBInput
          value={menuName}
          disabled={disableAllbtn}
          onChange={(e) => setMenuName(e.target.value)}
          maxLength={70}
          style={{ width: "350px" }}
          className="ac-inputs chef-menu-inputs"
          label="Dessert Menu Name"
          type="text"
        />

        <MDBTextArea
          disabled={disableAllbtn}
          onChange={(e) => setMenuDescription(e.target.value)}
          value={menuDescription}
          className="ac-inputs chef-menu-inputs "
          label="Dessert Menu Description"
          maxLength={300}
          cols={74}
          rows={5}
          type="text"
        />

        <MDBInput
          disabled={disableAllbtn}
          onChange={(e) => setMenuIngredients(e.target.value)}
          value={menuIngredients}
          className="ac-inputs chef-menu-inputs "
          label="Dessert Menu Ingredients"
          maxLength={100}
          type="text"
        />

        <div className="flex-row">
          <MDBBtn
            style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
            disabled={disableAllbtn}
            onClick={saveState}
            className="themeBtn"
          >
            Save
          </MDBBtn>
        </div>
      </div>
    </div>
  );
};

export default DessertUpload;
