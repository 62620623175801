import React, { useEffect, useState, useContext } from "react";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../../styles/index.css";
import TableBookingCard from "../components/child-components/chef-table-booking-card";
import AppContext from "../../../context/app-context";

const CdBookings = function ({ display, dashboardFunctions }) {
  const [tableBookingComp, setTableBookingComp] = useState([]);

  const { usersData, backendServer, setIsLoading, apiKey } = useContext(AppContext);

  useEffect(() => {
    if (dashboardFunctions?.setBookingDetailsData) {
      getCustomBookings(usersData?.chef_details?.chef_bookings);
    }
  }, [usersData, dashboardFunctions]);

  async function getCustomBookings(bookingsArr) {
    if (!bookingsArr) return;

    setIsLoading(true);

    const realBookings = await Promise.all(
      bookingsArr?.map(async (e) => {
        const { data } = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get/${e}`, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        return data;
      })
    );

    const comp = realBookings.map((e, i) => (
      <TableBookingCard
        setOnView={dashboardFunctions?.setOnView}
        setBookingDetailsData={dashboardFunctions?.setBookingDetailsData}
        setCustomBookingData={dashboardFunctions?.setCustomBookingData}
        data={e}
        key={i}
        type={e.booking_type}
      />
    ));

    if (comp.length === 0)
      setTableBookingComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO BOOKINGS YET</p>
          <p className="small">No Bookings to display.</p>
        </div>
      );
    else {
      comp.reverse();
      setTableBookingComp(comp);
    }

    setIsLoading(false);
  }

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <div className="flex-row ud-dash-header">
        <p className="big alignTextLeft removemargin">Your Bookings</p>

        <p className="small yellowText">
          {usersData?.chef_details?.chef_bookings.length} total orders
        </p>
      </div>

      <div
        className="flex-row align-row-left"
        style={{ padding: "0px 0px 0px 0px", justifyContent: "flex-start", marginTop: 40 }}
      >
        <p style={{ width: "20%" }} className="small boldText">
          Booking ID
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Date
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Status
        </p>

        <p style={{ width: "20%" }} className="small boldText">
          Menu
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          User
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Service Type
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Amount
        </p>
      </div>

      {tableBookingComp}

      {/* <div className="page-num-cont">
        <a className="xsmall removemargin rounded-number-yellow" href="">1</a>
      </div> */}
    </div>
  );
};

export default CdBookings;
