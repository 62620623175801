import React, { useState, useEffect, useRef, useContext } from "react";
import "../../styles/index.css";
import "./large-event-explore.css";
import { AiOutlineSearch, AiFillStar } from "react-icons/ai";
import UserCardLargeEvent from "../../components/user-card-large-event/user-card-large-event";
import "../../pages/home/home.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import AppContext from "../../context/app-context";
import { useParams } from "react-router-dom";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { GiCookingPot } from "react-icons/gi";
import "../../components/modal-edit-menu-details.jsx/modal-edit-menu-details.css";

const ExploreLargeEventsChefs = function () {
  const [compsOnShow, setCompsOnShow] = useState([]);

  const { backendServer, apiKey, setIsLoading, popup, calculateChefRating } =
    useContext(AppContext);

  const [queryLocation, setQueryLocation] = useState("");

  const queryRef = useRef(null);

  const topRef = useRef(null);

  const { query } = useParams();

  useEffect(() => {
    topRef.current.scrollIntoView({
      behaviour: "smooth",
    });
  }, []);

  useEffect(() => {
    if (query) {
      findChefByLocation(query);
    } else {
      findChef();
    }
  }, []);

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }

  const findMe = async function () {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const data = await (
        await fetch(
          `https://geocode.xyz/${pos.coords.latitude},${pos.coords.longitude}?auth=497013854179196392715x23250&json=1`
        )
      ).json();

      setQueryLocation(`${data.city}, ${data.country}`);
    });
  };

  const findChef = async (query) => {
    try {
      setIsLoading(true);

      let url;

      if (query) {
        url = `${backendServer}/ikook/api/v1/${apiKey}/user/get?query=only-chefs`;

        const { data } = await (
          await fetch(url, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const gotten = data.find((e) => {
          return (
            e.first_name === capitalizeEachWord(query) ||
            e.username === query ||
            e.last_name === capitalizeEachWord(query) ||
            e.first_name === capitalizeEachWord(query?.split(" ")[0]) ||
            e.last_name === capitalizeEachWord(query?.split(" ")[1] || query?.split(" ")[0]) ||
            e.last_name === capitalizeEachWord(query?.split(" ")[0]) ||
            e.first_name === capitalizeEachWord(query?.split(" ")[1] || query?.split(" ")[0])
          );
        });

        if (!gotten?.first_name) {
          setCompsOnShow(
            <div
              className="is-empty-cont"
              style={{
                width: "70%",
                height: "460px",
              }}
            >
              <img src="/images/no-booking.png" alt="ikook" />
              <p className="small boldText">No User Found 😞</p>

              <p className="small">Try another query</p>
            </div>
          );
          return setIsLoading(false);
        }

        const comp = [gotten].map((e, i) => {
          return <UserCardLargeEvent key={i} data={e} />;
        });

        setCompsOnShow(comp);
      } else {
        url = `${backendServer}/ikook/api/v1/${apiKey}/user/get?query=only-chefs`;

        const { data } = await (
          await fetch(url, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const comp = data.map((e, i) => {
          return <UserCardLargeEvent key={i} data={e} />;
        });

        if (comp.length === 0)
          setCompsOnShow(
            <div
              className="is-empty-cont"
              style={{
                width: "70%",
                height: "460px",
              }}
            >
              <img src="/images/no-booking.png" alt="ikook" />
              <p className="small boldText">No User Found 😞</p>

              <p className="small">Try another query</p>
            </div>
          );
        else setCompsOnShow(comp);
      }

      setIsLoading(false);
    } catch (err) {
      popup(err);
      setIsLoading(false);
    }
  };

  async function findChefByLocation(query) {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?location=${query}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (data?.length === 0) {
        setCompsOnShow(
          <div
            className="is-empty-cont"
            style={{
              width: "70%",
              height: "460px",
            }}
          >
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">No User Found 😞</p>

            <p className="small">No Chefs In That Location</p>
          </div>
        );
        return setIsLoading(false);
      } else {
        const comp = data.map((e, i) => {
          return <UserCardLargeEvent key={i} data={e} />;
        });

        setCompsOnShow(comp);
      }

      setIsLoading(false);
    } catch (err) {
      popup("Failed to fetch");
      setIsLoading(false);
    }
  }

  async function sortBy(paramss) {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?query=only-chefs`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const filtered = data.filter((e) => {
        if (paramss === "reviews") return calculateChefRating(e.chef_details.reviews) >= 3;
        if (paramss === "menus") return e.chef_details.chef_menus.length > 3;
      });

      if (filtered.length === 0) {
        setCompsOnShow(
          <div
            className="is-empty-cont"
            style={{
              width: "70%",
              height: "460px",
            }}
          >
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">No Chefs Found 😞</p>

            <p className="small">Try another query</p>
          </div>
        );
        return setIsLoading(false);
      }

      const comp = filtered.map((e, i) => {
        return <UserCardLargeEvent key={i} data={e} />;
      });

      setCompsOnShow(comp);

      setIsLoading(false);
    } catch (err) {
      popup(err);
      setIsLoading(false);
    }
  }

  return (
    <div className="explore-cont">
      <div className="smart-search-explore-le smart-search-large-event">
        <p ref={topRef} className="big" style={{ marginLeft: 30 }}>
          Search for the Best Private Chef for Large Events
        </p>

        <div className="search-cont-explore-le">
          <div className="insearch-explore-le">
            <p className="big" style={{ color: "#fcc01c", fontSize: "large" }}>
              Location
            </p>

            <input
              onChange={(e) => setQueryLocation(e.target.value)}
              className="explore-location in"
              placeholder={queryLocation}
            />
          </div>

          <MDBBtn onClick={findMe} color="#ffd93b" className="small themeBtn">
            Find Me
          </MDBBtn>

          <div className="insearch-explore-le">
            <p className="big" style={{ color: "#fcc01c", fontSize: "large" }}>
              Guests
            </p>

            <div className="filter-inputs">
              <MDBInput
                style={{ height: 35 }}
                className="booking-inputs booking-inputss"
                label="Number of Children"
                type="number"
                placeholder="Children"
              />

              <MDBInput
                style={{ height: 35 }}
                className="booking-inputs booking-inputss"
                label="Number of Teens"
                type="number"
                placeholder="Teens"
              />

              <MDBInput
                style={{ height: 35 }}
                className="booking-inputs booking-inputss"
                label="Number of Adults"
                type="number"
                placeholder="Adults"
              />
            </div>
          </div>

          <button
            onClick={() =>
              (document.location.href = `/meal-prep/chefs/${
                queryLocation.split(",")[0].split(" ")[0]
              }`)
            }
            className="themeBtn home-search-explore"
          >
            <AiOutlineSearch />
          </button>
        </div>
      </div>

      <div className="flex-row not-satisfied">
        <p className="big">
          Not satisfied with the Chefs? Use the custom booking to get exactly what you want
        </p>

        <button onClick={() => (document.location.href = "/cb")} className="themeBtn">
          Custom Booking
        </button>
      </div>

      <div className="flex-row filter-and-rest">
        <div className="lineafter">
          <p className="small">{compsOnShow.length} Chef(s) Available</p>
        </div>

        <div className="lineafter" style={{ gap: 15 }}>
          <p className="small">Filter By:</p>

          <button
            onClick={() => sortBy("menus")}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-price-btn"
          >
            <GiCookingPot />
            &nbsp; Cuisine
          </button>

          <button
            onClick={() => sortBy("reviews")}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-cuisines-btn"
          >
            <AiFillStar />
            &nbsp; High Rating
          </button>
        </div>

        <div className="lineafter" style={{ gap: 15, borderRightWidth: 0 }}>
          <input
            ref={queryRef}
            className="explore-location in"
            placeholder="chef-name; e.g. esther"
          />

          <button
            onClick={(e) => findChef(queryRef.current.value)}
            className="themeBtn home-search-explore whiteBg"
          >
            <AiOutlineSearch />
          </button>
        </div>
      </div>

      <div className="our-services-large-explore">{compsOnShow}</div>

      {/* <p style={{ "fontSize": "small" }} className="page-nav">Page &nbsp;&nbsp;&nbsp; <a href="#">&nbsp;1</a></p> */}

      <ForPageActions />
    </div>
  );
};

export default ExploreLargeEventsChefs;
