import { useState } from "react";
import IndividualComment from "./comment";
import ReplyForm from "./replyForm";

const Comments = ({ comments, id }) => {
  const [reply, setReply] = useState({});

  return (
    <div className="comments-container">
      {comments && (
        <>
          {comments?.length > 0 && (
            <p className="comments-heading">
              {comments?.length} Comment{comments?.length > 1 ? "s" : ""}
            </p>
          )}
          {comments?.map((comment, i) => {
            return <IndividualComment key={i} comment={comment} setReply={setReply} />;
          })}
        </>
      )}
      <ReplyForm reply={reply} setReply={setReply} id={id} />
    </div>
  );
};

export default Comments;
