import React, { useState, useContext, useEffect } from "react";
import "../../../../../menu-page/menu-page.css";
import "../../../../../../styles/index.css";
import { MDBBtn, MDBFile } from "mdb-react-ui-kit";
import AppContext from "../../../../../../context/app-context";

const MenuImageCard = function ({ menuImages, setMenuImages, type }) {
  const [menuPic, setMenuPic] = useState("");

  const [placeholderImage, setPlaceholderImage] = useState("/images/img-placeholder.png");

  const [disableAllbtn, setDisableAllBtn] = useState(false);

  const { popup } = useContext(AppContext);

  useEffect(() => {
    if (menuImages[type]) {
      const file = menuImages[type];

      const reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          setPlaceholderImage(e.target.result);
        };
      })(file);

      reader.readAsDataURL(file);

      setDisableAllBtn(true);
    }
  }, []);

  function saveState() {
    if (menuPic) {
      setMenuImages([...menuImages, menuPic]);
      setDisableAllBtn(true);
    } else popup("No Image Attached");
  }

  function handleFileChange(e) {
    setMenuPic(e.target.files[0]);

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (function (theFile) {
      return function (e) {
        setPlaceholderImage(e.target.result);
      };
    })(file);

    reader.readAsDataURL(file);
  }

  return (
    <div className="flex-row flex-gap align-row-left" style={{ alignItems: "flex-start" }}>
      <div
        className="chef-details-boxes-settings flex-col align-column-left"
        style={{ height: "300px", gap: 15 }}
      >
        <h5 className="greyText">MAIN IMAGE CATALOGUE</h5>

        <p className="greyText small">
          (Recommended 1000px width, 1000px height.
          <br /> Maximum of 1MB file size)
        </p>

        <MDBFile
          accept="image/jpg,image/png,image/jpeg"
          disabled={disableAllbtn}
          onChange={handleFileChange}
          style={{ width: "350px" }}
          placeholder="Attach Menu Image"
        />

        <MDBBtn
          style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
          disabled={disableAllbtn}
          onClick={saveState}
          className="themeBtn"
        >
          Save
        </MDBBtn>
      </div>

      <img
        className="add-a-image-image"
        src={placeholderImage}
        alt="menu"
      />
    </div>
  );
};

export default MenuImageCard;
