import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import "../../../components/menu-card-small/menu-card-small.css";
import AppContext from "../../../context/app-context";

const ChatHistoryCard = function ({ unread, data, setActiveChat }) {
  const [firstUserDetails, setFirstUserDetails] = useState("");
  const [secondUserDetails, setSecondUserDetails] = useState("");

  const [isUnRead, setisUnRead] = useState(false);

  const [theUserYouAreChattingWith, setTheUserYouAreChattingWith] = useState({});

  const {
    usersData,
    setIsLoading,
    apiKey,
    popup,
    backendServer,
    getSignedAwsUrl,
    setNotCount,
    setMsgCount,
  } = useContext(AppContext);

  const [nameToShow, setNameToShow] = useState("...................");
  const [messageToShow, setMessageToShow] = useState("...................");
  const [dateToShow, setDateToShow] = useState("...................");
  const [imageToShow, setImageToShow] = useState("/images/user_placeholder.png");

  useEffect(() => {
    setisUnRead(unread);
  }, [unread]);

  useEffect(() => {
    (async function () {
      if (!firstUserDetails || !secondUserDetails) return;

      setNameToShow(
        usersData?._id !== data?.firstUser
          ? `${firstUserDetails.username}`.slice(0, 30) + "."
          : `${secondUserDetails.username}`.slice(0, 30) + "."
      );

      setMessageToShow(data?.messages[data?.messages?.length - 1]?.message?.slice(0, 20));

      if (data?.messages[data?.messages?.length - 1]?.createdAt) {
        setDateToShow(
          new Intl.DateTimeFormat(navigator.language, {
            day: "2-digit",
            month: "2-digit",
          }).format(new Date(data?.messages[data?.messages?.length - 1]?.createdAt))
        );
      }

      if (usersData?._id !== data?.firstUser) {
        if (firstUserDetails?.photo) {
          setImageToShow(await getSignedAwsUrl(firstUserDetails?.photo, "users"));
        }
      } else {
        if (secondUserDetails?.photo) {
          setImageToShow(await getSignedAwsUrl(secondUserDetails?.photo, "users"));
        }
      }

      if (usersData?._id !== data?.firstUser) setTheUserYouAreChattingWith(firstUserDetails);
      else setTheUserYouAreChattingWith(secondUserDetails);
    })();
  }, [firstUserDetails, secondUserDetails]);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);

        const firstuser = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data?.firstUser}`, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        setFirstUserDetails(firstuser.data);

        const secondUser = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data?.secondUser}`, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        setSecondUserDetails(secondUser.data);

        setIsLoading(false);
      } catch (err) {
        popup("Failed to fetch some information");
      }
    })();

    return () => setImageToShow("/images/user_placeholder.png");
  }, [data]);

  async function readRoom() {
    await fetch(
      `${backendServer}/ikook/api/v1/${apiKey}/chat/room/read/${data._id}/${usersData._id}`,
      {
        method: "PATCH",
        credentials: "include",
        headers: {
          token: localStorage.getItem("ikooktoken"),
        },
      }
    );

    const dataaa = await (
      await fetch(
        `${backendServer}/ikook/api/v1/${apiKey}/user/get/${localStorage.getItem("ikookusername")}`,
        {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        }
      )
    ).json();

    setNotCount(dataaa?.data?.notifications?.filter((e) => e.isNotificationRead === false)?.length);

    setMsgCount(dataaa?.data?.unReadRooms?.length);

    setisUnRead(false);
  }

  return (
    <div
      onClick={async () => {
        setActiveChat({ ...data, theUserYouAreChattingWith: { ...theUserYouAreChattingWith } });
        readRoom();
      }}
      className={`chat-history-card ${
        isUnRead ? "chat-history-card-active" : ""
      } cursor media-ud-history-card`}
    >
      <img className="cd-history-card-img" src={imageToShow} alt="ikoo user" />

      <div className="ch-h-2">
        <p className="xsmall removemargin boldText">{nameToShow}</p>

        <p className="xsmall removemargin">{messageToShow}...</p>
      </div>

      <div className="ch-h-2 ch-h-2-two media-stat-history-ud" style={{ alignItems: "flex-end" }}>
        <p className="xsmall removemargin c-yellowText" style={{ fontSize: "xx-small" }}>
          {dateToShow}
        </p>

        <p
          className={`xsmall removemargin rounded-number-yellow ${unread ? "" : "ud-opa"} `}
          style={{ padding: 10 }}
        >
          📩
        </p>
      </div>
    </div>
  );
};

export default ChatHistoryCard;
