import React from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../menu-page/menu-page.css";
import "../../../../../components/auth/login/login-modal.css";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";

const ChefPassword = function ({ activeNav }) {
  if (activeNav !== "password") return;

  return (
    <div className="cd-box-1-in-acc media-chef-cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll", paddingTop: 20 }}>
      <MDBInput
        style={{ width: "300px" }}
        className="ac-inputs"
        label="Current Password"
        type="text"
      />

      <MDBInput style={{ width: "300px" }} className="ac-inputs" label="New Password" type="text" />

      <div className="flex-row align-row-left">
        <MDBBtn
          style={{ backgroundColor: "#fcc01c", color: "black" }}
          disabled={true}
          className="small themeBtn"
        >
          Save Changes
        </MDBBtn>
      </div>
    </div>
  );
};

export default ChefPassword;
