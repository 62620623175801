import React, { useContext } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../../components/menu-card-small/menu-card-small.css";
import AppContext from "../../../context/app-context";

const NotificationCard = function ({ read, data }) {
  const { usersData, updateUsersData, setIsLoading, backendServer, apiKey, emitRealDate } =
    useContext(AppContext);

  const deleteNotification = async function () {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/user/notification/delete/${usersData._id}/${data._id}`,
          {
            method: "DELETE",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`noti-card-cont cursor ${read ? "" : "noti-card-cont-unread"}`}
      style={{ width: "90%" }}
    >
      <img src="/images/noti.png" alt="ikook notification" />

      <p className="small removemargin alignTextLeft" style={{ width: "60%" }}>
        {data?.notificationMessage}
      </p>

      <div className="not-det-small-col" style={{ width: "5%" }}>
        <p className="ts-btn removemargin">{data?.notificationType}</p>

        <p className="xsmall removemargin">
          {data?.createdAt ? emitRealDate(data?.createdAt) : ""}
        </p>
      </div>

      <img onClick={deleteNotification} src="/images/delete.png" alt="ikook notification" />
    </div>
  );
};

export default NotificationCard;
