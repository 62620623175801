import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import NotificationCard from "../components/chef-noti-card";
import AppContext from "../../../context/app-context";

const CdNotifications = function ({ display }) {
  const [notArr, setNotArr] = useState([]);

  const { usersData } = useContext(AppContext);

  function renderNotifications() {
    const comp = usersData.notifications.map((e, i) => {
      return <NotificationCard read={e?.isNotificationRead ? true : false} key={i} data={e} />;
    });

    comp.reverse();

    setNotArr(comp);
  }

  useEffect(() => {
    renderNotifications();
  }, [usersData]);

  if (!display) return;

  return (
    <div className="ud-notifcations-cont">
      <div
        className="flex-row ud-dash-header"
        style={{ padding: "0px 40px 0px 40px", marginBottom: 5 }}
      >
        <p className="big alignTextLeft removemargin">Your Notifications</p>
      </div>

      {notArr.length === 0 ? (
        <div className="is-empty-cont">
          <img src="/images/no-booking.png" alt="ikook" />

          <p className="small boldText">No new Notifications at this time!</p>
        </div>
      ) : (
        notArr
      )}
    </div>
  );
};

export default CdNotifications;
