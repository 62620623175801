import React, { useContext, useEffect, useState } from "react";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../../styles/index.css";
import { BiMessageSquareDots, BiNotification } from "react-icons/bi";
import { BiWallet } from "react-icons/bi";
import AppContext from "../../../context/app-context";

const ChefDashboardHeader = function ({ msgCount, notCount }) {
  const [balance, setBalance] = useState(0);

  const { usersData } = useContext(AppContext);

  useEffect(() => {
    let currBalance = 0;

    usersData?.withdrawals.forEach((e) => {
      if (e?.status === "paid") return;
      currBalance = currBalance + e?.amount;
    });

    setBalance(currBalance || 0);
  }, [usersData]);

  return (
    <div className="ud-header-cont media-chef-dashboard-header">
      <div></div>

      <div className="ud-stat" style={{ width: "20%" }}>
        <div className="ud-stat-each">
          <BiNotification size={30} />

          <p className="small yellowText">{notCount}</p>
        </div>

        <div className="ud-stat-each">
          <BiMessageSquareDots size={30} />

          <p className="small yellowText">{msgCount}</p>
        </div>

        <div className="ud-wallet-balance">
          <BiWallet size={30} />

          <p className="small removemargin">{balance}</p>
        </div>
      </div>
    </div>
  );
};

export default ChefDashboardHeader;
