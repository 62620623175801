import React, { useContext, useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBTextArea,
  MDBInput,
} from "mdb-react-ui-kit";
import "../../styles/index.css";
import AppContext from "../../context/app-context";
import "./add-dish-modal.css";
import "../../pages/chef-dashboard/styles/chef-index.css";
import { MdOutlineFreeBreakfast, MdOutlineDinnerDining, MdOutlineFastfood } from "react-icons/md";
import { GiRiceCooker } from "react-icons/gi";
import { MenuDishesWhatIsThis } from "../what-is-this-collections/what-is-this-collections";

const AddADishModal = ({ display, setShowAddDishModal, menuID }) => {
  const [disableBtn, setDisableBtn] = useState(true);
  const [selected, setSelected] = useState("");
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [menuIngredients, setMenuIngredients] = useState("");

  const [menuData, setMenuData] = useState({});

  const { backendServer, apiKey, setIsLoading, popup } = useContext(AppContext);

  useEffect(() => {
    if (menuName && selected && menuDescription && menuID) setDisableBtn(false);
    else setDisableBtn(true);
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${menuID}`, {
            method: "GET",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setMenuData(returned?.data);
        setIsLoading(false);
      } catch (err) {
        popup("Failed to get Menu Data. Please try again later...");
        setIsLoading(false);
        setShowAddDishModal(false);
      }
    })();
  }, [menuID]);

  async function addDish() {
    if (selected === "starter" && menuData.menustarter.length >= 5)
      return popup(`Sorry, you have a maximum of five dishes for your ${selected} menu`);

    if (selected === "main" && menuData.menumain.length >= 5)
      return popup(`Sorry, you have a maximum of five dishes for your ${selected} menu`);

    if (selected === "side" && menuData.menuside.length >= 5)
      return popup(`Sorry, you have a maximum of five dishes for your ${selected} menu`);

    if (selected === "dessert" && menuData.menudessert.length >= 5)
      return popup(`Sorry, you have a maximum of five dishes for your ${selected} menu`);

    try {
      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/add/dish/${menuID}/${selected}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({
              description: menuName,
              price: 0,
              full_description: menuDescription,
              ingredients: menuIngredients || "",
            }),
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your dish has been added!");

      setIsLoading(false);
      setShowAddDishModal(false);

      setTimeout(() => {
        document.location.reload();
      }, 2000);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowAddDishModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <h6 className="removemargin">
                Add Dish to <span className="yellowText">{menuData?.menuName}</span>
              </h6>
            </MDBModalHeader>

            <MDBModalBody>
              <div className="flex-column" style={{ gap: 40 }}>
                <div className="flex-row" style={{ gap: "40px" }}>
                  <div
                    onClick={() => {
                      setSelected("starter");
                    }}
                    className={`div-image-text-cb ${
                      selected === "starter" ? "active-nav-indicator" : ""
                    }`}
                  >
                    <MdOutlineFreeBreakfast size={40} />
                    <p className="small">Starter Dish</p>
                  </div>

                  <div
                    onClick={() => {
                      setSelected("main");
                    }}
                    className={`div-image-text-cb ${
                      selected === "main" ? "active-nav-indicator" : ""
                    }`}
                  >
                    <GiRiceCooker size={40} />
                    <p className="small">Main Dish</p>
                  </div>

                  <div
                    onClick={() => {
                      setSelected("dessert");
                    }}
                    className={`div-image-text-cb ${
                      selected === "dessert" ? "active-nav-indicator" : ""
                    }`}
                  >
                    <MdOutlineDinnerDining size={40} />
                    <p className="small">Dessert Dish</p>
                  </div>

                  <div
                    onClick={() => {
                      setSelected("side");
                    }}
                    className={`div-image-text-cb ${
                      selected === "side" ? "active-nav-indicator" : ""
                    }`}
                  >
                    <MdOutlineFastfood size={40} />
                    <p className="small">Side Dish</p>
                  </div>
                </div>

                <div className="menu-boxex-settings flex-col align-column-left">
                  <h5 className="greyText">
                    <MenuDishesWhatIsThis title={`${selected.toUpperCase()} DISH`} />
                  </h5>

                  <MDBInput
                    value={menuName}
                    onChange={(e) => setMenuName(e.target.value)}
                    maxLength={70}
                    className="ac-inputs chef-menu-inputs"
                    label="Starter Menu Name"
                    type="text"
                  />

                  <MDBTextArea
                    onChange={(e) => setMenuDescription(e.target.value)}
                    value={menuDescription}
                    className="ac-inputs chef-menu-inputs "
                    label="Starter Menu Description"
                    maxLength={300}
                    cols={74}
                    rows={5}
                    type="text"
                  />

                  <MDBInput
                    onChange={(e) => setMenuIngredients(e.target.value)}
                    value={menuIngredients}
                    className="ac-inputs chef-menu-inputs "
                    label="Starter Menu Ingredients"
                    maxLength={100}
                    type="text"
                  />
                </div>

                <MDBBtn
                  className="mdbBtn"
                  style={{ backgroundColor: "#fcc01c", color: "black" }}
                  disabled={disableBtn}
                  onClick={addDish}
                >
                  Add Dish
                </MDBBtn>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AddADishModal;
