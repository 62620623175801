import React, { useContext, useEffect, useState } from "react";
import "../menu-card-small/menu-card-small.css";
import "../../styles/index.css";
import { AiOutlineAim, AiFillEdit } from "react-icons/ai";
import AppContext from "../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";
import "./utility-menu-card-small.css";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import DeletePopConfirm from "./pop-delete-menu";
import { MdDelete } from "react-icons/md";

const ChefUtilityMenuCard = function ({ data, setOnView, setEditMenuData }) {
  const [menuImage, setMenuImage] = useState("");
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");

  const [showUtilities, setShowUtilities] = useState(false);
  const [showDeletePop, setShowDeletePop] = useState(false);

  const { getSignedAwsUrl, convertAmount } = useContext(AppContext);

  const menu = { ...data };

  useEffect(() => {
    if (!data) return;
    (async function () {
      if (menu.chefID.photo) setChefImage(await getSignedAwsUrl(menu.chefID.photo, "users"));

      setMenuImage(await getSignedAwsUrl(menu.images[0], "menus"));
    })();

    return () => setMenuImage("");
  }, [data]);

  if (!data) return;

  return (
    <div
      className="menu-card-small-cont utility-menu-card"
      onMouseEnter={() => setShowUtilities(true)}
      onMouseLeave={() => {
        setShowUtilities(false);
        setShowDeletePop(false);
      }}
    >
      {showUtilities && (
        <div className="menu-action-cont">
          <AiFillEdit
            title="Edit this menu"
            onClick={() => {
              setEditMenuData(data);
              setOnView("edit menu");
            }}
            className="cursor media-chef-create-menu"
            color="black"
            size={40}
          />

          <FaExternalLinkSquareAlt
            title="Preview this menu"
            onClick={() => window.open(`/menu/${data._id}`, "_blank")}
            className="cursor"
            color="black"
            size={35}
          />

          <MdDelete
            title="Delete this menu"
            onClick={() => setShowDeletePop(true)}
            className="cursor"
            color="black"
            size={43}
          />

          <DeletePopConfirm
            setShowDeletePop={setShowDeletePop}
            display={showDeletePop}
            menu={data}
          />
        </div>
      )}
      <div style={{ width: "300px", height: "250px" }}>
        {menuImage ? (
          ""
        ) : (
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        )}
      </div>

      <div style={{ width: "300px", height: "250px" }}>
        {menuImage ? (
          <img className="menu-card-small-img" src={menuImage} alt="iKooK Chef Menu" />
        ) : (
          ""
        )}
      </div>

      <div className="roww">
        <h6 className="blackText" style={{ marginBottom: 0, fontWeight: "bold" }}>
          {menu.menuName.length < 20 ? menu.menuName : menu.menuName.slice(0, 20) + "..."}{" "}
        </h6>

        <p className="removemargin blackText boldText small">
          {convertAmount(menu.menuPrice, menu.currency)}
        </p>
      </div>

      <div className="roww">
        <div className="roww-one">
          <img className="menu-img" src={chefImage} alt="iKooK Chef" />

          <div className="menu-det">
            <p className="small removemargin blackText">Chef {menu.chefID.first_name}</p>

            <p className="small removemargin alignTextLeft">
              <AiOutlineAim color="#fcc01c" /> {menu.chefID.city.split(" ")[0]}
            </p>
          </div>
        </div>

        <div className="roww-two">
          <div className="menu-det-two">
            <p
              className={`ts-btn-yellow removemargin boldText ${
                menu?.status === "approved" ? "greenText" : "redText"
              }`}
            >
              {menu?.status === "approved" ? "LIVE" : menu?.status}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChefUtilityMenuCard;
