import React, { useState, useContext, useLayoutEffect } from "react";
import "./calendar-card.css";
import { MdPriceChange, MdLocationCity } from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { MDBSwitch } from "mdb-react-ui-kit";
import AppContext from "../../context/app-context";

const CalendarCard = ({ data, type }) => {
  const [showDetails, setShowDetails] = useState(false);

  const [dynamicColorType, setDynamicColorType] = useState("");

  const [monthNumber, setMonthNumber] = useState("");
  const [monthToShow, setMonthToShow] = useState("");

  const [menus, setMenus] = useState([]);

  const [day, setDay] = useState("");
  const [year, setYear] = useState("");

  const [radioOn, setRadioOn] = useState(true);

  const {
    updateUsersData,
    backendServer,
    setIsLoading,
    apiKey,
    convertAmount,
    emitRealDate,
    popup,
  } = useContext(AppContext);

  useLayoutEffect(() => {
    if (type === "custom-booking") {
      (async function () {
        const realMenus = await Promise.all(
          data?.proposals
            .find((el) => el.proposal_status === "accepted")
            .menus_proposed_ids.map(async (e) => {
              const { data } = await (
                await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${e}`, {
                  credentials: "include",
                  headers: {
                    token: localStorage.getItem("ikooktoken"),
                  },
                })
              ).json();

              return data;
            })
        );

        const comps = realMenus.map((e, i) => (
          <p key={i} className="calendar-menu-card">
            {e.menuName}
          </p>
        ));

        setMenus(comps);
      })();
    } else if (type === "chef-at-home") {
      const comps = data.addons.map((e, i) => (
        <p key={i} className="calendar-menu-card">
          {e.description}
        </p>
      ));
      setMenus(comps);
    } else if (type === "meal-prep") {
      const arrOfTheMenuName = [];

      data.addons.forEach((e) => {
        if (arrOfTheMenuName.includes(e.description)) return;
        else arrOfTheMenuName.push(e.description);
      });

      const comps = arrOfTheMenuName.map((e, i) => (
        <p key={i} className="calendar-menu-card">
          {e}
        </p>
      ));

      setMenus(comps);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (monthNumber === "01") setMonthToShow("January");
    if (monthNumber === "02") setMonthToShow("February");
    if (monthNumber === "03") setMonthToShow("March");
    if (monthNumber === "04") setMonthToShow("April");
    if (monthNumber === "05") setMonthToShow("May");
    if (monthNumber === "06") setMonthToShow("June");
    if (monthNumber === "07") setMonthToShow("July");
    if (monthNumber === "08") setMonthToShow("August");
    if (monthNumber === "09") setMonthToShow("September");
    if (monthNumber === "10") setMonthToShow("October");
    if (monthNumber === "11") setMonthToShow("November");
    if (monthNumber === "12") setMonthToShow("December");
  }, [monthNumber]);

  useLayoutEffect(() => {
    setMonthNumber(data?.startDate.split("+")[0].split("-")[1]);
    setDay(data?.startDate.split("+")[0].split("-")[2]);
    setYear(data?.startDate.split("+")[0].split("-")[0]);

    if (type === "chef-at-home") setDynamicColorType("calendar-cah-type");
    if (type === "meal-prep") setDynamicColorType("calendar-mp-type");
    if (type === "custom-booking") setDynamicColorType("calendar-le-type");
  }, [data]);

  const actOnBooking = async (doWhat) => {
    try {
      setIsLoading(true);

      if (doWhat === "cancel") {
        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/cancel/${data._id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Booking Cancelled");

        updateUsersData();

        setIsLoading(false);
      }
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`calendar-cont`}
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
    >
      <p className={`calendar-type removemargin ${dynamicColorType}`}>{type}</p>

      <p className="xsmall alignTextLeft">
        With {data?.user?.first_name + " " + data?.user?.last_name}
      </p>

      <div className="flex-row">
        <p className="big">
          {monthToShow.trim()} {day.trim()}, {year.trim()}
        </p>
      </div>

      <div className="flex-row align-row-left a-little-gap">
        <p className="xsmall removemargin">
          <MdPriceChange size={18} />{" "}
          {convertAmount(
            data?.amount ? data?.amount : "0",
            data?.menuID?.currency || data?.chef?.country
          )}
        </p>

        <p className="xsmall removemargin">
          <BsFillPeopleFill size={18} /> {data?.number_of_guest} Guests
        </p>
      </div>

      <div className="flex-row align-row-left a-little-gap" style={{ marginTop: 2 }}>
        <p className="xsmall removemargin alignTextLeft">
          <MdLocationCity size={18} /> {data?.location?.addressString?.slice(0, 30)}...
        </p>
      </div>

      {showDetails && (
        <div className="details-cont">
          <div className="each-calendar-details">
            <div className="flex-column align-column-left">
              <p className="big removemargin">
                {monthToShow.trim()} {day.trim()}, {year.trim()}
              </p>

              <p className="small removemargin greyText">
                Event starts @ {data.startDate.split("+")[1].trim()}{" "}
                {Number(data.startDate.split("+")[1].trim().split(":")[0]) > 12 ? "pm" : "am"} GMT
                +1
              </p>
            </div>
          </div>

          <div className="each-calendar-details">
            <div className="flex-column align-column-left">
              <p className={`calendar-type ${dynamicColorType}`}>
                {type} with {data?.user?.first_name + " " + data?.user?.last_name}
              </p>

              <p className="xsmall removemargin" style={{ marginLeft: 5 }}>
                {data?.number_of_adults} Adult(s). {data?.number_of_teens} Teen(s).{" "}
                {data?.number_of_children} Kid(s)
              </p>
            </div>
          </div>

          <div className="each-calendar-details">
            <div className="flex-row" style={{ justifyContent: "space-between" }}>
              <p className="small removemargin alignTextLeft">Change Availability</p>

              <MDBSwitch
                checked={radioOn}
                onChange={() => {
                  setRadioOn(false);
                  actOnBooking("cancel");
                }}
                btnColor="warning"
                id="flexSwitchCheckSMS"
                style={{ backgroundColor: "#f8e69c" }}
              />
            </div>

            <p className="xsmall removemargin alignTextLeft">
              Booking Date: {emitRealDate(data?.booking_date)}
            </p>

            <p className="xsmall removemargin alignTextLeft">
              Full Address: {data?.location?.addressString}.
            </p>
          </div>

          <div className="each-calendar-details">
            <div className="flex-column align-column-left" style={{ gap: 10 }}>
              <p className="small removemargin">Menu(s)</p>

              {menus}
            </div>

            <div className="flex-row" style={{ justifyContent: "flex-end" }}>
              <p className="xsmall removemrgin">Booking #{data?._id}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarCard;
