export const questions = [
  {
    id: 1,
    question: "How do I register as a Chef?",
    target: "chef",
    answer:
      "You can sign up from our website and upload all the required documents via your dashboard. Once your account is approved, you can list your menus and begin getting bookings.",
  },
  {
    id: 2,
    question: "Do I need to pay to register?",
    target: "chef",
    answer:
      "Registration and onboarding as an iKooK Private Chef comes at no cost to you at all; we only charge commission on all successful bookings.",
  },
  {
    id: 3,
    question: "How do I get bookings?",
    target: "chef",
    answer:
      "You can get bookings by creating menus and updating your availability. It's also a good idea to complete your profile.",
  },
  {
    id: 4,
    question: "Can I send in my menu?",
    target: "chef",
    answer:
      "Yes, you can either send in your menu as a private chef or upload it yourself.",
  },
  {
    id: 5,
    question: "Can I set bookings availability?",
    target: "chef",
    answer:
      "Yes, you are expected to provide details on your availability and always update your dashboard accordingly.",
  },
  {
    id: 6,
    question: "Do I get rated for my work by the clients?",
    target: "chef",
    answer:
      "Clients are always encouraged to rate your service, and these feedbacks will be passed on to your account.",
  },
  {
    id: 7,
    question: "Can I cancel a previously accepted booking?",
    target: "chef",
    answer:
      "You can cancel a previously accepted booking as long as it is done 10 days before event day. Inform the customer in advance, so that the customer can make an alternative arrangement.",
  },
  {
    id: 8,
    question: "How do I get paid?",
    target: "chef",
    answer:
      "Your payment will be sent to you via bank transfer when the booking from your client is completed and marked done.",
  },
  {
    id: 9,
    question: "How far in advance do I need to book?",
    target: "host",
    answer:
      "We recommend you book your chef as soon as possible so that a chef can be secured for you in a timely manner and to avoid disappointment.",
  },
  {
    id: 10,
    question: "Will you cook at my location?",
    target: "host",
    answer: "Yes, all dishes will be prepared fresh at your desired location.",
  },
  {
    id: 11,
    question: "Will I have to do the grocery shopping?",
    target: "host",
    answer: "No, The chef will handle the grocery shopping.",
  },
  {
    id: 12,
    question: "What time will the chef arrive?",
    target: "host",
    answer:
      "The chef will arrive few hours prior to the start of your service time. Times may differ based on party size. You will also be notified on the day of your service.",
  },
  {
    id: 13,
    question: "What do I need to provide?",
    target: "host",
    answer:
      "A clean and fully functional kitchen space, including pots and pans. Tables, chairs and cutlery for you and your guests. Our chefs will arrive equipped with small pieces of their own equipment and any cookware if needed.",
  },
  {
    id: 14,
    question: "Can i set specific dietary requirements?",
    target: "host",
    answer:
      "At the time of booking, you can notify us of any dietary requirements that your guests may have. You can also notify us after the booking is made by emailing us at team@ikook.co.uk.",
  },
  {
    id: 15,
    question: "Do I have to choose one of the set menus?",
    target: "host",
    answer:
      "No, if there is anything particular that you would like to be added to your menu, you can let us know beforehand and we will do our best to cater to your request.",
  },
  {
    id: 16,
    question: "Do you offer table/event setup services?",
    target: "host",
    answer:
      "No, you are responsible for your decorating/setting up. But, we have a good partnership with a decorating/setting up company as an add-on service if it is required.",
  },
];
