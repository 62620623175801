import { useState, useEffect } from "react";
import "./cities.css";
import { cities } from "./data";
import "../../styles/index.css";
import { useParams } from "react-router-dom";

const Cities = ({ country }) => {
  const [ikookChefIncomps, setiKooKChefInComps] = useState([]);
  const [ikookPrivateChefIncomps, setiKooKPrivateChefInComps] = useState([]);

  const { countryy } = useParams();

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }

  useEffect(() => {
    setiKooKChefInComps([
      cities[country || countryy]?.map((e, i) => (
        <div
          className="city-card"
          key={i}
          onClick={() => {
            document.location.href = `/how-it-works/user/book/${e?.city
              ?.split(" ")[0]
              .toLowerCase()}/${country || countryy}`;
          }}
        >
          <p className="small removemargin">iKooK chef in</p>
          <p className="small boldText removemargin">{e?.city?.split(" ")[0]}</p>
        </div>
      )),
    ]);

    setiKooKPrivateChefInComps(
      cities[country || countryy]?.map((e, i) => (
        <div
          className="city-card"
          key={i}
          onClick={() => {
            document.location.href = `/how-it-works/user/private/${e?.city
              ?.split(" ")[0]
              .toLowerCase()}/${country || countryy}`;
          }}
        >
          <p className="small removemargin">Private chef in</p>
          <p className="small boldText removemargin">{e?.city?.split(" ")[0]}</p>
        </div>
      ))
    );
  }, [country]);

  return (
    <div className="cities-cont">
      <p className="vbig BlackText boldText" style={{ textAlign: "center" }}>
        Book iKooK Chef in Nearby Cities
      </p>

      <p className="small cities-p-media" style={{ textAlign: "center" }}>
        Discover cities in {capitalizeEachWord(country || countryy)} where you can book a private
        chef
      </p>

      <div className="the-cities-cont">{ikookChefIncomps}</div>

      <p className="big BlackText boldText" style={{ marginTop: 40, textAlign: "center" }}>
        Book a Private Chef in Nearby Cities
      </p>

      <div className="the-cities-cont">{ikookPrivateChefIncomps}</div>
    </div>
  );
};

export default Cities;
