import React from "react";
import Intro from "./Intro";
import "./chefjoin.css";
import "../../../src/styles/index.css";
import Join from "./Join";
import Scope from "./Scope";
import FAQ from "../../components/FAQ/FAQ"
import "../../styles/index.css";
import "../user-dashboard/styles/user-index.css";

const HowItWorks = function () {
  return (
    <div className="how-it-works-cont ">
      <Join />

      <Scope />

      <Intro />


      <div style={{ width: "80%", padding: "1em" }}>

        <FAQ role="chef" />;
      </div>
    </div>
  );
};

export default HowItWorks;
