const cities = {
  uk: [
    { index: 0, city: "London" },
    { index: 1, city: "Birmingham" },
    { index: 2, city: "Manchester" },
    { index: 3, city: "Glasgow" },
    { index: 4, city: "Liverpool" },
    { index: 5, city: "Edinburgh" },
    { index: 6, city: "Leeds" },
    { index: 7, city: "Newcastle upon Tyne" },
    { index: 8, city: "Bristol" },
    { index: 9, city: "Sheffield" },
    { index: 10, city: "Nottingham" },
    { index: 11, city: "Cardiff" },
    { index: 12, city: "Belfast" },
    { index: 13, city: "Southampton" },
    { index: 14, city: "Leicester" },
    { index: 15, city: "Plymouth" },
    { index: 16, city: "Coventry" },
    { index: 17, city: "Oxford" },
    { index: 18, city: "Aberdeen" },
    { index: 19, city: "Dundee" },
    { index: 20, city: "Norwich" },
    { index: 21, city: "Swansea" },
    { index: 22, city: "Cambridge" },
    { index: 23, city: "York" },
    { index: 24, city: "Wakefield" },
    { index: 25, city: "Luton" },
    { index: 26, city: "Middlesbrough" },
    { index: 27, city: "Sunderland" },
    { index: 28, city: "Warrington" },
    { index: 29, city: "Reading" },
    { index: 30, city: "Huddersfield" },
    { index: 31, city: "Peterborough" },
    { index: 32, city: "Blackpool" },
    { index: 33, city: "Poole" },
    { index: 34, city: "Telford" },
    { index: 35, city: "Ipswich" },
    { index: 36, city: "Exeter" },
    { index: 37, city: "Derry" },
    { index: 38, city: "Chelmsford" },
    { index: 39, city: "Derby" },
    { index: 40, city: "Doncaster" },
    { index: 41, city: "Preston" },
    { index: 42, city: "Chester" },
    { index: 43, city: "Lincoln" },
    { index: 44, city: "Chesterfield" },
    { index: 45, city: "Bath" },
    { index: 46, city: "Stoke-on-Trent" },
    { index: 47, city: "Worcester" },
    { index: 48, city: "St Albans" },
    { index: 49, city: "Carlisle" },
    { index: 50, city: "Inverness" },
    { index: 51, city: "Lancaster" },
    { index: 52, city: "Canterbury" },
    { index: 53, city: "Salisbury" },
    { index: 54, city: "Lichfield" },
    { index: 55, city: "Newry" },
    { index: 56, city: "Ripon" },
    { index: 57, city: "Ely" },
    { index: 58, city: "Truro" },
    { index: 59, city: "Armagh" },
    { index: 60, city: "Wells" },
    { index: 61, city: "Derry" },
    { index: 62, city: "Londonderry" },
    // Continue adding more cities as needed
  ],
  canada: [
    { index: 0, city: "Toronto" },
    { index: 1, city: "Montreal" },
    { index: 2, city: "Vancouver" },
    { index: 3, city: "Calgary" },
    { index: 4, city: "Edmonton" },
    { index: 5, city: "Ottawa" },
    { index: 6, city: "Winnipeg" },
    { index: 7, city: "Quebec City" },
    { index: 8, city: "Hamilton" },
    { index: 9, city: "Kitchener" },
    { index: 10, city: "Halifax" },
    { index: 11, city: "London" },
    { index: 12, city: "Victoria" },
    { index: 13, city: "Saskatoon" },
    { index: 14, city: "Regina" },
    { index: 15, city: "St. John's" },
    { index: 16, city: "Sherbrooke" },
    { index: 17, city: "Kelowna" },
    { index: 18, city: "Barrie" },
    { index: 19, city: "Abbotsford" },
    { index: 20, city: "Kingston" },
    { index: 21, city: "Thunder Bay" },
    { index: 22, city: "Moncton" },
    { index: 23, city: "Guelph" },
    { index: 24, city: "Milton" },
    { index: 25, city: "Brantford" },
    { index: 26, city: "Orillia" },
    { index: 27, city: "Timmins" },
    { index: 28, city: "Red Deer" },
    { index: 29, city: "Prince George" },
    { index: 30, city: "Brandon" },
    { index: 31, city: "Lethbridge" },
    { index: 32, city: "Nanaimo" },
    { index: 33, city: "Kamloops" },
    { index: 34, city: "Saint John" },
    { index: 35, city: "Belleville" },
    // Continue with the rest of the cities
    { index: 36, city: "Greater Sudbury" },
    { index: 37, city: "Peterborough" },
    { index: 38, city: "Sarnia" },
    { index: 39, city: "Leduc" },
    { index: 40, city: "Fort McMurray" },
    { index: 41, city: "Medicine Hat" },
    { index: 42, city: "Prince Albert" },
    { index: 43, city: "Chilliwack" },
    { index: 44, city: "Sault Ste. Marie" },
    // Continue to add more cities as needed
  ],
  nigeria: [
    { index: 0, city: "Abia" },
    { index: 1, city: "Adamawa" },
    { index: 2, city: "Akwa Ibom" },
    { index: 3, city: "Anambra" },
    { index: 4, city: "Bauchi" },
    { index: 5, city: "Bayelsa" },
    { index: 6, city: "Benue" },
    { index: 7, city: "Borno" },
    { index: 8, city: "Cross River" },
    { index: 9, city: "Delta" },
    { index: 10, city: "Ebonyi" },
    { index: 11, city: "Edo" },
    { index: 12, city: "Ekiti" },
    { index: 13, city: "Enugu" },
    { index: 14, city: "Gombe" },
    { index: 15, city: "Imo" },
    { index: 16, city: "Jigawa" },
    { index: 17, city: "Kaduna" },
    { index: 18, city: "Kano" },
    { index: 19, city: "Katsina" },
    { index: 20, city: "Kebbi" },
    { index: 21, city: "Kogi" },
    { index: 22, city: "Kwara" },
    { index: 23, city: "Lagos" },
    { index: 24, city: "Nasarawa" },
    { index: 25, city: "Niger" },
    { index: 26, city: "Ogun" },
    { index: 27, city: "Ondo" },
    { index: 28, city: "Osun" },
    { index: 29, city: "Oyo" },
    { index: 30, city: "Plateau" },
    { index: 31, city: "Rivers" },
    { index: 32, city: "Sokoto" },
    { index: 33, city: "Taraba" },
    { index: 34, city: "Yobe" },
    { index: 35, city: "Zamfara" },
    // Add more states as needed
  ],
};

export { cities };
