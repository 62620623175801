import React, { useContext, useState, useEffect, useRef } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import TableBookingCard from "../components/child-components/table-booking-card";
import AppContext from "../../../context/app-context";

const UdBookings = function ({ display, dashboardFunctions }) {
  const bookingContRef = useRef(null);

  const [tableBookingComp, setTableBookingComp] = useState([]);

  const { usersData, setIsLoading, backendServer, apiKey } = useContext(AppContext);

  useEffect(() => {
    if (dashboardFunctions?.setBookingDetailsData) {
      getBookings(usersData.user_bookings);
    }
  }, [usersData, dashboardFunctions]);

  useEffect(() => {
    if (!bookingContRef?.current?.style) return;

    if (navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500) {
      bookingContRef.current.style = `height: ${window?.screen?.availHeight - 160}px;`;
    }
  });

  async function getBookings(bookingsArr) {
    if (!bookingsArr) return;

    setIsLoading(true);

    const realBookings = await Promise.all(
      bookingsArr?.map(async (e) => {
        const { data } = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/n-populate/${e._id}`, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        return data;
      })
    );

    const comp = realBookings.map((e, i) => (
      <TableBookingCard
        setOnView={dashboardFunctions?.setOnView}
        setCustomBookingDetails={dashboardFunctions?.setCustomBookingDetails}
        setBookingDetailsData={dashboardFunctions?.setBookingDetailsData}
        data={e}
        key={i}
        type={e.booking_type}
      />
    ));

    if (comp.length === 0)
      setTableBookingComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO BOOKINGS</p>
          <p className="small">No Bookings to display. Start booking now!</p>
          <button onClick={() => window.open("/explore", "_blank")} className="themeBtn">
            Book A Chef
          </button>
        </div>
      );
    else {
      comp.reverse();
      setTableBookingComp(comp);
    }

    setIsLoading(false);
  }

  if (!display) return;

  return (
    <div ref={bookingContRef} className="ud-dashboard-cont media-ud-dashboard-cont">
      <div className="flex-row ud-dash-header media-ud-dash-header">
        <p className="big alignTextLeft removemargin">Your Bookings</p>

        <button onClick={() => window.open("/users", "_blank")} className="themeBtn ud-btns">
          Book A Chef
        </button>
      </div>

      <div
        className="flex-row align-row-left media-table-headers"
        style={{ padding: "0px 0px 0px 0px", justifyContent: "flex-start", marginTop: 40 }}
      >
        <p style={{ width: "17%" }} className="small boldText">
          Booking ID
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Date
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Status
        </p>

        <p style={{ width: "20%" }} className="small boldText">
          Event
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Service
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Guests
        </p>
      </div>

      {tableBookingComp}

      {/* 
      <div className="page-num-cont">
        <a className="xsmall removemargin rounded-number-yellow" href="">1</a>
      </div> */}
    </div>
  );
};

export default UdBookings;
