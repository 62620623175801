import React, { useContext, useEffect, useState } from "react";
import "../../pages/menu-page/menu-page.css";
import "../../styles/index.css";
import { AiTwotoneStar } from "react-icons/ai";
import "../../pages/home/home.css";
import ReviewCard from "../review-card/review-card";
import AppContext from "../../context/app-context";

const MealPrepMenuDetailsReviews = function ({ activeNav, theUser }) {
  const [reviewsComp, setReviewsComp] = useState([]);
  const { calculateChefRating } = useContext(AppContext);

  useEffect(() => {
    if (!theUser) return;

    const comps = theUser?.chef_details?.reviews.map((e, i) => <ReviewCard key={i} data={e} />);

    comps?.reverse();

    setReviewsComp(comps?.slice(0, 12));
  }, [theUser]);

  if (activeNav !== "reviews") return;

  return (
    <div className="box-1-in">
      <div className="flex-column align-column-left">
        <h6 className="blackText">{theUser?.username}'s Reviews</h6>

        <p className="small">Here's what people are saying.</p>
      </div>

      <div className="review-brief-cont">
        <h4 className="yellowText">{calculateChefRating(theUser?.chef_details?.reviews)}/5</h4>

        <div className="rating-row">
          <AiTwotoneStar
            color={calculateChefRating(theUser?.chef_details?.reviews) > 0 ? "yellow" : "grey"}
          />
          <AiTwotoneStar
            color={calculateChefRating(theUser?.chef_details?.reviews) > 1 ? "yellow" : "grey"}
          />
          <AiTwotoneStar
            color={calculateChefRating(theUser?.chef_details?.reviews) > 2 ? "yellow" : "grey"}
          />
          <AiTwotoneStar
            color={calculateChefRating(theUser?.chef_details?.reviews) > 3 ? "yellow" : "grey"}
          />
          <AiTwotoneStar
            color={calculateChefRating(theUser?.chef_details?.reviews) > 4 ? "yellow" : "grey"}
          />
        </div>

        <p className="xsmall">{theUser?.chef_details?.reviews?.length} Verified Reviews</p>
      </div>

      <div className="flex-row align-row-left the-review-cont mppp-the-review-cont">
        {reviewsComp}
      </div>
    </div>
  );
};

export default MealPrepMenuDetailsReviews;
