import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageFive = function ({ currPage, setCurrPage, setEventType }) {
  const [selected, setSelected2] = useState([]);

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  function hasItem(text){
    const index = selected.indexOf(text);
    return index!==-1
  }

  const addOrRemoveText = (text) => {
    if (selected.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = selected.filter(item => item !== text);
      setSelected2(updatedList);
      console.log(`Text "${text}" removed from the list.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...selected, text];
      setSelected2(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  function setSelected(selected) {
    addOrRemoveText(selected)
  }

  useEffect(() => {
    if (selected.length>0) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  if (currPage !== 5) return;

  return (
    <div className="pages-cont" data-page="5">
      <h4>Event Type</h4>

      <div className="flex-row" style={{ gap: 10, justifyContent: "center" }}>
        <div
          onClick={() => {
            setSelected("Wedding");
            setEventType("Wedding");
          }}
          className={`div-image-text-cb-s ${hasItem("Wedding") ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Wedding</p>
        </div>

        <div
          onClick={() => {
            setSelected("Christmas Party");
            setEventType("Christmas Party");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Christmas Party") ? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Christmas Party</p>
        </div>

        <div
          onClick={() => {
            setSelected("Kids Party");
            setEventType("Kids Party");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Kids Party") ? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Kids Party</p>
        </div>

        <div
          onClick={() => {
            setSelected("Valentine");
            setEventType("Valentine");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Valentine") ? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Valentine</p>
        </div>

        <div
          onClick={() => {
            setSelected("Anniversary");
            setEventType("Anniversary");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Anniversary") ? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Anniversary</p>
        </div>

        <div
          onClick={() => {
            setSelected("Corporate");
            setEventType("Corporate");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Corporate") ? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Corporate</p>
        </div>

        <div
          onClick={() => {
            setSelected("Canepe Party");
            setEventType("Canepe Party");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Canepe Party") ? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Canepe Party</p>
        </div>

        <div
          onClick={() => {
            setSelected("Drink");
            setEventType("Drink");
          }}
          className={`div-image-text-cb-s ${hasItem("Drink") ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Drink</p>
        </div>

        <div
          onClick={() => {
            setSelected("Other");
            setEventType("Other");
          }}
          className={`div-image-text-cb-s ${hasItem("Other") ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Other</p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageFive;
