import React from "react";
import "../../../menu-page/menu-page.css";
import "../../../../styles/index.css";
import "../../styles/chef-index.css";

const EachTagBox = function ({ text }) {
  return (
    <div className="each-tag-box">
      <p className="small removemargin">{text}</p>
    </div>
  );
};

export default EachTagBox;
