import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../menu-page/menu-page.css";
import { BiArrowBack } from "react-icons/bi";
import { SubmitProposal } from "../../../components/propose-modal/propose";
import SupportModal from "../../../components/support-modal/support-modal";
import AppContext from "../../../context/app-context";

const CustomBookingDetails = function ({ display, setOnView, data }) {
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);

  const [theUserImage, setTheUserImage] = useState("/images/user_placeholder.png");

  const { usersData, getSignedAwsUrl, convertAmount, popup, backendServer, setIsLoading, apiKey } =
    useContext(AppContext);

  useEffect(() => {
    (async function () {
      if (data?.user?.photo) setTheUserImage(await getSignedAwsUrl(data?.user?.photo, "users"));
    })();

    return () => setTheUserImage("/images/user_placeholder.png");
  }, [data]);

  async function propose() {
    try {
      setIsLoading(true);

      const { data: theeBooking } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get/${data?._id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (theeBooking?.proposals?.find((e) => e?.chef_who_proposed_id === usersData?._id))
        popup("You have already submitted a proposal for this booking");
      else setShowProposalModal(true);

      setIsLoading(false);
    } catch {
      propose("Something went wrong");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    document.querySelector("#chefCustomBookingPageTop")?.scrollIntoView({ behavior: "smooth" });
  }, [display]);

  if (!display) return;

  return (
    <div>
      <div
        className="flex-row ud-dash-header ud-dash-header-to-have-back-btn media-chef-ud-dash-header-to-have-back-btn"
        id="chefCustomBookingPageTop"
      >
        <BiArrowBack
          className="cursor"
          onClick={() => {
            setOnView("explore-init");
          }}
        />

        <p className="big alignTextLeft removemargin">{data.user.first_name}'s Custom Booking</p>

        <div className="ud-stat ">
          <button id="report_booking"
            onClick={() => setShowSupportModal(true)}
            className="themeBtn ud-btns xsmall redBg whiteText"
          >
            Report Booking
          </button>
        </div>
      </div>

      <div className="cd-page-main-cont media-chef-cd-page-main-cont">
        <div className="cd-box-1">
          <div className="menu-page-nav-cont">
            <p
              style={{ width: "fit-content" }}
              className={`blackText boldText each-menu-page-nav each-menu-page-nav-active`}
            >
              Custom Booking
            </p>
          </div>

          <div className="cd-box-1-in">
            <div className="flex-column">
              <p className="small boldText">Booking #{data._id}</p>

              <img
                style={{ width: 50, height: 50, borderRadius: 50 }}
                src={theUserImage}
                alt={data?.user?.first_name}
              />

              <p className="small boldText">
                By {data?.user?.first_name} {data?.user?.last_name}
              </p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}> Custom Booking Type</p>
              </div>

              <p className="small">{data?.custom_booking_type_selected}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Order Type</p>
              </div>

              <p className="small">{data?.custom_booking_type_selected}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Service Preference</p>
              </div>

              <p className="small">{data?.meal_prep_option}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Event Type</p>
              </div>

              <p className="small">{data?.eventDetailsForLargeEvents?.eventType}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Chef Commitment</p>
              </div>

              <p className="small">{data?.meal?.join(", ")}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Cuisine</p>
              </div>

              <p className="small">{data?.cuisines[0]?.name}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Kitchen Configuration</p>
              </div>

              <p className="small removemargin">Hob Type: {data?.stoveType}</p>
              <p className="small removemargin">Number of Hob Tops: {data?.numberOfStove}</p>
              <p className="small removemargin">Oven Present: {data?.haveOven ? "Yes" : "No"}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Dietary Restrictions</p>
              </div>

              <p className="small removemargin">{data?.dietary_restrictions.join(", ")}</p>

              {data?.dietary_restrictions_message ? (
                <p className="small removemargin">{data?.dietary_restrictions_message}</p>
              ) : (
                ""
              )}
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Message To Chefs</p>
              </div>

              <p style={{ paddingRight: 15 }} className="small alignTextLeft">
                {data?.other_info}
              </p>
            </div>
          </div>
        </div>

        <div className="cd-box-2">
          <button id="submit proposals"
            onClick={propose}
            className="themeBtn small"
            style={{ width: "100%", padding: 5 }}
          >
            Submit Proposals
          </button>

          <div className="box-2-booking-details">
            <div className="box-2-booking-details">
              <div className="menu-page-nav-cont">
                <p className={`small boldText yellowText`}>Booking Details</p>
              </div>

              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Location</p>

                <p className="small blackText removemargin alignTextLeft">
                  {data.location.addressString}
                </p>
              </div>

              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Event Theme</p>

                <p className="small blackText removemargin alignTextLeft">
                  {data?.eventDetailsForLargeEvents?.eventName}
                </p>
              </div>

              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Event Start Date</p>

                <p className="small blackText removemargin alignTextLeft">
                  {data.startDate.split("+")[0] + " @ " + data.startDate.split("+")[1]}{" "}
                  {Number(data.startDate.split("+")[1].trim().split(":")[0]) > 12 ? "pm" : "am"}
                </p>
              </div>

              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Event End Date</p>

                <p className="small blackText removemargin alignTextLeft">
                  {data?.endDate?.split("+")[0] + " @ " + data?.endDate?.split("+")[1]}{" "}
                  {Number(data?.endDate?.split("+")[1]?.trim()?.split(":")[0]) > 12 ? "pm" : "am"}
                </p>
              </div>

              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Guests</p>

                <p className="small blackText removemargin alignTextLeft">
                  {data.number_of_adults} Adult(s). {data.number_of_teens} Teen(s).{" "}
                  {data.number_of_children} Kid(s)
                </p>
              </div>

              {data?.amount ? (
                <div className="flex-column align-column-left">
                  <p className="tablesmall removemargin">Amount</p>

                  <p className="small blackText removemargin alignTextLeft">
                    {convertAmount(data?.amount, data?.currency || data?.chef?.country)}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <SubmitProposal
        currency={
          usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
        }
        data={data}
        display={showProposalModal}
        setShowProposalModal={setShowProposalModal}
      />

      <SupportModal display={showSupportModal} setShowSupportModal={setShowSupportModal} />
    </div>
  );
};

export default CustomBookingDetails;
