import React, { useState, useContext, useEffect } from "react";
import "../../../../../menu-page/menu-page.css";
import "../../../../../../styles/index.css";
import { MDBBtn, MDBFile } from "mdb-react-ui-kit";
import AppContext from "../../../../../../context/app-context";

const MenuImageCardForEditMenu = function ({ data, editMenuData }) {
  const [menuPic, setMenuPic] = useState("");

  const [menuName, setMenuName] = useState("");
  const [menuID, setMenuID] = useState("");

  const [placeholderImage, setPlaceholderImage] = useState("/images/img-placeholder.png");

  const { getSignedAwsUrl, backendServer, apiKey, setIsLoading, popup } = useContext(AppContext);

  useEffect(() => {
    if (!data) return;
    (async function () {
      setPlaceholderImage(await getSignedAwsUrl(data, "menus"));
    })();
  }, []);

  useEffect(() => {
    setMenuName(data);
    setMenuID(editMenuData?._id);
  }, []);

  async function updateImage() {
    if (!menuPic) return popup("You haven't changed this image...");

    try {
      setIsLoading(true);

      const form = new FormData();
      form.append("image", menuPic);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/edit/image/${menuID}/${menuName}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              enctype: "multipart/form-data",
              token: localStorage.getItem("ikooktoken"),
            },
            body: form,
          }
        )
      ).json();

      if (returned.status === "Internal server error")
        throw new Error("Something went wrong, try again later...");

      popup("Menu image updated!");

      setPlaceholderImage(await getSignedAwsUrl(returned?.data, "menus"));
      setMenuName(returned?.data);

      setIsLoading(false);

      setMenuPic("");
    } catch (err) {
      popup("Error, please try again later");
      setIsLoading(false);
    }
  }

  function handleFileChange(e) {
    setMenuPic(e.target.files[0]);

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (function (_theFile) {
      return function (e) {
        setPlaceholderImage(e.target.result);
      };
    })(file);

    reader.readAsDataURL(file);
  }

  async function deleteImage() {
    if (editMenuData.images.length <= 5) return popup("You must have at least five Menu images...");

    if (!menuName || !menuID) return popup("Something went wrong");
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/delete/image/${menuID}/${menuName}`,
          {
            method: "DELETE",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error")
        throw new Error("Something went wrong, try again later...");

      popup("Menu image deleted!");

      setTimeout(() => {
        document.location.reload();
      }, 2000);

      setIsLoading(false);
    } catch (err) {
      popup("Error, please try again later");
      setIsLoading(false);
    }
  }

  return (
    <div className="flex-row flex-gap align-row-left" style={{ alignItems: "flex-start" }}>
      <div
        className="chef-details-boxes-settings flex-col align-column-left"
        style={{ height: "300px", gap: 15 }}
      >
        <h5 className="greyText">MAIN IMAGE CATALOGUE</h5>

        <p className="greyText small">
          (Recommended 1000px width, 1000px height.
          <br /> Maximum of 1MB file size)
        </p>

        <MDBFile
          accept="image/jpg,image/png,image/jpeg"
          onChange={handleFileChange}
          style={{ width: "350px" }}
          placeholder="Attach Menu Image"
        />

        <div className="flex-row">
          <MDBBtn
            style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
            onClick={updateImage}
            className="themeBtn"
          >
            Update Image
          </MDBBtn>
          <MDBBtn
            style={{ backgroundColor: "#fd2222", color: "white", fontWeight: "bolder" }}
            onClick={deleteImage}
            className="themeBtn"
          >
            Delete Image
          </MDBBtn>
        </div>
      </div>

      <img
        className="add-a-image-image"
        src={placeholderImage}
        alt="menu"
      />
    </div>
  );
};

export default MenuImageCardForEditMenu;
