import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageTwelve = function ({ currPage, setCurrPage, setHaveOven }) {
  const [selected, setSelected] = useState("");

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  useEffect(() => {
    if (selected) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  if (currPage !== 12) return;

  return (
    <div className="pages-cont" data-page="12">
      <h4>Do you have an Oven?</h4>

      <div className="flex-row" style={{ gap: 10, justifyContent: "center" }}>
        <div
          onClick={() => {
            setSelected("Yes");
            setHaveOven(true);
          }}
          className={`div-image-text-cb-s ${selected === "Yes" ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Yes</p>
        </div>

        <div
          onClick={() => {
            setSelected("No");
            setHaveOven(false);
          }}
          className={`div-image-text-cb-s ${selected === "No" ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">No</p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageTwelve;
