import React, { useContext, useEffect, useState, useRef } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import TableReviewCard from "../components/child-components/table-review-card";
import AppContext from "../../../context/app-context";

const UdReviews = function ({ display }) {
  const [reviews, setReviews] = useState([]);
  const { usersData, backendServer, apiKey } = useContext(AppContext);

  const reviewContRef = useRef(null);

  async function renderTableReviews() {
    const filtered = usersData.user_bookings.filter((e) => {
      if (e.booking_type !== "custom-booking" && e.status === "completed") return e;
    });

    const comp = await Promise.all(
      filtered.map(async (e, i) => {
        if (e.booking_type === "custom-booking") return;
        if (e.status !== "completed") return;
        const { data } = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${e.chef}`, {
            credentials: "include",
            headers: { token: localStorage.getItem("ikooktoken") },
          })
        ).json();

        return <TableReviewCard username={data.username} key={i} data={e} />;
      })
    );

    if (comp.length === 0)
      return setReviews(
        <div className="flex-column for-no-bookings">
          <p className="small boldText" style={{ marginTop: 50 }}>
            No pending reviews at this time...
          </p>
        </div>
      );

    comp.reverse();

    setReviews(comp.slice(0, 5));
  }

  useEffect(() => {
    renderTableReviews();
  }, [usersData]);

  useEffect(() => {
    if (!reviewContRef?.current?.style) return;

    if (navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500) {
      reviewContRef.current.style = `height: ${window?.screen?.availHeight - 160}px;`;
    }
  });

  if (!display) return;

  return (
    <div ref={reviewContRef} className="ud-dashboard-cont media-cd-help-cont">
      <div className="flex-row ud-dash-header review-header">
        <p className="big alignTextLeft removemargin">Your Reviews</p>

        <button
          onClick={() => window.open("/explore", "_blank")}
          className="themeBtn ud-btns media-ud-not-btn"
        >
          Book A Chef
        </button>
      </div>

      <div
        className="flex-row align-row-left review-header review-header-table media-hide"
        style={{ justifyContent: "flex-start", marginTop: 40 }}
      >
        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Booking ID
        </p>

        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Chef
        </p>

        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Date
        </p>

        <p className="small alignTextLeft boldText" style={{ width: "25%" }}>
          Action
        </p>
      </div>

      {reviews}

      {/* <div className="page-num-cont">
        <a className="xsmall removemargin rounded-number-yellow" href="">1</a>
      </div> */}
    </div>
  );
};

export default UdReviews;
