import React, { useEffect, useState } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../styles/index.css";
import MenuImageCardForEditMenu from "./upload-cards/menu";

const EMMImages = function ({ activeNav, editMenuData }) {
  const [comps, setComps] = useState([]);

  useEffect(() => {
    if (!editMenuData?.images) return;
    setComps(
      editMenuData?.images?.map((e, i) => (
        <MenuImageCardForEditMenu editMenuData={editMenuData} key={i} data={e} />
      ))
    );
  }, []);

  if (activeNav !== "images") return;

  if (!editMenuData) return;

  return (
    <div className="cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll" }}>
      {comps}
    </div>
  );
};

export default EMMImages;
