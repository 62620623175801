import preciousMoments from "./assets/smile.svg";
import convenient from "./assets/convenient.svg";
import vegetarian from "./assets/vegetarian.svg";
import bin from "./assets/bin.svg";
import chart from "./assets/chart.svg";
import secure from "./assets/secure.svg";

const cardData = [
  {
    img: preciousMoments,
    heading: "Precious Moments",
    body: "Experience memorable moments with family and friends over a tasteful meal.",
  },
  {
    img: convenient,
    heading: "At Your Convenience",
    body: "Affordable five star quality food that's prepared for you at your chosen location.",
  },
  {
    img: vegetarian,
    heading: "Allergies & Dietary Restrictions",
    body: "Our website allows you to register your allergies and dietary restrictions and highlights them to your chef.",
  },
  {
    img: bin,
    heading: "No Food Wastage",
    body: "We partner with local producers and Our chefs are trained to only buy ingredients in quantities that are needed.",
  },
  {
    img: secure,
    heading: "Payment protection",
    body: "Our website ensures a secure payment system, we hold all payments until event completion.",
  },
  {
    img: chart,
    heading: "Economic Growth & Employment",
    body: "We aim to achieve sustainable economic growth and inclusive employment, aligned with the UN's SDG goal 8.",
  },
];
export default cardData;
