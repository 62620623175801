import React from "react";
import "../how-it-works-user/chefjoin.css";
import "../../styles/index.css";
import "./host.css";

const ConditionalIntro = ({ city, country, type }) => {
  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }
  return (
    <div className="ch-i" style={{ marginBottom: 100 }}>
      <div className="conditionalintro-hwt-container">
        <div className="ch-i-top">
          <div className="ch-i-left">
            <div className="ch-i-left-wrapper">
              <div className="ch-i-title">
                <p className="vbig blackText">
                  {type === "private" ? "Book a private chef in" : "Book iKooK chef in"}{" "}
                  {capitalizeEachWord(city || country)}
                </p>
              </div>
              <div className="ch-i-desc">
                <p className="small removemargin blackText">
                  Hire one of our {type === "private" ? "private" : ""} chefs in{" "}
                  {capitalizeEachWord(city || country)} and have them bring your dream event to
                  life!
                </p>
              </div>
              <button
                onClick={() => (document.location.href = "/cb")}
                className="themeBtn conditional-hwt-btn"
              >
                Start
              </button>
            </div>
          </div>
          <img
            src="/images/plenty.png"
            alt="ikook-chef"
            className="ch-i-img hwt-host-image-hwt-new"
          />
        </div>
      </div>
    </div>
  );
};

export default ConditionalIntro;
