import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
} from "mdb-react-ui-kit";
import "./stripe-modal.css";
import "../modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../styles/index.css";
import "../../pages/user-dashboard/styles/user-index.css";
import StripeCheckoutForm from "../../payment/stripe";
import { RiSecurePaymentLine } from "react-icons/ri";

const StripeModal = ({ display, setShowStripeModal, amount, sendBooking, rawAmount, currency }) => {
  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowStripeModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader className="flex-row">
              <h5>
                Pay <span style={{ color: "#5865F2" }}>{rawAmount}</span>
              </h5>

              <img style={{ width: 70 }} src="/images/Logo.png" alt="ikook" />
            </MDBModalHeader>

            <MDBModalBody>
              <div
                className="flex-row"
                style={{ marginBottom: 30, justifyContent: "center", gap: 20 }}
              >
                <img
                  style={{ width: 60 }}
                  src="/images/mastercard.svg"
                  alt="ikook-payment"
                  title="iKook-Credit-Card-Payment"
                />

                <img
                  style={{ width: 60 }}
                  src="/images/visa.svg"
                  alt="ikook-payment"
                  title="iKook-Credit-Card-Payment"
                />

                <img
                  style={{ width: 60 }}
                  src="/images/amex.svg"
                  alt="ikook-payment"
                  title="iKook-Credit-Card-Payment"
                />

                <img
                  style={{ width: 60 }}
                  src="/images/maestro.svg"
                  alt="ikook-payment"
                  title="iKook-Credit-Card-Payment"
                />

                <img
                  style={{ width: 60 }}
                  src="/images/discover.svg"
                  alt="ikook-payment"
                  title="iKook-Credit-Card-Payment"
                />
              </div>

              <StripeCheckoutForm
                currency={currency}
                amount={amount}
                sendBooking={sendBooking}
                setShowStripeModal={setShowStripeModal}
              />

              <div
                className="flex-row"
                style={{ justifyContent: "flex-end", marginTop: 30, gap: 10 }}
              >
                <RiSecurePaymentLine size={25} color="#5865F2" />

                <img style={{ width: 150 }} src="/images/stripe.svg" alt="ikook" />
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default StripeModal;
