const bannedWords = [
  "email",
  "mail",
  "@",
  ".com",
  "phone",
  "mobile",
  "message",
  "yahoo",
  "gmail",
  "hotmail",
  "whatsapp",
  "telegram",
  "Telegram",
  "WhatsApp",
  "Whatsapp",
  "Gmail",
  "Hotmail",
  "Phone",
  "Mobile",
  "Email",
  "Mail",
  "Number",
  "number",
  "facebook",
  "Facebook",
  "Instagram",
  "instagram",
];

export default bannedWords;
