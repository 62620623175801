import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageEight = function ({ currPage, setCurrPage, setCuisine }) {
  const [selected, setSelected2] = useState([]);

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  useEffect(() => {
    if (selected.length>0) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  function hasItem(text){
    const index = selected.indexOf(text);
    return index!==-1
  }

  const addOrRemoveText = (text) => {
    if (selected.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = selected.filter(item => item !== text);
      setSelected2(updatedList);
      console.log(`Text "${text}" removed from the list.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...selected, text];
      setSelected2(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  function setSelected(selected) {
    addOrRemoveText(selected)
  }


  if (currPage !== 8) return;

  return (
    <div className="pages-cont" data-page="8" style={{ padding: 20 }}>
      <h4>Select Cuisine</h4>

      <div className="flex-row" style={{ gap: 10, justifyContent: "center" }}>
        <div
          onClick={() => {
            setSelected("Italian");
            setCuisine("Italian");
          }}
          className={`div-image-text-cb-s ${hasItem("Italian" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Italian</p>
        </div>

        <div
          onClick={() => {
            setSelected("French");
            setCuisine("French");
          }}
          className={`div-image-text-cb-s ${hasItem("French" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">French</p>
        </div>

        <div
          onClick={() => {
            setSelected("Pan Asian");
            setCuisine("Pan Asian");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Pan Asian" )? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Pan Asian</p>
        </div>

        <div
          onClick={() => {
            setSelected("African");
            setCuisine("African");
          }}
          className={`div-image-text-cb-s ${hasItem("African" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">African</p>
        </div>

        <div
          onClick={() => {
            setSelected("Latin America");
            setCuisine("Latin America");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Latin America" )? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Latin America</p>
        </div>

        <div
          onClick={() => {
            setSelected("Nigerian");
            setCuisine("Nigerian");
          }}
          className={`div-image-text-cb-s ${hasItem("Nigerian" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Nigerian</p>
        </div>

        <div
          onClick={() => {
            setSelected("Modern British");
            setCuisine("Modern British");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Modern British" )? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Modern British</p>
        </div>

        <div
          onClick={() => {
            setSelected("Japanese");
            setCuisine("Japanese");
          }}
          className={`div-image-text-cb-s ${hasItem("Japanese" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Japanese</p>
        </div>

        <div
          onClick={() => {
            setSelected("Modern European");
            setCuisine("Modern European");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Modern European" )? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Modern European</p>
        </div>

        <div
          onClick={() => {
            setSelected("Indian");
            setCuisine("Indian");
          }}
          className={`div-image-text-cb-s ${hasItem("Indian" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Indian</p>
        </div>

        <div
          style={{ width: 140 }}
          onClick={() => {
            setSelected("Mediterranean");
            setCuisine("Mediterranean");
          }}
          className={`div-image-text-cb-s ${
            hasItem("Mediterranean" )? "active-nav-indicator" : ""
          }`}
        >
          <p className="small removemargin">Mediterranean</p>
        </div>

        <div
          onClick={() => {
            setSelected("Mexican");
            setCuisine("Mexican");
          }}
          className={`div-image-text-cb-s ${hasItem("Mexican" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Mexican</p>
        </div>

        <div
          onClick={() => {
            setSelected("Others");
            setCuisine("Others");
          }}
          className={`div-image-text-cb-s ${hasItem("Others" )? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Others</p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageEight;
