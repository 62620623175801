import React, { useState, useEffect, useContext } from "react";
import "../../pages/menu-page/menu-page.css";
import "../../styles/index.css";
import "../../pages/home/home.css";
import { GoLocation } from "react-icons/go";
import FaqCard from "../faq-card/faq-card";
import AppContext from "../../context/app-context";

const MenuDetailsChefInfo = function ({ activeNav, menuData }) {
  const [faqComp, setFaqComp] = useState([]);
  const [image, setImage] = useState("/images/user_placeholder.png");

  const { getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    if (!menuData) return;
    (async function () {
      setImage(await getSignedAwsUrl(menuData?.chefID?.photo, "users"));
    })();

    const comp = menuData?.chefID?.chef_details?.chef_faq?.map((e, i) => {
      return <FaqCard key={i} data={e} />;
    });

    setFaqComp(comp);
  }, [menuData]);

  if (activeNav !== "chef") return;

  return (
    <div className="box-1-in">
      <div className="flex-column align-column-left">
        <h6 className="blackText">About {menuData?.chefID?.username}</h6>
      </div>

      <div className="flex-row align-row-left mpp-abt-chef">
        <img
          style={{ width: 300, height: 300, borderRadius: 300 }}
          src={image ? image : "/images/user_placeholder.png"}
          alt={menuData?.chefID?.username}
        />

        <div className="review-card">
          <p className="big blackText">{menuData?.chefID?.username}</p>

          <p className="yellowText small alignTextLeft">
            {" "}
            <GoLocation color="#FCC01C" /> &nbsp; {menuData?.chefID?.city},{" "}
            {menuData?.chefID?.country}
          </p>

          <p className="small" style={{ textAlign: "left" }}>
            {menuData?.chefID?.chef_details?.bio}
          </p>
        </div>
      </div>

      <div
        className="flex-row align-row-left mpp-extra-chef-details"
        style={{ gap: 15, width: "100%" }}
      >
        <div className="chef-details-boxes">
          <h6 className="alignTextLeft">Chef Experience</h6>

          <p className="tablesmall alignTextLeft removemargin">
            {menuData?.chefID?.chef_details?.experience}
          </p>
        </div>

        <div className="chef-details-boxes">
          <h6 className="alignTextLeft">Cooking Style</h6>

          <p className="tablesmall alignTextLeft removemargin">
            {menuData?.chefID?.chef_details?.style_cooking}
          </p>
        </div>
      </div>

      <div className="menu-page-nav-cont">
        <p className={`big blackText boldText`}>FAQ</p>
      </div>

      <div className="flex-row align-row-left mpp-feq-list" style={{ gap: 20 }}>
        {faqComp}
      </div>
    </div>
  );
};

export default MenuDetailsChefInfo;
