import "./faq.css";
import { questions } from "./questionsData";
import { useState, useEffect } from "react";
import Question from "./question";
const FAQ = ({ role }) => {
  const [filteredQuestions, setFilteredQuestion] = useState([]);

  useEffect(() => {
    setFilteredQuestion(
      questions.filter((question) => question.target === role)
    );
  }, []);
  return (
    <>
      <p className="Faq-heading">FAQs</p>
      {filteredQuestions.map((question) => {
        return <Question key={question.id} {...question} />;
      })}
    </>
  );
};

export default FAQ;
