export const scopes = [
  {
    id: 1,
    title: "Personal Calendar",
    desc: "Sign up is free with iKooK, we only take a commission when your service is booked.",
  },
  {
    id: 2,
    title: "24hrs Payout",
    desc: "You will get paid 24 hours within the booking completion.",
  },
  {
    id: 3,
    title: "Free Signup",
    desc: "Signing up and creating your  profile is completely free. We only charge a commission per booking. No other fees will be taken.",
  },
  {
    id: 4,
    title: "Assistance Available",
    desc: "If you require any assistance our dedicated team is here to help you throughout the process.",
  },
  {
    id: 5,
    title: "Unrestricted Access to Clients",
    desc: "Gain unrestricted access to iKooK clients and take bookings according to your preferences and availability.",
  },
];
