import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MDBBtn } from "mdb-react-ui-kit";
import AppContext from "../context/app-context";

const StripeCheckoutForm = ({ amount, setShowStripeModal, sendBooking, currency }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [disableBtn, setDisableBtn] = useState(false);

  const { backendServer, apiKey, popup, usersData } = useContext(AppContext);

  const handleSubmit = async (event) => {
    setDisableBtn(true);
    popup("Please wait, don't reload this page...");

    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;

        const paymentReturned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/stripe/pay`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({
              id: id,
              userID: usersData._id,
              currency: currency,
              amount: amount,
            }),
            credentials: "include",
          })
        ).json();

        if (paymentReturned.status === "Stripe payment success") {
          popup("Payment Successful");
          setShowStripeModal(false);
          return sendBooking(paymentReturned.data);
        } else if (paymentReturned.status === "3D secure required") {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            paymentReturned.data.clientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            }
          );

          if (error) {
            popup("Payment Failed");
            return setDisableBtn(false);
          } else if (paymentIntent.status === "succeeded") {
            popup("Payment Successful");
            return sendBooking(paymentReturned.data.payment);
          } else {
            popup("Something went wrong. Try again in a few minutes.");
          }
        } else {
          popup("Payment Failed");
          setDisableBtn(false);
        }
      } catch (error) {
        setDisableBtn(false);
        popup("Payment Failed. Please try again later...");
      }
    } else {
      popup(error.message);
      setDisableBtn(false);
    }
  };

  if (!currency) return;

  return (
    <form>
      <CardElement />

      <MDBBtn
        onClick={handleSubmit}
        disabled={disableBtn}
        style={{
          width: "80%",
          cursor: "pointer",
          marginTop: 40,
          backgroundColor: "#5865F2",
          color: "black",
        }}
      >
        Pay Now
      </MDBBtn>
    </form>
  );
};

export default StripeCheckoutForm;
