import React, { useContext, useEffect, useRef } from "react";
import "../../pages/menu-page/menu-page.css";
import AppContext from "../../context/app-context";

const MenuOptionCard = function ({
  data,
  setMenuDetailsSelected,
  menuDetailObjs,
  setPTagsMenu,
  currency,
  type,
}) {
  const { usersData, setShowEmailVerModal } = useContext(AppContext);

  const elRef = useRef(null);

  useEffect(() => {
    if (menuDetailObjs.find((e) => String(e._id) === String(data._id))) {
      elRef.current.classList.add("an-extra-menu-card-active");
    }
  }, [data]);

  return (
    <div
      ref={elRef}
      onClick={(e) => {
        if (menuDetailObjs.find((e) => String(e._id) === String(data._id))) {
          const newArr = menuDetailObjs.filter((el) => {
            return String(el._id) !== String(data._id);
          });

          menuDetailObjs.length = 0;

          newArr.forEach((ell) => {
            menuDetailObjs.push(ell);
          });

          const comp = newArr.map((ek, i) => (
            <p key={i} className="small removemargin alignTextLeft" style={{ marginBottom: 5 }}>
              {ek.description.slice(0, 20) + "..."}
            </p>
          ));

          setMenuDetailsSelected(menuDetailObjs);

          setPTagsMenu(comp);
        } else {
          data.type = type;
          menuDetailObjs.push(data);

          const comp = menuDetailObjs.map((e, i) => (
            <p key={i} className="small removemargin alignTextLeft" style={{ marginBottom: 5 }}>
              {e.description.slice(0, 20) + "..."}
            </p>
          ));

          setPTagsMenu(comp);

          setMenuDetailsSelected(menuDetailObjs);
        }

        e.target.closest(".an-extra-menu-card-new").classList.toggle("an-extra-menu-card-active");
      }}
      className="an-extra-menu-card-new"
    >
      <div className="extra-menu-one-new">
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="the-menu-option-card-option-details">
            <p className="small yellowText menu-option-card-annotation removemargin">{type}</p>

            <p className="removemargin alignTextLeft blackText">{data.description}</p>
          </div>
        </div>

        <p className="removemargin alignTextLeft the-menu-option-card-option-details-p-full-description">
          {data.full_description}
        </p>

        {data?.ingredients && (
          <p className="removemargin alignTextLeft tablesmall ingredients-list">
            Ingredients: {data?.ingredients}
          </p>
        )}
      </div>
    </div>
  );
};

export default MenuOptionCard;
