import React, { useContext } from "react";
import "../../../../pages/user-dashboard/styles/user-index.css";
import "../../../../styles/index.css";
import AppContext from "../../../../context/app-context";

const WithdrawalCard = function ({ data }) {
  const { convertAmount, usersData } = useContext(AppContext);

  return (
    <>
      <div
        className="flex-row align-row-left table-booking-card no-hover-color"
        style={{ padding: "10px 40px 0px 40px", justifyContent: "space-between", height: 50 }}
      >
        <p className="tablesmall alignTextLeft" style={{ width: "25%" }}>
          {data._id}
        </p>

        <p className="tablesmall alignTextLeft" style={{ width: "25%" }}>
          {data.bookingID}
        </p>

        <p className="tablesmall alignTextLeft" style={{ width: "25%" }}>
          {convertAmount(data?.amount, usersData?.country)}
        </p>

        <p
          className={`tablesmall alignTextLeft ${data.status === "paid" ? "greenText" : ""}`}
          style={{ width: "25%" }}
        >
          {data.status}
        </p>
      </div>
    </>
  );
};

export default WithdrawalCard;
