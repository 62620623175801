import React, { useContext, useState, useEffect, useRef } from "react";
import "../../../styles/index.css";
import "../../user-dashboard/styles/user-index.css";
import { MdSend, MdArrowDownward } from "react-icons/md";
import CDAMessagge from "./chef-a-message";
import AppContext from "../../../context/app-context";
import socket from "../../../socket/socket";
import bannedWords from "../../../context/banned-words";
import { BiArrowBack } from "react-icons/bi";

let rawMessagesArr = [];

const CDChatBox = function ({ activeChat, mediaShowMessages, mediaShowHistory }) {
  const [alertMode, setAlertMode] = useState(false);

  const [userImage, setUserImage] = useState("/images/user_placeholder.png");

  const [socketActiveChat, setSocketActiveChat] = useState({});

  const [messagesComp, setMessagesComp] = useState([]);

  const [newChat, setNewChat] = useState(false);

  const [messageToSend, setMessageToSend] = useState("");

  const whereMsgsAre = useRef(null);

  const { usersData, backendServer, apiKey, popup, getSignedAwsUrl, backgroundUpdateUsersData } =
    useContext(AppContext);

  const messageInputRef = useRef(null);

  const messagesContRef = useRef(null);

  async function readRoom() {
    await fetch(
      `${backendServer}/ikook/api/v1/${apiKey}/chat/room/read/${activeChat._id}/${usersData._id}`,
      {
        method: "PATCH",
        credentials: "include",
        headers: {
          token: localStorage.getItem("ikooktoken"),
        },
      }
    );
  }

  useEffect(() => {
    setSocketActiveChat(activeChat);
  }, [activeChat]);

  useEffect(() => {
    mediaShowMessages();
  }, [activeChat]);

  useEffect(() => {
    if (!whereMsgsAre?.current?.style) return;

    if (navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500) {
      whereMsgsAre.current.style = `height: ${window?.screen?.availHeight - 160}px;`;
    }
  });

  useEffect(() => {
    socket?.on("notifyAppend2", async (type, _message, _toId, messageObj) => {
      if (type !== "message") return;

      if (messageObj?.senderUsername === socketActiveChat?.theUserYouAreChattingWith?.username) {
        setMessagesComp([
          ...rawMessagesArr,
          <CDAMessagge type="recieved" data={messageObj} key={rawMessagesArr.length} />,
        ]);

        rawMessagesArr.push(
          <CDAMessagge type="recieved" data={messageObj} key={rawMessagesArr.length} />
        );

        setTimeout(() => {
          readRoom();
        }, 2000);

        setNewChat(false);
      } else backgroundUpdateUsersData();
    });

    return () => socket.removeAllListeners("notifyAppend2");
  }, [socketActiveChat]);

  useEffect(() => {
    setUserImage("/images/user_placeholder.png");

    const comp = activeChat?.messages?.map((e, i) => (
      <CDAMessagge
        type={usersData.username === e.senderUsername ? "sent" : "recieved"}
        data={e}
        key={i}
      />
    ));

    if (comp?.length === 0) {
      (async function () {
        setMessagesComp([
          <div
            key={0}
            className="is-empty-cont"
            style={{
              width: "70%",
              height: "460px",
            }}
          >
            <img src="/images/ud-msg.png" alt="ikook" />

            <p className="small">Say Hi to {activeChat?.theUserYouAreChattingWith.first_name}</p>
          </div>,
        ]);

        if (activeChat?.theUserYouAreChattingWith?.photo)
          setUserImage(
            await getSignedAwsUrl(activeChat?.theUserYouAreChattingWith?.photo, "users")
          );

        setNewChat(true);
      })();
    } else {
      (async function () {
        const { data } = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chat/room/get/${activeChat._id}`, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const compFallback = data.messages.map((e, i) => (
          <CDAMessagge
            type={usersData.username === e.senderUsername ? "sent" : "recieved"}
            data={e}
            key={i}
          />
        ));

        setMessagesComp(compFallback);
        rawMessagesArr = compFallback;

        if (activeChat?.theUserYouAreChattingWith?.photo)
          setUserImage(
            await getSignedAwsUrl(activeChat?.theUserYouAreChattingWith?.photo, "users")
          );
        setNewChat(false);
      })();
    }

    return () => {
      setMessagesComp([]);
      rawMessagesArr = [];
    };
  }, [activeChat]);

  const srollTolastMessage = () => {
    messagesContRef?.current?.children[messagesComp.length - 1]?.scrollIntoView({
      behaviour: "smmoth",
    });
  };

  useEffect(() => {
    srollTolastMessage();
  }, [messagesComp]);

  async function sendMessage() {
    if (!messageToSend) return;

    if (newChat) {
      setMessagesComp([
        <CDAMessagge
          type="sent"
          data={{
            senderFirstName: usersData.first_name,
            senderLastName: usersData.last_name,
            senderUsername: usersData.username,
            createdAt: Date.now(),
            message: messageToSend,
          }}
          key={rawMessagesArr.length}
        />,
      ]);
      setNewChat(false);
    } else
      setMessagesComp([
        ...messagesComp,
        <CDAMessagge
          type="sent"
          data={{
            senderFirstName: usersData.first_name,
            senderLastName: usersData.last_name,
            senderUsername: usersData.username,
            createdAt: Date.now(),
            message: messageToSend,
          }}
          key={rawMessagesArr.length}
        />,
      ]);

    messageInputRef.current.value = "";

    rawMessagesArr.push(
      <CDAMessagge
        type="sent"
        data={{
          senderFirstName: usersData.first_name,
          senderLastName: usersData.last_name,
          senderUsername: usersData.username,
          createdAt: Date.now(),
          message: messageToSend,
        }}
        key={rawMessagesArr.length}
      />
    );

    try {
      await fetch(
        `${backendServer}/ikook/api/v1/${apiKey}/chat/room/send/message/${activeChat._id}/${usersData._id}`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify({ message: messageToSend }),
        }
      );

      socket?.emit(
        "smartNotification",
        "message",
        `New message from ${usersData.first_name} - ${messageToSend.slice(0, 30)}...`,
        activeChat?.theUserYouAreChattingWith?.username,
        activeChat?.theUserYouAreChattingWith?._id,
        {
          senderFirstName: usersData.first_name,
          senderLastName: usersData.last_name,
          senderUsername: usersData.username,
          createdAt: Date.now(),
          message: messageToSend,
        }
      );
    } catch (err) {
      popup("FYI, something went wrong and we are not sure you message delivered.");
    }
  }

  return (
    <div
      ref={whereMsgsAre}
      id="chef-dashboard-where-messages-are"
      className="ud-chat-engine-cont media-hide"
    >
      <div className="chat-header media-chat-header-ud">
        <div className="ch-header-first">
          <BiArrowBack
            className="cursor chat-back"
            onClick={() => {
              mediaShowHistory();
            }}
          />
          <img className="cd-history-card-img" src={userImage} alt="ikook large events" />

          <div className="ch-h-2">
            <p className="small removemargin boldText">
              {activeChat.theUserYouAreChattingWith.first_name}{" "}
              {activeChat.theUserYouAreChattingWith.last_name}
            </p>

            <p className="xsmall yellowText removemargin">
              {activeChat.theUserYouAreChattingWith.role}
            </p>
          </div>
        </div>

        <div className="ch-header-first" style={{ justifyContent: "flex-end" }}></div>
      </div>

      <div className="where-messages-are media-msg-chat-and-input-cont-ud">
        <div ref={messagesContRef} className="messages-cont">
          {messagesComp}
        </div>

        <div className="messages-input-cont media-ud-mss-input-cont">
          <input
            maxLength={200}
            onChange={(e) => {
              setMessageToSend(e.target.value);

              if (e.target.value.split(" ").some((el) => bannedWords.includes(el)))
                setAlertMode(true);
              else setAlertMode(false);
            }}
            ref={messageInputRef}
            type="text"
            className={`message-input ${alertMode && "border-red"}`}
            placeholder="say hi"
          />

          <div onClick={sendMessage} className="send-btn-cont cursor">
            <MdSend size={20} />
          </div>

          <div onClick={srollTolastMessage} className="send-btn-cont cursor">
            <MdArrowDownward size={20} />
          </div>

          {alertMode && (
            <p className={`xsmall redText removemargin alignTextLeft boldText`}>
              T&C Reminder: Keep all communications on the iKooK platform
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CDChatBox;
