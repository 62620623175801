import React from "react";
import "./blogCard.css";
import "./index.css";

const BlogCard = ({ title, excerpts, picture, category,slug, tag, id }) => {
  return (
    <div className="blog-card">
      <img src={picture} />
      <div className="blog-card-body">
        <p className="title">{title}</p>
        <p className="excerpt truncate"  dangerouslySetInnerHTML={{ __html: excerpts }}/>
        <a href={`/blog/${slug}`} className="read-more">
          READ MORE »
        </a>
      </div>
    </div>
  );
};

export default BlogCard;
