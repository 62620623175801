import React, { useState, useContext, useLayoutEffect } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../menu-page/menu-page.css";
import "../../../../../components/auth/login/login-modal.css";
import { MDBBtn, MDBSwitch } from "mdb-react-ui-kit";
import AppContext from "../../../../../context/app-context";
import "../../../styles/chef-index.css";

const ChefServices = function ({ activeNav }) {
  const [chefAtHomeValue, setChefAtHomeValue] = useState(true);
  const [mealPrepValue, setMealPrepValue] = useState(true);
  const [largeEventValue, setLargeEventValue] = useState(true);

  const { backendServer, apiKey, popup, usersData, setIsLoading } = useContext(AppContext);

  useLayoutEffect(() => {
    if (usersData?.availableForMealPrep === "no") setMealPrepValue(false);

    if (usersData?.availableForLargeEvents === "no") setLargeEventValue(false);

    if (usersData?.availableForChefAtHome === "no") setChefAtHomeValue(false);
  }, [usersData]);

  const updateServices = async () => {
    let obj = {};

    if (chefAtHomeValue) obj.availableForChefAtHome = "yes";
    else obj.availableForChefAtHome = "no";

    if (mealPrepValue) obj.availableForMealPrep = "yes";
    else obj.availableForMealPrep = "no";

    if (largeEventValue) obj.availableForLargeEvents = "yes";
    else obj.availableForLargeEvents = "no";

    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/update/${usersData._id}`, {
          credentials: "include",
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error")
        throw new Error(
          "I failed. Sorry about that, but I'm not sure if its me or your network..."
        );

      setIsLoading(false);

      popup("Your services have been updated!");
    } catch {
      setIsLoading(false);
      popup("I failed. Sorry about that, but I'm not sure if its me or your network...");
    }
  };

  if (activeNav !== "services") return;

  return (
    <div
      className="cd-box-1-in-acc media-chef-cd-box-1-in-acc"
      style={{ width: "90%", overflowY: "scroll" }}
    >
      <h5 className="big">Services</h5>

      <p className="small greyText removemargin">
        Activate the services you are available for and set your preference. Don't forget to save
        your changes.
      </p>

      <div className="flex-row" style={{ justifyContent: "space-between", width: "70%" }}>
        <p className="small">Chef At Home</p>

        <MDBSwitch
          checked={chefAtHomeValue}
          onChange={() => {
            setChefAtHomeValue(!chefAtHomeValue);
          }}
          btnColor="warning"
          id="flexSwitchCheckSMS"
          style={{ backgroundColor: `${chefAtHomeValue ? "#ffd93b" : "#eeeded"}` }}
        />
      </div>

      <div className="flex-row" style={{ justifyContent: "space-between", width: "70%" }}>
        <p className="small">Large Event</p>

        <MDBSwitch
          checked={largeEventValue}
          onChange={() => {
            setLargeEventValue(!largeEventValue);
          }}
          btnColor="warning"
          id="flexSwitchCheckSMS"
          style={{ backgroundColor: `${largeEventValue ? "#ffd93b" : "#eeeded"}` }}
        />
      </div>

      <div className="flex-row" style={{ justifyContent: "space-between", width: "70%" }}>
        <p className="small">Meal Prep</p>

        <MDBSwitch
          checked={mealPrepValue}
          onChange={() => {
            setMealPrepValue(!mealPrepValue);
          }}
          btnColor="warning"
          id="flexSwitchCheckSMS"
          style={{ backgroundColor: `${mealPrepValue ? "#ffd93b" : "#eeeded"}` }}
        />
      </div>

      <div onClick={updateServices} className="flex-row align-row-left">
        <MDBBtn style={{ backgroundColor: "#fcc01c", color: "black" }} className="small themeBtn">
          Save Changes
        </MDBBtn>
      </div>
    </div>
  );
};

export default ChefServices;
