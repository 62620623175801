let country;

if (!localStorage.getItem("edition")) {
  (async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const { ip } = await response.json();

      const responseCountry = await fetch(
        `https://api.ipgeolocation.io/ipgeo?apiKey=ed8db69751a544dbbe02545ba3f9a84f&ip=${ip}`
      );
      const data = await responseCountry.json();

      if (data?.country_name === "Nigeria") {
        localStorage.setItem("edition", "NGN");
      } else if (data?.country_name === "United Kingdom") {
        localStorage.setItem("edition", "GBP");
      } else if (data?.country_name === "Canada") {
        localStorage.setItem("edition", "CAD");
      } else localStorage.setItem("edition", "NGN");

      document.location.reload();
    } catch {
      localStorage.setItem("edition", "NGN");
      document.location.reload();
    }
  })();
}

if (localStorage.getItem("edition") === "NGN") country = "Nigeria";
if (localStorage.getItem("edition") === "CAD") country = "Canada";
if (localStorage.getItem("edition") === "GBP") country = "UK";

export default country;
