import "./host.css";
import Steps from "./steps";
import HostHeading from "./heading";
import FAQ from "../../components/FAQ/FAQ";
import Intro from "../how-it-works-user/Intro";
import { useParams } from "react-router-dom";
import ConditionalIntro from "./conditional-intro";
import Cities from "../cities/cities";

const Host = () => {
  const { city, country, type } = useParams();
  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }
  return (
    <div className="host-container">
      {(city || country) && (
        <ConditionalIntro
          type={type}
          country={capitalizeEachWord(country)}
          city={capitalizeEachWord(city)}
        />
      )}
      <HostHeading />
      <Steps background={true} />
      {!city && <Intro />}
      <FAQ role={"host"} />
      {country && <Cities country={country} />}
    </div>
  );
};

export default Host;
