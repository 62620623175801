import React from "react";
import { MDBSpinner } from "mdb-react-ui-kit";
import "../styles/index.css";

const ErrorPage = function () {
  return (
    <div className="loading-cont">
      <MDBSpinner size="lg" className="mx-2" color="warning">
        <span className="">Please wait...</span>
      </MDBSpinner>
    </div>
  );
};

export default ErrorPage;
