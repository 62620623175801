import React from "react";
import "../../pages/menu-page/menu-page.css";
import "../../styles/index.css";
import "../../pages/home/home.css";

const FaqCard = function ({ data }) {
  return (
    <div
      className="review-card"
      style={{ width: "250px", height: "250px", justifyContent: "flex-start", padding: 10 }}
    >
      <p className="small blackText removemargin alignTextLeft boldText">{data?.question}</p>

      <p className="small alignTextLeft" style={{ textAlign: "left" }}>
        {data?.answer}
      </p>
    </div>
  );
};

export default FaqCard;
