import React, { useState, useContext } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../menu-page/menu-page.css";
import AppContext from "../../../context/app-context";
import EMMAndP from "./child-components/edit-menu/em-menus";
import EMDetails from "./child-components/edit-menu/em-details";
import EMMImages from "./child-components/edit-menu/em-images";
import AddADishModal from "../../../components/add-dish-modal/add-dish-modal";
import AddMenuImageModal from "../../../components/add-menu-img-modal/add-menu-img-modal";

const CdEditMenu = function ({ onView, setOnView, editMenuData }) {
  const [activeNav, setActiveNav] = useState("details");

  const [menuName, setMenuName] = useState("");
  const [menuPrice, setMenuPrice] = useState("");
  const [minimumNumberOfGuests, setMinimumNumberOfGuests] = useState("");
  const [menuDescription, setMenuDesciption] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [cuisineType, setCuisineType] = useState([]);

  const [showAddADishModal, setShowAddDishModal] = useState(false);
  const [showAddMenuImageModal, setShowAddMenuImgModal] = useState(false);

  const { backendServer, apiKey, setIsLoading, popup } = useContext(AppContext);

  async function updateMenuBasicDetails() {
    try {
      if (
        !menuName &&
        !menuPrice &&
        !minimumNumberOfGuests &&
        !menuDescription &&
        searchTags.length === 0 &&
        eventTags.length === 0 &&
        cuisineType.length === 0
      )
        return popup("You have not made any edits");

      if (menuPrice) {
        if (menuPrice < 1) return popup("Please set the right Menu Price");
      }

      setIsLoading(true);

      const obj = {};

      if (menuName) obj.menuName = menuName;
      if (menuPrice) obj.menuPrice = menuPrice;
      if (minimumNumberOfGuests) obj.minimum_number_of_guest = minimumNumberOfGuests;
      if (menuDescription) obj.description = menuDescription;

      if (searchTags.length > 0) obj.searchtags = searchTags.join(",");
      if (eventTags.length > 0) obj.eventTags = eventTags.join(",");
      if (cuisineType.length > 0) obj.cuisineTags = cuisineType.join(",");

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/update/${editMenuData._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify(obj),
          }
        )
      ).json();

      if (returned.status === "Internal server error")
        throw new Error("Something went wrong, try again later...");

      popup("Menu updated successfully");

      setTimeout(() => {
        document.location.reload();
      }, 1500);

      setIsLoading(false);
    } catch (err) {
      popup("Error, please try again later");
      setIsLoading(false);
    }
  }

  if (onView !== "edit menu") return;

  if (!editMenuData) return;

  return (
    <div className="cd-box-1-settings" style={{ overflow: "hidden" }}>
      <div
        className="flex-row ud-dash-header"
        style={{
          justifyContent: "space-between",
          marginTop: -20,
          paddingRight: 25,
          marginBottom: 0,
        }}
      >
        <p className="big alignTextLeft removemargin">
          Edit Menu - <span className="yellowText boldText">{editMenuData.menuName}</span>
        </p>

        <div
          className="flex-row"
          style={{ width: "fit-content", justifyContent: "flex-end", gap: 15 }}
        >
          {activeNav === "m&p" && (
            <button onClick={() => setShowAddDishModal(true)} className="themeBtn ud-btns">
              ADD DISH
            </button>
          )}

          {activeNav === "images" && (
            <button onClick={() => setShowAddMenuImgModal(true)} className="themeBtn ud-btns">
              ADD MENU IMAGE
            </button>
          )}

          <button onClick={() => setOnView("menu")} className="themeBtn ud-btns">
            Exit
          </button>
        </div>
      </div>

      <div className="menu-page-nav-cont">
        <p
          onClick={() => {
            setActiveNav("details");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "details" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Details
        </p>

        <p
          onClick={() => {
            setActiveNav("m&p");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "m&p" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Menus & Prices
        </p>

        <p
          onClick={() => {
            setActiveNav("images");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "images" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Menus Images
        </p>
      </div>

      <EMDetails
        updateMenuBasicDetails={updateMenuBasicDetails}
        editMenuData={editMenuData}
        eventTags={eventTags}
        searchTags={searchTags}
        cuisineType={cuisineType}
        setMenuName={setMenuName}
        setMenuPrice={setMenuPrice}
        setMenuDesciption={setMenuDesciption}
        setEventTags={setEventTags}
        setCuisineType={setCuisineType}
        setSearchTags={setSearchTags}
        activeNav={activeNav}
        setMinimumNumberOfGuests={setMinimumNumberOfGuests}
      />

      <EMMAndP editMenuData={editMenuData} activeNav={activeNav} />

      <EMMImages editMenuData={editMenuData} activeNav={activeNav} />

      <AddADishModal
        display={showAddADishModal}
        setShowAddDishModal={setShowAddDishModal}
        menuID={editMenuData?._id}
      />

      <AddMenuImageModal
        display={showAddMenuImageModal}
        setShowAddMenuImgModal={setShowAddMenuImgModal}
        menuID={editMenuData?._id}
      />
    </div>
  );
};

export default CdEditMenu;
