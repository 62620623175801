import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";
import twoTops from "./assets/twoTops.svg";
import threeTops from "./assets/threeTops.svg";
import fourTops from "./assets/fourTops.svg";
import moreTops from "./assets/moreTops.svg";

const PageEleven = function ({ currPage, setCurrPage, setNumberOfStoves }) {
  const [selected, setSelected] = useState("");

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  useEffect(() => {
    if (selected) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  if (currPage !== 11) return;

  return (
    <div className="pages-cont" data-page="11">
      <h4>How many hob tops do you have?</h4>

      <div className="flex-row" style={{ gap: 10, justifyContent: "center" }}>
        <div
          onClick={() => {
            setSelected("2");
            setNumberOfStoves("2");
          }}
          className={`div-image-text-cb-s cb-with-img ${
            selected === "2" ? "active-nav-indicator" : ""
          }`}
        >
          <img alt="iKooK Custom Booking" src={twoTops} style={{ width: "40px" }} />
          <p className="small removemargin" style={{ whiteSpace: "nowrap" }}>
            2 hobs
          </p>
        </div>

        <div
          onClick={() => {
            setSelected("3");
            setNumberOfStoves("3");
          }}
          className={`div-image-text-cb-s cb-with-img ${
            selected === "3" ? "active-nav-indicator" : ""
          }`}
        >
          <img alt="iKooK Custom Booking" src={threeTops} style={{ width: "40px" }} />
          <p className="small removemargin" style={{ whiteSpace: "nowrap" }}>
            3 hobs
          </p>
        </div>

        <div
          onClick={() => {
            setSelected("4");
            setNumberOfStoves("4");
          }}
          className={`div-image-text-cb-s cb-with-img ${
            selected === "4" ? "active-nav-indicator" : ""
          }`}
        >
          <img alt="iKooK Custom Booking" src={fourTops} style={{ width: "40px" }} />
          <p className="small removemargin" style={{ whiteSpace: "nowrap" }}>
            4 hobs
          </p>
        </div>

        <div
          onClick={() => {
            setSelected("more");
            setNumberOfStoves("4+");
          }}
          className={`div-image-text-cb-s cb-with-img ${
            selected === "more" ? "active-nav-indicator" : ""
          }`}
        >
          <img alt="iKooK Custom Booking" src={moreTops} style={{ width: "40px" }} />
          <p className="small removemargin" style={{ whiteSpace: "nowrap" }}>
            4+ hobs
          </p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageEleven;
