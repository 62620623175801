import React, { useState, useEffect, useContext } from "react";
import "../menu-page/menu-page.css";
import "../../styles/index.css";
import "./user-page.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import UserDetailsMenuList from "../../components/user-page-comp/user-menu-list";
import UserDetailsReview from "../../components/user-page-comp/reviews";
import UserDetailsChefInfo from "../../components/user-page-comp/chef-info";
import { useParams } from "react-router-dom";
import AppContext from "../../context/app-context";

const UserDetails = function () {
  const [activeNav, setActiveNav] = useState("chef");
  const [theUser, setTheUser] = useState({});
  const { id } = useParams();

  const { backendServer, apiKey, setIsLoading, popup, handleChefInactiveState } =
    useContext(AppContext);

  useEffect(() => {
    if (!theUser?.username) return;
    document.title = `iKooK - Chef ${theUser?.username || ""}`;
  }, [theUser]);

  const fetchUser = async function () {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?id=${id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setTheUser(returned.data);

      setIsLoading(false);
    } catch (err) {
      popup("Failed to fetch user");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const renderMenuDetails = async () => {
    if (theUser?.availableForChefAtHome === "no")
      return popup("Sorry, this Chef is not currently available for this service.");
    setActiveNav("menu");
  };

  const renderReviews = async () => {
    setActiveNav("reviews");
  };

  const renderChefDetails = async () => {
    setActiveNav("chef");
  };

  if (!theUser) return;

  if (theUser?._id && !theUser?.is_active) return handleChefInactiveState();

  return (
    <div className="menu-page-cont upp-menu-page-cont">
      <div className="menu-page-main-cont">
        <div className="box-1 upp-box-1">
          <div className="menu-page-nav-cont upp-menu-page-nav-cont">
            <p
              onClick={renderChefDetails}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "chef" ? "each-menu-page-nav-active" : ""
              }`}
            >
              About Chef
            </p>

            <p
              onClick={renderMenuDetails}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "menu" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Menus
            </p>

            <p
              onClick={renderReviews}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "reviews" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Reviews
            </p>
          </div>

          <UserDetailsMenuList data={theUser} activeNav={activeNav} />

          <UserDetailsChefInfo data={theUser} activeNav={activeNav} />

          <UserDetailsReview data={theUser} activeNav={activeNav} />
        </div>

        <div className="box-2 upp-box-2">
          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Chef Details</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="xsmall removemargin">Location</p>

              <p className="small blackText removemargin alignTextLeft">
                {theUser?.city ? theUser?.city : ""}, {theUser?.country ? theUser?.country : ""}
              </p>
            </div>

            {theUser?.chef_details?.chef_bookings?.length > 0 && (
              <div className="flex-column align-column-left">
                <p className="xsmall removemargin">Bookings</p>

                <p className="small blackText removemargin alignTextLeft">
                  {theUser?.chef_details?.chef_bookings?.length &&
                    `${theUser?.chef_details?.chef_bookings?.length} Completed Bookings`}
                </p>
              </div>
            )}

            {theUser?.chef_details?.languages?.length > 0 && (
              <div className="flex-column align-column-left">
                <p className="xsmall removemargin">Languages</p>

                <p className="small blackText removemargin alignTextLeft">
                  {theUser?.chef_details?.languages.join(", ")}
                </p>
              </div>
            )}

            {theUser?.chef_details?.type_of_chef && (
              <div className="flex-column align-column-left">
                <p className="xsmall removemargin">Type of Chef</p>

                <p className="small blackText removemargin alignTextLeft">
                  {theUser?.chef_details?.type_of_chef}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <ForPageActions />
    </div>
  );
};

export default UserDetails;
