import React, { useState, useContext } from "react";
import { Popconfirm } from "antd";
import AppContext from "../../context/app-context";

const DeletePopConfirm = ({ display, menu, setShowDeletePop }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { backendServer, apiKey, popup, usersData, updateUsersData } = useContext(AppContext);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/delete/${menu?._id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          token: localStorage.getItem("ikooktoken"),
        },
      });

      setShowDeletePop(false);
      setConfirmLoading(false);
      popup("Menu Deleted!");
      updateUsersData();
    } catch {
      popup("Error deleting your menu");
      setShowDeletePop(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setShowDeletePop(false);
  };

  return (
    <Popconfirm
      title={`${menu?.menuName}`}
      description={`${usersData?.first_name}, are you sure you want to delete this menu?`}
      open={display}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
      okText={"Yes, please"}
      okType="text"
      cancelButtonProps={{ type: "text" }}
    ></Popconfirm>
  );
};

export default DeletePopConfirm;
