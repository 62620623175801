import { useState } from "react";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

const Question = ({ question, answer }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="question-container" onClick={() => setShowInfo(!showInfo)}>
      <header className="question-row">
        <h6 style={showInfo ? { fontWeight: "bold" } : {}}>{question}</h6>
        <button
          className="drop-down-arrow"
          
        >
          {showInfo ? <AiFillCaretUp /> : <AiFillCaretDown />}
        </button>
      </header>
      {showInfo && (
        <div>
          <p className="answer-txt">{answer}</p>
        </div>
      )}
    </div>
  );
};

export default Question;
