import React from "react";
import "../../../../pages/user-dashboard/styles/user-index.css";
import "../../../../styles/index.css";

const ExploreTableBookingCard = function ({ setOnView, setCustomBookingData, data }) {
  return (
    <div
      onClick={() => {
        setOnView("custom-booking-details");
        setCustomBookingData({ ...data });
      }}
      className="flex-row align-row-left table-booking-card"
      style={{
        padding: "10px 0px 0px 0px",
        justifyContent: "flex-start",
        marginBottom: 20,
        alignItems: "center",
      }}
    >
      <p style={{ width: "15%" }} className="tablesmall">
        {data?.eventDetailsForLargeEvents?.eventName?.slice(0, 30)}
        {data?.eventDetailsForLargeEvents?.eventName?.length > 30 ? "..." : ""}
      </p>

      <p style={{ width: "14%" }} className="tablesmall">
        {data.startDate?.split("+")[0]}
      </p>

      <p
        style={{ width: "8%" }}
        className={`tablesmall ${data?.status === "cancelled" ? "redText" : "greenText"}`}
      >
        {data.status}
      </p>

      <p style={{ width: "20%" }} className="tablesmall">
        {data?.user?.first_name ? data?.user?.first_name + ` ${data.user.last_name}` : "nil"}
      </p>

      <p style={{ width: "18%" }} className="tablesmall">
        {data?.booking_type}
      </p>

      <p style={{ width: "25%" }} className="tablesmall">
        {data?.location?.addressString?.slice(0, 35)}...
      </p>
    </div>
  );
};

export default ExploreTableBookingCard;
