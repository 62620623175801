import React from "react";
import video from "./videos/video.mp4";

const Video = () => {
  return (
    <video className="the-home-image-1" muted loop autoPlay>
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
