import React from "react";
import "../../../menu-page/menu-page.css";
import { MDBInput } from "mdb-react-ui-kit";
import "../../../../components/modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../../../styles/index.css";

const EventDetailsForLargeEvents = function ({ activeNav }) {
  if (activeNav !== "event-details") return;

  return (
    <div className="box-1-in">
      <MDBInput
        disabled={true}
        style={{ width: 500 }}
        label="Event Name"
        type="text"
        placeholder="Event Name"
      />

      <MDBInput disabled={true} style={{ width: 500 }} type="date" placeholder="Event Date" />

      <MDBInput disabled={true} style={{ width: 500 }} type="time" placeholder="Event Time" />

      <MDBInput
        disabled={true}
        style={{ width: 500 }}
        label="Event Location"
        type="text"
        placeholder="Event Location"
      />
    </div>
  );
};

export default EventDetailsForLargeEvents;
