import React, { useState, useContext, useEffect } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../menu-page/menu-page.css";
import "../../../../../components/auth/login/login-modal.css";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import IDUpload from "./upload-cards/ID";
import CVUpload from "./upload-cards/CV";
import AppContext from "../../../../../context/app-context";
import "../../../../../styles/index.css";
import "../../../../custom-bookings/cb.css";
import ChefLicenceUpload from "./upload-cards/chef-licence";
import UKWorkAuthorization from "./upload-cards/uk-work-authorization";
import VNinPrompt from "../../../../../components/vNiN-prompt/vnin-prompt";

const useIdentityPass = false;

const ChefVerification = function ({ activeNav }) {
  const [dob, setDob] = useState("");

  const [idNumber, setIdNumber] = useState("");
  const [idImage, setIdImage] = useState("");
  const [cv, setCv] = useState("");
  const [chefLicence, setChefLicence] = useState("");

  const [uKWorkAuthorization, setUkWorkAuthorization] = useState("");

  const [hideBtn, setHideBtn] = useState(true);

  const [selectedIDType, setSelectedIdType] = useState("");

  const { backendServer, apiKey, setIsLoading, popup, usersData, identityPassKey, identityPassId } =
    useContext(AppContext);

  useEffect(() => {
    if (idImage && cv && selectedIDType && chefLicence && idNumber) setHideBtn(false);
    else setHideBtn(true);

    if (usersData.country === "UK" && !uKWorkAuthorization) setHideBtn(true);

    if (selectedIDType === "driver's licence" && !dob) setHideBtn(true);
  });

  async function submitVerificationDocs() {
    try {
      setIsLoading(true);

      //if user is from Nigeria, verify with prembly first
      if (usersData?.country === "Nigeria" && useIdentityPass) {
        let url;

        if (selectedIDType === "national id")
          url = `https://api.prembly.com/identitypass/verification/vnin`;
        else if (selectedIDType === "driver's licence")
          url = `https://api.prembly.com/identitypass/verification/drivers_license`;
        else if (selectedIDType === "international passport")
          url = `https://api.prembly.com/identitypass/verification/national_passport`;

        let body;

        if (selectedIDType === "national id")
          body = {
            number: idNumber,
          };
        else if (selectedIDType === "driver's licence")
          body = {
            number: idNumber,
            dob: dob,
            first_name: usersData?.first_name,
            last_name: usersData?.last_name,
          };
        else if (selectedIDType === "international passport")
          body = {
            number: idNumber,
            last_name: usersData?.last_name,
          };

        const identityPassReturned = await (
          await fetch(url, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "x-api-key": identityPassKey,
              "app-id": identityPassId,
            },
            body: JSON.stringify(body),
          })
        ).json();

        if (
          identityPassReturned?.message === "Service not Available" ||
          identityPassReturned?.message === "NIN is currently unavailable"
        ) {
          setIsLoading(false);
          return popup("Verification failed. Please try again later...");
        }

        if (!identityPassReturned?.status) {
          setIsLoading(false);
          return popup("Failed to verify ID. Try again later...");
        }
      }

      //send data to db

      const form = new FormData();

      form.append("docs", idImage);
      form.append("docs", cv);
      form.append("docs", chefLicence);

      if (uKWorkAuthorization) form.append("docs", uKWorkAuthorization);

      form.append("document_id", idNumber);
      form.append("document_type", selectedIDType);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/docs/upload/${usersData._id}`, {
          method: "PATCH",
          headers: {
            enctype: "multipart/form-data",
            token: localStorage.getItem("ikooktoken"),
          },
          body: form,
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Recieved. We'll get back to you with results.");

      setIsLoading(false);

      setTimeout(() => {
        document.location.reload();
      }, 1500);
    } catch (err) {
      popup("Identity Verification Failed");
      setIsLoading(false);
    }
  }

  if (activeNav !== "verification") return;

  if (usersData?.chef_details?.chef_verification_details?.is_documents_verified === true)
    return (
      <div className="flex-column for-no-bookings">
        <img src="/images/no-booking.png" alt="ikook" />
        <p className="small boldText">ALL DOCUMENTS VERIFIED</p>
        <p className="small">Thank you!</p>
      </div>
    );

  if (usersData.chef_details.chef_verification_details.documents.length > 0)
    return (
      <div
        className="cd-box-1-in-acc"
        style={{ width: "90%", overflowY: "scroll", paddingBottom: 20 }}
      >
        <h5 className="big removemargin">Verification</h5>
        <p className="small greyText alignTextLeft removemargin">
          You have submitted your verification details. You'll recieve an update in your email.
        </p>{" "}
      </div>
    );

  return (
    <div
      className="cd-box-1-in-acc media-chef-cd-box-1-in-acc"
      style={{ width: "90%", overflowY: "scroll", paddingBottom: 20 }}
    >
      <h5 className="big removemargin">Verification</h5>

      <p className="small greyText alignTextLeft removemargin">
        Please submit the required documents to get verified.
      </p>

      <div className="flex-row align-row-left" style={{ justifyContent: "center", gap: 50 }}>
        <div
          onClick={() => {
            setSelectedIdType("driver's licence");
          }}
          className={`div-image-text-cb ${
            selectedIDType === "driver's licence" ? "active-nav-indicator" : ""
          }`}
          style={{ justifyContent: "flex-start", padding: 20, height: 125 }}
        >
          <img style={{ width: 30 }} src="/images/dl.png" alt="iKook Verification" />
          <p className="small">Driver's Licence</p>
        </div>

        <div
          onClick={() => {
            setSelectedIdType("national id");
          }}
          className={`div-image-text-cb ${
            selectedIDType === "national id" ? "active-nav-indicator" : ""
          }`}
          style={{ justifyContent: "flex-start", padding: 20, height: 125 }}
        >
          <img style={{ width: 30 }} src="/images/dl.png" alt="iKook Verification" />
          <p className="small">National ID</p>
        </div>

        <div
          onClick={() => {
            setSelectedIdType("international passport");
          }}
          className={`div-image-text-cb ${
            selectedIDType === "international passport" ? "active-nav-indicator" : ""
          }`}
          style={{ justifyContent: "flex-start", padding: 20, height: 125 }}
        >
          <img style={{ width: 30 }} src="/images/ip.png" alt="iKook Verification" />
          <p className="small">International Passport</p>
        </div>

        {selectedIDType === "national id" && <VNinPrompt />}
      </div>

      <div className="flex-column a-little-gap">
        <MDBInput
          onChange={(e) => setIdNumber(e.target.value)}
          style={{ width: 300 }}
          className="ac-inputs"
          label={selectedIDType === "national id" ? "Virtual NIN" : "ID Number"}
          type="text"
        />

        {selectedIDType === "driver's licence" && (
          <MDBInput
            onChange={(e) => setDob(e.target.value)}
            style={{ width: 300 }}
            className="ac-inputs"
            label="Date of Birth"
            type="date"
            value={dob}
          />
        )}

        <IDUpload setIdImage={setIdImage} />

        <CVUpload setCv={setCv} />

        <ChefLicenceUpload setChefLicence={setChefLicence} />

        {usersData.country === "UK" && (
          <UKWorkAuthorization setUkWorkAuthorization={setUkWorkAuthorization} />
        )}

        {usersData.chef_details.chef_verification_details.documents.length > 0 ? (
          ""
        ) : (
          <MDBBtn
            style={{ backgroundColor: "#fcc01c", color: "black" }}
            disabled={hideBtn}
            className="small themeBtn"
            onClick={submitVerificationDocs}
            id="signupbtn-disable"
          >
            Submit Documents
          </MDBBtn>
        )}
      </div>
    </div>
  );
};

export default ChefVerification;
