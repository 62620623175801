import React from 'react'

function UpandDownText(props) {
  return (
    <div className='flex flex-col'>

    <span className='text-4xl  mdw:text-[22px] font-bold text-[#060605] p-0 m-0 font-Poppins leading-none'>{props.title}</span>
    <span className='text-sm mdw:text-base text-text-bodyColor font-Poppins p-0 m-0 leading-none'>{props.value}</span>
      
    </div>
  )
}

export default UpandDownText
