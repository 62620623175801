import React from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../../components/menu-card-small/menu-card-small.css";

const CDAMessagge = function ({ type, data }) {
  return (
    <div
      className={`a-message blackText ${
        type === "recieved" ? "a-message-recieved" : "a-message-sent"
      }`}
    >
      <div className="flex-row" style={{ justifyContent: "flex-start", gap: 10, marginBottom: -5 }}>
        <p className="small">
          {data.senderFirstName} {data.senderLastName}
        </p>

        <p className="xsmall greyText">
          {new Intl.DateTimeFormat(navigator.language, {
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(data.createdAt))}
        </p>
      </div>
      {data.message}
    </div>
  );
};

export default CDAMessagge;
