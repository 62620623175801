import React, { useState, useContext, useEffect } from "react";
import "../../styles/user-index.css";
import "../../../../styles/index.css";
import "../../../menu-page/menu-page.css";
import { AiOutlineCaretDown, AiOutlineCaretUp, AiTwotoneStar } from "react-icons/ai";
import { GoLocation, GoVerified } from "react-icons/go";
import { TbMessageLanguage } from "react-icons/tb";
import ProposalMenuCard from "../../../../components/proposal-menu-card/proposal-menu-card-small";
import AppContext from "../../../../context/app-context";
import { VscUnverified } from "react-icons/vsc";
import PaystackHook from "../../../../payment/paystack-hook";

let pprosalAmout;
let pprosalAcceptFunction;
let proposalCurr;

const TableProposalCard = function ({
  data,
  setOnView,
  bookingID,
  aProposalHasBennAccepted,
  setShowStripeModal,
}) {
  const [revealFullProposal, setRevealFullProposal] = useState(false);

  const [chef, setChef] = useState({});
  const [rating, setRating] = useState(0);
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");
  const [chefCurr, setChefCurr] = useState("");

  const [menusProposed, setMenusProposed] = useState([]);

  const {
    backendServer,
    apiKey,
    setIsLoading,
    getSignedAwsUrl,
    calculateChefRating,
    usersData,
    updateUsersData,
    popup,
    convertAmount,
  } = useContext(AppContext);

  useEffect(() => {
    if (!data) return;

    (async function () {
      setIsLoading(true);

      const chef = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data?.chef_who_proposed_id}`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      setChef(chef.data);
      setRating(calculateChefRating(chef.data.chef_details.reviews));
      setChefCurr(
        chef.data?.country === "UK" ? "GBP" : chef.data?.country === "Canada" ? "CAD" : "NGN"
      );

      if (chef?.data.photo) setChefImage(await getSignedAwsUrl(chef?.data.photo, "users"));

      const objMenus = await Promise.all(
        data.menus_proposed_ids.map(async (e) => {
          const { data } = await (
            await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${e}`, {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            })
          ).json();

          return data;
        })
      );

      const comps = objMenus.map((e, i) => <ProposalMenuCard key={i} data={e} />);

      setMenusProposed(comps);

      setIsLoading(false);
    })();
  }, [data, aProposalHasBennAccepted]);

  const rejectProposal = async function () {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/booking/proposals/reject/${bookingID}/${data._id}`,
          {
            method: "DELETE",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your booking has been updated!");

      updateUsersData();

      setIsLoading(false);

      setTimeout(() => {
        setOnView("custom booking");
      }, 1500);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  };

  const acceptProposalAndPay = async function (ref) {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/booking/proposals/accept/${bookingID}/${data._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({ paymentRef: ref, currency: chefCurr }),
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your booking has been updated!");

      setIsLoading(false);

      setTimeout(() => {
        document.location.reload();
      }, 1500);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  };

  const successPayment = (reference) => {
    acceptProposalAndPay(reference);
  };

  const closedPayment = () => {};

  const config = {
    reference: new Date().getTime().toString(),
    email: usersData.email,
    amount: data?.price_proposed * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_76e5521f96453545d74e2d8b9753ac2e6ff5a1fc",
  };

  const componentProps = {
    ...config,
    text: "Book Now",
    onSuccess: (reference) => successPayment(reference),
    onClose: closedPayment,
  };

  return (
    <div className="box-1-in" style={{ borderBottom: "1px solid grey", paddingBottom: 10 }}>
      <div className="flex-row align-row-left table-booking-card no-hover-color proposal-table-booking-card">
        <p className="tablesmall alignTextLeft yellowText" style={{ width: "20%" }}>
          {convertAmount(data?.price_proposed, chefCurr)}
        </p>

        <p className="tablesmall alignTextLeft" style={{ width: "30%" }}>
          {chef?.username ? chef?.username : ""}
        </p>

        <div className="tablesmall alignTextLeft" style={{ width: "20%", marginTop: -20 }}>
          <div className="rating-row">
            <AiTwotoneStar size={10} color={rating > 0 ? "yellow" : "grey"} />
            <AiTwotoneStar size={10} color={rating > 1 ? "yellow" : "grey"} />
            <AiTwotoneStar size={10} color={rating > 2 ? "yellow" : "grey"} />
            <AiTwotoneStar size={10} color={rating > 3 ? "yellow" : "grey"} />
            <AiTwotoneStar size={10} color={rating > 4 ? "yellow" : "grey"} />
          </div>
        </div>

        <p className="tablesmall alignTextLeft" style={{ width: "20%" }}>
          {data?.proposal_status}
        </p>

        <p className="xsmall alignTextLeft" style={{ width: "10%", marginTop: -2 }}>
          {revealFullProposal ? (
            <AiOutlineCaretUp
              className="cursor"
              onClick={() => setRevealFullProposal(!revealFullProposal)}
              size={15}
            />
          ) : (
            <AiOutlineCaretDown
              className="cursor"
              onClick={() => setRevealFullProposal(!revealFullProposal)}
              size={15}
            />
          )}
        </p>
      </div>

      <div className={revealFullProposal ? "box-1-in media-proposal-box-1-in-ud" : "cd-hide"}>
        <div className="no-border">
          <p className={`big blackText boldText removemargin`}>About {chef?.username}</p>
        </div>

        <div className="flex-row align-row-left" style={{ alignItems: "flex-start", gap: 10 }}>
          <img
            style={{ width: 150, height: 150, borderRadius: 150 }}
            src={chefImage}
            alt={chef?.username}
          />

          <div className="review-card">
            <p className="big blackText">{chef?.username}</p>

            {chef?.chef_details?.chef_verification_details?.is_documents_verified ? (
              <GoVerified color="green" />
            ) : (
              <VscUnverified color="red" />
            )}

            <p className="yellowText small removemargin alignTextLeft">
              {" "}
              <GoLocation color="#FCC01C" /> &nbsp; {chef?.city}
            </p>

            <p className="yellowText small alignTextLeft">
              {" "}
              <TbMessageLanguage color="#FCC01C" /> &nbsp;{" "}
              {chef?.chef_details?.languages?.join(", ")}
            </p>

            <p className="small" style={{ textAlign: "left" }}>
              {chef?.chef_details?.bio}
            </p>
          </div>
        </div>

        <div className="flex-row align-row-left" style={{ gap: 15, width: "100%" }}>
          <div className="chef-details-boxes">
            <h6 className="alignTextLeft">Chef Experience</h6>

            <p className="tablesmall alignTextLeft removemargin">
              {chef?.chef_details?.experience}
            </p>
          </div>

          <div className="chef-details-boxes">
            <h6 className="alignTextLeft">Cooking Style</h6>

            <p className="tablesmall alignTextLeft removemargin">
              {chef?.chef_details?.style_cooking}
            </p>
          </div>
        </div>

        <div className="no-border">
          <p className={`big blackText boldText removemargin`}>Proposal Message</p>
        </div>

        <div className="flex-row" style={{ gap: 15, width: "100%" }}>
          <div className="chef-details-boxes" style={{ width: "90%" }}>
            <h6 className="small alignTextLeft">Hello {usersData.first_name}</h6>

            <p className="tablesmall alignTextLeft removemargin">{data.proposal_message}</p>
          </div>
        </div>

        <div className="no-border">
          <p className={`big blackText boldText removemargin`}>Menus Proposed</p>
        </div>

        <div className="flex-row" style={{ gap: 15 }}>
          {menusProposed}
        </div>

        {data?.proposal_status === "rejected" ||
        data?.proposal_status === "accepted" ||
        aProposalHasBennAccepted ? (
          ""
        ) : (
          <div
            onClick={() => {
              pprosalAmout = data?.price_proposed;
              pprosalAcceptFunction = acceptProposalAndPay;
              proposalCurr = chefCurr;
            }}
            className="flex-row"
            style={{ marginBottom: 15 }}
          >
            <PaystackHook
              config={componentProps}
              disabled={false}
              paymentOnSuccess={successPayment}
              paymentClosed={closedPayment}
              setShowStripeModal={setShowStripeModal}
              currency={chefCurr}
            />

            <button onClick={() => rejectProposal()} className="themeBtn ud-btns xsmall redBg">
              Reject Proposal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export { TableProposalCard, pprosalAcceptFunction, pprosalAmout, proposalCurr };
