import React from "react";
import deal from "./images/Deal.png"
import meeting from "./images/Meeting.png"
import trust from "./images/Trust.png"
import bookimg1 from "./images/bookimg1.png"
import bookimg2 from "./images/bookimg2.png"
import chefuser from "./images/chefuser.png"
import "../../styles/index.css";
import "./about-us.css";

const AboutUs = function () {
  return (
    <div className="tac-cont" style={{ paddingTop: 50 }}>
      <div className="design-text-cont">
        <div>
          <div className="yellow-rec-ab"></div>
          <p className="big bigTextHome blackText removemargin" style={{ color: "#323335" }}>
            About Us
          </p>
        </div>
      </div>

      <div className="tac-flex-row">
        <div style={{ width: "100%" }}>
          <p className="small tac-text-ab">
            iKooK is passionate about bringing the joy of fine dining to your doorstep. Our platform connects you 
            with a curated selection of talented private chefs, ready to craft personalised culinary experiences in
            the comfort of your home.
        
          </p>
          
        </div>
        <p className="abt-big-gray-txt">Our Story</p>
          <p className="small tac-text-ab removemargin">
            iKook was born out of a need for good food and desire to create special moments around the dining table.
            We set out on a missionto simplify the process of hiringa private chef and make it accessible to more people.
          </p>
          <p className="abt-big-gray-txt">Join Our Community</p>
          <p className="small tac-text-ab removemargin">
            We're thrilled to have you as part of our community of food enthusiasts. Whether it's an intimate dinner for two,
            a family celebration, or a social gathering, our chefs are here to make your occassions memorable and stress-free.
          </p>
        <div className="ab-image">
          <img
            style={{ width: 250}}
            src="/images/aboutu.png"
            alt="ikook-terms-and-condition"
            title="Ikook Terms and Condition"
          />
        </div>
      </div>

      <div className="flex-row" style={{ marginBottom: 50 }}>
        <div className="yellow-bg-about">
          <div>
            <div className="black-rec-ab"></div>
            <p className="big bigTextHome removemargin" style={{ color: "white" }}>
              Purpose
            </p>
          </div>

          <p className="small alignTextLeft">
            To elevate the dining experiences from mere meal to cherish moment
          </p>
        </div>
        <div className="yellow-bg-about">
          <div>
            <div className="black-rec-ab"></div>
            <p className="big bigTextHome removemargin" style={{ color: "white" }}>
              Vision
            </p>
          </div>

          <p className="small alignTextLeft">
            To offer a diverse selection of private chefs who cater to a rich array of
            cuisines thereby bringing global culinary delights to your doorstep.
          </p>
        </div>
        <div className="yellow-bg-about">
          <div>
            <div className="black-rec-ab"></div>
            <p className="big bigTextHome removemargin" style={{ color: "white" }}>
              Mission
            </p>
          </div>

          <p className="small alignTextLeft">
            To provide a platform that brings talented chefs and food enthusiasts 
            together
          </p>
        </div>
      </div>
      <div>
        <div  className="core-values-head">
        
          <p> Core Value</p>
        </div>
        <div className="cv-bx-flex">
          <div className="cv-white-bx">
            <div className="sm-inner-yell">
                <img src={deal} alt="deal" />
            </div>
                <div>
                  <p className="cv-bx-head">Fairness</p>
                  <p className="cv-bx-bd">
                    We ensure transparent and equitable experiences
                    for clients and customer by upholding fairness in every
                    aspect, fostering trust, inclusivity, and mutual success.
                  </p>
                </div>
              
            </div>
            <div className="cv-white-bx">
            <div className="sm-inner-yell">
                <img src={meeting} alt="meeting" />
            </div>
                <div>
                  <p className="cv-bx-head">Building Community</p>
                  <p className="cv-bx-bd">
                    Build more of a genuine community with chefs and local business
                  </p>
                </div>
              
            </div>
            <div className="cv-black-bx">
            <div className="sm-inner-yell">
                <img src={trust} alt="trust" />
            </div>
                <div>
                  <p className="cv-bx-head">Security & Trust</p>
                  <p className="cv-bx-bd">
                    By ensuring that our chefs are verified and we also ensure that
                    we follow the GDPR law in order toprotect our user details.
                  </p>
                </div>
              
            </div>
          </div>    
        </div>
        <div className="core-values-head">
         
      <p> Chef & User</p>
        
        </div>
      <div className="flex-row" style={{ width: "100%", justifyContent: "center" }}>
        
        <div
          className="flex-row"
          style={{ marginBottom: 50, justifyContent: "center", gap: 80, width: "80%" }}
        >
          <div className="white-bg-about">
            
              <div className="ch-us-div">
              <img style={{ width: 70 }} src={chefuser} alt="ikook-uk" />

              <p className="big boldText removemargin">Chef</p>
              </div>
           
            <p className="small alignTextLeft">
              <strong>Earning potential:</strong> Fair compensation for culinary expertise
            </p>
            <p className="small alignTextLeft">
              <strong>Respect and Recognition:</strong> Valued contribution and open communication
            </p>
          </div>

          {/* <div className="white-bg-about">
            <img style={{ width: 70 }} src="/images/ca.png" alt="ikook-uk" />

            <p className="big boldText removemargin">Custom Cuisine</p>

            <p className="small alignTextLeft">
              Experienced and trained chefs tailor to your exact needs, making it the perfect
              solution for busy individuals who want to enjoy having a professional chef cook for
              them and for special occasions.
            </p>
          </div> */}

          <div className="white-bg-about">
            
            <div className="ch-us-div">
            <img style={{ width: 70 }} src={chefuser} alt="ikook-uk" />

            <p className="big boldText removemargin">User</p>
            </div>
          
            <p className="small alignTextLeft">
              <strong>Fair Pricing:</strong> Transparent and competitive pricing without hidden fees
            </p>
            <p className="small alignTextLeft">
              <strong>Equal Opportunities:</strong> Diverse access to talented chefs regardless of background
            </p>
          </div>

          
        </div>
      </div>

      <div className="flex-row bn-abt" >
        <div className="flex-col">
          <p className="vbig alignTextLeft">
            Book a Private <br></br>Chef now
          </p>

          <button onClick={() => (document.location.href = "/users")} className="themeBtn">
            Get Started
          </button>
        </div>
        <div>
        <img src={bookimg1} alt="ikook chefs" className="b-p-ione" />
        <img src={bookimg2} alt="ikook chefs" className="b-p-itwo"/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
