import React, { useState } from "react";
import Textfield from "../components/textfield/Textfield";
import Select from "react-select";
import FilledButton from "../components/buttons/FilledButton";
import { registerUsers } from "./service";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";

function WaitlistDialog(props) {
  const [form, setform] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [services, setServices] = useState([]);
  const [type, setType] = useState([]);
  const options = [
    { value: "Meal Prep", label: "Meal Prep" },
    { value: "Chef at Home", label: "Chef at Home" },
    { value: "Corporate Dining", label: "Corporate Dining" },
    { value: "Gifting", label: "Gifting" },
    { value: "Cooking Lesson", label: "Cooking Lesson" },
    { value: "Meal Delivery", label: "Meal Delivery" },
    {
      value: "Nutritionist",
      label: "Nutritionist",
    },
    { value: "Other", label: "Other" },
  ];
  const options2 = [
    { value: "host", label: "Host" },
    { value: "chef", label: "Chef" },
    { value: "service", label: "Service Provider" }
  ];

  async function submit() {
    const combined = services.map((e)=>e.value).join(",").replace("Other,","")+", "+(form.service!=undefined?("Others:"+form.service):"-")
   
    setLoading(true);
    const result = await registerUsers(form,combined);
    console.log(result);
    if (result.status == true) {
      setLoading(false);
      alert("Registration Successful, please check your email for details");
      props.onDismiss();
    } else {
      setLoading(false);
    }
  }

  return (
    <div className="w-screen z-50 h-screen fixed backdrop-blur-md top-0 left-0 right-0 bottom-0 bg-black/30 flex place-content-center place-items-center">
      <div className="flex flex-col bg-white rounded-xl mdw:py-16 py-4 overflow-y-auto max-h-[80vh] mdw:max-w-[90vw]">
        <div className="flex place-content-between px-3">
          <h1 className="text-base text-text-bodyColor2">Join waiting list</h1>
          <svg
            onClick={(e) => props.onDismiss()}
            className="cursor-pointer stroke-text-bodyColor hover:stroke-primary-100"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_5815)">
              <path
                d="M15 15L12 12M12 12L9 9M12 12L9 15M12 12L15 9M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12Z"
                stroke="inherit"
                stroke-width="2"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_5815">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <hr className="w-full border-gray-200" />
        <h1 className="text-base text-text-bodyColor2  md:px-5">
          Be among the early ones to be informed when we launch live.
        </h1>
        <form className="flex flex-col px-3 mt-4">
          <Textfield
            textfieldcustomClass="w-full mt-2"
            title="Full Name *"
            titleStyle="text-base text-black  "
            value={form.firstName}
            id="firstname"
            name="firstname"
            padding="px-2 py-2.5"
            border="border border-border rounded-lg"
            onValueChanged={(e) => {
              setform((pre) => ({ ...pre, firstName: e }));
            }}
            placeholder="What is your full name"
          />
          <Textfield
            textfieldcustomClass="w-full mt-2"
            customClass="mt-6"
            title="Email *"
            titleStyle="text-base text-black p-0 m-0 leading-0 "
            value={form.email}
            id="email"
            name="email"
            type="email"
            padding="px-2 py-2.5"
            border="border border-border rounded-lg"
            onValueChanged={(e) => {
              setform((pre) => ({ ...pre, email: e }));
            }}
            placeholder="What is your email address?"
          />
          <Textfield
            textfieldcustomClass="w-full mt-2"
            customClass="mt-6 p-0 gap-0"
            title="Phone Number *"
            id="phone"
            name="phone"
            titleStyle="text-base text-black  "
            value={form.phone}
            padding="px-2 py-2.5"
            border="border border-border rounded-lg"
            onValueChanged={(e) => {
              setform((pre) => ({ ...pre, phone: e }));
            }}
            placeholder="Provide your phone number"
          />
          '
          <Textfield
            customClass="mt-2 p-0 gap-0"
            textfieldcustomClass="min-w-[350px]"
            title="What would you like to use iKooK for?"
            padding=""
            border=""
            titleStyle="text-base text-black  "
            CustomInput={
              <Select
                placeholder="select an option"
                blurInputOnSelect={true}
                className="text-start"
                isMulti
                value={type}
                closeMenuOnSelect={true}
                onChange={(e) => {
                  setType(e);
                  setform((pre) => ({ ...pre, type: e }));
                  console.log(type);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "transparent",
                    border: 0,
                    boxShadow: "none",
                  }),
                }}
                getOptionLabel={(option2) => option2.label}
                getOptionValue={(option2) => option2.value}
                options={options2}
              />
            }
            value=""
            onValueChanged={(e) => {}}
            placeholder="What type of service do you want?"
          />
          {type.length > 0 && type.find((e)=> e.value!="host")!=undefined &&
          <Textfield
            customClass="mt-2 p-0 gap-0"
            textfieldcustomClass="min-w-[350px]"
            title="Which services do you offer"
            padding=""
            border=""
            titleStyle="text-base text-black  "
            CustomInput={
              <Select
                placeholder="select a service"
                blurInputOnSelect={true}
                className="text-start"
                isMulti
                value={services}
                closeMenuOnSelect={true}
                onChange={(e) => {
                  setServices(e);
                  console.log(services);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "transparent",
                    border: 0,
                    boxShadow: "none",
                  }),
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                options={options}
              />
            }
            value=""
            onValueChanged={(e) => {}}
            placeholder="What type of service do you want?"
          />
          }
          {services.find((e) => e.value === "Other") && (
            <Textfield
              textfieldcustomClass="w-full mt-2"
              customClass="mt-2 p-0 gap-0"
              title="Enter service"
              titleStyle="text-base text-black  "
              value={form.service}
              padding="px-2 py-2.5"
              border="border border-border rounded-lg"
              onValueChanged={(e) => {
                setform((pre) => ({ ...pre, service: e }));
              }}
              placeholder="e.g Meal Prep, Fine Dining, Cooking Class, etc..."
            />
          )}
          '
          <FilledButton
            type={submit}
            loading={loading}
            onclick={(e) => {
              e.preventDefault();
              submit();
            }}
            enabled={
              type.length <=1 && type.find((e)=> e.value=="host")!=undefined ?true:(services?.length ?? 0) > 0 &&
              (form.phone?.length ?? 0) > 5 &&
              (form.email?.length ?? 0) > 5 &&
              (form.firstName?.length ?? 0) > 3
            }
            colors="bg-primary-100 disabled:bg-primary-100/30 mt-[30px] w-fit px-6"
          >
            Submit
          </FilledButton>
        </form>
      </div>
    </div>
  );
}

export default WaitlistDialog;
