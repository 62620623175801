import React, { useState } from "react";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

const FAQ = ({ question, answer }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="ch-q-qcontainer">
      <header className="ch-q-header">
        <h6 style={{ textAlign: "left" }}>{question}</h6>
        <button className="ch-q-button" onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? (
            <AiFillCaretUp className="ch-q-img" />
          ) : (
            <AiFillCaretDown className="ch-q-img" />
          )}
        </button>
      </header>
      {showInfo && (
        <div style={{ textAlign: "left" }} className="ch-q-answer">
          <p>{answer}</p>
        </div>
      )}
      <div className="ch-q-line"></div>
    </div>
  );
};

export default FAQ;
