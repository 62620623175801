import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/app-context";
import ExploreTableBookingCard from "./child-components/explore-booking-card";

const ExploreCustomBookingInit = ({ setCustomBookingData, display, setOnView }) => {
  const [tableBookingComp, setTableBookingComp] = useState([]);

  const { usersData, backendServer, setIsLoading, apiKey, popup } = useContext(AppContext);

  useEffect(() => {
    getCustomBookings();
  }, [usersData]);

  async function getCustomBookings() {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get?type=custom-booking`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const filtered = data.filter((e) => {
        return (
          e.status === "pending" &&
          e.booking_type === "custom-booking" &&
          !e.amount &&
          e?.custom_booking_country === usersData?.country
        );
      });

      const comp = filtered.map((e, i) => (
        <ExploreTableBookingCard
          setCustomBookingData={setCustomBookingData}
          data={e}
          key={i}
          setOnView={setOnView}
        />
      ));

      if (comp.length === 0)
        setTableBookingComp(
          <div className="flex-column for-no-bookings">
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">NO HOST INQUIRIES YET</p>
            <p className="small">No worries, check back later...</p>
          </div>
        );
      else {
        comp.reverse();
        setTableBookingComp(comp);
        setIsLoading(false);
      }

      setIsLoading(false);
    } catch (err) {
      popup("Something went wrong");
    }
  }

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <div className="flex-row ud-dash-header">
        <p className="big alignTextLeft removemargin">Explore Host Inquiries</p>

        <button onClick={() => getCustomBookings()} className="themeBtn ud-btns">
          Refresh
        </button>
      </div>

      <div
        className="flex-row align-row-left"
        style={{ padding: "0px 0px 0px 0px", justifyContent: "flex-start", marginTop: 40 }}
      >
        <p style={{ width: "15%" }} className="small boldText">
          Event Theme
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Date
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Status
        </p>

        <p style={{ width: "20%" }} className="small boldText">
          User
        </p>

        <p style={{ width: "18%" }} className="small boldText">
          Service
        </p>

        <p style={{ width: "25%" }} className="small boldText">
          Location
        </p>
      </div>

      {tableBookingComp}
    </div>
  );
};

export default ExploreCustomBookingInit;
