import React, { useEffect, useState } from "react";
import "../../../tailwind.css";
import country from "../../../context/country";
import Textfield from "./components/textfield/Textfield";
import { useContext } from "react";
import AppContext from "../../../context/app-context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import restrictCountry from "../../../context/country-restrictions";
import MenuCard from "./components/card/MenuCard";
import FilledButton from "./components/buttons/FilledButton";
import axios from "axios";

function ResetPassword() {
  const {
    popup,
  } = useContext(AppContext);
  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  let location = useLocation();

  // Parse the query parameters
  let queryParams = new URLSearchParams(location.search);
  let _se = queryParams.get("_se");
  let token = queryParams.get("token");
  let uidb64 = queryParams.get("uidb64");

  const navigate = useNavigate();

  const submitReset = async () => {
    try {
      setLoading(true);

      const res = await axios.post(
        "https://ikook-mobile-master-4388d48544b9.herokuapp.com/user-management/password-reset/confirm?token=" +
          token +
          "&uidb64=" +
          uidb64,
        {
          new_password: password,
          confirm_password: confirmPassword,
        }
      );

      //      production
      //  const res =  await axios.post("https://ikook-mobile-a6399b12d008.herokuapp.com/user-management/password-reset/confirm?token="+token+"&uidb64="+uidb64,{
      //       "new_password": password,
      //       "confirm_password": confirmPassword
      //     });
      const data = res.data;
      if (res.status >= 200 && res.status <= 399) {
        alert("Password reset successfully, login ");
        navigate("/");
      } else {
        popup(res.data.error);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      popup(`Password reset failed`);
    }
  };

  useEffect(() => {
    console.log(_se + token);

    if (
      _se == undefined ||
      (token == undefined && _se?.length < 5) ||
      token?.length < 10
    ) {
      console.log(_se + token);
      navigate("/");
    } else {
    }
  }, [_se, token]);

  return (
    <div className="flex flex-col w-screen font-Poppins">
      <head>
        <title>ResetPassword - Ikook</title>
        <meta name="title" content="Reset Password ikook" />
        <meta name="description" content="Reset your accounts today" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Reser password - ikook" />
        <meta property="og:description" content="Reset your password" />
        <meta
          property="og:image"
          content="https://metatags.io/images/meta-tags.png"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta property="twitter:title" content="Reset Password" />
        <meta property="twitter:description" content="Reset your password" />
        <meta
          property="twitter:image"
          content="https://metatags.io/images/meta-tags.png"
        />
      </head>

      <div className="customContainer flex flex-col place-content-center place-items-center min-h-[80vh]">
        <div className="shadow-2xl bg-white rounded-xl drop-shadow-xl px-8 py-6">
          <h1 className="mt-2 mb-4"> Reset your password </h1>

          <Textfield
            textfieldcustomClass="mdw:min-w-auto min-w-[350px] "
            title="New Password"
            titleStyle="text-base text-primary-100 font-semibold "
            value={password}
            type="text"
            onValueChanged={(e) => {
              setPassword(e);
            }}
            placeholder="Enter new password"
          />
          {password.length > 0 && password.length < 8 && (
            <p className="mt-1 mb-4 text-base text-red-500">
              Password length must be greater than 5
            </p>
          )}

          <Textfield
            customClass="mt-10"
            textfieldcustomClass="mdw:min-w-auto min-w-[350px] "
            title="Confirm New Password"
            titleStyle="text-base text-primary-100 font-semibold "
            value={confirmPassword}
            type="text"
            onValueChanged={(e) => {
              setConfirm(e);
            }}
            placeholder="Confirm new password"
          />

          <FilledButton
            enabled={confirmPassword === password && password.length >= 8}
            onclick={() => submitReset()}
            loading={loading}
            padding="p-[10px]  px-[24px] mdw:px-[10px] mdw:py-[10px]"
            radius="rounded-full mt-12"
            colors="border-primary-100 bg-primary-100 disabled:bg-primary-100/50 border-2 rounded-full gap-2 text-white"
          >
            Submit
          </FilledButton>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
