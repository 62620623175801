import React, { useState, useContext } from "react";
import "../../../../../menu-page/menu-page.css";
import "../../../../../../styles/index.css";
import { MDBBtn, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import AppContext from "../../../../../../context/app-context";
import { MenuDishesWhatIsThis } from "../../../../../../components/what-is-this-collections/what-is-this-collections";

const DessertUpload = function ({ data, editMenuData }) {
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [menuIngredients, setMenuIngredients] = useState("");

  const { backendServer, apiKey, setIsLoading, popup } = useContext(AppContext);

  async function updateMenu() {
    try {
      if (!menuName && !menuDescription && !menuIngredients)
        return popup("You haven't changed any values");

      if (!menuDescription && !data?.full_description)
        return popup("Please set a Menu Description");

      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/update/${editMenuData._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({
              menudessert: {
                id: data?._id,
                description: menuName || data?.description,
                price: 0,
                full_description: menuDescription || data?.full_description,
                ingredients: menuIngredients || data?.ingredients || "",
              },
            }),
          }
        )
      ).json();

      if (returned.status === "Internal server error")
        throw new Error("Something went wrong, try again later...");

      popup("Menu updated successfully");

      setIsLoading(false);
    } catch (err) {
      popup("Error, please try again later");
      setIsLoading(false);
    }
  }

  return (
    <div className="flex-row flex-gap align-row-left" style={{ alignItems: "flex-start" }}>
      <div className="menu-boxex-settings flex-col align-column-left">
        <h5 className="greyText">
          <MenuDishesWhatIsThis title={"DESSERT DISH"} />
        </h5>

        <MDBInput
          defaultValue={data?.description}
          onChange={(e) => setMenuName(e.target.value)}
          maxLength={50}
          style={{ width: "350px" }}
          className="ac-inputs chef-menu-inputs"
          label="Dessert Menu Name"
          type="text"
        />

        <MDBTextArea
          defaultValue={data?.full_description}
          onChange={(e) => setMenuDescription(e.target.value)}
          className="ac-inputs chef-menu-inputs "
          label="Dessert Menu Description"
          maxLength={300}
          cols={74}
          rows={5}
          type="text"
        />

        <MDBInput
          defaultValue={data?.ingredients}
          onChange={(e) => setMenuIngredients(e.target.value)}
          className="ac-inputs chef-menu-inputs "
          label="Dessert Menu Ingredients"
          maxLength={100}
          type="text"
        />

        <MDBBtn
          style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
          onClick={updateMenu}
          className="themeBtn"
        >
          Update
        </MDBBtn>
      </div>
    </div>
  );
};

export default DessertUpload;
