import React from "react";

import "./chefjoin.css";
import "../../../src/styles/index.css";
import FAQ from "../../components/FAQ/FAQ"
import Host from "../works-host/host"
import "../../styles/index.css";
import UserJoin from "./user-join";
import AreYouUser from "./are-you-user";
import "../user-dashboard/styles/user-index.css";

const HowItWorksUser = function () {
  return (
    <div className="how-it-works-cont ">
      {/* <UserJoin /> */}

      {/* <AreYouUser /> */}
      <Host />
      {/* <Steps background={true} />
        <div style={{ width: "80%" }}>
          <FAQ role="host" />;
        </div> */}

    </div>
  );
};

export default HowItWorksUser;
