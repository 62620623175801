import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import { CdCreateMenu } from "../components/chef-create-menu";
import AppContext from "../../../context/app-context";
import country from "../../../context/country";
import ChefUtilityMenuCard from "../../../components/utility-menu-card/utility-menu-card-small";
import CdEditMenu from "../components/chef-edit-menu";

const CdMenus = function ({ display }) {
  const [onView, setOnView] = useState("menu");

  const [menuComps, setMenuComps] = useState([]);

  const [editMenuData, setEditMenuData] = useState({});

  const { backendServer, apiKey, setIsLoading, popup, usersData } = useContext(AppContext);

  useEffect(() => {
    getMenus();
  }, [usersData]);

  async function getMenus() {
    try {
      if (usersData.chef_details.chef_menus.length === 0)
        return setMenuComps(
          <div className="flex-column for-no-bookings">
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">No Menus to display. Get started by creating a Menu.</p>
          </div>
        );

      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get?chefID=${usersData?._id}`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const servicesFilteredData = returned.data.filter(
        (e) => e?.chefID?.country === country && e?.status !== "deleted"
      );

      const compss = servicesFilteredData.map((e, i) => {
        return (
          <ChefUtilityMenuCard
            setOnView={setOnView}
            setEditMenuData={setEditMenuData}
            key={i}
            data={e}
          />
        );
      });

      if (compss.length === 0)
        return setMenuComps(
          <div className="flex-column for-no-bookings">
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">No Menus to display.</p>
          </div>
        );

      compss.reverse();
      setMenuComps(compss);

      setIsLoading(false);
    } catch (err) {
      popup("Failed to fetch menus");
      setIsLoading(false);
      setMenuComps(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO MENUS TO DISPLAY</p>
        </div>
      );
    }
  }

  if (!display) return;

  return (
    <div className="chef-ud-dashboard-cont">
      <div style={{ display: `${onView === "menu" ? "" : "none"}` }}>
        <div className="flex-row ud-dash-header">
          <p className="big alignTextLeft removemargin">Your Menus</p>

          <button
            onClick={() => {
              if (!usersData?.chef_details?.chef_verification_details?.is_documents_verified)
                return popup("Complete your chef verification...");

              if (usersData?.country !== country)
                return popup("You cannot create Menu(s) in this Edition");

              setOnView("create menu");
            }}
            className="themeBtn ud-btns media-chef-create-menu"
          >
            Create Menu
          </button>
        </div>

        <div className="ud-active-booking-cont">{menuComps}</div>
      </div>

      <CdCreateMenu setOnView={setOnView} onView={onView} />

      <CdEditMenu
        setEditMenuData={setEditMenuData}
        editMenuData={editMenuData}
        setOnView={setOnView}
        onView={onView}
      />
    </div>
  );
};

export default CdMenus;
