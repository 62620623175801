import menu from "./assets/menuIcon.svg";
import book from "./assets/book.svg";
import celebrate from "./assets/celebrate.svg";
import secure from "./assets/secure.svg";

const stepsData = [
  {
    image: menu,
    heading: "Explore menus & chefs",
    info: "Discover diverse menus and talented chefs, offering a variety of delicious options. Refine choices based on location or cuisine preferences.",
  },
  {
    image: book,
    heading: "Make a booking",
    info: "Reserve your culinary journey with ease. Share dietary preferences and relax while chefs craft a unique experience for you.",
  },
  {
    image: secure,
    heading: "Checkout securely",
    info: "Finalize bookings confidently with our secure checkout. Payments are held until event completion, ensuring peace of mind.",
  },
  {
    image: celebrate,
    heading: "Enjoy your experience",
    info: "Embark on a culinary adventure as chefs provide exceptional service on your special day. Savor memories through flavors and hospitality.",
  },
];

export default stepsData;
