import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageThree = function ({ currPage, setCurrPage, setBookingType, query }) {

  const [selected, setSelected] = useState([]);

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  function hasItem(text){
    const index = selected.indexOf(text);
    return index!==-1
  }

  const addOrRemoveText = (text) => {
    if (selected.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = selected.filter(item => item !== text);
      setSelected(updatedList);
      console.log(`Text "${text}" removed from the list.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...selected, text];
      setSelected(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  useEffect(() => {
    if (query === "le" && currPage === 3) {
      setSelected([...selected,"le"]);
      setBookingType("large-event");
      setCurrPage(3 + 1);
    }
  }, [currPage]);

  useEffect(() => {
    if (selected.length>0) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  if (currPage !== 3) return;

  return (
    <div className="pages-cont" data-page="3">
      <h4>What Service Would You Like?</h4>

      <div className="flex-row" style={{ gap: "40px" }}>
        <div
          onClick={() => {
            addOrRemoveText("mp");
            setBookingType("meal-prep");
          }}
          className={`div-image-text-cb ${hasItem("mp") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 40 }} src="/images/new-mp.png" alt="iKook Chef At Home" />
          <p className="small">Meal Prep</p>
        </div>

        <div
          onClick={() => {
            addOrRemoveText("ceh");
            setBookingType("chef-at-home");
          }}
          className={`div-image-text-cb ${hasItem("ceh") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/cah.png" alt="iKook Meal Prep" />
          <p className="small">Chef At Home</p>
        </div>

        <div
          onClick={() => {
            addOrRemoveText("le");
            setBookingType("large-event");
          }}
          className={`div-image-text-cb ${hasItem("le") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/le.png" alt="iKook Large Events" />
          <p className="small">Large Events</p>
        </div>

        <div
          onClick={() => {
            addOrRemoveText("co");
            setBookingType("corporate");
          }}
          className={`div-image-text-cb ${hasItem("co") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/co.png" alt="iKook Corporate Events" />
          <p className="small">Corporate</p>
        </div>

        <div
          onClick={() => {
            addOrRemoveText("coh");
            setBookingType("chef-only");
          }}
          className={`div-image-text-cb ${hasItem("coh") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/coh.png" alt="iKook Corporate Events" />
          <p className="small">Chef Only</p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageThree;
