import React, { useState, useContext, useEffect } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../components/auth/login/login-modal.css";
import "../../../styles/chef-index.css";
import "../../../../../styles/index.css";
import "../../../../../pages/meal-prep-menu-page/meal-prep-menu-page.css";
import { MDBBtn } from "mdb-react-ui-kit";
import AppContext from "../../../../../context/app-context";

const ChefBank = function ({ activeNav }) {
  const [hideSubmitBtn, setHideSubmitBtn] = useState(true);

  const [bankName, setBankName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accName, setAccName] = useState("");

  const { usersData, backendServer, apiKey, setIsLoading, updateUsersData, popup } =
    useContext(AppContext);

  useEffect(() => {
    if (accName && accNumber && sortCode && bankName) setHideSubmitBtn(false);
    else setHideSubmitBtn(true);

    return () => {
      if (activeNav === "bank") return;

      setBankName("");
      setAccName("");
      setAccNumber("");
      setSortCode("");
    };
  });

  async function updateBank() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/bank/update/${usersData._id}`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify({
            account_number: accNumber,
            bank_name: bankName,
            sort_code: sortCode,
            account_name: accName,
          }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your Bank Details Have Been Updated");
      setIsLoading(false);
      updateUsersData();
      setBankName("");
      setAccName("");
      setAccNumber("");
      setSortCode("");
    } catch (err) {
      popup("Something went wrong");
      setIsLoading(false);
    }
  }

  if (activeNav !== "bank") return;

  return (
    <div className="cd-box-1-in-acc media-chef-cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll" }}>
      <p className="small removemargin">Bank Name</p>

      <input
        onChange={(e) => setBankName(e.target.value)}
        className="meal-prep-inputs meal-prep-inputs-chef-settings"
        type="text"
        placeholder={usersData?.chef_details?.bank_account?.bank_name}
        style={{ marginTop: -10 }}
      />

      <p className="small removemargin">Sort Code</p>

      <input
        onChange={(e) => setSortCode(e.target.value)}
        className="meal-prep-inputs meal-prep-inputs-chef-settings"
        type="text"
        placeholder={usersData?.chef_details?.bank_account?.sort_code}
        style={{ marginTop: -10 }}
      />

      <p className="small removemargin">Account Number</p>

      <input
        onChange={(e) => setAccNumber(e.target.value)}
        className="meal-prep-inputs meal-prep-inputs-chef-settings"
        type="number"
        placeholder={usersData?.chef_details?.bank_account?.account_number}
        style={{ marginTop: -10 }}
      />

      <p className="small removemargin">Account Name</p>

      <input
        onChange={(e) => setAccName(e.target.value)}
        className="meal-prep-inputs meal-prep-inputs-chef-settings"
        type="text"
        placeholder={usersData?.chef_details?.bank_account?.account_name}
        style={{ marginTop: -10 }}
      />

      <div className="flex-row align-row-left">
        <MDBBtn
          onClick={updateBank}
          style={{ backgroundColor: "#fcc01c", color: "black" }}
          disabled={hideSubmitBtn}
          className="small themeBtn"
          id="signupbtn-disable"
        >
          Update Bank Information
        </MDBBtn>
      </div>
    </div>
  );
};

export default ChefBank;
