import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/user-index.css";
import UserDashBoardHeader from "../ud-header/ud-header";
import UdBookings from "../pages/ud-bookings";
import UdDashboard from "../pages/ud-dashboard";
import UdNotifications from "../pages/ud-notifications";
import UdMessages from "../pages/ud-messages";
import UdReviews from "../pages/ud-review";
import UdSupport from "../pages/ud-support";
import UdSettings from "../pages/ud-settings";

const UserDashBoardEngine = forwardRef(function (
  { msgCount, notCount, setDashboardFunctions, dashboardFunctions },
  ref
) {
  const [onView, setOnView] = useState("dashboard");

  useImperativeHandle(
    ref,
    () => {
      return {
        changeScreen(screen) {
          setOnView(screen);
        },
      };
    },
    [onView]
  );

  return (
    <div className="ud-engine-cont media-ud-engine-cont">
      <UserDashBoardHeader msgCount={msgCount} notCount={notCount} />

      <UdDashboard
        setDashboardFunctions={setDashboardFunctions}
        display={onView === "dashboard" ? true : false}
      />

      <UdBookings
        dashboardFunctions={dashboardFunctions}
        display={onView === "bookings" ? true : false}
      />

      <UdNotifications display={onView === "notifications" ? true : false} />

      <UdMessages display={onView === "messages" ? true : false} />

      <UdReviews display={onView === "review" ? true : false} />

      <UdSupport display={onView === "support" ? true : false} />

      <UdSettings display={onView === "settings" ? true : false} />
    </div>
  );
});

export default UserDashBoardEngine;
