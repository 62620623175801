import React, { useEffect, useState, useContext } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../styles/index.css";
import DessertUpload from "./upload-cards/dessert";
import SideUpload from "./upload-cards/side";
import MainUpload from "./upload-cards/main";
import StarterUpload from "./upload-cards/starter";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import {
  MenuAdditionalChargeWhatIsThis,
  MenuBounderyNumberOfMenuWhatIsThis,
} from "../../../../../components/what-is-this-collections/what-is-this-collections";
import AppContext from "../../../../../context/app-context";

let boundariesRaw = {
  min_starter: {
    value: "",
    additional_charge: "",
  },
  min_main: {
    value: "",
    additional_charge: "",
  },
  min_dessert: {
    value: "",
    additional_charge: "",
  },
  min_side: {
    value: "",
    additional_charge: "",
  },
};

const EMMAndP = function ({ activeNav, editMenuData }) {
  const [starterComps, setStarterComps] = useState([]);
  const [mainComps, setMainComps] = useState([]);
  const [sideComps, setSideComps] = useState([]);
  const [dessertComps, setDessertComps] = useState([]);
  const [_boundaries, setBoundaries] = useState([]);

  const { backendServer, apiKey, setIsLoading, popup, usersData } = useContext(AppContext);

  useEffect(() => {
    setStarterComps(
      editMenuData.menustarter?.map((e, i) => (
        <StarterUpload key={i} editMenuData={editMenuData} data={e} />
      ))
    );

    setMainComps(
      editMenuData.menumain?.map((e, i) => (
        <MainUpload key={i} editMenuData={editMenuData} data={e} />
      ))
    );

    setSideComps(
      editMenuData.menuside?.map((e, i) => (
        <SideUpload key={i} editMenuData={editMenuData} data={e} />
      ))
    );

    setDessertComps(
      editMenuData.menudessert?.map((e, i) => (
        <DessertUpload key={i} editMenuData={editMenuData} data={e} />
      ))
    );

    boundariesRaw.min_starter = editMenuData.boundaries.min_starter;
    boundariesRaw.min_main = editMenuData.boundaries.min_main;
    boundariesRaw.min_dessert = editMenuData.boundaries.min_dessert;
    boundariesRaw.min_side = editMenuData.boundaries.min_side;
  }, [editMenuData]);

  async function updateBoundary(type) {
    try {
      const obj = {};

      obj[type] = {
        value: boundariesRaw[type].value,
        additional_charge: boundariesRaw[type].additional_charge,
      };

      if (obj[type]?.value && Number(obj[type]?.value) < 0)
        return popup("You have an invalid minimum value");
      if (obj[type]?.additional_charge && Number(obj[type]?.additional_charge) < 0)
        return popup("You have an invalid additional charge");

      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/menu/update/${editMenuData._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify(obj),
          }
        )
      ).json();

      if (returned.status === "Internal server error")
        throw new Error("Something went wrong, try again later...");

      popup("Menu updated successfully");

      setIsLoading(false);
    } catch (err) {
      popup("Error, please try again later");
      setIsLoading(false);
    }
  }

  if (activeNav !== "m&p") return;

  if (!editMenuData) return;

  return (
    <div className="cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll" }}>
      {starterComps}

      {editMenuData?.menustarter?.length !== 0 && (
        <div className="flex-row align-row-left flex-gap">
          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Starter"} />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_starter?.value}
              onChange={(e) => {
                boundariesRaw.min_starter.value = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Minimum Number of Starter"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuAdditionalChargeWhatIsThis
              title={`Additional Starter Charge (${
                usersData?.country === "UK"
                  ? "GBP"
                  : usersData?.country === "Canada"
                  ? "CAD"
                  : "NGN"
              })`}
            />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_starter?.additional_charge}
              onChange={(e) => {
                boundariesRaw.min_starter.additional_charge = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Additional Starter Charge"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <div>&nbsp;</div>
            <MDBBtn
              style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
              onClick={() => updateBoundary("min_starter")}
              className="themeBtn edit-menu-updte-btns"
            >
              Update
            </MDBBtn>
          </div>
        </div>
      )}

      {mainComps}

      {editMenuData?.menumain?.length !== 0 && (
        <div className="flex-row align-row-left flex-gap">
          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Main"} />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_main?.value}
              onChange={(e) => {
                boundariesRaw.min_main.value = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Minimum Number of Main"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuAdditionalChargeWhatIsThis
              title={`Additional Main Charge (${
                usersData?.country === "UK"
                  ? "GBP"
                  : usersData?.country === "Canada"
                  ? "CAD"
                  : "NGN"
              })`}
            />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_main?.additional_charge}
              onChange={(e) => {
                boundariesRaw.min_main.additional_charge = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Additional Main Charge"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <div>&nbsp;</div>
            <MDBBtn
              style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
              onClick={() => updateBoundary("min_main")}
              className="themeBtn edit-menu-updte-btns"
            >
              Update
            </MDBBtn>
          </div>
        </div>
      )}

      {dessertComps}

      {editMenuData?.menudessert?.length !== 0 && (
        <div className="flex-row align-row-left flex-gap">
          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Dessert"} />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_dessert?.value}
              onChange={(e) => {
                boundariesRaw.min_dessert.value = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Minimum Number of Dessert"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuAdditionalChargeWhatIsThis
              title={`Additional Dessert Charge (${
                usersData?.country === "UK"
                  ? "GBP"
                  : usersData?.country === "Canada"
                  ? "CAD"
                  : "NGN"
              })`}
            />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_dessert?.additional_charge}
              onChange={(e) => {
                boundariesRaw.min_dessert.additional_charge = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Additional Dessert Charge"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <div>&nbsp;</div>
            <MDBBtn
              style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
              onClick={() => updateBoundary("min_dessert")}
              className="themeBtn edit-menu-updte-btns"
            >
              Update
            </MDBBtn>
          </div>
        </div>
      )}

      {sideComps}

      {editMenuData?.menuside?.length !== 0 && (
        <div className="flex-row align-row-left flex-gap">
          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuBounderyNumberOfMenuWhatIsThis title={"Minimum Number of Side"} />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_side?.value}
              onChange={(e) => {
                boundariesRaw.min_side.value = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Minimum Number of Side"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <MenuAdditionalChargeWhatIsThis
              title={`Additional Side Charge (${
                usersData?.country === "UK"
                  ? "GBP"
                  : usersData?.country === "Canada"
                  ? "CAD"
                  : "NGN"
              })`}
            />
            <MDBInput
              defaultValue={editMenuData?.boundaries?.min_side?.additional_charge}
              onChange={(e) => {
                boundariesRaw.min_side.additional_charge = e.target.value;
                setBoundaries({});
              }}
              style={{ width: "335px" }}
              className="ac-inputs"
              label="Additional Side Charge"
              type="number"
              min="1"
            />
          </div>

          <div className="flex-column align-column-left" style={{ width: "fit-content", gap: 10 }}>
            <div>&nbsp;</div>
            <MDBBtn
              style={{ backgroundColor: "white", color: "black", fontWeight: "bolder" }}
              onClick={() => updateBoundary("min_side")}
              className="themeBtn edit-menu-updte-btns"
            >
              Update
            </MDBBtn>
          </div>
        </div>
      )}
    </div>
  );
};

export default EMMAndP;
