// const apiCall = async (id) => {
//   try {
//     const individualRes = await fetch(`https://ikook.herokuapp.com/accounts/blog/get/${id}`, {
//       method: "GET",
//     });
//     const res = await fetch(`https://ikook.herokuapp.com/accounts/blog/get`, {
//       method: "GET",
//     });

//     const data = await individualRes.json();
//     const blogInfoData = await res.json();
//     const filteredBlogData = blogInfoData?.data?.find((obj) => obj.id === Number(id));
//     return { data, filteredBlogData };
//   } catch (error) {
//     throw error;
//   }
// };

const apiCall = async (slug) => {
  try {
    const individualRes = await fetch(`https://ikook.herokuapp.com/accounts/blog/get/slug/${slug}`, {
      method: "GET",
    });
    const res = await fetch(`https://ikook.herokuapp.com/accounts/blog/get`, {
      method: "GET",
    });

    const data = await individualRes.json();
    const blogInfoData = await res.json();
    const filteredBlogData = blogInfoData?.data?.find((obj) => obj.slug === slug);
    return { data, filteredBlogData };
  } catch (error) {
    throw error;
  }
};

export default apiCall;
