import React, { useState, useEffect } from "react"
import "../cb.css"
import "../../../styles/index.css"
import { MDBBtn } from "mdb-react-ui-kit"



const PageTen = function ({ currPage, setCurrPage, setStoveType }) {

  const [selected, setSelected2] = useState([])

  const [deactivateBtn, setDeActivateBtn] = useState(true)


  useEffect(() => {
    if (selected.length>0) setDeActivateBtn(false)
    else setDeActivateBtn(true)
  }, [selected])

  function hasItem(text){
    const index = selected.indexOf(text);
    return index!==-1
  }

  const addOrRemoveText = (text) => {
    if (selected.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = selected.filter(item => item !== text);
      setSelected2(updatedList);
      console.log(`Text "${text}" removed from the list.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...selected, text];
      setSelected2(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  function setSelected(selected) {
    addOrRemoveText(selected)
  }



  if (currPage !== 10) return

  return (
    <div className="pages-cont" data-page="10">

      <h4>Your Hob Type</h4>

      <div className="flex-row" style={{ gap: 10, justifyContent: "center" }}>

        <div onClick={() => {
          setSelected("Electric")
          setStoveType("Electric")
        }} className={`div-image-text-cb cb-with-img ${hasItem("Electric" )? "active-nav-indicator" : ''}`}>
          <img alt="iKooK Custom Booking" src="https://ikook.tech/wp-content/uploads/2022/03/electric-power.png" style={{ width: "60px" }} />
          <p className="small removemargin">Electric</p>
        </div>

        <div onClick={() => {
          setSelected("Gas")
          setStoveType("Gas")
        }} className={`div-image-text-cb cb-with-img ${hasItem("Gas" )? "active-nav-indicator" : ''}`}>
          <img alt="iKooK Custom Booking" src="https://ikook.tech/wp-content/uploads/2022/03/gas-stove.png" style={{ width: "60px" }} />
          <p className="small removemargin">Gas</p>
        </div>

        <div onClick={() => {
          setSelected("Induction")
          setStoveType("Induction")
        }} className={`div-image-text-cb cb-with-img ${hasItem("Induction" )? "active-nav-indicator" : ''}`}>
          <img alt="iKooK Custom Booking" src="https://ikook.tech/wp-content/uploads/2022/03/induction-stove.png" style={{ width: "60px" }} />
          <p className="small removemargin">Induction</p>
        </div>

      </div>


      <MDBBtn className="mdbBtn" style={{ backgroundColor: "#fcc01c", color: "black" }} disabled={deactivateBtn} onClick={() => setCurrPage(currPage + 1)}>Continue</MDBBtn>


    </div>
  )
}


export default PageTen