import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";

const PageSix = function ({
  currPage,
  setCurrPage,
  setNumOfChildren,
  setNumOfAdult,
  setNumOfTeens,
}) {
  const [numOfTeens, setNumOfTeenss] = useState("0");
  const [numOFAdults, setNumOfAdults] = useState("0");
  const [numofChildrens, setNumofChildrens] = useState("0");
  const [deactivateBtn, setDeActivateBtn] = useState(true);

  useEffect(() => {
    if (
      Number(numOFAdults) < 0 ||
      Number(numOfTeens) < 0 ||
      Number(numofChildrens) < 0 ||
      Number(numOFAdults) + Number(numOfTeens) + Number(numofChildrens) < 1
    )
      setDeActivateBtn(true);
    else setDeActivateBtn(false);
  }, [numOfTeens, numOFAdults, numofChildrens]);

  if (currPage !== 6) return;

  return (
    <div className="pages-cont" data-page="6">
      <h4>Number of Guests</h4>

      <MDBInput
        defaultValue="0"
        min="0"
        onChange={(e) => {
          setNumOfChildren(e.target.value);
          setNumofChildrens(e.target.value);
        }}
        required={true}
        className="cb-inputs"
        label="Number of Children"
        type="number"
      />

      <MDBInput
        defaultValue="0"
        min="0"
        onChange={(e) => {
          setNumOfTeens(e.target.value);
          setNumOfTeenss(e.target.value);
        }}
        required={true}
        className="cb-inputs"
        label="Number of Teens"
        type="number"
      />

      <MDBInput
        defaultValue="0"
        min="0"
        onChange={(e) => {
          setNumOfAdults(e.target.value);
          setNumOfAdult(e.target.value);
        }}
        required={true}
        className="cb-inputs"
        label="Number of Adults"
        type="number"
      />

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageSix;
