import { useEffect, useState } from "react";
import formatDate from "../functions/formatDate";
import date from "../assets/date.svg";
import person from "../assets/person.svg";
import tag from "../assets/tag.svg";

const IndividualHeader = ({ blog, blogInfo }) => {
  const [pubDate, setPubDate] = useState();

  useEffect(() => {
    const formattedDate = formatDate(blogInfo);
    setPubDate(formattedDate);
  }, [blogInfo]);

  return (
    <>
      <img alt="ikook blog" className="main-img" src={blog?.picture} />
      <div className="blog-info-container">
        <div style={{ display: "flex", height: "20px", gap: "0.5em" }}>
          <img alt="ikook blog" src={date} style={{ width: "20px" }} />
          <p className="info">{pubDate}</p>
        </div>
        <div style={{ display: "flex", height: "20px", gap: "0.5em" }}>
          <img alt="ikook blog" src={person} style={{ width: "20px" }} />
          <p className="info">{blogInfo?.author?.Name}</p>
        </div>
        {blogInfo?.blog_tag?.length > 0 && (
          <div style={{ display: "flex", height: "20px", gap: "0.5em" }}>
            <img alt="ikook blog" src={tag} style={{ width: "20px" }} />
            <p>{blogInfo?.blog_tag[0]?.name}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default IndividualHeader;
