import React from "react";
import "./cb.css";
import "../../styles/index.css";

const CustomBookingNavIndicators = function ({ page }) {
  return (
    <div className="nav-indicator-cont">
    

      <div
        className={`nav-indicator ${page > 1 ? "active-nav-indicator" : ""}`}
        data-page="2"
      ></div>


      <div
        className={`nav-indicator ${page > 3 ? "active-nav-indicator" : ""}`}
        data-page="4"
      ></div>

      <div
        className={`nav-indicator ${page > 4 ? "active-nav-indicator" : ""}`}
        data-page="5"
      ></div>

      <div
        className={`nav-indicator ${page > 5 ? "active-nav-indicator" : ""}`}
        data-page="6"
      ></div>

      <div
        className={`nav-indicator ${page > 6 ? "active-nav-indicator" : ""}`}
        data-page="7"
      ></div>

      <div
        className={`nav-indicator ${page > 7 ? "active-nav-indicator" : ""}`}
        data-page="8"
      ></div>

      <div
        className={`nav-indicator ${page > 8 ? "active-nav-indicator" : ""}`}
        data-page="9"
      ></div>

      <div
        className={`nav-indicator ${page > 9 ? "active-nav-indicator" : ""}`}
        data-page="10"
      ></div>

      <div
        className={`nav-indicator ${page > 10 ? "active-nav-indicator" : ""}`}
        data-page="11"
      ></div>

      <div
        className={`nav-indicator ${page > 11 ? "active-nav-indicator" : ""}`}
        data-page="12"
      ></div>

      <div
        className={`nav-indicator ${page > 12 ? "active-nav-indicator" : ""}`}
        data-page="13"
      ></div>

      <div
        className={`nav-indicator ${page > 13 ? "active-nav-indicator" : ""}`}
        data-page="14"
      ></div>

      <div
        className={`nav-indicator ${page > 14 ? "active-nav-indicator" : ""}`}
        data-page="15"
      ></div>

      <div
        className={`nav-indicator ${page > 15 ? "active-nav-indicator" : ""}`}
        data-page="16"
      ></div>

<div
        className={`nav-indicator ${page > 16 ? "active-nav-indicator" : ""}`}
        data-page="17"
      ></div>
    </div>
  );
};

export default CustomBookingNavIndicators;
