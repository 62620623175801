import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBTextArea, MDBBtn } from "mdb-react-ui-kit";

const PageFourteen = function ({ currPage, setCurrPage, setDietary, setDietaryMessage }) {
  const [_selectedArr, setSelectedArr] = useState([]);

  const [vegetarianActive, setVegetarianActive] = useState(false);
  const [shellfishActive, setShellfishActive] = useState(false);
  const [glutenActive, setGlutenActive] = useState(false);
  const [nutsActive, setNutsActive] = useState(false);
  const [veganActive, setVeganActive] = useState(false);
  const [dairyActive, setDairyActive] = useState(false);
  const [halalActive, setHalalActive] = useState(false);
  const [kosherActive, setKoserActive] = useState(false);
  const [ketoActive, setKetoActive] = useState(false);

  useEffect(() => {
    const arr = [];
    if (vegetarianActive) arr.push("Vegetarian");
    if (shellfishActive) arr.push("No Shellfish");
    if (glutenActive) arr.push("Gluten Free");
    if (nutsActive) arr.push("Nuts Free");
    if (veganActive) arr.push("Vegan");
    if (dairyActive) arr.push("Dairy Free");
    if (halalActive) arr.push("Halal");
    if (kosherActive) arr.push("Kosher");
    if (ketoActive) arr.push("Keto");

    setSelectedArr(arr);

    setDietary(arr);
  }, [
    vegetarianActive,
    shellfishActive,
    glutenActive,
    nutsActive,
    veganActive,
    dairyActive,
    halalActive,
    kosherActive,
    ketoActive,
  ]);

  if (currPage !== 14) return;

  return (
    <div style={{ marginTop: -30 }} className="pages-cont" data-page="14">
      <h4>Do you have any Dietary Restrictions?</h4>

      <p className="small">
        Do you have any allergies or diet restrictions that you would like the Chef to know about?
      </p>

      <div className="flex-row" style={{ gap: 10, justifyContent: "center" }}>
        <div
          onClick={() => setVegetarianActive(!vegetarianActive)}
          className={`div-image-text-cb-s ${vegetarianActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Vegetarian</p>
        </div>

        <div
          onClick={() => setGlutenActive(!glutenActive)}
          className={`div-image-text-cb-s ${glutenActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Gluten Free</p>
        </div>

        <div
          onClick={() => setShellfishActive(!shellfishActive)}
          className={`div-image-text-cb-s ${shellfishActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Shellfish</p>
        </div>

        <div
          onClick={() => setNutsActive(!nutsActive)}
          className={`div-image-text-cb-s ${nutsActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Nuts Free</p>
        </div>

        <div
          onClick={() => setVeganActive(!veganActive)}
          className={`div-image-text-cb-s ${veganActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Vegan</p>
        </div>

        <div
          onClick={() => setDairyActive(!dairyActive)}
          className={`div-image-text-cb-s ${dairyActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Dairy Free</p>
        </div>

        <div
          onClick={() => setHalalActive(!halalActive)}
          className={`div-image-text-cb-s ${halalActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Halal</p>
        </div>

        <div
          onClick={() => setKoserActive(!kosherActive)}
          className={`div-image-text-cb-s ${kosherActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Kosher</p>
        </div>

        <div
          onClick={() => setKetoActive(!ketoActive)}
          className={`div-image-text-cb-s ${ketoActive ? "active-nav-indicator" : ""}`}
        >
          <p className="small removemargin">Keto</p>
        </div>
      </div>

      <MDBTextArea
        onChange={(e) => setDietaryMessage(e.target.value)}
        maxLength={500}
        label="Tell us more about any other restrictions"
        id="textAreaExample"
        cols={50}
        rows={4}
      />

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={false}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageFourteen;
