import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import "../../menu-page/menu-page.css";
import { BiArrowBack } from "react-icons/bi";
import AppContext from "../../../context/app-context";

const CustomBookingDetails = function ({ display, setOnView, data, setProposalDetailsData }) {
  const [chefCurr, setChefCurr] = useState("");

  const { backendServer, apiKey, setIsLoading, popup, updateUsersData, convertAmount } =
    useContext(AppContext);

  useEffect(() => {
    (async function () {
      if (!data?.chef) return;

      const chef = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data?.chef}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setChefCurr(chef?.data?.country);
    })();

    return () => setChefCurr("");
  }, [data]);

  const actOnBooking = async (doWhat) => {
    try {
      setIsLoading(true);

      if (doWhat === "accept") {
        if (!data.amount) return popup("No proposal accepted");

        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/complete/${data._id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Booking marked as complete");

        updateUsersData();

        setIsLoading(false);

        setOnView("dashboard init");
      }

      if (doWhat === "cancel") {
        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/cancel/${data._id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Booking Cancelled");

        updateUsersData();

        setIsLoading(false);

        setIsLoading(false);

        setOnView("dashboard init");
      }
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.querySelector("#userCustomBookingTop")?.scrollIntoView({ behavior: "smooth" });
  }, [display]);

  if (!display) return;

  return (
    <div>
      <div
        className="flex-row ud-dash-header ud-dash-header-to-have-back-btn"
        id="userCustomBookingTop"
      >
        <BiArrowBack
          className="cursor"
          onClick={() => {
            setOnView("dashboard init");
          }}
        />

        <p className="big alignTextLeft removemargin">
          Your Custom Booking ({data.proposals.length} Proposals)
        </p>

        <div className="ud-stat media-custom-booking-ud-state">
          {data.status === "cancelled" || data.status === "completed" ? (
            <p className={`small ${data.status === "cancelled" ? "redText" : "greenText"}`}>
              booking {data.status}
            </p>
          ) : (
            <>
              <button
                onClick={() => actOnBooking("cancel")}
                className="themeBtn ud-btns xsmall blackText"
                style={{ backgroundColor: "#faeebe" }}
              >
                Cancel Booking
              </button>

              <button
                style={{ display: `${!data.amount ? "none" : "flex"}` }}
                onClick={() => actOnBooking("accept")}
                className="themeBtn ud-btns xsmall"
              >
                Mark As Completed
              </button>
            </>
          )}
        </div>
      </div>

      <div className="cd-page-main-cont media-cd-page-main-cont">
        <div className="cd-box-1 cd-media-box-1">
          <div className="menu-page-nav-cont">
            <p
              style={{ width: "fit-content" }}
              className={`blackText boldText each-menu-page-nav each-menu-page-nav-active`}
            >
              Custom Booking
            </p>
          </div>

          <div className="cd-box-1-in">
            <div className="flex-column align-column-left">
              <p style={{ fontSize: 16 }} className="small alignTextLeft">
                See details for this booking #{data._id}
              </p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Order Type</p>
              </div>

              <p className="small">{data?.custom_booking_type_selected}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Service Preference</p>
              </div>

              <p className="small">{data?.meal_prep_option}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Event Type</p>
              </div>

              <p className="small">{data?.eventDetailsForLargeEvents?.eventType}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Chef Commitment</p>
              </div>

              <p className="small">{data?.meal?.join(", ")}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Cuisine</p>
              </div>

              <p className="small">{data?.cuisines[0]?.name}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Kitchen Configuration</p>
              </div>

              <p className="small removemargin">Hob Type: {data?.stoveType}</p>
              <p className="small removemargin">Number of Hob Tops: {data?.numberOfStove}</p>
              <p className="small removemargin">Oven Present: {data?.haveOven ? "Yes" : "No"}</p>
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Dietary Restrictions</p>
              </div>

              <p className="small removemargin">{data?.dietary_restrictions.join(", ")}</p>

              {data?.dietary_restrictions_message ? (
                <p className="small removemargin">{data?.dietary_restrictions_message}</p>
              ) : (
                ""
              )}
            </div>

            <div className="starter">
              <div className="menu-page-nav-cont">
                <p className={`big blackText boldText`}>Message To Chefs</p>
              </div>

              <p style={{ paddingRight: 15 }} className="small alignTextLeft">
                {data?.other_info}
              </p>
            </div>
          </div>
        </div>

        <div className="cd-box-2 cd-media-box-2">
          <button
            onClick={() => {
              if (!data?.proposals || data?.proposals?.length === 0)
                return popup("No proposals for this booking");
              setProposalDetailsData(data);
              setOnView("see proposals");
            }}
            className="themeBtn small"
            style={{ width: "100%", padding: 5 }}
          >
            See Proposals
          </button>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Booking Details</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Location</p>

              <p className="small blackText removemargin alignTextLeft">
                {data.location.addressString}
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Event Theme</p>

              <p className="small blackText removemargin alignTextLeft">
                {data?.eventDetailsForLargeEvents?.eventName}
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Event Start Date</p>

              <p className="small blackText removemargin alignTextLeft">
                {data.startDate.split("+")[0] + " @ " + data.startDate.split("+")[1]}{" "}
                {Number(data.startDate.split("+")[1].trim().split(":")[0]) > 12 ? "pm" : "am"}
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Event End Date</p>

              <p className="small blackText removemargin alignTextLeft">
                {data?.endDate?.split("+")[0] + " @ " + data?.endDate?.split("+")[1]}{" "}
                {Number(data?.endDate?.split("+")[1]?.trim()?.split(":")[0]) > 12 ? "pm" : "am"}
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Guests</p>

              <p className="small blackText removemargin alignTextLeft">
                {data.number_of_adults} Adult(s). {data.number_of_teens} Teen(s).{" "}
                {data.number_of_children} Kid(s)
              </p>
            </div>

            {data.amount ? (
              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Amount</p>

                <p className="small blackText removemargin alignTextLeft">
                  {convertAmount(data?.amount, chefCurr)}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Proposal Details</p>
            </div>

            <div className="flex-column align-column-left">
              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Number of Proposals</p>

                <p className="small removemargin alignTextLeft">{data.proposals.length}</p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Rejected Proposals</p>

                <p className="small removemargin alignTextLeft">
                  {data.proposals.filter((e) => e.proposal_status === "rejected").length}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomBookingDetails;
