import stepsData from "./stepsData";
import "./steps.css";

const Steps = ({ background }) => {
  return (
    <div className="steps-container">
      {stepsData.map((step, i) => (
        <div
          className={`${background ? "step-background" : ""} step-container`}
          key={i}
        >
          <img className="step-icon" src={step.image} />
          <p className="step-heading">{step.heading}</p>
          <p className="step-info">{step.info}</p>
        </div>
      ))}
    </div>
  );
};

export default Steps;
