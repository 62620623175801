import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../styles/index.css";
import { MDBBtn, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import "../../../styles/chef-index.css";
import EachTagBox from "../each-tag-boxes";
import AppContext from "../../../../../context/app-context";
import {
  SearchTagWhatIsThis,
  CuisingTypeWhatIsThis,
  EventTypeWhatIsThis,
} from "../../../../../components/what-is-this-collections/what-is-this-collections";

const CMMenuDetails = function ({
  activeNav,
  setMenuName,
  setMenuPrice,
  setMenuDesciption,
  setEventTags,
  setCuisineType,
  setSearchTags,
  searchTags,
  eventTags,
  cuisineType,
  menuName,
  menuPrice,
  menuDescription,
  minimumNumberOfGuests,
  setMinimumNumberOfGuests,
}) {
  const [searchTagComps, setSearchTagComps] = useState([]);
  const [eventTagComps, setEventTagComps] = useState([]);
  const [cuisingTagComps, setCuisineTagsComps] = useState([]);

  const [disableSearchTagBtn, setDisableSearchTagBtn] = useState(false);

  const [disableEventTagBtn, setDisableEventTagBtn] = useState(false);

  const [disableCuisineTagBtn, setDisableCuisineTagBtn] = useState(false);

  const [hideSearchTagInput, setHideSearchTagInput] = useState(false);
  const [hideEventTagInput, setHideEventTagInput] = useState(false);
  const [hideCuisineTagInput, setHideCuisineTagInput] = useState(false);

  const addSearchTagRef = useRef(null);
  const addEventTagRef = useRef(null);
  const addCuisingTagRef = useRef(null);

  const { popup, usersData } = useContext(AppContext);

  useEffect(() => {
    if (searchTagComps.length >= 3) {
      setDisableSearchTagBtn(true);
      setHideSearchTagInput(true);
    } else {
      setDisableSearchTagBtn(false);
      setHideSearchTagInput(false);
    }

    if (eventTagComps.length >= 3) {
      setDisableEventTagBtn(true);
      setHideEventTagInput(true);
    } else {
      setDisableEventTagBtn(false);
      setHideEventTagInput(false);
    }

    if (cuisingTagComps.length >= 3) {
      setDisableCuisineTagBtn(true);
      setHideCuisineTagInput(true);
    } else {
      setDisableCuisineTagBtn(false);
      setHideCuisineTagInput(false);
    }
  }, [searchTagComps, eventTagComps, cuisingTagComps]);

  function handleAddSearchTags(e) {
    if (searchTagComps.length >= 3) return popup("You can only add 3 search tags");

    if (!addSearchTagRef.current.value) return;

    setSearchTagComps([
      ...searchTagComps,
      <EachTagBox key={searchTagComps.length - 1} text={addSearchTagRef.current.value} />,
    ]);

    setSearchTags([...searchTags, addSearchTagRef.current.value]);

    addSearchTagRef.current.value = "";
  }

  function handleAddEventTags(e) {
    if (eventTagComps.length >= 3) return popup("You can only add 3 event tags");

    if (!addEventTagRef.current.value) return;

    setEventTagComps([
      ...eventTagComps,
      <EachTagBox key={eventTagComps.length - 1} text={addEventTagRef.current.value} />,
    ]);

    setEventTags([...eventTags, addEventTagRef.current.value]);

    addEventTagRef.current.value = "";
  }

  function handleAddCuisieTags(e) {
    if (cuisingTagComps.length >= 3) return popup("You can only add 3 cuisine tags");

    if (!addCuisingTagRef.current.value) return;

    setCuisineTagsComps([
      ...cuisingTagComps,
      <EachTagBox key={cuisingTagComps.length - 1} text={addCuisingTagRef.current.value} />,
    ]);

    setCuisineType([...cuisineType, addCuisingTagRef.current.value]);

    addCuisingTagRef.current.value = "";
  }

  const handleRemoveSearchtags = function () {
    setSearchTagComps([]);

    setSearchTags([]);
  };

  const handleRemoveEventTags = function () {
    setEventTagComps([]);

    setEventTags([]);
  };

  const handleRemoveCuisinetags = function () {
    setCuisineTagsComps([]);

    setCuisineType([]);
  };

  if (activeNav !== "details") return;

  return (
    <div
      className="cd-box-1-in-acc"
      style={{ width: "90%", overflowY: "scroll", paddingTop: 15, paddingLeft: 10 }}
    >
      <div className="flex-row align-row-left flex-gap">
        <MDBInput
          value={menuName}
          onChange={(e) => setMenuName(e.target.value)}
          maxLength={60}
          style={{ width: "300px" }}
          className="ac-inputs"
          label="Menu Name"
          type="text"
        />

        <MDBInput
          min="1"
          value={menuPrice}
          onChange={(e) => setMenuPrice(Number(e.target.value))}
          style={{ width: "300px" }}
          className="ac-inputs"
          label={`Price Per Person (${
            usersData?.country === "UK" ? "GBP" : usersData?.country === "Canada" ? "CAD" : "NGN"
          })`}
          type="number"
          max="1000000"
        />

        <MDBInput
          min="1"
          value={minimumNumberOfGuests}
          onChange={(e) => setMinimumNumberOfGuests(Number(e.target.value))}
          style={{ width: "300px" }}
          className="ac-inputs"
          label={`Minimum Number of Guests`}
          type="number"
          max="1000000"
        />
      </div>

      <MDBTextArea
        value={menuDescription}
        onChange={(e) => setMenuDesciption(e.target.value)}
        className="ac-inputs"
        label="Menu Description"
        type="text"
        rows={5}
        cols={74}
        maxLength={500}
      />

      <div className="implement-tag-cont">
        <p className="small boldText removemargin">Search Tags</p>

        <div className="allContTagBoxes">
          <div className="tag-boxes" style={{ width: "60%" }}>
            {searchTagComps}

            {!hideSearchTagInput ? (
              <MDBInput ref={addSearchTagRef} className="ac-inputs" type="text" maxLength={20} />
            ) : (
              ""
            )}
          </div>

          <MDBBtn
            style={{ backgroundColor: "#FCC01C", color: "black" }}
            disabled={disableSearchTagBtn}
            onClick={handleAddSearchTags}
            className="themeBtn"
          >
            Add
          </MDBBtn>

          <MDBBtn
            style={{ backgroundColor: "#fff", color: "black" }}
            onClick={handleRemoveSearchtags}
            className="themeBtn redBg"
          >
            Clear
          </MDBBtn>
        </div>

        <SearchTagWhatIsThis />
      </div>

      <div className="implement-tag-cont">
        <p className="small boldText removemargin">Event Types</p>

        <div className="allContTagBoxes">
          <div className="tag-boxes" style={{ width: "60%" }}>
            {eventTagComps}

            {!hideEventTagInput ? (
              <MDBInput ref={addEventTagRef} className="ac-inputs" type="text" maxLength={20} />
            ) : (
              ""
            )}
          </div>

          <MDBBtn
            style={{ backgroundColor: "#FCC01C", color: "black" }}
            disabled={disableEventTagBtn}
            onClick={handleAddEventTags}
            className="themeBtn"
          >
            Add
          </MDBBtn>

          <MDBBtn
            style={{ backgroundColor: "#fff", color: "black" }}
            onClick={handleRemoveEventTags}
            className="themeBtn redBg"
          >
            Clear
          </MDBBtn>
        </div>

        <EventTypeWhatIsThis />
      </div>

      <div className="implement-tag-cont">
        <p className="small boldText removemargin">Cuisine Types</p>

        <div className="allContTagBoxes">
          <div className="tag-boxes" style={{ width: "60%" }}>
            {cuisingTagComps}

            {!hideCuisineTagInput ? (
              <MDBInput ref={addCuisingTagRef} className="ac-inputs" type="text" maxLength={20} />
            ) : (
              ""
            )}
          </div>

          <MDBBtn
            style={{ backgroundColor: "#FCC01C", color: "black" }}
            disabled={disableCuisineTagBtn}
            onClick={handleAddCuisieTags}
            className="themeBtn"
          >
            Add
          </MDBBtn>

          <MDBBtn
            style={{ backgroundColor: "#fff", color: "black" }}
            onClick={handleRemoveCuisinetags}
            className="themeBtn redBg"
          >
            Clear
          </MDBBtn>
        </div>

        <CuisingTypeWhatIsThis />
      </div>
    </div>
  );
};

export default CMMenuDetails;
