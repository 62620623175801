import React from "react";
import Picture3A from "./Images/Picture3A.png";
import Picture3B from "./Images/Picture3B.png";
import { scopes } from "./ScopeList";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../../styles/index.css";

const Scope = () => {
  return (
    <>
      <div className="ch-scope">
        <div className="ch-s-container">
          <div className="ch-s-paper">
            <div className="ch-s-left">
              <div className="ch-s-lheader">
                <h1 className="ch-s-lintro blackText">We cover it all</h1>
              </div>
              <div className="ch-s-lbody">
                {scopes.map((scope, i) => (
                  <div key={i} style={{ textAlign: "left" }} className="ch-s-lbox">
                    <div className="ch-s-logo">
                      <AiOutlineCheckCircle />
                    </div>
                    <div className="ch-s-lbcontent">
                      <h2 className="ch-s-title">{scope.title}</h2>
                      <p className="ch-s-desc">{scope.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="ch-s-right">
              <div className="ch-s-rimgbox1">
                <img src={Picture3A} alt="wedding-party" className="ch-s-img" />
              </div>
              <div className="ch-s-rimgbox2">
                <img src={Picture3B} alt="birthday-party" className="ch-s-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scope;
