import React, { useRef, useState, useImperativeHandle, forwardRef } from "react";
import "../../../pages/user-dashboard/styles/user-index.css";
import ChefDashboardHeader from "../cd-header/cd-header";
import CdBookings from "../pages/cd-bookings";
import CdDashboard from "../pages/cd-dashboard";
import CdNotifications from "../pages/cd-notifications";
import CdMessages from "../pages/cd-messages";
import CdReviews from "../pages/cd-review";
import CdSupport from "../pages/cd-support";
import CdSettings from "../pages/cd-settings";
import CdMenus from "../pages/cd-menus";
import CdRevenues from "../pages/cd-revenues";
import CdCalendar from "../pages/cd-calendar";
import ExploreCustomBooking from "../pages/explore-custom-bookings";

const ChefDashBoardEngine = forwardRef(function (
  { setDashboardFunctions, dashboardFunctions, msgCount, notCount, changeAppScreen },
  ref
) {
  const [onView, setOnView] = useState("dashboard");

  const dashboardRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        changeScreen(screen) {
          setOnView(screen);
        },
      };
    },
    [onView]
  );

  return (
    <div className="ud-engine-cont chef-ud-engine-cont">
      <ChefDashboardHeader
        msgCount={msgCount}
        notCount={notCount}
        dashboardRef={dashboardRef?.current?.changeDashBoardScreen}
        changeAppScreen={changeAppScreen}
      />

      <CdDashboard
        dashboardFunctions={dashboardFunctions}
        setDashboardFunctions={setDashboardFunctions}
        ref={(ref) => (dashboardRef.current = ref)}
        display={onView === "dashboard" ? true : false}
      />

      <CdBookings
        dashboardFunctions={dashboardFunctions}
        display={onView === "orders" ? true : false}
      />

      <CdCalendar display={onView === "calendar" ? true : false} />

      <CdMenus display={onView === "menu" ? true : false} />

      <CdNotifications display={onView === "notifications" ? true : false} />

      <CdMessages display={onView === "messages" ? true : false} />

      <CdReviews display={onView === "review" ? true : false} />

      <CdSupport display={onView === "support" ? true : false} />

      <CdSettings display={onView === "settings" ? true : false} />

      <CdRevenues display={onView === "revenues" ? true : false} />

      <ExploreCustomBooking
        display={onView === "explore custom booking" ? true : false}
        setOnView={setOnView}
      />
    </div>
  );
});

export default ChefDashBoardEngine;
