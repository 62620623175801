import React, { useContext, useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBTextArea,
  MDBInput,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import "../modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../styles/index.css";
import "../../pages/user-dashboard/styles/user-index.css";
import AppContext from "../../context/app-context";
import "./support-modal.css";

const SupportModal = ({ display, setShowSupportModal }) => {
  const [notLoggedInEmail, setNotLoggedInEmail] = useState("");
  const [supportTitle, setSupportTitle] = useState("");
  const [supportBody, setSupportBody] = useState("");
  const [category, setCategory] = useState("");
  const [disbaleBtn, setDisableBtn] = useState(true);

  const { backendServer, apiKey, setIsLoading, popup, usersData, validateEmail } =
    useContext(AppContext);

  useEffect(() => {
    if (supportBody && supportTitle && category) setDisableBtn(false);
    else setDisableBtn(true);
  }, [supportBody, supportTitle, category, notLoggedInEmail]);

  async function submitSupport() {
    if (!supportTitle || !supportBody) return;

    if (notLoggedInEmail && !validateEmail(notLoggedInEmail)) return popup("Email Address Invalid");

    try {
      setIsLoading(true);

      let obj;

      if (usersData?._id) {
        obj = {
          userID: usersData?._id,
          title: supportTitle,
          message: supportBody,
          category,
        };
      } else {
        obj = {
          email: notLoggedInEmail,
          title: supportTitle,
          message: supportBody,
          category,
        };
      }

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/support/submit`, {
          method: "POST",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your support request has been submitted!");

      setIsLoading(false);
      setShowSupportModal(false);
      setSupportBody("");
      setSupportTitle("");
      setNotLoggedInEmail("");
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowSupportModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <h4 className="blackText removemargin">Submit a Support Request</h4>
            </MDBModalHeader>

            <MDBModalBody>
              <MDBDropdown id="mdb-dropdown-custom-support">
                <MDBDropdownToggle id="mdb-dropdown-custom-in-support" color="light">
                  {category || "Select a Category"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="mdb-menu-custom-support">
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Booking Management
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    General Inquiry
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Chef Verification
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Report a Bug
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Sales
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Technical
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Trust & Safety
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Payment
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setCategory(e.target.textContent);
                    }}
                  >
                    Other
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>

              {!usersData?._id && (
                <MDBInput
                  value={notLoggedInEmail}
                  maxLength={100}
                  onChange={(e) => setNotLoggedInEmail(e.target.value)}
                  className="login-inputs"
                  label="Email"
                  type="email"
                />
              )}

              <MDBInput
                value={supportTitle}
                maxLength={100}
                onChange={(e) => setSupportTitle(e.target.value)}
                className="login-inputs"
                label="Title"
                type="text"
              />

              <MDBTextArea
                value={supportBody}
                onChange={(e) => setSupportBody(e.target.value)}
                label="Your Message"
                type="text"
                placeholder="Your Message"
                maxLength={300}
                rows={5}
              />

              <MDBBtn
                id="signupbtn-disable"
                disabled={disbaleBtn}
                style={{
                  width: "80%",
                  cursor: "pointer",
                  marginTop: 40,
                  backgroundColor: "#fcc01c",
                  color: "black",
                }}
                onClick={submitSupport}
              >
                Submit
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default SupportModal;
