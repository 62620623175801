import React, { useContext } from "react";
import "../styles/user-index.css";
import "../../../styles/index.css";
import { BiMessageSquareDots, BiNotification } from "react-icons/bi";
import { BiWallet } from "react-icons/bi";
import AppContext from "../../../context/app-context";

const UserDashBoardHeader = function ({ msgCount, notCount }) {
  const { usersData } = useContext(AppContext);

  return (
    <div className="ud-header-cont media-ud-header-cont">
      <div className="ud-in-cont media-ud-header-search">
        {/* <input className="ud-in" type="text" placeholder="Search Bookings" />

        <BiSearch onClick={() => changeAppScreen("bookings")} size={25} style={{ marginLeft: -45, zIndex: 1000, opacity: 0.7, cursor: "pointer" }} /> */}
      </div>

      <div className="ud-stat media-ud-stats">
        <div className="ud-stat-each">
          <BiNotification size={30} />

          <p className="small yellowText">{notCount}</p>
        </div>

        <div className="ud-stat-each">
          <BiMessageSquareDots size={30} />

          <p className="small yellowText">{msgCount}</p>
        </div>

        <div className="ud-wallet-balance">
          <BiWallet size={30} />

          <p className="small removemargin">
            {usersData.walletBalance ? usersData.walletBalance : 0.0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDashBoardHeader;
