import React, { useState, useContext, forwardRef, useImperativeHandle } from "react";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../../styles/index.css";
import { RiDashboardLine } from "react-icons/ri";
import { TbReportMoney } from "react-icons/tb";
import { BiMessageSquareDots, BiSupport, BiNotification } from "react-icons/bi";
import { VscPreview } from "react-icons/vsc";
import { CiSettings } from "react-icons/ci";
import { MdRestaurantMenu, MdEventNote, MdOutlineBorderColor } from "react-icons/md";
import { AiTwotoneCalendar } from "react-icons/ai";
import AppContext from "../../../context/app-context";
import { Tooltip } from "@mui/material";

const ChefDashboardSideNav = forwardRef(function ({ changeScreen }, ref) {
  const [activeNav, setActiveNav] = useState("dashboard");

  const { usersData, updateUsersData, setIsLoading, backendServer, apiKey } =
    useContext(AppContext);

  async function markAllNotificationsAsRead() {
    try {
      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/user/notification/read/${usersData._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();
    } catch (err) {
      setIsLoading(false);
    }
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        changeAppScreen(screen) {
          setActiveNav(screen);
          changeScreen(screen);
          document.location.hash = screen;
        },
      };
    },
    [changeScreen]
  );

  return (
    <div className="ud-nav-cont media-ud-nav-cont">
      <div
        onClick={() => {
          setActiveNav("dashboard");
          changeScreen("dashboard");
          document.location.hash = "dashboard";
        }}
        className={`ud-each-nav ${activeNav === "dashboard" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Dashboard">
          <span>
            <RiDashboardLine size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">DASHBOARD</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("orders");
          changeScreen("orders");
          document.location.hash = "order-management";
        }}
        className={`ud-each-nav ${activeNav === "orders" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip
          disableHoverListener
          placement="right"
          arrow
          enterTouchDelay={0}
          title="Order Management"
        >
          <span>
            <MdEventNote size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">ORDER MANAGEMENT</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("explore custom booking");
          changeScreen("explore custom booking");
          document.location.hash = "host-inquiries";
        }}
        className={`ud-each-nav ${
          activeNav === "explore custom booking" ? "ud-each-nav-active" : "hide"
        }`}
      >
        <Tooltip
          disableHoverListener
          placement="right"
          arrow
          enterTouchDelay={0}
          title="Host Inquiries"
        >
          <span>
            <MdOutlineBorderColor size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">HOST INQUIRIES</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("calendar");
          changeScreen("calendar");
          document.location.hash = "schedule";
        }}
        className={`ud-each-nav ${activeNav === "calendar" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Schedule">
          <span>
            <AiTwotoneCalendar size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">SCHEDULE</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("revenues");
          changeScreen("revenues");
          document.location.hash = "revenues";
        }}
        className={`ud-each-nav ${activeNav === "revenues" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Revenues">
          <span>
            <TbReportMoney size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">REVENUES</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("menu");
          changeScreen("menu");
          document.location.hash = "menu";
        }}
        className={`ud-each-nav ${activeNav === "menu" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Menu">
          <span>
            <MdRestaurantMenu size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">MENU</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("notifications");
          changeScreen("notifications");
          document.location.hash = "notifications";
          setTimeout(() => {
            markAllNotificationsAsRead();
          }, 3000);
        }}
        className={`ud-each-nav ${activeNav === "notifications" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip
          disableHoverListener
          placement="right"
          arrow
          enterTouchDelay={0}
          title="Notifications"
        >
          <span>
            <BiNotification size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">NOTIFICATIONS</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("messages");
          changeScreen("messages");
          document.location.hash = "messages";
        }}
        className={`ud-each-nav ${activeNav === "messages" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Messages">
          <span>
            <BiMessageSquareDots size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">MESSAGES</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("settings");
          changeScreen("settings");
          document.location.hash = "settings";
        }}
        className={`ud-each-nav ${activeNav === "settings" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Settings">
          <span>
            <CiSettings size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">SETTINGS</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("review");
          changeScreen("review");
          document.location.hash = "reviews";
        }}
        className={`ud-each-nav ${activeNav === "review" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Review">
          <span>
            <VscPreview size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">REVIEW</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("support");
          changeScreen("support");
          document.location.hash = "support";
        }}
        className={`ud-each-nav ${activeNav === "support" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Support">
          <span>
            <BiSupport size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">SUPPORT</p>
      </div>
    </div>
  );
});

export default ChefDashboardSideNav;
