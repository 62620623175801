import React, { useContext } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBTextArea, MDBBtn } from "mdb-react-ui-kit";
import AppContext from "../../../context/app-context";

const PageFifteen = function ({ currPage, setMessageToChef, submitBooking }) {
  const { loggedIn, universalShowLoginObject } = useContext(AppContext);

  if (currPage !== 16) return;

  return (
    <div className="pages-cont" data-page="16">
      <h4>Any other information? Let our chefs know.</h4>

      <MDBTextArea
        onChange={(e) => setMessageToChef(e.target.value)}
        maxLength={1000}
        label="Provide Further Information for Your Booking"
        id="textAreaExample"
        cols={80}
        rows={8}
      />

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={false}
        onClick={() => {
          if (!loggedIn || loggedIn === "none") return universalShowLoginObject.showLogin(true);
          submitBooking();
        }}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageFifteen;
