import React, { useContext } from "react";
import "../../../../pages/user-dashboard/styles/user-index.css";
import "../../../../styles/index.css";
import AppContext from "../../../../context/app-context";
import ChefDashboradContext from "../../context/chef-index-context";

const TableBookingCard = function ({
  setOnView,
  type,
  setCustomBookingData,
  setBookingDetailsData,
  data,
}) {
  const { emitRealDate, convertAmount } = useContext(AppContext);
  const { changeAppScreen } = useContext(ChefDashboradContext);

  return (
    <div
      onClick={() => {
        if (type === "chef-at-home") {
          setBookingDetailsData(data);
          changeAppScreen("dashboard");
          setOnView("chef at home");
        }
        if (type === "large-event") {
          setBookingDetailsData(data);
          changeAppScreen("dashboard");
          setOnView("chef at home");
        }
        if (type === "meal-prep") {
          setBookingDetailsData(data);
          changeAppScreen("dashboard");
          setOnView("chef at home");
        }
        if (type === "custom-booking") {
          setCustomBookingData(data);
          changeAppScreen("dashboard");
          setOnView("custom booking");
        }
      }}
      className="flex-row align-row-left table-booking-card"
      style={{
        padding: "10px 0px 0px 0px",
        justifyContent: "flex-start",
        marginBottom: 20,
        alignItems: "center",
      }}
    >
      <p style={{ width: "20%" }} className="tablesmall">
        {data._id}
      </p>

      <p style={{ width: "8%" }} className="tablesmall">
        {emitRealDate(data.booking_date)}
      </p>

      <p
        style={{ width: "8%" }}
        className={`tablesmall ${data?.status === "cancelled" ? "redText" : "greenText"}`}
      >
        {data.status}
      </p>

      <p style={{ width: "20%" }} className="tablesmall">
        {data?.menuID?.menuName ? `${data?.menuID?.menuName.slice(0, 25)}...` : "nil"}
      </p>

      <p style={{ width: "14%" }} className="tablesmall">
        {data?.user?.first_name ? data?.user?.first_name + ` ${data.user.last_name}` : "nil"}
      </p>

      <p style={{ width: "14%" }} className="tablesmall">
        {data?.booking_type}
      </p>

      <p style={{ width: "8%" }} className="tablesmall">
        {convertAmount(data.amount ? data.amount : "0", data?.menuID?.currency) ||
          convertAmount(data.amount ? data.amount : "0", data?.chef?.country)}
      </p>
    </div>
  );
};

export default TableBookingCard;
