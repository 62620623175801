import React from "react";
import "../cb.css";
import "../../../styles/index.css";
import { AiOutlineCheck } from "react-icons/ai";
import { MDBBtn } from "mdb-react-ui-kit";

const PageSixteen = function ({ currPage, setCurrPage }) {
  if (currPage !== 17) return;

  return (
    <div className="pages-cont" data-page="17">
      <AiOutlineCheck size={40} />

      <h4>Request Submitted</h4>

      <p className="small">
        Check your dashboard for the summary of your order. Look out for proposals from our chefs!
      </p>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={false}
        onClick={() => {}}
      >
        Go To Dashboard
      </MDBBtn>
    </div>
  );
};

export default PageSixteen;
