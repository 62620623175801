import { useEffect, useRef, useState } from "react";

const ReplyForm = ({ reply, setReply, id }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [userComment, setUserComment] = useState("");
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [reqBody, setReqBody] = useState({});

  const handleClick = () => {
    setReply({});
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    reply.name
      ? setReqBody({
          full_name: fname,
          email: email,
          reply: userComment,
        })
      : setReqBody({
          full_name: fname,
          email: email,
          comment: userComment,
        });

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 10000);
  };

  useEffect(() => {
    if (Object.keys(reqBody).length === 0) {
      return;
    } else {
      const postComment = async () => {
        setError("");
        const endpoint = reply?.name ? `post?commentId=${reply?.id}` : `post?blogId=${id}`;
        try {
          setIsSubmitting(false);
          const response = await fetch(
            `https://ikook.herokuapp.com/accounts/blog/comment_reply/${endpoint}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(reqBody),
            }
          );
          setTimeout(() => setIsSubmitting(false), 50000);
          if (response.ok) {
            setIsAlertVisible(true);
            setTimeout(() => setIsAlertVisible(false), 5000);
            setFname("");
            setEmail("");
            setUserComment("");
          }
          if (!response.ok) throw new Error("Please fill all fields and try again");
        } catch (error) {
          setError(error);
        }
      };
      postComment();
    }
  }, [reqBody]);

  return (
    <div className="form-container">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p className="form-heading">
          {reply?.name ? `Reply to ${reply?.name}` : "Leave a comment"}
        </p>
        {reply?.name && (
          <button id="reply_form_button" onClick={handleClick} className="cancel-btn">
            Cancel reply
          </button>
        )}
      </div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <textarea
          className="comment-input comment-content"
          name="vistor-comment"
          placeholder="Enter your comment here..."
          onChange={(e) => setUserComment(e.target.value)}
          value={userComment}
          required
        />
        <div className="sub-form-container">
          <input
            className="comment-input"
            type="text"
            placeholder="Full name"
            onChange={(e) => setFname(e.target.value)}
            value={fname}
            required
          />
          <input
            className="comment-input"
            type="email"
            placeholder="E-mail"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input className="comment-submit" type="submit" disabled={isSubmitting} />
        </div>
      </form>
      {isAlertVisible && (
        <div className="comment-alert">
          <p>
            Comment Sent! Please be aware that your comment will be moderated by an admin before it
            can appear. <br />
            Thank you!
          </p>
        </div>
      )}
      {error && (
        <div className="error-alert">
          <p>{error.message}</p>
        </div>
      )}
    </div>
  );
};

export default ReplyForm;
