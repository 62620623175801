import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import TableBookingCard from "./child-components/chef-table-booking-card";
import "../../chef-dashboard/styles/chef-index.css";
import AppContext from "../../../context/app-context";
import BookingCardCD from "../../../components/booking-card-mini/cd-booking-card";
import ChefDashboradContext from "../context/chef-index-context";

const DashboardInit = function ({
  display,
  setOnView,
  setBookingDetailsData,
  setCustomBookingData,
}) {
  const [bookingsComp, setBookingComp] = useState([]);
  const [tableBookingComp, setTableBookingComp] = useState([]);

  const [revenue, setRevenue] = useState(0);
  const [revInRecently, setRevInRecently] = useState(0);

  const [ratingInRecently, setRatingInRecently] = useState(0);

  const { usersData, backendServer, apiKey, calculateChefRating, formatNumber } =
    useContext(AppContext);
  const { changeAppScreen } = useContext(ChefDashboradContext);

  useEffect(() => {
    getActiveBooking(usersData?.chef_details?.chef_bookings);

    getCustomBookings(usersData?.chef_details?.chef_bookings);

    let revenue = 0;
    usersData?.withdrawals?.forEach((e) => (revenue = e.amount + revenue));

    setRevenue(revenue);

    setRevInRecently(usersData?.withdrawals[usersData?.withdrawals?.length - 1]?.amount || 0);

    setRatingInRecently(
      usersData?.chef_details?.reviews[usersData?.chef_details?.reviews?.length - 1]?.rating || 0
    );
  }, [usersData]);

  async function getActiveBooking(bookingsArr) {
    if (!bookingsArr) return;
    try {
      const realBookings = await Promise.all(
        bookingsArr.map(async (e) => {
          const { data } = await (
            await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get/${e}`, {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            })
          ).json();

          return data;
        })
      );

      const filtered = realBookings.filter((e) => {
        return e.status === "pending" && e.booking_type !== "custom-booking";
      });

      const comp = filtered.map((e, i) => (
        <BookingCardCD
          setBookingDetailsData={setBookingDetailsData}
          setOnView={setOnView}
          data={e}
          key={i}
          type={e.booking_type}
        />
      ));

      if (comp.length === 0)
        setBookingComp(
          <div className="flex-column for-no-bookings">
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">NO ACTIVE BOOKINGS</p>
            <p className="small">No Bookings to display.</p>
          </div>
        );
      else {
        comp.reverse();
        setBookingComp(comp.slice(0, 3));
      }
    } catch (err) {
      setBookingComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO ACTIVE BOOKINGS</p>
          <p className="small">No Bookings to display.</p>
        </div>
      );
    }
  }

  async function getCustomBookings(bookingsArr) {
    if (!bookingsArr) return;

    try {
      const realBookings = await Promise.all(
        bookingsArr?.map(async (e) => {
          const { data } = await (
            await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/get/${e}`, {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            })
          ).json();

          return data;
        })
      );

      const filtered = realBookings.filter((e) => {
        return e.status === "pending" && e.booking_type === "custom-booking";
      });

      const comp = filtered.map((e, i) => (
        <TableBookingCard
          setOnView={setOnView}
          setBookingDetailsData={setBookingDetailsData}
          setCustomBookingData={setCustomBookingData}
          data={e}
          key={i}
          type={e.booking_type}
        />
      ));

      if (comp.length === 0)
        setTableBookingComp(
          <div className="flex-column for-no-bookings">
            <img src="/images/no-booking.png" alt="ikook" />
            <p className="small boldText">NO ACTIVE CUSTOM BOOKINGS</p>
            <p className="small">No Bookings to display.</p>
          </div>
        );
      else {
        comp.reverse();
        setTableBookingComp(comp.slice(0, 3));
      }
    } catch (err) {
      setTableBookingComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO ACTIVE CUSTOM BOOKINGS</p>
          <p className="small">No Bookings to display.</p>
        </div>
      );
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row ud-dash-header">
        <div className="cd-stat-box chef-cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">Bookings</p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(10, 20, 129, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-booking.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin" style={{ marginTop: -10 }}>
            {usersData?.chef_details?.chef_bookings.length}
          </p>

          <p className="xsmall removemargin greenText">
            <img src="/images/up-progress.png" alt="ikook-chef" /> 20%{" "}
            <span className="blackText">from last month</span>
          </p>
        </div>

        <div className="cd-stat-box chef-cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">
              Revenue (
              {usersData?.country === "UK"
                ? "GBP"
                : usersData?.country === "Canada"
                ? "CAD"
                : "NGN"}
              )
            </p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(237, 76, 92, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-revenue.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin" style={{ marginTop: -10 }}>
            {formatNumber(revenue) || 0}
          </p>

          <p className="xsmall removemargin greenText">
            <img src="/images/up-progress.png" alt="ikook-chef" /> {revInRecently}{" "}
            <span className="blackText">added recently</span>
          </p>
        </div>

        <div className="cd-stat-box chef-cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">Menus</p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(44, 163, 232, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-menu.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin" style={{ marginTop: -10 }}>
            {usersData?.chef_details?.chef_menus.length}
          </p>

          <p className="xsmall removemargin blackText">
            {usersData?.chef_details?.chef_menus.length} added recently
          </p>
        </div>

        <div className="cd-stat-box chef-cd-stat-box">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <p className="small removemargin">Rating</p>

            <div
              className="rounded-stat"
              style={{ backgroundColor: "rgba(252, 192, 28, 0.1)", marginTop: 10 }}
            >
              <img src="/images/i-rating.png" alt="ikook bookings" />
            </div>
          </div>

          <p className="big removemargin yellowText" style={{ marginTop: -10 }}>
            {calculateChefRating(usersData?.chef_details?.reviews)}/5
          </p>

          <p className={`xsmall removemargin ${ratingInRecently >= 3 ? "greenText" : "redText"}`}>
            {ratingInRecently} star added recently
          </p>
        </div>
      </div>

      <div className="flex-row ud-dash-header">
        <p className="big alignTextLeft removemargin">Active Bookings</p>

        <button onClick={() => changeAppScreen("orders")} className="themeBtn ud-btns">
          See All
        </button>
      </div>

      <div className="ud-active-booking-cont">
        {/* <div className="flex-column for-no-bookings">

          <img src="/images/no-booking.png" alt="ikook" />

          <p className="small boldText">
            NO BOOKINGS
          </p>

          <p className="small">
            No Bookings to display. Start booking now!
          </p>

          <button className="themeBtn">Book A Chef</button>

        </div> */}

        {bookingsComp}
      </div>

      <div className="flex-row ud-dash-header" style={{ marginTop: 40 }}>
        <p className="big alignTextLeft removemargin">Previous Bookings</p>

        <button onClick={() => changeAppScreen("orders")} className="themeBtn ud-btns">
          See All
        </button>
      </div>

      <div
        className="flex-row align-row-left"
        style={{ padding: "0px 0px 0px 0px", justifyContent: "flex-start", marginTop: 40 }}
      >
        <p style={{ width: "20%" }} className="small boldText">
          Booking ID
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Date
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Status
        </p>

        <p style={{ width: "20%" }} className="small boldText">
          Menu
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          User
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Service
        </p>

        <p style={{ width: "8%" }} className="small boldText">
          Amount
        </p>
      </div>

      {tableBookingComp}
    </div>
  );
};

export default DashboardInit;
