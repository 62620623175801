import React, { useContext } from "react";
import "../../pages/menu-page/menu-page.css";
import "../../styles/index.css";
import "../../pages/home/home.css";
import { FiDelete } from "react-icons/fi";
import AppContext from "../../context/app-context";

const ChefFAQCard = function ({ data }) {
  const { backendServer, apiKey, setIsLoading, popup, updateUsersData, usersData } =
    useContext(AppContext);

  async function deleteFaq() {
    try {
      setIsLoading(true);

      await fetch(
        `${backendServer}/ikook/api/v1/${apiKey}/chef/faq/delete/${usersData?._id}/${data?._id}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
        }
      );

      popup("Your FAQ has been deleted!");

      setIsLoading(false);

      updateUsersData();
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  return (
    <div
      className="review-card"
      style={{ width: "250px", height: "250px", justifyContent: "flex-start", padding: 10 }}
    >
      <FiDelete onClick={deleteFaq} size={20} className="cursor" title="Delete FAQ" color="black" />

      <p className="small blackText removemargin alignTextLeft boldText">{data?.question}</p>

      <p className="small alignTextLeft" style={{ textAlign: "left" }}>
        {data?.answer}
      </p>
    </div>
  );
};

export default ChefFAQCard;
