import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import "../../menu-page/menu-page.css";
import ChefAtHomeMenuDetails from "./child-components/cah-menu-det";
import ChefAtHomeChefDetails from "./child-components/cah-chef-details";
import EventDetailsForLargeEvents from "./child-components/cah-event-details";
import { BiArrowBack } from "react-icons/bi";
import AppContext from "../../../context/app-context";
import UserDashBoardIndexContext from "../context/user-index-context";

const ChefAtHomeBookingDetails = function ({ display, setOnView, data, type }) {
  const [activeNav, setActiveNav] = useState("menu");
  const [menuCurr, setMenuCurr] = useState("");
  const [chefCurr, setChefCurr] = useState("");

  const {
    backendServer,
    apiKey,
    setIsLoading,
    popup,
    updateUsersData,
    emitRealDate,
    convertAmount,
  } = useContext(AppContext);

  const { changeAppScreen } = useContext(UserDashBoardIndexContext);

  useEffect(() => {
    (async function () {
      if (!data?.menuID) return;

      const menu = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${data?.menuID}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setMenuCurr(menu?.data?.currency);
    })();

    return () => setMenuCurr("");
  }, [data]);

  useEffect(() => {
    (async function () {
      if (!data?.chef) return;

      const chef = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data?.chef}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setChefCurr(chef?.data?.country);
    })();

    return () => setChefCurr("");
  }, [data]);

  const actOnBooking = async (doWhat) => {
    try {
      setIsLoading(true);

      if (doWhat === "accept") {
        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/complete/${data._id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Booking marked as complete");

        updateUsersData();

        setIsLoading(false);

        setOnView("dashboard init");
      }

      if (doWhat === "cancel") {
        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/bookings/cancel/${data._id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Booking Cancelled");

        updateUsersData();

        setIsLoading(false);

        setIsLoading(false);

        setOnView("dashboard init");
      }
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.querySelector("#userChefAtHomeTop")?.scrollIntoView({ behavior: "smooth" });
  }, [display]);

  if (!display) return;

  return (
    <div>
      <div
        className="flex-row ud-dash-header ud-dash-header-to-have-back-btn"
        id="userChefAtHomeTop"
      >
        <BiArrowBack
          className="cursor"
          onClick={() => {
            setOnView("dashboard init");
          }}
        />

        <p className="big alignTextLeft removemargin">Booking #{data._id}</p>

        <div className="ud-stat media-custom-booking-ud-state">
          {data.status === "cancelled" || data.status === "completed" ? (
            <p className={`small ${data.status === "cancelled" ? "redText" : "greenText"}`}>
              booking {data.status}
            </p>
          ) : (
            <>
              <button
                onClick={() => actOnBooking("cancel")}
                className="themeBtn ud-btns xsmall redBg whiteText"
              >
                Cancel Booking
              </button>

              <button onClick={() => actOnBooking("accept")} className="themeBtn ud-btns xsmall">
                Mark As Completed
              </button>
            </>
          )}
        </div>
      </div>

      <div className="cd-page-main-cont media-cd-page-main-cont">
        <div className="cd-box-1 cd-media-box-1">
          <div className="menu-page-nav-cont ud-menu-page-nav-cont">
            <p
              onClick={() => {
                setActiveNav("menu");
              }}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "menu" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Menu
            </p>

            {/* for event details: hide if not large event */}
            {type === "large-event" ? (
              <p
                onClick={() => {
                  setActiveNav("event-details");
                }}
                className={`blackText boldText each-menu-page-nav ${
                  activeNav === "event-details" ? "each-menu-page-nav-active" : ""
                }`}
              >
                Event Details
              </p>
            ) : (
              ""
            )}

            <p
              onClick={() => {
                setActiveNav("profile");
              }}
              className={`blackText boldText each-menu-page-nav ${
                activeNav === "profile" ? "each-menu-page-nav-active" : ""
              }`}
            >
              Chef Profile
            </p>
          </div>

          <ChefAtHomeMenuDetails data={data} activeNav={activeNav} />

          <ChefAtHomeChefDetails data={data} activeNav={activeNav} />

          <EventDetailsForLargeEvents data={data} activeNav={activeNav} />
        </div>

        <div className="cd-box-2 cd-media-box-2">
          <button
            onClick={() => changeAppScreen("messages")}
            className="themeBtn xsmall"
            style={{ width: "100%", padding: 5 }}
          >
            Chat With Chef
          </button>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Booking Details</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Location</p>

              <p className="small blackText removemargin alignTextLeft">
                {data.location.addressString ? data.location.addressString : "nil"}
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Event Date</p>

              <p className="small blackText removemargin alignTextLeft">
                {data.startDate.split("+")[0] + " @ " + data.startDate.split("+")[1]}{" "}
                {Number(data.startDate.split("+")[1].trim().split(":")[0]) > 12 ? "pm" : "am"}
              </p>
            </div>

            {data?.booking_type !== "meal-prep" ? (
              ""
            ) : (
              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">End Date</p>

                <p className="small blackText removemargin alignTextLeft">{data.endDate}</p>
              </div>
            )}

            {data?.booking_type !== "meal-prep" ? (
              ""
            ) : (
              <div className="flex-column align-column-left">
                <p className="tablesmall removemargin">Number of Weeks</p>

                <p className="small blackText removemargin alignTextLeft">
                  {data?.number_of_weeks || "nil"}
                </p>
              </div>
            )}

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Guests</p>

              <p className="small blackText removemargin alignTextLeft">
                {data.number_of_adults} Adult(s). {data.number_of_teens} Teen(s).{" "}
                {data.number_of_children} Kid(s)
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Booking Date</p>

              <p className="small blackText removemargin alignTextLeft">
                {emitRealDate(data.booking_date)}
              </p>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Payment Details</p>
            </div>

            <div className="flex-column align-column-left">
              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft boldText">Total Amount</p>

                <p className="small removemargin alignTextLeft boldText">
                  {convertAmount(data?.amount ? data?.amount : "0", menuCurr) ||
                    convertAmount(data?.amount ? data?.amount : "0", chefCurr)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChefAtHomeBookingDetails;
