import React from "react";
import "../communication-guideline/communication-guideline.css";
import "../../styles/index.css";
import { TbAlertOctagon } from "react-icons/tb";
import "../lightening-list/lightening-list.css";
import "./vnin-prompt.css";

const VNinPrompt = () => {
  return (
    <div className="vninCont">
      <div
        className="flex-row align-row-left"
        style={{ justifyContent: "flex-start", gap: 15, padding: 0 }}
      >
        <TbAlertOctagon color="#f8d134" size={30} />

        <p style={{ marginTop: 15 }} className="small boldText">
          Using Virtual NIN
        </p>
      </div>

      <p className="small alignTextLeft">
        You'll need to use your Virtual NIN, which consists of 16 alpha-numeric characters that can
        be generated and used following the below steps:
      </p>

      <div className="l-lightening-title" style={{ marginLeft: 15, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">
          Dial the USSD shortcode *346*3*YOUR_NIN*696739# with a NIN-Registered phone number
        </p>
      </div>

      <div className="l-lightening-title" style={{ marginLeft: 15, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">
          Input the NIN generated into the Virtual NIN field
        </p>
      </div>
    </div>
  );
};

export default VNinPrompt;
