import "./why-ikook.css";

const Card = ({ data }) => {
  return (
    <div className="why-ikook-individual-card">
      <img className="why-ikook-imgs" src={data?.img} alt="iKooK Blog" />
      <p className="why-ikook-card-heading">{data?.heading}</p>
      <p className="why-ikook-card-text">{data?.body}</p>
    </div>
  );
};

export default Card;
