import React, { useState, useEffect } from "react";
import "../../../menu-page/menu-page.css";
import "../../../menu-page/menu-page.css";
import "../../../../components/auth/login/login-modal.css";
import { MDBSwitch } from "mdb-react-ui-kit";

const UserNotificationSettings = function ({ activeNav }) {
  const [emailNot, setEmailNot] = useState(false);
  const [smsNot, setSmsNot] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("emailNot") || localStorage.getItem("emailNot") === "off")
      setEmailNot(false);
    else setEmailNot(true);

    if (!localStorage.getItem("smsNot") || localStorage.getItem("smsNot") === "off")
      setSmsNot(false);
    else setSmsNot(true);
  }, []);

  if (activeNav !== "notifications") return;

  return (
    <div className="cd-box-1-in-acc ud-accounts-cont">
      <h5 className="big">Notifications</h5>

      <div className="flex-row setiings-noti-row" style={{ justifyContent: "space-between" }}>
        <p className="small">Email Notification</p>

        <MDBSwitch
          checked={emailNot}
          onChange={() => {
            localStorage.setItem("emailNot", emailNot ? "off" : "on");
            setEmailNot(!emailNot);
          }}
          btnColor="yellow"
          id="flexSwitchCheckSMS"
          style={{ backgroundColor: `${emailNot ? "#ffd93b" : "#eeeded"}` }}
        />
      </div>

      <div className="flex-row setiings-noti-row" style={{ justifyContent: "space-between" }}>
        <p className="small">SMS Notification</p>

        <MDBSwitch
          checked={smsNot}
          onChange={() => {
            localStorage.setItem("smsNot", smsNot ? "off" : "on");
            setSmsNot(!smsNot);
          }}
          btnColor="yellow"
          id="flexSwitchCheckSMS"
          style={{ backgroundColor: `${smsNot ? "#ffd93b" : "#eeeded"}` }}
        />
      </div>
    </div>
  );
};

export default UserNotificationSettings;
