import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import filterBlogs from "./functions/filterBlogs";
import AppContext from "../../context/app-context";
import BlogCard from "./Blogcard";

const Blogs = ({ homePage }) => {
  const { edition } = useContext(AppContext);
  const [blogs, setBlogs] = useState([]);
  const [err, setErr] = useState(false);
  const [_blogsFilteredByRegion, setBlogsFilteredByRegion] = useState([]);


  useEffect(() => {
    const getBlogs = async () => {
      try {
        const res = await fetch(`https://ikook.herokuapp.com/accounts/blog/get`, { method: "GET", cache:"no-store" });
        const { data } = await res.json();
        setBlogs(data);
      } catch (error) {
        setErr(true);
      }
    };
    getBlogs();
  }, []);
  useEffect(() => {
    setBlogsFilteredByRegion(filterBlogs(blogs, homePage, edition));
  }, [blogs]);
  setTimeout(() => {
    if (err) {
      return <p>Oops, something went wrong</p>;
    } else if (blogs?.length === 0) {
      return <p style={{ width: "100%", textAlign: "center" }}>Oops! No blogs here</p>;
    }
  }, 5000);

  return (
    <div className="bloggrid">
      {homePage
        ? blogs.slice(0, 3).map((item, i) => <BlogCard {...item} key={i} />)
        : blogs.map((item, i) => <BlogCard {...item} key={i} />)}
    </div>
  );
};

export default Blogs;
