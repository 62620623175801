import React, { useState, useEffect, useContext } from "react";
import "../../../menu-page/menu-page.css";
import "../../../../styles/index.css";
import { TbMessageLanguage } from "react-icons/tb";
import ReviewCard from "../../../../components/review-card/review-card";
import FaqCard from "../../../../components/faq-card/faq-card";
import AppContext from "../../../../context/app-context";
import { GoVerified, GoLocation } from "react-icons/go";
import { VscUnverified } from "react-icons/vsc";

const ChefAtHomeChefDetails = function ({ activeNav, data }) {
  const [chefName, setChefName] = useState("");
  const [chefImage, setChefImage] = useState("/images/user_placeholder.png");
  const [faqComp, setFaqComp] = useState("");
  const [reviewComp, setReviewComp] = useState("");
  const [fullChef, setFullChef] = useState({});

  const { backendServer, apiKey, setIsLoading, getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    if (!data) return;

    (async function () {
      setIsLoading(true);

      const chef = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data.chef}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setFullChef(chef.data);

      if (chef?.data.photo) setChefImage(await getSignedAwsUrl(chef?.data.photo, "users"));

      setChefName(chef.data.username);

      const comps = chef?.data.chef_details?.reviews.map((e, i) => <ReviewCard key={i} data={e} />);

      comps?.reverse();

      setReviewComp(comps.slice(0, 12));

      const comp = chef?.data.chef_details?.chef_faq?.map((e, i) => {
        return <FaqCard key={i} data={e} />;
      });

      setFaqComp(comp);

      setIsLoading(false);
    })();
  }, [data]);

  if (activeNav !== "profile") return;

  return (
    <div className="box-1-in">
      <p className={`big blackText boldText removemargin`}>About {fullChef?.username}</p>

      <div className="flex-row align-row-left" style={{ alignItems: "flex-start", gap: 10 }}>
        <img style={{ width: 150, height: 150, borderRadius: 150 }} src={chefImage} alt="" />

        <div className="review-card">
          <p className="big blackText">
            {chefName ? chefName : ""}{" "}
            {fullChef?.chef_details?.chef_verification_details?.is_documents_verified ? (
              <GoVerified color="green" size={15} style={{ marginTop: -10 }} />
            ) : (
              <VscUnverified color="red" size={15} style={{ marginTop: -10 }} />
            )}
          </p>

          <p className="yellowText small removemargin alignTextLeft">
            {" "}
            <GoLocation color="#FCC01C" /> &nbsp; {fullChef?.city}
          </p>

          <p className="yellowText small alignTextLeft">
            {" "}
            <TbMessageLanguage color="#FCC01C" /> &nbsp;{" "}
            {fullChef?.chef_details?.languages?.join(", ")}
          </p>

          <p className="small" style={{ textAlign: "left" }}>
            {fullChef?.chef_details?.bio}
          </p>
        </div>
      </div>

      <div className="flex-row align-row-left" style={{ gap: 15, width: "100%" }}>
        <div className="chef-details-boxes">
          <h6 className="alignTextLeft">Chef Experience</h6>

          <p className="tablesmall alignTextLeft removemargin">
            {fullChef?.chef_details?.experience}
          </p>
        </div>

        <div className="chef-details-boxes">
          <h6 className="alignTextLeft">Cooking Style</h6>

          <p className="tablesmall alignTextLeft removemargin">
            {fullChef?.chef_details?.style_cooking}
          </p>
        </div>
      </div>

      <div className="menu-page-nav-cont">
        <p className={`big blackText boldText`}>Reviews</p>
      </div>

      <div className="flex-row align-row-left">{reviewComp}</div>

      <div className="menu-page-nav-cont">
        <p className={`big blackText boldText`}>FAQ</p>
      </div>

      <div className="flex-row align-row-left">{faqComp}</div>
    </div>
  );
};

export default ChefAtHomeChefDetails;
