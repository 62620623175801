import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import BookingCardMini from "../../../components/booking-card-mini/booking-card";
import TableBookingCard from "./child-components/table-booking-card";
import AppContext from "../../../context/app-context";
import UserDashBoardIndexContext from "../context/user-index-context";

const DashboardInit = function ({
  display,
  setOnView,
  setBookingDetailsData,
  setCustomBookingDetails,
}) {
  const [bookingsComp, setBookingComp] = useState([]);
  const [tableBookingComp, setTableBookingComp] = useState([]);

  const { usersData } = useContext(AppContext);
  const { changeAppScreen } = useContext(UserDashBoardIndexContext);

  useEffect(() => {
    getActiveBooking(usersData.user_bookings);

    getCustomBookings(usersData.user_bookings);
  }, [usersData]);

  function getActiveBooking(bookingsArr) {
    const filtered = bookingsArr.filter((e) => {
      return e.status === "pending" && e.booking_type !== "custom-booking";
    });

    const comp = filtered.map((e, i) => (
      <BookingCardMini
        setBookingDetailsData={setBookingDetailsData}
        setOnView={setOnView}
        data={e}
        key={i}
        type={e.booking_type}
      />
    ));

    if (comp.length === 0)
      setBookingComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO ACTIVE BOOKINGS</p>
          <p className="small">No Bookings to display. Start booking now!</p>
          <button onClick={() => window.open("/explore", "_blank")} className="themeBtn">
            Book A Chef
          </button>
        </div>
      );
    else {
      comp.reverse();
      setBookingComp(comp.slice(0, 3));
    }
  }

  function getCustomBookings(bookingsArr) {
    const filtered = bookingsArr.filter((e) => {
      return e.status === "pending" && e.booking_type === "custom-booking";
    });

    const comp = filtered.map((e, i) => (
      <TableBookingCard
        setOnView={setOnView}
        setCustomBookingDetails={setCustomBookingDetails}
        setBookingDetailsData={setBookingDetailsData}
        data={e}
        key={i}
        type={e.booking_type}
      />
    ));

    if (comp.length === 0)
      setTableBookingComp(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO ACTIVE CUSTOM BOOKINGS</p>
          <p className="small">No Bookings to display. Start booking now!</p>
          <button onClick={() => window.open("/explore", "_blank")} className="themeBtn">
            Book A Chef
          </button>
        </div>
      );
    else {
      comp.reverse();
      setTableBookingComp(comp.slice(0, 3));
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row ud-dash-header">
        <p className="big alignTextLeft removemargin">Active Bookings</p>

        <button onClick={() => changeAppScreen("bookings")} className="themeBtn ud-btns">
          See All
        </button>
      </div>

      <div className="ud-active-booking-cont">
        {/* <div className="flex-column for-no-bookings">

          <img src="/images/no-booking.png" alt="ikook" />

          <p className="small boldText">
            NO BOOKINGS
          </p>

          <p className="small">
            No Bookings to display. Start booking now!
          </p>

          <button className="themeBtn">Book A Chef</button>

        </div> */}

        {bookingsComp}
      </div>

      <div className="flex-row ud-dash-header media-dashboard-table-cont" style={{ marginTop: 40 }}>
        <p className="big alignTextLeft removemargin">Custom Bookings</p>
      </div>

      <div
        className="flex-row align-row-left media-dashboard-table-cont"
        style={{ padding: "0px 0px 0px 0px", justifyContent: "flex-start", marginTop: 40 }}
      >
        <p style={{ width: "17%" }} className="small boldText">
          Booking ID
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Date
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Status
        </p>

        <p style={{ width: "20%" }} className="small boldText">
          Event Theme
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Service
        </p>

        <p style={{ width: "14%" }} className="small boldText">
          Guests
        </p>

        {tableBookingComp}
      </div>
    </div>
  );
};

export default DashboardInit;
