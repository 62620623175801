import React, { useState, useEffect } from "react";
import "../../pages/menu-page/menu-page.css";
import MenuOptionCard from "./menu-option-card";
import MenuGuidelines from "./IC/IC";

const MenuDetails = function ({
  menuDetailsSelected,
  activeNav,
  menuData,
  setMenuDetailsSelected,
  menuDetailObjs,
  setPTagsMenu,
  setThePlatterSelected,
  platterWarning,
  menuDetailsWarning,
}) {
  const [selectedPlatter, setSelectedPlatter] = useState("");

  const [starter, setstarter] = useState([]);

  const [side, setside] = useState([]);

  const [main, setmain] = useState([]);

  const [dessert, setdessert] = useState([]);

  useEffect(() => {
    const starterComp = menuData?.menustarter?.map((e, i) => {
      return (
        <MenuOptionCard
          type={"Starter"}
          setPTagsMenu={setPTagsMenu}
          menuDetailObjs={menuDetailObjs}
          menuDetailsSelected={menuDetailsSelected}
          setMenuDetailsSelected={setMenuDetailsSelected}
          key={i}
          data={e}
          currency={menuData?.currency}
        />
      );
    });
    setstarter(starterComp);

    const mainComp = menuData?.menumain?.map((e, i) => {
      return (
        <MenuOptionCard
          type={"Main"}
          setPTagsMenu={setPTagsMenu}
          menuDetailObjs={menuDetailObjs}
          menuDetailsSelected={menuDetailsSelected}
          setMenuDetailsSelected={setMenuDetailsSelected}
          key={i}
          data={e}
          currency={menuData?.currency}
        />
      );
    });
    setmain(mainComp);

    const sideComp = menuData?.menuside?.map((e, i) => {
      return (
        <MenuOptionCard
          type={"Side"}
          setPTagsMenu={setPTagsMenu}
          menuDetailObjs={menuDetailObjs}
          menuDetailsSelected={menuDetailsSelected}
          setMenuDetailsSelected={setMenuDetailsSelected}
          key={i}
          data={e}
          currency={menuData?.currency}
        />
      );
    });
    setside(sideComp);

    const dessertComp = menuData?.menudessert?.map((e, i) => {
      return (
        <MenuOptionCard
          type={"Dessert"}
          setPTagsMenu={setPTagsMenu}
          menuDetailObjs={menuDetailObjs}
          menuDetailsSelected={menuDetailsSelected}
          setMenuDetailsSelected={setMenuDetailsSelected}
          key={i}
          data={e}
          currency={menuData?.currency}
        />
      );
    });
    setdessert(dessertComp);
  }, [menuData]);

  if (activeNav !== "menu") return;

  return (
    <div className="box-1-in">
      <MenuGuidelines activeNav={activeNav} menuData={menuData} />

      <div className="flex-column align-column-left">
        <h6 className="blackText removemargin" id="platterWarningRef">
          Choose Your Menu
        </h6>

        <p className="small">Listed below are the various menus available.</p>

        <p style={{ marginBottom: -10 }} className="small redText removemargin alignTextLeft">
          {platterWarning}
        </p>
      </div>

      <div className="flex-row align-row-left" style={{ gap: "30px", width: "100%" }}>
        <div
          onClick={() => {
            setSelectedPlatter("shared");
            setThePlatterSelected("sharing-platter");
          }}
          className={`div-image-text-menu-page ${
            selectedPlatter === "shared" ? "active-platter-indactor" : ""
          }`}
        >
          <p className="small removemargin">Sharing Menu</p>
        </div>

        <div
          onClick={() => {
            setSelectedPlatter("platted");
            setThePlatterSelected("plated");
          }}
          className={`div-image-text-menu-page ${
            selectedPlatter === "platted" ? "active-platter-indactor" : ""
          }`}
        >
          <p className="small removemargin">Plated Menu</p>
        </div>
      </div>

      <div className="starter">
        <p
          style={{ marginBottom: -10 }}
          id="menuDetailsWarningRef"
          className="small redText removemargin alignTextLeft"
        >
          {menuDetailsWarning}
        </p>

        <div className="menu-page-nav-cont">
          <p className={`big blackText boldText`}>Starter Menu</p>
        </div>

        {starter}
      </div>

      <div className="main">
        <div className="menu-page-nav-cont">
          <p className={`big blackText boldText`}>Main Menu</p>
        </div>

        {main}
      </div>

      <div className="dessert">
        <div className="menu-page-nav-cont">
          <p className={`big blackText boldText`}>Dessert Menu</p>
        </div>

        {dessert}
      </div>

      <div className="side">
        <div className="menu-page-nav-cont">
          <p className={`big blackText boldText`}>Side Menu</p>
        </div>

        {side}
      </div>

      <div className="chef-details-boxes mpp-menu-desc">
        <h6 className="alignTextLeft boldText">Menu Description</h6>

        <p className="small alignTextLeft removemargin">{menuData?.description}</p>
      </div>
    </div>
  );
};

export default MenuDetails;
