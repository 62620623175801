import React, {useEffect, useRef, useState} from "react";
import "../../../../tailwind.css";
import FilledButton from "../components/buttons/FilledButton";
import {useContext} from "react";
import AppContext from "../../../../context/app-context";
import UpandDownText from "../components/UpandDownText";
import {Navigate, useNavigate} from "react-router-dom";
import WaitlistDialog from "./WaitlistDialog";

function WaitingList() {
    const {setShowAppbar, setShowFooter, popup, loggedIn, edition} =
        useContext(AppContext);

    const [showWaitListDialog, setshowWaitListDialog] = useState(false);
    const navigate = useNavigate();

    const loginModal = useRef(null);

    useEffect(() => {
        setShowAppbar(false);
        setShowFooter(false);

        return () => {
            setShowAppbar(true);
            setShowFooter(true);
        };
    }, []);

    return (
        <div className="flex flex-col w-screen h-screen font-Poppins pt-8">
            {showWaitListDialog && (
                <WaitlistDialog onDismiss={() => setshowWaitListDialog(false)}/>
            )}
            <div className="flex customContainer place-content-between">
                <img
                    src="/images/Logo.png"
                    alt="Logo"
                    className="w-[116px] object-contain"
                />

                <a href="https://wa.link/esg0ln" target="_">
                    <FilledButton
                        colors="bg-primary-100"
                        radius="rounded-full"
                        padding="py-2 px-3"
                        customClass="rounded-full py-2"
                    >
                        Contact Us
                    </FilledButton>
                </a>
            </div>

            <div className="flex flex-col customContainer mt-10">
                <div className="w-fit mx-auto relative">
                    <div className=" yellow-rec media-yellow-rec"></div>
                    <p
                        className="vbig bigTextHome blackText whitespace-pre-line leading-[1.12em]"
                        style={{color: "#323335"}}
                    >
                        Make your own rules, and {"\n"}
                        cook with
                        <span style={{color: "#fcc01c"}}> iKooK</span>
                    </p>
                </div>
                <h3 className="text-[1em] text-text-bodyColor md:w-[70%] mx-auto whitespace-pre-line">
                    Embark on a flavor adventure with iKooK, connecting users with chefs for tailor-made dishes at home
                    or events. Join now to showcase your passion and bring joy through culinary mastery!
                    {/* Showcase Your Culinary Mastery! Join our app to connect with users
          seeking professionally prepared food for any occasion. Your skills,
          their delight – the perfect culinary match awaits!" */}
                    {/* Experience different cuisines and enjoy professionally prepared food, at your home or for any occasion! Our app will connect you with trained chefs who tailor to your exact need */}
                </h3>
                <div className="flex flex-wrap mdw:place-content-center gap-y-4 place-items-center mt-8 mx-auto">
                    <UpandDownText value="Happy chefs" title="+1000"/>
                    <div
                        className="h-[40px] w-[1px] border-[1px] border-[#CFCFCE] bg-[#CFCFCE] mdw:mx-[19px] mx-[42px]"></div>
                    <UpandDownText value="Countries" title="+3"/>
                    <div
                        className="h-[40px] w-[1px] border-[1px] border-[#CFCFCE] bg-[#CFCFCE] mdw:mx-[19px] mx-[42px]"></div>
                    <UpandDownText value="Experience Delivered" title="+10,000"/>
                </div>

                <div className="flex mdw:flex-col mdw:gap-2 mx-auto mt-8 gap-x-10">
                    <FilledButton
                        onclick={() => {
                            setshowWaitListDialog(true);
                        }}
                        radius="rounded-full mdw:w-full"
                        padding="p-[15px] px-[24px] mdw:px-[10px] mdw:py-[10px]"
                        colors="bg-primary-100 rounded-full gap-2 w-fit text-white"
                    >
                        Join Waiting List
                    </FilledButton>
                    {/* <FilledButton
            onclick={() => navigate(`/explore`)}
            padding="p-[15px]  px-[24px] mdw:px-[10px] mdw:py-[10px]"
            radius="rounded-full"
            textColor="text-primary-100"
            colors="border-primary-100 bg-transparent border-2 rounded-full gap-2 text-primary-100"
          >
            Explore our menus
          </FilledButton> */}
                </div>

                <div className="flex flex-row items-center justify-center gap-2.5 mt-8">
                    <StoreButton
                        name="App Store"
                        icon="/icons/apple.svg"
                        link="https://apps.apple.com/ng/app/ikook/id6480477546"
                    />
                    <StoreButton
                        name="Play Store"
                        icon="/icons/playstore.svg"
                        link="https://play.google.com/store/apps/details?id=com.ikook.ikook"
                    />
                </div>
            </div>

            <div className="grow mdw:mt-[50px] mt-[200px] bg-primary-100">
                <div className="flex grow flex-col  py-8 relative ">
                    <div
                        className="grid mdw:hidden grid-cols-4 bottom-[50%] left-0 right-0 absolute customContainer gap-x-[50px]">
                        <img
                            src="/images/wait1.png"
                            alt="wait"
                            className="h-[300px] w-full object-cover rounded-lg"
                        />
                        <img
                            src="/images/wait2.png"
                            alt="wait"
                            className="h-[300px] w-full object-cover rounded-lg"
                        />
                        <img
                            src="/images/wait3.png"
                            alt="wait"
                            className="h-[300px] w-full object-cover rounded-lg"
                        />
                        <img
                            src="/images/wait4.png"
                            alt="wait"
                            className="h-[300px] w-full object-cover rounded-lg"
                        />
                    </div>
                    <div className="flex mdw:flex-col place-content-between customContainer mdw:mt-[20px] mt-[170px]">
                        <img
                            onClick={() => (document.location.href = "/")}
                            className="cursor mdw:w-[15%] w-[50px] object-contain h-auto mdw:mb-6 opacity-50"
                            src="/images/logo-black.png"
                            alt="iKooK Logo"
                        />

                        <div className="flex flex-col place-items-end place-content-end justify-end">
                            <div className="flex-row flex place-content-end gap-3 ">
                                <img
                                    onClick={() => {
                                        if (edition === "NGN")
                                            return window.open(
                                                "https://www.facebook.com/IKOOKAPP/",
                                                "_blank"
                                            );

                                        window.open("https://www.facebook.com/IKOOKAPP/", "_blank");
                                    }}
                                    className="cursor"
                                    src="/lpv2/fb.png"
                                    alt="ikook-social"
                                    title="Facebook"
                                />
                                <img
                                    onClick={() => {
                                        if (edition === "NGN")
                                            return window.open(
                                                "https://twitter.com/IKOOKAPP",
                                                "_blank"
                                            );

                                        window.open("https://twitter.com/IKOOKAPP", "_blank");
                                    }}
                                    className="cursor"
                                    src="/lpv2/x.png"
                                    alt="ikook-social"
                                    title="X"
                                />
                                <img
                                    onClick={() => {
                                        if (edition === "NGN")
                                            return window.open(
                                                "http://instagram.com/ikookapp/",
                                                "_blank"
                                            );

                                        window.open(
                                            "http://instagram.com/ikookapp/",
                                            "_blank"
                                        );
                                    }}
                                    className="cursor"
                                    src="/lpv2/ig.png"
                                    alt="ikook-social"
                                    title="Instagram"
                                />
                                <img
                                    onClick={() =>
                                        window.open(
                                            "https://www.linkedin.com/company/ikookapp/",
                                            "_blank"
                                        )
                                    }
                                    className="cursor"
                                    src="/lpv2/li.png"
                                    alt="ikook-social"
                                    title="Linkdln"
                                />
                            </div>

                            <p className="text-base f-grey">
                                Copyright &copy; 2024 iKooK Ltd. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function StoreButton({name, icon, link}) {
    return (
        <a
            className="flex flex-row w-fit items-center border border-[#BABABA] rounded-md px-6 py-2 gap-2.5"
            href={link}
            target="_blank" rel="noreferrer"
        >
            <div className="w-10 h-10 rounded-lg bg-black place-content-center">
                <img
                    src={icon}
                    alt="store"
                    className="object-contain"
                />
            </div>
            <div className="flex flex-col font-inter text-[#0F0E0C]">
                <span className="text-normal text-sm">Stay Ahead</span>
                <span className="text-semibold text-lg">{name}</span>
            </div>
        </a>
    );
}

export default WaitingList;
