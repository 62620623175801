import React, {
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { MDBBtn, MDBInput, MDBModalBody } from "mdb-react-ui-kit";
import "./login-modal.css";
import "../../../styles/index.css";
import AppContext from "../../../context/app-context";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { usePlacesWidget } from "react-google-autocomplete";
import ChefRequirements from "../../chef-requirements/chef-requirements";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const chefObjRaw = {
  role: "chef",
  chef_details: {},
};

const chefAlertTextInputsRaw = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  country: "",
  experience: "",
  criminalRecord: "",
  rightToWork: "",
  address: "",
  city: "",
  mobile: "",
  postal_code: "",
};

const userAlertInputsRaw = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  mobile: "",
  password: "",
};

const SignupModal = forwardRef(({ display, setShowLogin, setActive }, ref) => {
  const [activeSignupType, setActiveSignupType] = useState("user");

  const {
    backendServer,
    apiKey,
    setIsLoading,
    popup,
    setUsersData,
    setLoggedIn,
    getSignedAwsUrl,
    setUsersPhoto,
    renderAdvancedPopUp,
    googleMapsApiKey,
    validateEmail,
  } = useContext(AppContext);

  const uFirstNameRef = useRef(null);
  const uLastNameRef = useRef(null);
  const uUserNameRef = useRef(null);
  const uEmailRef = useRef(null);
  const uMobileRef = useRef(null);
  const uPasswordRef = useRef(null);

  const [userAlertTextInputs, setUserAlertTextInputs] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    mobile: "",
    password: "",
  });

  const cFirstNameRef = useRef(null);
  const cLastNameRef = useRef(null);
  const cUserNameRef = useRef(null);
  const cEmailRef = useRef(null);
  const cMobileRef = useRef(null);
  const cPasswordRef = useRef(null);
  const cCityRef = useRef(null);
  const cPostalCodeRef = useRef(null);

  const [chefAlertTextInputs, setChefAlertTextInputs] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    country: "",
    experience: "",
    criminalRecord: "",
    rightToWork: "",
    address: "",
    city: "",
    mobile: "",
    postal_code: "",
  });

  //signup as user state
  const [userObj, setUserObj] = useState({
    role: "user",
  });

  const [chefObj, setChefObj] = useState({
    role: "chef",
    chef_details: {},
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        setActiveSignup(screen) {
          setActiveSignupType(screen);
        },
      };
    },
    []
  );

  const { ref: googlePlacesRef } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setChefObj({
        ...chefObjRaw,
        chef_details: {
          ...chefObjRaw.chef_details,
          address: place?.formatted_address,
        },
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: ["ng", "gb", "ca"] },
    },
  });

  useEffect(() => {
    if (userObj.first_name) {
      userAlertInputsRaw.first_name = "";
      setUserAlertTextInputs({ ...userAlertTextInputs, first_name: "" });
    }

    if (userObj.last_name) {
      userAlertInputsRaw.last_name = "";
      setUserAlertTextInputs({ ...userAlertTextInputs, last_name: "" });
    }

    if (userObj.username) {
      userAlertInputsRaw.username = "";
      setUserAlertTextInputs({ ...userAlertTextInputs, username: "" });
    }

    if (userObj?.email && validateEmail(userObj?.email)) {
      userAlertInputsRaw.email = "";
      setUserAlertTextInputs({ ...userAlertTextInputs, email: "" });
    }

    if (userObj.mobile && userObj?.mobile?.length >= 11) {
      userAlertInputsRaw.mobile = "";
      setUserAlertTextInputs({ ...userAlertTextInputs, mobile: "" });
    }

    if (userObj.password) {
      userAlertInputsRaw.password = "";
      setUserAlertTextInputs({ ...userAlertTextInputs, password: "" });
    }
  }, [userObj]);

  useEffect(() => {
    if (chefObjRaw.first_name) {
      chefAlertTextInputsRaw.first_name = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, first_name: "" });
    }

    if (chefObjRaw.last_name) {
      chefAlertTextInputsRaw.last_name = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, last_name: "" });
    }

    if (chefObjRaw.username) {
      chefAlertTextInputsRaw.username = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, username: "" });
    }

    if (chefObjRaw?.email && validateEmail(chefObjRaw?.email)) {
      chefAlertTextInputsRaw.email = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, email: "" });
    }

    if (chefObjRaw.mobile && chefObjRaw?.mobile?.length >= 11) {
      chefAlertTextInputsRaw.mobile = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, mobile: "" });
    }

    if (chefObjRaw.password) {
      chefAlertTextInputsRaw.password = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, password: "" });
    }

    if (chefObj?.country) {
      chefAlertTextInputsRaw.country = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, country: "" });
    }

    if (
      chefObj?.chef_details?.country_right_to_work === true ||
      chefObj?.chef_details?.country_right_to_work === false
    ) {
      chefAlertTextInputsRaw.rightToWork = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, rightToWork: "" });
    }

    if (chefObjRaw.chef_details.address) {
      chefAlertTextInputsRaw.address = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, address: "" });
    }

    if (chefObjRaw.city) {
      chefAlertTextInputsRaw.city = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, city: "" });
    }

    if (chefObjRaw.post_code) {
      chefAlertTextInputsRaw.postal_code = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, postal_code: "" });
    }

    if (chefObj?.chef_details?.type_of_chef) {
      chefAlertTextInputsRaw.experience = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, experience: "" });
    }

    if (
      chefObj?.chef_details?.criminal_record === true ||
      chefObj?.chef_details?.criminal_record === false
    ) {
      chefAlertTextInputsRaw.criminalRecord = "";
      setChefAlertTextInputs({ ...chefAlertTextInputs, criminalRecord: "" });
    }
  }, [chefObj]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        setIsLoading(true);

        setUserObj({
          ...userObj,
          geo_location: {
            type: "point",
            coordinates: [pos.coords.longitude, pos.coords.latitude],
          },
        });

        setChefObj({
          ...chefObj,
          geo_location: {
            type: "point",
            coordinates: [pos.coords.longitude, pos.coords.latitude],
          },
        });

        chefObjRaw.geo_location = {
          type: "point",
          coordinates: [pos.coords.longitude, pos.coords.latitude],
        };

        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [display]);

  const signupWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsLoading(true);
        const googleData = await (
          await fetch(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`
          )
        ).json();

        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/signup/user`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              ...userObj,
              first_name: googleData.given_name,
              last_name: googleData.family_name,
              username: `${googleData.given_name}-${googleData.family_name}-${googleData.id}`,
              email: googleData.email,
              googleID: googleData.id,
            }),
            credentials: "include",
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Sign Up Successful");

        //then login
        const loginReturned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/login/google`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ email: googleData.email, googleID: googleData.id }),
            credentials: "include",
          })
        ).json();

        if (loginReturned.status === "Internal server error")
          throw new Error(loginReturned.message);

        localStorage.setItem("ikookusername", loginReturned.data.username);

        localStorage.setItem("ikooktoken", loginReturned.data.token);

        const data = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/user/get/${loginReturned.data.username}`,
            {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            }
          )
        ).json();

        localStorage.setItem("ikookid", data.data._id);

        setUsersData(data.data);

        if (data.data.photo) setUsersPhoto(await getSignedAwsUrl(data.data.photo, "users"));
        else setUsersPhoto("/images/user_placeholder.png");

        localStorage.setItem("chefathome", "on");
        localStorage.setItem("mealprep", "on");
        localStorage.setItem("largeevent", "on");

        setIsLoading(false);

        setShowLogin(false);

        setLoggedIn(true);
      } catch (err) {
        popup("Something went wrong");
        setIsLoading(false);
      }
    },
  });

  function validateUserFields() {
    if (!userObj?.first_name) {
      userAlertInputsRaw.first_name = "first name is requried";

      setUserAlertTextInputs({ ...userAlertTextInputs, first_name: "first name is requried" });

      uFirstNameRef.current.focus();

      return false;
    } else if (!userObj?.last_name) {
      userAlertInputsRaw.last_name = "last name is required";

      setUserAlertTextInputs({ ...userAlertTextInputs, last_name: "last name is required" });

      uLastNameRef.current.focus();

      return false;
    } else if (!userObj?.username) {
      userAlertInputsRaw.username = "username is required";

      setUserAlertTextInputs({ ...userAlertTextInputs, username: "usernme is required" });

      uUserNameRef.current.focus();

      return false;
    } else if (!userObj?.email || !validateEmail(userObj?.email)) {
      userAlertInputsRaw.email = "email is invalid";

      setUserAlertTextInputs({ ...userAlertTextInputs, email: "email is invalid" });

      uEmailRef.current.focus();

      return false;
    } else if (!userObj?.mobile || userObj?.mobile?.length < 11) {
      userAlertInputsRaw.mobile = "mobile number is invalid";

      setUserAlertTextInputs({ ...userAlertTextInputs, mobile: "mobile number is invalid" });

      uMobileRef.current.focus();

      return false;
    } else if (!userObj?.password) {
      userAlertInputsRaw.password = "password is required";

      setUserAlertTextInputs({ ...userAlertTextInputs, password: "password is required" });

      uPasswordRef.current.focus();

      return false;
    } else return true;
  }

  function validateChefFields() {
    if (!chefObjRaw?.first_name) {
      chefAlertTextInputsRaw.first_name = "first name is requried";

      setChefAlertTextInputs({ ...chefAlertTextInputs, first_name: "first name is requried" });

      cFirstNameRef.current.focus();

      return false;
    } else if (!chefObjRaw?.last_name) {
      chefAlertTextInputsRaw.last_name = "last name is required";

      setChefAlertTextInputs({ ...chefAlertTextInputs, last_name: "last name is required" });

      cLastNameRef.current.focus();

      return false;
    } else if (!chefObjRaw?.username) {
      chefAlertTextInputsRaw.username = "username is required";

      setChefAlertTextInputs({ ...chefAlertTextInputs, username: "username is required" });

      cUserNameRef.current.focus();

      return false;
    } else if (!chefObjRaw?.email || !validateEmail(chefObjRaw.email)) {
      chefAlertTextInputsRaw.email = "email is invalid";

      setChefAlertTextInputs({ ...chefAlertTextInputs, email: "email is invalid" });

      cEmailRef.current.focus();

      return false;
    } else if (!chefObjRaw?.mobile || chefObjRaw?.mobile?.length < 11) {
      chefAlertTextInputsRaw.mobile = "mobile number is invalid";

      setChefAlertTextInputs({ ...chefAlertTextInputs, mobile: "mobile number is invalid" });

      cMobileRef.current.focus();

      return false;
    } else if (!chefObjRaw?.password) {
      chefAlertTextInputsRaw.password = "password is required";

      setChefAlertTextInputs({ ...chefAlertTextInputs, password: "password is required" });

      cPasswordRef.current.focus();

      return false;
    } else if (!chefObj?.country) {
      chefAlertTextInputsRaw.country = "select a country of residence";

      setChefAlertTextInputs({ ...chefAlertTextInputs, country: "select a country of residence" });

      document.querySelector("#corRef").scrollIntoView({ behavior: "smooth" });

      return false;
    } else if (
      chefObj?.chef_details?.country_right_to_work !== true &&
      chefObj?.chef_details?.country_right_to_work !== false
    ) {
      chefAlertTextInputsRaw.rightToWork = "select a right to work option";

      setChefAlertTextInputs({
        ...chefAlertTextInputs,
        rightToWork: "select a right to work option",
      });

      document.querySelector("#crwRef").scrollIntoView({ behavior: "smooth" });

      return false;
    } else if (!chefObjRaw?.chef_details?.address) {
      chefAlertTextInputsRaw.address = "address is required";

      setChefAlertTextInputs({ ...chefAlertTextInputs, address: "address is required" });

      googlePlacesRef.current.focus();

      return false;
    } else if (!chefObjRaw?.city) {
      chefAlertTextInputsRaw.city = "city is required";

      setChefAlertTextInputs({ ...chefAlertTextInputs, city: "city is required" });

      cCityRef.current.focus();

      return false;
    } else if (chefObjRaw?.post_code && chefObjRaw?.post_code?.length < 4) {
      chefAlertTextInputsRaw.postal_code = "invalid postal code";

      setChefAlertTextInputs({ ...chefAlertTextInputs, postal_code: "invalid postal code" });

      cPostalCodeRef.current.focus();

      return false;
    } else if (!chefObj?.chef_details?.type_of_chef) {
      chefAlertTextInputsRaw.experience = "select a chef type";

      setChefAlertTextInputs({ ...chefAlertTextInputs, experience: "select a chef type" });

      document.querySelector("#tocRef").scrollIntoView({ behavior: "smooth" });

      return false;
    } else if (
      chefObj?.chef_details?.criminal_record !== true &&
      chefObj?.chef_details?.criminal_record !== false
    ) {
      chefAlertTextInputsRaw.criminalRecord = "select a criminal record option";

      setChefAlertTextInputs({
        ...chefAlertTextInputs,
        criminalRecord: "select a criminal record option",
      });

      document.querySelector("#crrRef").scrollIntoView({ behavior: "smooth" });

      return false;
    } else return true;
  }

  const signupAsUser = async function () {
    if (!validateUserFields()) return;

    setIsLoading(true);

    try {
      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/signup/user`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(userObj),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);

      setShowLogin(false);
      setActive("login");

      renderAdvancedPopUp(
        true,
        true,
        "Log In",
        () => {
          setActive("login");
          setShowLogin(true);
        },
        "Welcome to iKooK. Your Sign Up was successful and you can now Login. Don't forget to verify your email via our verification email sent to your inbox."
      );
    } catch (err) {
      if (err.message.split(":")[0].trim() === "E11000 duplicate key error collection") {
        popup("User already exists. Please Login.");
        setActive("login");
        setIsLoading(false);
      } else {
        popup("Something went wrong");
        setIsLoading(false);
      }
    }
  };

  const signupAsChef = async function () {
    if (!validateChefFields()) return;

    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/signup/chef`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(chefObj),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);

      setShowLogin(false);
      setActive("login");

      renderAdvancedPopUp(
        true,
        true,
        "Log In",
        () => {
          setActive("login");
          setShowLogin(true);
        },
        "Welcome to iKooK. Your Sign Up was successful and you can now login. Don't forget to verify your email via our verification email sent to your inbox."
      );
    } catch (err) {
      if (err.message.split(":")[0].trim() === "E11000 duplicate key error collection") {
        popup("User already exists. Please Login.");
        setActive("login");
        setIsLoading(false);
      } else {
        popup("Something went wrong");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <MDBModalBody style={{ display: `${display ? "block" : "none"}` }}>
        <div className="login-modal-option-cont forsignupcont media-forsignupcont-options">
          <div
            onClick={() => setActiveSignupType("user")}
            className={
              activeSignupType === "user"
                ? "login-modal-option forsignup login-modal-option-active"
                : "login-modal-option forsignup"
            }
          >
            Sign Up as Host
          </div>

          <div
            onClick={() => setActiveSignupType("chef")}
            className={
              activeSignupType === "chef"
                ? "login-modal-option forsignup login-modal-option-active"
                : "login-modal-option forsignup"
            }
          >
            Sign Up as Chef
          </div>
        </div>

        <div
          className={`${
            activeSignupType === "user" ? "signunwith-cont" : "signunwith-cont hideForAuth"
          }`}
        >
          <div
            onClick={signupWithGoogle}
            className="continue-with-google"
            style={{ cursor: "pointer" }}
          >
            <FcGoogle size={20} />

            <p className="small removemargin">SIGNUP WITH GOOGLE</p>
          </div>
        </div>

        {/* <div className={`${activeSignupType === "user" ? "signunwith-cont" : "signunwith-cont hideForAuth"}`}>

          <BsFacebook style={{ cursor: "pointer" }} size={30} />

          <AiFillGoogleCircle onClick={signupWithGoogle} style={{ marginTop: -3, cursor: "pointer" }} size={36} />

        </div> */}

        <div
          className="signupas-user-cont"
          style={{ display: `${activeSignupType === "user" ? "block" : "none"}` }}
        >
          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={uFirstNameRef}
              onChange={(e) => setUserObj({ ...userObj, first_name: e.target.value })}
              className="login-inputs removemargin-val"
              label="First Name"
              type="text"
            />
            <p className="redText xsmall removemargin alignTextLeft">
              {userAlertInputsRaw.first_name}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={uLastNameRef}
              onChange={(e) => setUserObj({ ...userObj, last_name: e.target.value })}
              className="login-inputs removemargin-val"
              label="Last Name"
              type="text"
            />
            <p className="redText xsmall removemargin alignTextLeft">
              {userAlertInputsRaw.last_name}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={uUserNameRef}
              onChange={(e) => setUserObj({ ...userObj, username: e.target.value })}
              className="login-inputs removemargin-val"
              label="Username"
              type="text"
            />
            <p className="redText xsmall removemargin alignTextLeft">
              {userAlertInputsRaw.username}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={uEmailRef}
              onChange={(e) => setUserObj({ ...userObj, email: e.target.value })}
              className="login-inputs removemargin-val"
              label="Your Email"
              type="email"
            />
            <p className="redText xsmall removemargin alignTextLeft">{userAlertInputsRaw.email}</p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={uMobileRef}
              onChange={(e) => setUserObj({ ...userObj, mobile: e.target.value })}
              className="login-inputs removemargin-val"
              label="Mobile"
              type="text"
            />
            <p className="redText xsmall removemargin alignTextLeft">{userAlertInputsRaw.mobile}</p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={uPasswordRef}
              onChange={(e) => setUserObj({ ...userObj, password: e.target.value })}
              className="login-inputs removemargin-val"
              label="Your Password"
              type="password"
            />
            <p className="redText xsmall removemargin alignTextLeft">
              {userAlertInputsRaw.password}
            </p>
          </div>

          <div className="loginbtn-cont">
            <MDBBtn
              disabled={false}
              style={{ width: "80%", cursor: "pointer", color: "black" }}
              onClick={signupAsUser}
              id="signupbtn-disable"
            >
              Sign Up
            </MDBBtn>
          </div>

          <p className="small removemargin">
            By signing up, you agree to our{" "}
            <span>
              <a href="/tac" target="_blank">
                Terms of Service.
              </a>
            </span>
          </p>
        </div>

        <div
          className="signupas-chef-cont"
          style={{ display: `${activeSignupType === "chef" ? "block" : "none"}` }}
        >
          <ChefRequirements />

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cFirstNameRef}
              onChange={(e) => {
                setChefObj({ ...chefObj, first_name: e.target.value });
                chefObjRaw.first_name = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="First Name"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.first_name}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cLastNameRef}
              onChange={(e) => {
                setChefObj({ ...chefObj, last_name: e.target.value });
                chefObjRaw.last_name = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Last Name"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.last_name}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cUserNameRef}
              onChange={(e) => {
                setChefObj({ ...chefObj, username: e.target.value });
                chefObjRaw.username = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Username"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.username}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cEmailRef}
              onChange={(e) => {
                setChefObj({ ...chefObj, email: e.target.value });
                chefObjRaw.email = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Your Email"
              type="email"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.email}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cMobileRef}
              onChange={(e) => {
                setChefObj({ ...chefObj, mobile: e.target.value });
                chefObjRaw.mobile = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Mobile"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.mobile}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cPasswordRef}
              onChange={(e) => {
                setChefObj({ ...chefObj, password: e.target.value });
                chefObjRaw.password = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Your Password"
              type="password"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.password}
            </p>
          </div>

          <div className="select-in-cont">
            <div className="flex-column align-column-left removemargin" style={{ gap: 10 }}>
              <p className="small removemargin" id="corRef">
                Country of Residence
              </p>

              <MDBDropdown id="mdb-dropdown-custom-auth">
                <MDBDropdownToggle id="mdb-dropdown-custom-in-auth" color="light">
                  {chefObj?.country || "--"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="mdb-menu-custom-auth">
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        country: e.target.textContent,
                        chef_details: {
                          ...chefObj.chef_details,
                        },
                      });
                      chefObjRaw.country = e.target.textContent;
                    }}
                  >
                    Nigeria
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        country: "UK",
                        chef_details: {
                          ...chefObj.chef_details,
                        },
                      });
                      chefObjRaw.country = "UK";
                    }}
                  >
                    United Kingdom
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        country: e.target.textContent,
                        chef_details: {
                          ...chefObj.chef_details,
                        },
                      });
                      chefObjRaw.country = e.target.textContent;
                    }}
                  >
                    Canada
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>

              <p className="redText xsmall removemargin alignTextLeft">
                {chefAlertTextInputsRaw.country}
              </p>
            </div>

            <div className="flex-column align-column-left removemargin" style={{ gap: 10 }}>
              <p className="small removemargin" id="crwRef">
                Do you have right to work in this country?
              </p>

              <MDBDropdown id="mdb-dropdown-custom-auth">
                <MDBDropdownToggle id="mdb-dropdown-custom-in-auth" color="light">
                  {chefObj?.chef_details?.country_right_to_work === true
                    ? "Yes"
                    : chefObj?.chef_details?.country_right_to_work === false
                    ? "No"
                    : "--"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="mdb-menu-custom-auth">
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          country_right_to_work: e.target.textContent === "Yes" ? true : false,
                        },
                      });
                      chefObjRaw.chef_details.country_right_to_work =
                        e.target.textContent === "Yes" ? true : false;
                    }}
                  >
                    Yes
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          country_right_to_work: e.target.textContent === "Yes" ? true : false,
                        },
                      });
                      chefObjRaw.chef_details.country_right_to_work =
                        e.target.textContent === "Yes" ? true : false;
                    }}
                  >
                    No
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>

              <p className="redText xsmall removemargin alignTextLeft">
                {chefAlertTextInputsRaw.rightToWork}
              </p>
            </div>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={googlePlacesRef}
              onChange={(e) => {
                setChefObj({
                  ...chefObj,
                  chef_details: {
                    ...chefObj.chef_details,
                    address: e.target.value,
                  },
                });
                chefObjRaw.chef_details.address = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Your Address"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.address}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cCityRef}
              onChange={(e) => {
                setChefObj({
                  ...chefObj,
                  city: e.target.value,
                  chef_details: {
                    ...chefObj.chef_details,
                  },
                });
                chefObjRaw.city = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="City/State"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.city}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <MDBInput
              ref={cPostalCodeRef}
              onChange={(e) => {
                setChefObj({
                  ...chefObj,
                  post_code: e.target.value,
                  chef_details: {
                    ...chefObj.chef_details,
                  },
                });
                chefObjRaw.post_code = e.target.value;
              }}
              className="login-inputs removemargin-val"
              label="Postal Code"
              type="text"
            />

            <p className="redText xsmall removemargin alignTextLeft">
              {chefAlertTextInputsRaw.postal_code}
            </p>
          </div>

          <div className="select-in-cont">
            <div className="flex-column align-column-left removemargin" style={{ gap: 10 }}>
              <p className="small removemargin" id="tocRef">
                Type of Chef
              </p>

              <MDBDropdown id="mdb-dropdown-custom-auth">
                <MDBDropdownToggle id="mdb-dropdown-custom-in-auth" color="light">
                  {chefObj?.chef_details?.type_of_chef || "--"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="mdb-menu-custom-auth">
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          type_of_chef: e.target.textContent,
                        },
                      });
                      chefObjRaw.chef_details.type_of_chef = e.target.textContent;
                    }}
                  >
                    Commis (junior) Chef
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          type_of_chef: e.target.textContent,
                        },
                      });
                      chefObjRaw.chef_details.type_of_chef = e.target.textContent;
                    }}
                  >
                    Chef de Partie
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          type_of_chef: e.target.textContent,
                        },
                      });
                      chefObjRaw.chef_details.type_of_chef = e.target.textContent;
                    }}
                  >
                    Sous Chef
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          type_of_chef: e.target.textContent,
                        },
                      });
                      chefObjRaw.chef_details.type_of_chef = e.target.textContent;
                    }}
                  >
                    Head Chef
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          type_of_chef: e.target.textContent,
                        },
                      });
                      chefObjRaw.chef_details.type_of_chef = e.target.textContent;
                    }}
                  >
                    Executive Chef
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>

              <p className="redText xsmall removemargin alignTextLeft">
                {chefAlertTextInputsRaw.experience}
              </p>
            </div>

            <div className="flex-column align-column-left removemargin" style={{ gap: 10 }}>
              <p className="small removemargin" id="crrRef">
                Do you have any criminal record?
              </p>

              <MDBDropdown id="mdb-dropdown-custom-auth">
                <MDBDropdownToggle id="mdb-dropdown-custom-in-auth" color="light">
                  {chefObj?.chef_details?.criminal_record === true
                    ? "Yes"
                    : chefObj?.chef_details?.criminal_record === false
                    ? "No"
                    : "--"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="mdb-menu-custom-auth">
                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          criminal_record: e.target.textContent === "Yes" ? true : false,
                        },
                      });
                      chefObjRaw.chef_details.criminal_record =
                        e.target.textContent === "Yes" ? true : false;
                    }}
                  >
                    Yes
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    onClick={(e) => {
                      setChefObj({
                        ...chefObj,
                        chef_details: {
                          ...chefObj.chef_details,
                          criminal_record: e.target.textContent === "Yes" ? true : false,
                        },
                      });
                      chefObjRaw.chef_details.criminal_record =
                        e.target.textContent === "Yes" ? true : false;
                    }}
                  >
                    No
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>

              <p className="redText xsmall removemargin alignTextLeft">
                {chefAlertTextInputsRaw.criminalRecord}
              </p>
            </div>
          </div>

          <div className="loginbtn-cont">
            <MDBBtn
              disabled={false}
              style={{ width: "80%", cursor: "pointer", color: "black" }}
              onClick={signupAsChef}
              id="signupbtn-disable"
            >
              Sign Up
            </MDBBtn>
          </div>

          <p className="small removemargin">
            By signing up, you agree to our{" "}
            <span>
              <a href="/tac" target="_blank">
                Terms of Service.
              </a>
            </span>
          </p>
        </div>
      </MDBModalBody>
    </>
  );
});

export default SignupModal;
