import React, { useState, useEffect } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBBtn } from "mdb-react-ui-kit";

const PageSeven = function ({ currPage, setCurrPage, setMeal }) {
  const [selected, setSelected2] = useState([]);

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  useEffect(() => {
    if (selected.length>0) setDeActivateBtn(false);
    else setDeActivateBtn(true);
  }, [selected]);

  function hasItem(text){
    const index = selected.indexOf(text);
    return index!==-1
  }

  const addOrRemoveText = (text) => {
    if (selected.includes(text)) {
      // Text exists in the list, so remove it
      const updatedList = selected.filter(item => item !== text);
      setSelected2(updatedList);
      console.log(`Text "${text}" removed from the list.`);
    } else {
      // Text doesn't exist in the list, so add it
      const updatedList = [...selected, text];
      setSelected2(updatedList);
      console.log(`Text "${text}" added to the list.`);
    }
  };

  function setSelected(selected) {
    addOrRemoveText(selected)
  }


  if (currPage !== 7) return;

  return (
    <div className="pages-cont" data-page="7">
      <h4>Meal Type</h4>

      <div className="flex-row" style={{ gap: "40px" }}>
        <div
          onClick={() => {
            setSelected("breakfast");
            setMeal("breakfast");
          }}
          className={`div-image-text-cb ${hasItem("breakfast") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/breakfast.png" alt="iKook Chef At Home" />
          <p className="small">Breakfast</p>
        </div>

        <div
          onClick={() => {
            setSelected("dinner");
            setMeal("dinner");
          }}
          className={`div-image-text-cb ${hasItem("dinner") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/dinner.png" alt="iKook Meal Prep" />
          <p className="small">Dinner</p>
        </div>

        <div
          onClick={() => {
            setSelected("lunch");
            setMeal("lunch");
          }}
          className={`div-image-text-cb ${hasItem("lunch") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/lunch.png" alt="iKook Large Events" />
          <p className="small">Lunch</p>
        </div>

        <div
          onClick={() => {
            setSelected("all");
            setMeal("all");
          }}
          className={`div-image-text-cb ${hasItem("all") ? "active-nav-indicator" : ""}`}
        >
          <img style={{ width: 30 }} src="/images/all.png" alt="iKook Large Events" />
          <p className="small">All</p>
        </div>
      </div>

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageSeven;
