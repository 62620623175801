import React, { useContext, useEffect, useState } from "react";
import "../menu-card-small/menu-card-small.css";
import "../../styles/index.css";
import "./user-card-large-event.css";
import { AiTwotoneStar } from "react-icons/ai";
import AppContext from "../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";
import { GiCookingPot } from "react-icons/gi";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";

const UserCardLargeEvent = function ({ data }) {
  const [userImage, setUserImage] = useState("");
  const [rating, setRating] = useState(0);
  const [fromPrice, setFromPrice] = useState(0);

  const { getSignedAwsUrl, calculateChefRating, backendServer, apiKey, convertAmount } =
    useContext(AppContext);

  useEffect(() => {
    setRating(calculateChefRating(data.chef_details.reviews));

    return () => setRating(0);
  }, [data]);

  useEffect(() => {
    (async function () {
      if (data.photo) setUserImage(await getSignedAwsUrl(data.photo, "users"));
      else setUserImage("/images/user_placeholder.png");
    })();

    getChefStartingPrice();

    return () => setUserImage("/images/user_placeholder.png");
  }, [data]);

  const smallestArrayValue = function (array) {
    if (array.length === 0) return 0;

    let smallest = array[0];

    array.forEach((e) => {
      if (e < smallest) smallest = e;
    });

    return smallest;
  };

  async function getChefStartingPrice() {
    if (data.chef_details.chef_menus.length === 0) return setFromPrice("-");

    try {
      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get?chefID=${data?._id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const allMenuPrices = returned?.data?.map((e) => e.menuPrice);

      setFromPrice(smallestArrayValue(allMenuPrices) || 0);
    } catch (err) {}
  }

  return (
    <div
      onClick={() => (document.location.href = `/user/${data._id}`)}
      className="m-user-card-small-cont"
    >
      {userImage ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <img style={{ width: 150, height: 150, borderRadius: 150 }} src={userImage} alt="" />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      )}

      <div className="m-user-roww" style={{ marginTop: 15 }}>
        <h6 style={{ marginBottom: 0, fontWeight: "bold" }}>
          {data.first_name.slice(0, 15) + ` ${data.last_name.slice(0, 15)}`}
        </h6>

        <div className="menu-det-two" style={{ width: "fit-content" }}>
          <p className="small removemargin">
            <AiTwotoneStar color={rating > 0 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 1 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 2 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 3 ? "yellow" : "grey"} />
            <AiTwotoneStar color={rating > 4 ? "yellow" : "grey"} />
          </p>

          <p className="small removemargin">({data.chef_details.reviews.length} Reviews)</p>
        </div>
      </div>

      <div className="m-user-roww">
        <div className="m-user-roww-one">
          <div className="menu-det">
            <p className="small removemargin">
              <HiLocationMarker color="#f8c808" />{" "}
              {data?.chef_details?.address
                ? data?.chef_details?.address.slice(0, 15) + "..."
                : "nil"}
            </p>

            <p className="small removemargin">
              <GiCookingPot color="#f8c808" /> {data.chef_details.chef_menus.length}
              {data.chef_details.chef_menus.length === 0 ? "" : "+"} Cuisines
            </p>
          </div>
        </div>

        <div className="m-user-roww-two">
          <div className="menu-det-two">
            <p className="small removemargin">
              <BsFillCalendarDateFill color="#f8c808" /> Weekly
            </p>

            <p className="small removemargin">
              <FaUserFriends color="#f8c808" /> 10+ people
            </p>
          </div>
        </div>
      </div>

      <div className="m-user-roww-two" style={{ justifyContent: "flex-end", width: "100%" }}>
        <div className="menu-det-two">
          <p className="xsmall removemargin">From</p>

          <p className="big yellowText">NGN{convertAmount(fromPrice, true)}</p>
        </div>
      </div>
    </div>
  );
};

export default UserCardLargeEvent;
