import "../../../tailwind.css";
import React from 'react'

function Section({items, title}) {
  return (
    <div className=' flex flex-col gap-1 text-gray-800 text-start place-items-start place-content-start'>
     
     <div className="my-10 hr border-gray-300 stroke-gray-300 border h-[1px] w-full bg-gray-300"/>

        <div className='flex gap-2 place-content-center'>
        <img src="/images/list-item.png" className="w-6 h-6 object-contain" alt="iKooK" />

        <p className='text-xl font-bold text-primary-100'>{title}</p>

        </div>

        {items.map((e)=>{
            return e
        })}

    </div>
  )
}

export default Section