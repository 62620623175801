import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../menu-page/menu-page.css";
import "../../../../components/auth/login/login-modal.css";
import { MDBBtn, MDBInput, MDBFile } from "mdb-react-ui-kit";
import AppContext from "../../../../context/app-context";

const UserAccounts = function ({ activeNav }) {
  const [mobile, setMobile] = useState("");
  const [profilePic, setProfilePic] = useState("");

  const [disableBtn, setDisableBtn] = useState(true);
  const [placeholderImage, setPlaceholderImage] = useState("/images/img-placeholder.png");

  const mobileRef = useRef(null);

  const {
    backendServer,
    apiKey,
    setIsLoading,
    getSignedAwsUrl,
    setUsersPhoto,
    usersData,
    updateUsersData,
    popup,
  } = useContext(AppContext);

  useEffect(() => {
    if (!mobile && !profilePic) setDisableBtn(true);
    else setDisableBtn(false);
  }, [mobile, profilePic]);

  function handleFileChange(e) {
    setProfilePic(e.target.files[0]);

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (function (theFile) {
      return function (e) {
        setPlaceholderImage(e.target.result);
      };
    })(file);

    reader.readAsDataURL(file);
  }

  async function handleUpdate() {
    try {
      if (mobile) {
        setIsLoading(true);

        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/update/${usersData._id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({ mobile: mobile }),
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Your mobile has been updated!");

        updateUsersData();

        setIsLoading(false);
      }

      if (profilePic) {
        setIsLoading(true);

        const form = new FormData();

        form.append("photo", profilePic);

        const returned = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/user/profile/pic/update/${usersData.username}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup("Your profile picture has been updated!");

        const data = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/user/get/${localStorage.getItem(
              "ikookusername"
            )}`,
            {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            }
          )
        ).json();

        if (data.data.photo) setUsersPhoto(await getSignedAwsUrl(data.data.photo, "users"));
        else setUsersPhoto("/images/user_placeholder.png");

        setIsLoading(false);
      }

      mobileRef.current.value = "";
    } catch (err) {
      popup("An error occured while updating your profile");
      setIsLoading(false);
    }
  }

  if (activeNav !== "account") return;

  return (
    <div className="cd-box-1-in-acc ud-accounts-cont">
      <div className="flex-row align-row-left flex-gap">
        <MDBInput disabled={true} className="ac-inputs" label={usersData.first_name} type="text" />

        <MDBInput disabled={true} className="ac-inputs" label={usersData.last_name} type="text" />
      </div>

      <div className="flex-row align-row-left flex-gap">
        <MDBInput disabled={true} className="ac-inputs" label={usersData.username} type="text" />

        <MDBInput disabled={true} className="ac-inputs" label={usersData.email} type="email" />
      </div>

      <div className="flex-row align-row-left flex-gap" style={{ alignItems: "flex-start" }}>
        <MDBInput
          ref={mobileRef}
          onChange={(e) => setMobile(e.target.value)}
          className="ac-inputs"
          label={usersData.mobile || "Mobile"}
          type="text"
        />
      </div>

      <div className="flex-row flex-gap align-row-left" style={{ alignItems: "flex-start" }}>
        <div
          className="chef-details-boxes-settings flex-col align-column-left ud-media-chef-details-boxes-settings"
          style={{ height: "250px", gap: 15 }}
        >
          <h5 className="greyText">PROFILE PICTURE</h5>

          <MDBFile
            id="ud-file-in"
            accept="image/png,image/jpeg,image/jpg"
            onChange={handleFileChange}
            placeholder="Attach Menu Image"
          />
        </div>

        <img style={{ width: "200px", height: "200px" }} src={placeholderImage} alt="user" />
      </div>

      <div className="flex-row">
        <MDBBtn
          onClick={handleUpdate}
          style={{ backgroundColor: "#fcc01c", color: "black" }}
          disabled={disableBtn}
          className="small themeBtn"
          id="signupbtn-disable"
        >
          Save Changes
        </MDBBtn>
      </div>
    </div>
  );
};

export default UserAccounts;
