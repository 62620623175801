import "./why-ikook.css";
import Card from "./card";
import cardData from "./cardData";

const WhyIkook = () => {
  return (
    <div className="why-ikook-container">
      <p className="why-ikook-heading">Why iKooK?</p>
      <div className="why-ikook-cards-container">
        {cardData.map((data, i) => {
          return <Card key={i} data={data} />;
        })}
      </div>
    </div>
  );
};

export default WhyIkook;
