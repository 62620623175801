import React from "react";
import "../../../../../menu-page/menu-page.css";
import "../../../../../../styles/index.css";

import { MDBFile } from "mdb-react-ui-kit";

const IDUpload = function ({ setIdImage }) {
  return (
    <div className="flex-row flex-gap" style={{ alignItems: "flex-start" }}>
      <div
        className="chef-details-boxes-settings flex-col align-column-left"
        style={{ height: "250px", gap: 15 }}
      >
        <h5 className="greyText removemargin">UPLOAD ID</h5>

        <p className="xsmall removemargin greyText">
          (JPEG/PNG. Ensure your ID is clear and readable) Upload a government ID. Driver's License
          or an International Passport.
        </p>

        <MDBFile
          accept="image/jpg,image/png,image/jpeg"
          onChange={(e) => setIdImage(e.target.files[0])}
          style={{ width: "350px" }}
          placeholder="Attach Your ID"
        />
      </div>
    </div>
  );
};

export default IDUpload;
