import React, { useState, useContext } from "react";
import "../../pages/menu-page/menu-page.css";
import "../../pages/meal-prep-menu-page/meal-prep-menu-page.css";
import { MDBCheckbox } from "mdb-react-ui-kit";
import "../../pages/home/home.css";
import AppContext from "../../context/app-context";
import "../../styles/index.css";
import { usePlacesWidget } from "react-google-autocomplete";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import country from "../../context/country";
import restrictCountry from "../../context/country-restrictions";
import ExtensiveMealPrepDay from "./ExtensiveMealPrepDay/extensive-meal-prep-day";
import { FiAlertTriangle } from "react-icons/fi";

const MealPrepMenuDetails = function ({
  activeNav,
  allTheUsersMenus,
  setMenusSelected,
  setnumberOfAdults,
  setnumberOfKids,
  setnumberOfTeens,
  setEventEndDate,
  setEventStartDate,
  setEventTime,
  setBudgetPerPerson,
  setExperience,
  setDeliverType,
  setDeliveryDays,
  delieryDays,
  setNumberofWeeks,
  fullAddress,
  city,
  postalCode,
  setFullAddress,
  setCity,
  setPostalCode,
  sortOutMenuSelected,
  numberOfWeeksWarning,
  addressWarning,
  experienceWarning,
  eventDateWarning,
  eventTimeWarning,
  numberOfGuestsWarning,
  deliveryTypeWarning,
  chefAppearanceWarning,
  menuWarning,
  budgetPerGuestsWarning,
  menuThereInWarning,
  removeMenusByDay,
  numberOfKids,
  numberOfAdults,
  numberOfTeens,
  eventStartDate,
  eventEndDate,
  eventTime,
  budgetPerPerson,
  numberOfWeeks,
}) {
  const [revealLocationSettings, setRevealLocationSetting] = useState(false);

  const [selectedDelivery, setSelectedDelivery] = useState("");

  const [selectedExperience, setSelectedExperience] = useState("");

  const [allDaysToShow, setAllDaysToShow] = useState([]);

  const { googleMapsApiKey, edition } = useContext(AppContext);

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setFullAddress(place?.formatted_address);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: restrictCountry },
    },
  });

  function updateShowingDeliveryDaysExtensiveComponent(daysArr) {
    const comp = daysArr.map((e, i) => (
      <ExtensiveMealPrepDay
        key={i}
        day={e}
        allTheUsersMenus={allTheUsersMenus}
        setMenusSelected={setMenusSelected}
        sortOutMenuSelected={sortOutMenuSelected}
        delieryDays={delieryDays}
      />
    ));
    setAllDaysToShow(comp);
  }

  function handleDeliveryDaysChanges(e) {
    if (delieryDays.includes(e.target.value)) {
      removeMenusByDay(e.target.value);
      const newArr = [...delieryDays].filter((el) => el !== e.target.value);
      setDeliveryDays(newArr);
      updateShowingDeliveryDaysExtensiveComponent(newArr);
    } else {
      const newArr = [...delieryDays, e.target.value];
      setDeliveryDays(newArr);
      updateShowingDeliveryDaysExtensiveComponent(newArr);
    }
  }

  if (activeNav !== "menu") return;

  return (
    <div className="box-1-in">
      <p className="small boldText removemargin mppp-header-first" id="numberOfWeeksWarningRef">
        MEAL PREP DETAILS
      </p>

      <input
        value={numberOfWeeks}
        min="1"
        onChange={(e) => setNumberofWeeks(Number(e.target.value))}
        className="meal-prep-inputs"
        type="number"
        placeholder="Number of Weeks"
      />

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {numberOfWeeksWarning}
      </p>

      <div className="local-btn-join" id="addressWarningRef">
        <input
          disabled
          value={`${fullAddress} - ${city} - ${postalCode ? postalCode + "." : ""}`}
          className="local-btn-in"
          type="text"
          placeholder="Your Address"
        />

        <button id="change_address_page"
          onClick={() => setRevealLocationSetting(!revealLocationSettings)}
          className="local-btn-btn"
        >
          Change Address{" "}
          {revealLocationSettings ? (
            <AiOutlineCaretUp className="cursor" size={15} />
          ) : (
            <AiOutlineCaretDown className="cursor" size={15} />
          )}
        </button>
      </div>

      <p
        className="small redText removemargin alignTextLeft"
        style={{ marginTop: -20, marginBottom: -20 }}
      >
        {addressWarning}
      </p>

      <div className={`local-btn-join-col ${!revealLocationSettings && "meal-prep-hide"}`}>
        <input
          ref={ref}
          onChange={(e) => {
            setFullAddress(e.target.value);
          }}
          className="meal-prep-inputs"
          type="text"
          placeholder="Full Address"
        />

        <input
          onChange={(e) => {
            setCity(e.target.value);
          }}
          className="meal-prep-inputs"
          type="text"
          placeholder="Your City"
        />

        <input
          onChange={(e) => {
            setPostalCode(e.target.value);
          }}
          className="meal-prep-inputs"
          type="text"
          placeholder="Postal Code"
        />

        <input disabled className="meal-prep-inputs" type="text" placeholder={country} />
      </div>

      <div
        id="experienceWarningRef"
        className="flex-row align-row-left"
        style={{ gap: "30px", width: "100%" }}
      >
        <div
          style={{ width: "45%" }}
          onClick={() => {
            setSelectedExperience("one-time");
            setExperience("one-time");
          }}
          className={`div-image-text-menu-page ${
            selectedExperience === "one-time" ? "active-platter-indactor" : ""
          }`}
        >
          <p className="small removemargin blackText">One-Time Experience</p>
        </div>

        <div
          style={{ width: "45%" }}
          onClick={() => {
            setSelectedExperience("multiple");
            setExperience("multiple");
          }}
          className={`div-image-text-menu-page ${
            selectedExperience === "multiple" ? "active-platter-indactor" : ""
          }`}
        >
          <p className="small removemargin blackText">Multiple Experience</p>
        </div>
      </div>

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {experienceWarning}
      </p>

      <div className="start-date-end-join" id="eventDateWarningRef">
        <div className="flex-column align-column-left removemargin" style={{ gap: 10 }}>
          <p className="small removemargin blackText" style={{ marginBottom: -10 }}>
            Start Date
          </p>

          <input
            value={eventStartDate}
            onChange={(e) => setEventStartDate(e.target.value)}
            className="meal-prep-inputs date-inputs-edit"
            type="date"
            placeholder="Start Date"
          />
        </div>

        <div className="flex-column align-column-left removemargin" style={{ gap: 10 }}>
          <p className="small removemargin blackText" style={{ marginBottom: -10 }}>
            End Date
          </p>

          <input
            value={eventEndDate}
            onChange={(e) => setEventEndDate(e.target.value)}
            className="meal-prep-inputs date-inputs-edit"
            type="date"
            placeholder="End Date"
          />
        </div>
      </div>

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {eventDateWarning}
      </p>

      <div
        className="flex-column align-column-left removemargin"
        style={{ gap: 10 }}
        id="eventTimeWarningRef"
      >
        <p className="small removemargin blackText" style={{ marginBottom: -10 }}>
          Daily Start Time
        </p>

        <input
          value={eventTime}
          onChange={(e) => setEventTime(e.target.value)}
          className="meal-prep-inputs date-inputs-edit"
          type="time"
          placeholder="Weekly Time"
        />
      </div>

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {eventTimeWarning}
      </p>

      <p className="small boldText removemargin" style={{ marginBottom: -10 }}>
        BUDGET
      </p>

      <p style={{ width: "90%" }} className="small removemargin alignTextLeft greyText">
        Let us know your budget This price includes: ingredients (excl drinks), shopping, transport,
        preparation, service and cleaning up.
      </p>

      <p
        id="numberOfGuestsWarningRef"
        className="small blackText removemargin"
        style={{ marginBottom: -20 }}
      >
        Number of Guests
      </p>

      <div className="number-of-guests-join">
        <div className="flex-column align-column-left removemargin">
          <input
            value={numberOfKids}
            min="0"
            onChange={(e) => setnumberOfKids(Number(e.target.value))}
            className="meal-prep-inputs"
            type="number"
            placeholder="Number of Children"
            title="Number of Children"
          />
        </div>
        <div className="flex-column align-column-left removemargin">
          <input
            value={numberOfTeens}
            min="0"
            onChange={(e) => setnumberOfTeens(Number(e.target.value))}
            className="meal-prep-inputs"
            type="number"
            placeholder="Number of Teens"
            title="Number of Teens"
          />
        </div>
        <div className="flex-column align-column-left removemargin">
          <input
            value={numberOfAdults}
            min="0"
            onChange={(e) => setnumberOfAdults(Number(e.target.value))}
            className="meal-prep-inputs"
            type="number"
            placeholder="Number of Adults"
            title="Number of Adults"
          />
        </div>
      </div>

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {numberOfGuestsWarning}
      </p>

      <p
        id="budgetPerPerson"
        className="small blackText removemargin"
        style={{ marginBottom: -20, marginTop: -20 }}
      >
        Budget Per Guests ({edition})
      </p>

      <input
        value={budgetPerPerson}
        min="1"
        onChange={(e) => setBudgetPerPerson(e.target.value)}
        className="meal-prep-inputs"
        type="number"
        placeholder={`Budget per Guest`}
      />

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {budgetPerGuestsWarning}
      </p>

      <p
        id="deliveryTypeWarningRef"
        className="small boldText removemargin"
        style={{ marginBottom: -10 }}
      >
        DELIVERY
      </p>

      <div className="flex-row align-row-left" style={{ gap: "30px", width: "100%" }}>
        <div
          style={{ width: "45%" }}
          onClick={() => {
            setSelectedDelivery("Physical");
            setDeliverType("physical");
          }}
          className={`div-image-text-menu-page ${
            selectedDelivery === "Physical" ? "active-platter-indactor" : ""
          }`}
        >
          <p className="small removemargin blackText">Physical</p>
        </div>

        <div
          style={{ width: "45%" }}
          onClick={() => {
            setSelectedDelivery("Gourmet");
            setDeliverType("gourmet");
          }}
          className={`div-image-text-menu-page ${
            selectedDelivery === "Gourmet" ? "active-platter-indactor" : ""
          }`}
        >
          <p className="small removemargin blackText">Gourmet</p>
        </div>
      </div>

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {deliveryTypeWarning}
      </p>

      <p className="small boldText removemargin" id="chefAppearanceWarningRef">
        Select Chef Apperances/Delivery days
      </p>

      <div className="home-check-boxes mppp-check-boxes">
        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Monday"
          label="Monday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />

        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Tuesday"
          label="Tuesday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />

        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Wednesday"
          label="Wednesday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />

        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Thursday"
          label="Thursday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />

        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Friday"
          label="Friday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />

        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Saturday"
          label="Saturday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />

        <MDBCheckbox
          onChange={handleDeliveryDaysChanges}
          name="inlineCheck"
          id="inlineCheckbox3"
          className="check-box-class"
          wrapperClass="border-chekbox"
          value="Sunday"
          label="Sunday"
          inline
          labelStyle={{ marginTop: 2.5 }}
        />
      </div>

      <p className="small redText removemargin alignTextLeft" style={{ marginTop: -15 }}>
        {chefAppearanceWarning}
      </p>

      <p id="menuWarningRef" className="small boldText removemargin" style={{ marginBottom: -10 }}>
        CHOOSE YOUR WEEKLY MENU(s)
      </p>

      <p
        className="small redText removemargin alignTextLeft"
        style={{ marginTop: -15, marginBottom: -20 }}
      >
        {menuWarning}
      </p>

      <p
        className="small redText removemargin alignTextLeft"
        style={{ marginTop: -15, marginBottom: -20 }}
      >
        {menuThereInWarning}
      </p>

      <p className="small greyText removemargin alignTextLeft">
        Let us know what meal you’ll like to have at your house.
      </p>

      {allDaysToShow.length === 0 ? (
        <p className="small alignTextLeft yellowText">
          <FiAlertTriangle /> Select Chef Apperances/Delivery days to set Menu(s)
        </p>
      ) : (
        ""
      )}

      {allDaysToShow}
    </div>
  );
};

export default MealPrepMenuDetails;
