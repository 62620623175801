import React from "react";
import "../../../../../menu-page/menu-page.css"
import "../../../../../../styles/index.css"

import {
  MDBFile
} from 'mdb-react-ui-kit';



const ChefLicenceUpload = function ({ setChefLicence }) {


  return (
    <div className="flex-row flex-gap" style={{ alignItems: 'flex-start' }}>

      <div className="chef-details-boxes-settings flex-col align-column-left" style={{ height: "250px", gap: 15 }}>

        <h5 className="greyText removemargin">UPLOAD CHEF CERTIFICATE</h5>

        <p className="xsmall removemargin greyText">(PDF, doc, docx)</p>

        <MDBFile accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setChefLicence(e.target.files[0])} style={{ width: "350px" }} placeholder="Attach Your CV" />

      </div>

    </div>
  )
}

export default ChefLicenceUpload