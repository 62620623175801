import React, { useEffect, useState, useContext } from "react";
import "../../../menu-page/menu-page.css";
import AppContext from "../../../../context/app-context";
import "../../styles/user-index.css";
import { MdDinnerDining, MdFreeBreakfast, MdLunchDining } from "react-icons/md";

const ChefAtHomeMenuDetails = function ({ activeNav, data }) {
  const [menusComp, setMenusComp] = useState([]);
  const [menuName, setMenuName] = useState("");

  const [menuCurr, setMenuCurr] = useState("");
  const [chefCurr, setChefCurr] = useState("");

  const { backendServer, apiKey, setIsLoading, getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    if (!data) return;
    if (data?.booking_type === "meal-prep") return;
    (async function () {
      setIsLoading(true);

      //fetch menu

      const menu = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${data.menuID}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setMenuName(menu.data.menuName);
      setMenuCurr(menu?.data?.currency);
    })();
  }, [data]);

  useEffect(() => {
    (async function () {
      if (!data?.chef) return;

      const chef = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${data?.chef}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      setChefCurr(chef?.data?.country);
    })();
  }, [data]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      if (data?.booking_type === "meal-prep") {
        const comps = data.addons.map(async (e, i) => {
          return (
            <div
              key={i}
              className="an-extra-menu-card an-extra-menu-card-active ud-dash-extra-menu-card"
            >
              <div className="extra-menu-one ud-dash-extra-menu-card">
                <p className="small removemargin alignTextLeft">
                  {e?.description?.slice(0, 60)}...
                </p>
              </div>
              <div
                className="extra-menu-two media-mp-extra-menu-two"
                style={{ alignItems: "flex-end", width: "30%" }}
              >
                {e?.type === "Breakfast" ? (
                  <MdFreeBreakfast />
                ) : e?.type === "Lunch" ? (
                  <MdLunchDining />
                ) : (
                  <MdDinnerDining />
                )}
                <p className="xsmall removemargin" style={{ textAlign: "right" }}>
                  {e?.day} - {e?.type}
                </p>
              </div>
            </div>
          );
        });
        setMenusComp(await Promise.all(comps));
      } else {
        const comps = data.addons.map(async (e, i) => {
          return (
            <div
              key={i}
              className="an-extra-menu-card an-extra-menu-card-active ud-dash-extra-menu-card"
            >
              <div className="extra-menu-one ud-dash-extra-menu-card">
                <p className="small removemargin alignTextLeft">{e.description.slice(0, 60)}...</p>
              </div>
            </div>
          );
        });
        setMenusComp(await Promise.all(comps));
      }

      setIsLoading(false);
    })();
  }, [data, menuCurr, chefCurr]);

  if (activeNav !== "menu") return;

  return (
    <div className="cd-box-1-in">
      <div className="flex-column align-column-left">
        <p className="small alignTextLeft">
          Listed below are the menus for this {data?.booking_type} booking.
        </p>
      </div>

      {data?.booking_type === "meal-prep" ? (
        ""
      ) : (
        <div className="starter">
          <div className="menu-page-nav-cont media-no-bottom-border">
            <p className={`big blackText boldText`}>Menu</p>
          </div>

          <p className="small">{menuName}</p>
        </div>
      )}

      <div className="starter">
        <div className="menu-page-nav-cont media-no-bottom-border">
          <p className={`big blackText boldText alignTextLeft`}>Menus/Addons Selected</p>
        </div>

        {menusComp}
      </div>

      {data?.booking_type === "chef-at-home" && (
        <div className="starter">
          <div className="menu-page-nav-cont media-no-bottom-border">
            <p className="big blackText boldText">Service Type</p>
          </div>

          <div className="flex-row align-row-left" style={{ gap: "30px", width: "100%" }}>
            <div className={`div-image-text-menu-page div-image-text-menu-page-edit`}>
              <p className="small removemargin">{data.booking_option}</p>
            </div>
          </div>
        </div>
      )}

      {data?.booking_type !== "meal-prep" ? (
        ""
      ) : (
        <div className="cd-box-1-in">
          <div className="starter">
            <div className="menu-page-nav-cont media-no-bottom-border">
              <p className="big blackText boldText">Experience</p>
            </div>

            <div className="flex-row align-row-left" style={{ gap: "30px", width: "100%" }}>
              <div className={`div-image-text-menu-page div-image-text-menu-page-edit`}>
                <p className="small removemargin">{data?.meal_prep_option}</p>
              </div>
            </div>
          </div>

          <div className="starter">
            <div className="menu-page-nav-cont media-no-bottom-border">
              <p className="big blackText boldText">Delivery Type</p>
            </div>

            <div className="flex-row align-row-left" style={{ gap: "30px", width: "100%" }}>
              <div className={`div-image-text-menu-page div-image-text-menu-page-edit`}>
                <p className="small removemargin">{data?.delivery_type}</p>
              </div>
            </div>
          </div>

          <div className="starter">
            <div className="menu-page-nav-cont media-no-bottom-border">
              <p className="big blackText boldText">Days of the Week</p>
            </div>

            <p className="small">{data?.delivery_days.join(" | ")}</p>
          </div>
        </div>
      )}

      <div className="starter">
        <div className="menu-page-nav-cont media-no-bottom-border">
          <p className="big blackText boldText">Dietary Restrictions</p>
        </div>

        <p className="small alignTextLeft">
          {data.dietary_restrictions.length === 0 ? "nil" : data.dietary_restrictions.join(", ")}
        </p>
      </div>

      <div className="starter">
        <div className="menu-page-nav-cont media-no-bottom-border">
          <p className="big blackText boldText">Order Message</p>
        </div>

        <p style={{ paddingRight: 15 }} className="small">
          {data.other_info}
        </p>
      </div>
    </div>
  );
};

export default ChefAtHomeMenuDetails;
