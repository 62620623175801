import React, { useState, useContext, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBInput,
  MDBModalBody,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../styles/index.css";
import "../../pages/user-dashboard/styles/user-index.css";
import "../auth/login/login-modal.css";
import AppContext from "../../context/app-context";

const AddAFAQModal = ({ display, setShowFaQModal }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [hideBtn, setHideBtn] = useState(true);

  const { backendServer, apiKey, setIsLoading, popup, usersData, updateUsersData } =
    useContext(AppContext);

  useEffect(() => {
    if (question && answer) setHideBtn(false);
    else setHideBtn(true);
  }, [question, answer]);

  async function setFaq() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/profile/update/${usersData._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({ chef_faq: [{ question: question, answer: answer }] }),
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your FAQ has been posted!");

      setShowFaQModal(false);

      setIsLoading(false);

      updateUsersData();
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowFaQModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <h4 className="removemargin">Add a FAQ</h4>
            </MDBModalHeader>

            <MDBModalBody>
              <div className="flex-column" style={{ marginBottom: 30, justifyContent: "center" }}>
                <MDBInput
                  onChange={(e) => setQuestion(e.target.value)}
                  style={{ width: 500 }}
                  maxLength={50}
                  label="FAQ Question"
                  type="text"
                />
              </div>

              <MDBTextArea
                onChange={(e) => setAnswer(e.target.value)}
                label="FAQ Answer"
                type="text"
                maxLength={200}
                rows={5}
              />

              <MDBBtn
                style={{
                  width: "80%",
                  cursor: "pointer",
                  marginTop: 40,
                  backgroundColor: "#fcc01c",
                  color: "black",
                }}
                disabled={hideBtn}
                onClick={setFaq}
                id="signupbtn-disable"
              >
                Confirm FAQ
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AddAFAQModal;
