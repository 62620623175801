import axios from "axios";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { fireStoreDB } from "./firebase";


export async function registerUsers(form,combined){
        
    const { firstName, email, service, phone,  } = form;
    if (firstName != null && email != null && combined != null && phone != null ) {

        const collec = doc(fireStoreDB, "ikookWaitList",email)
        const docRef = await getDoc(collec);
        
        if (docRef.exists()) {
          alert("user already in Wait List")
          return  {status:false,message:"user already registered"};
        }
        else {
          const date = new Date()
          const seatNo = await getSeatNo() + 100
          const ticketNo = "IkookWL" + seatNo + firstName.slice(-2).toUpperCase();
  
          const newBody = { ...form, selectedServices:combined, "date": date, "waitNo": seatNo, "registrationNo": ticketNo }
          console.log(newBody)
  
          try {
            const docRef = await setDoc(collec,newBody)
  
            return await axios.post('https://api.emailjs.com/api/v1.0/email/send', {
              "service_id": "service_surk8pu",
              "template_id": "template_hzp95jh",
              "user_id": "user_cozVpqgMHDZyInQOCOYCD",
              "template_params": {
                "name": firstName,
                "email": email,
                "ticket": ticketNo,
                "seat": seatNo,
                "from_name": "Ikook"
              }
            }
            )
              .then(function (response) {
                return {"status":true,"message":"Registration Successfull"};
              })
              .catch(function (error) {
                console.log(error)
                return  {"status":false,"message":"error occured"};
              });
  
  
          } catch (e) {
            console.error("Error adding document: ", e);
            return  {"status":false,"message":"Error creating reservations"};
          }
        }
      }
      else {
        return  {"status":false,"message":"Invalid inputs, check your form again"};
      }
  
  
    }

async function getSeatNo() {
    const collec = collection(fireStoreDB,'nlic2023')
    const snapshot = await getDocs(collec)
    return snapshot.size?snapshot.size:0;
  }