import React, { useState, useEffect, useContext } from "react";
import "../cb.css";
import "../../../styles/index.css";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import AppContext from "../../../context/app-context";
import { usePlacesWidget } from "react-google-autocomplete";
import '../../../tailwind.css'
import restrictCountry from "../../../context/country-restrictions";
import country from "../../../context/country";

const PageTwo = function ({ currPage, setCurrPage, setFetchLocation, setCustomBookingCity }) {
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");

  const { googleMapsApiKey } = useContext(AppContext);

  const [deactivateBtn, setDeActivateBtn] = useState(true);

  let { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
      setFetchLocation(place.formatted_address);
    },
    options: {
      componentRestrictions: { country: restrictCountry },
      types: ["address"],
    },
  });

  useEffect(() => {
    if (location && city) setDeActivateBtn(false);
    else setDeActivateBtn(true);
    return () => {
      ref = null;
    }
  }, [location, city]);

  

  const findMe = async function () {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const data = await (
        await fetch(
          `https://geocode.xyz/${pos.coords.latitude},${pos.coords.longitude}?auth=497013854179196392715x23250&json=1`
        )
      ).json();

      setLocation(`${data.staddress}, ${data.region}, ${data.city}; ${data.country}`);

      setFetchLocation(`${data.staddress}, ${data.region}, ${data.city}; ${data.country}`);
    });
  };

  if (currPage !== 15) return;

  return (
    <div className="pages-cont" data-page="2">
      <img style={{ width: 30 }} src="/images/location.png" alt="iKook chef" />

      <MDBBtn onClick={findMe} color="#ffd93b" className="small themeBtn">
        Find Me
      </MDBBtn>

      <MDBInput
        value={location}
        onChange={(el) => {
          setLocation(el.target.value);
          setFetchLocation(el.target.value);
        }}
        className="cb-inputs shadow-none drop-shadow-none"
        label="Full Address"
        type="text"
        style={{ width: 300 }}
      />

      <MDBInput
        value={city}
        onChange={(el) => {
          setCity(el.target.value);
          setCustomBookingCity(el.target.value);
        }}
        className="cb-inputs"
        label="City"
        type="text"
        style={{ width: 300 }}
      />

      <MDBInput
        id="stopDisabled"
        defaultValue={
          country === "UK" ? "United Kingdom" : country === "Canada" ? country : "Nigeria"
        }
        className="cb-inputs"
        label={"Country"}
        type="text"
        style={{ width: 300 }}
        disabled={true}
      />

      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black" }}
        disabled={deactivateBtn}
        onClick={() => setCurrPage(currPage + 1)}
      >
        Continue
      </MDBBtn>
    </div>
  );
};

export default PageTwo;
