import React, { useState, useRef, useEffect } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import "../../menu-page/menu-page.css";
import UserAccounts from "../components/child-components/cd-account";
import UserNotificationSettings from "../components/child-components/cd-noti-settings";
import UserPassword from "../components/child-components/cd-password";

const UdSettings = function ({ display }) {
  const [activeNav, setActiveNav] = useState("account");

  const settingsContRef = useRef(null);

  useEffect(() => {
    if (!settingsContRef?.current?.style) return;

    if (navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500) {
      settingsContRef.current.style = `height: ${window?.screen?.availHeight - 160}px;`;
    }
  });

  if (!display) return;

  return (
    <div ref={settingsContRef} className="cd-box-1-settings media-cd-help-cont">
      <div className="menu-page-nav-cont ud-menu-page-nav-cont">
        <p
          onClick={() => {
            setActiveNav("account");
          }}
          className={`blackText boldText each-menu-page-nav ${
            activeNav === "account" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Account
        </p>

        {/* <p onClick={() => {
          setActiveNav("password")
        }} className={`blackText boldText each-menu-page-nav ${activeNav === "password" ? "each-menu-page-nav-active" : ""}`}>Password</p> */}

        <p
          onClick={() => {
            setActiveNav("notifications");
          }}
          className={`blackText boldText each-menu-page-nav ${
            activeNav === "notifications" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Notifications
        </p>
      </div>

      <UserAccounts activeNav={activeNav} />

      <UserNotificationSettings activeNav={activeNav} />

      <UserPassword activeNav={activeNav} />
    </div>
  );
};

export default UdSettings;
