import { Button, Popconfirm } from "antd";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import "../../styles/index.css";
import "./what-is-it-collection.css";

const SearchTagWhatIsThis = () => (
  <>
    <div>
      <Popconfirm
        placement="top"
        title={"Search Tags"}
        description={
          "Search tags or menu keywords are used to populate your menu(s) in the right search results to improve menu performance, and, consequently, improve sales. You can select up to 3 keywords that fits your menu. N.B. The better the keyword, the better the menu performance."
        }
        onConfirm={() => {}}
        okText=""
        cancelText="Cancel"
        okType="ghost"
        cancelButtonProps={{ type: "text" }}
        overlayStyle={{ maxWidth: 350 }}
      >
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <p className="small removemargin greyText italics">e.g. pizza, rice</p>

          <Button className="btnStyle" type="text" title="Learn More">
            <AiOutlineQuestionCircle color="grey" size={17} />
          </Button>
        </div>
      </Popconfirm>
    </div>
  </>
);

const EventTypeWhatIsThis = () => (
  <>
    <div>
      <Popconfirm
        placement="top"
        title={"Event Type"}
        description={
          "Select at most three types of event you are typically available for. This would help potential client in their decision to select you as their chef for their upcoming event."
        }
        onConfirm={() => {}}
        okText=""
        cancelText="Cancel"
        okType="ghost"
        cancelButtonProps={{ type: "text" }}
        overlayStyle={{ maxWidth: 350 }}
      >
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <p className="small removemargin greyText italics">e.g. birthdays, weddings</p>

          <Button className="btnStyle" type="text" title="Learn More">
            <AiOutlineQuestionCircle color="grey" size={17} />
          </Button>
        </div>
      </Popconfirm>
    </div>
  </>
);

const CuisingTypeWhatIsThis = () => (
  <>
    <div>
      <Popconfirm
        placement="top"
        title={"Cuisine Type"}
        description={
          "With cuisines, you get to set the foods and possible methods of preparation that are typical to the menu region."
        }
        onConfirm={() => {}}
        okText=""
        cancelText="Cancel"
        okType="ghost"
        cancelButtonProps={{ type: "text" }}
        overlayStyle={{ maxWidth: 350 }}
      >
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <p className="small removemargin greyText italics">e.g. local, french</p>

          <Button className="btnStyle" type="text" title="Learn More">
            <AiOutlineQuestionCircle color="grey" size={17} />
          </Button>
        </div>
      </Popconfirm>
    </div>
  </>
);

const MenuDishesWhatIsThis = ({ title }) => (
  <>
    <div>
      <Popconfirm
        placement="top"
        title={"Menu Info"}
        description={
          <p>
            Here is an example dish to help you understand the information we’re looking for:
            <br></br>
            <br></br>Dish name: Queso dip with tortilla chips<br></br>
            <br></br>Dish description: A creamy and cheesy dip made with melted cheese, often
            cheddar or Monterey Jack, blended with spices and served with crispy tortilla chips.
            Perfect for parties and gatherings.<br></br>
            <br></br>Dish ingredients: Cheese (cheddar, Monterey Jack, or a combination), milk,
            diced tomatoes with green chilies, jalapenos (optional), onion, garlic, cumin, paprika,
            salt, pepper, tortilla chips.
          </p>
        }
        onConfirm={() => {}}
        okText=""
        cancelText="Cancel"
        okType="ghost"
        cancelButtonProps={{ type: "text" }}
        overlayStyle={{ maxWidth: 350 }}
      >
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <p className="small removemargin" style={{ color: "#fcc01c" }}>
            {title}
          </p>

          <Button className="btnStyle-no-border" type="text" title="Learn More">
            <AiOutlineQuestionCircle color="#fcc01c" size={17} />
          </Button>
        </div>
      </Popconfirm>
    </div>
  </>
);

const MenuBounderyNumberOfMenuWhatIsThis = ({ title }) => (
  <>
    <div>
      <Popconfirm
        placement="top"
        title={"Menu Info"}
        description={
          <span>
            This field lets you set the minimum number of dish/course that users must select from
            the menu. Users will need to choose at least this many dish/course in order to proceed
            with their booking.<br></br>
            <br></br>For example, Let's say the minimum starters required is set to 3. If a user
            selects fewer than 3 starters, they will be prompted to select additional starters until
            the minimum requirement is met before they can proceed with the booking.
          </span>
        }
        onConfirm={() => {}}
        okText=""
        cancelText="Cancel"
        okType="ghost"
        cancelButtonProps={{ type: "text" }}
        overlayStyle={{ maxWidth: 350 }}
      >
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <p className="small boldText removemargin">{title}</p>

          <Button className="btnStyle-no-border" type="text" title="Learn More">
            <AiOutlineQuestionCircle color="#fcc01c" size={17} />
          </Button>
        </div>
      </Popconfirm>
    </div>
  </>
);

const MenuAdditionalChargeWhatIsThis = ({ title }) => (
  <>
    <div>
      <Popconfirm
        placement="top"
        title={"Menu Info"}
        description={
          <span>
            This field lets you set the extra charge per additional dish selected by customer. If a
            customer chooses more course meal than the minimum requirement, they will be charged the
            amount specified for each additional course meal.<br></br>
            <br></br>For example, if the Additional Starter Charge is set to $3 and the Minimum
            Starters Required is 2, selecting 4 starters would result in an extra charge of $6 ($3
            per additional starter x 2 additional starters).
          </span>
        }
        onConfirm={() => {}}
        okText=""
        cancelText="Cancel"
        okType="ghost"
        cancelButtonProps={{ type: "text" }}
        overlayStyle={{ maxWidth: 350 }}
      >
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <p className="small boldText removemargin">{title}</p>

          <Button className="btnStyle-no-border" type="text" title="Learn More">
            <AiOutlineQuestionCircle color="#fcc01c" size={17} />
          </Button>
        </div>
      </Popconfirm>
    </div>
  </>
);

export {
  SearchTagWhatIsThis,
  EventTypeWhatIsThis,
  CuisingTypeWhatIsThis,
  MenuDishesWhatIsThis,
  MenuAdditionalChargeWhatIsThis,
  MenuBounderyNumberOfMenuWhatIsThis,
};
