import React, { useContext, useState, useEffect, useRef } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import DashboardInit from "../components/cd-dashboard-init";
import ChefAtHomeBookingDetails from "../components/cd-chef-at-home";
import CustomBookingDetails from "../components/cd-custom-booking";
import SeeProposals from "../components/cd-proposals";
import AppContext from "../../../context/app-context";

const UdDashboard = function ({ display, setDashboardFunctions }) {
  const [onView, setOnView] = useState("dashboard init");

  const [bookingDetailsData, setBookingDetailsData] = useState({});

  const [proposalDetailsData, setProposalDetailsData] = useState({});

  const [customBookingDetails, setCustomBookingDetails] = useState({});

  const { usersData, loggedIn } = useContext(AppContext);

  const dashboardContRef = useRef(null);

  useEffect(() => {
    setDashboardFunctions({
      setOnView,
      setBookingDetailsData,
      setProposalDetailsData,
      setCustomBookingDetails,
    });
  }, [usersData, loggedIn]);

  useEffect(() => {
    if (!dashboardContRef?.current?.style) return;

    if (navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500) {
      dashboardContRef.current.style = `height: ${window?.screen?.availHeight - 160}px;`;
    }
  });

  if (!display) return;

  return (
    <div ref={dashboardContRef} className="ud-dashboard-cont media-ud-dashboard-cont">
      <DashboardInit
        setCustomBookingDetails={setCustomBookingDetails}
        setBookingDetailsData={setBookingDetailsData}
        setOnView={setOnView}
        display={onView === "dashboard init" ? true : false}
      />

      <ChefAtHomeBookingDetails
        data={bookingDetailsData}
        setOnView={setOnView}
        display={onView === "chef at home" ? true : false}
        type={bookingDetailsData.type}
      />

      <CustomBookingDetails
        setProposalDetailsData={setProposalDetailsData}
        data={customBookingDetails}
        setOnView={setOnView}
        display={onView === "custom booking" ? true : false}
      />

      <SeeProposals
        data={proposalDetailsData}
        setOnView={setOnView}
        display={onView === "see proposals" ? true : false}
      />
    </div>
  );
};

export default UdDashboard;
