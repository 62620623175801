import "../../../tailwind.css";
import React from "react";
import Section from "./Section";

function PrivacyPolicyNew() {
  return (
    <div className="w-screen relative z-10 bg-white customContainer  my-5 flex text-base flex-col place-items-start place-content-start text-start">
      <div className=" w-[50vw] mdw:w-[90vw]">
        {" "}
        <div className=" flex flex-col">
          <div className="relative">
            <div className="p-yellow-rec absolute -translate-y-4"></div>
            <p
              className="big bigTextHome blackText removemargin"
              style={{ color: "#323335" }}
            >
              Privacy Policy
            </p>
            <p className="text-base mt-4">Last updated April 6th, 2024</p>
          </div>
        </div>
        <div className="flex z-10">
          <div className="z-10">
            <p className="z-10">
              This privacy notice for iKooK LTD (
              <span className="font-bold">'we'</span>,{" "}
              <span className="font-bold">'us'</span>, or{" "}
              <span className="font-bold">'our'</span>), describes how and why
              we might collect, store, use, and/or share ('process') your
              information when you use our services (
              <span className="font-bold">'Services'</span>), such as when you:
            </p>
            <ul>
              <li>
                {" "}
                Visit our website at{" "}
                <a
                  href="https://ikook.co.uk"
                  className="font-bold text-primary-100"
                >
                  https://ikook.co.uk
                </a>
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                {" "}
                Download and use our mobile application (iKooK), or any other
                application of ours that links to this privacy notice
              </li>
              <li>
                {" "}
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              <span className="font-bold"> Questions or concerns?</span> Reading
              this privacy notice will help you understand your privacy rights
              and choices. If you do not agree with our policies and practices,
              please do not use our Services. If you still have any questions or
              concerns, please contact us at{" "}
              <a
                href="mailto:Support@ikook.co.uk"
                className="text-primary-100 font-bold"
              >
                Support@ikook.co.uk.
              </a>{" "}
            </p>
          </div>

          <div
            className=" absolute mdw:hidden z-0 top-[140px] right-0"
            style={{ width: "30%" }}
          >
            <img
              src="/images/prv.png"
              alt="ikook-privacy-policy"
              title="Ikook Privacy Policy"
            />
          </div>
        </div>
        <Section
          title={"SUMMARY OF KEY POINTS"}
          items={[
            <p className="font-bold ">
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{" "}
              <a href="#tableofcontents" className="text-primary-100">
                table of contents
              </a>{" "}
              below to find the section you are looking for.
            </p>,
            <p className=" ">
              <span className="font-bold">
                What personal information do we process?
              </span>{" "}
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use. Learn more about{" "}
              <a href="#personalinfo" className="text-primary-100 font-bold">
                personal information you disclose to us.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">
                Do we process any sensitive personal information?
              </span>
              We may process sensitive personal information when necessary with
              your consent or as otherwise permitted by applicable law. Learn
              more about
              <a href="#sensitive_info" className="text-primary-100 font-bold">
                {" "}
                sensitive information we process.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">
                Do we receive any information from third parties?
              </span>
              We may receive information from public databases, marketing
              partners, social media platforms, and other outside sources. Learn
              more about
              <a href="#infothirdparty" className="text-primary-100 font-bold">
                {" "}
                information collected from other sources.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">
                How do we process your information?{" "}
              </span>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so. Learn
              more about
              <a
                href="#process_your_info"
                className="text-primary-100 font-bold"
              >
                {" "}
                how we process your information.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">
                In what situations and with which parties do we share personal
                information?
              </span>
              We may share information in specific situations and with specific
              third parties. Learn more about
              <a href="#share_personal" className="text-primary-100 font-bold">
                {" "}
                when and with whom we share your personal information.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">
                How do we keep your information safe?
              </span>
              We have organisational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Learn more about
              <a href="#keep_info_sage" className="text-primary-100 font-bold">
                {" "}
                how we keep your information safe.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">What are your rights?</span>
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information. Learn more about
              <a href="#privacy_rights" className="text-primary-100 font-bold">
                {" "}
                your privacy rights.
              </a>
            </p>,
            <p className=" ">
              <span className="font-bold">
                How do you exercise your rights?
              </span>
              The easiest way to exercise your rights is by submitting a{" "}
              <a
                href="https://app.termly.io/notify/467ba43d-e9f6-4ddd-9b39-d707f5854f9f"
                className="text-primary-100 font-bold"
              >
                data subject access request
              </a>
              , or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>,
            <p className=" ">
              Want to learn more about what we do with any information we
              collect? Review the privacy notice in full.
            </p>,
          ]}
        />
        <Section
          title={"SUMMARY OF KEY POINTS"}
          items={[
            <a id="tableofcontents" href="#personalinfo" className="text-black font-bold">
              {" "}
              1. WHAT INFORMATION DO WE COLLECT?
            </a>,
            <a href="#process_your_info" className="text-black font-bold">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </a>,
            <a href="#legal_bases" className="text-black font-bold">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </a>,
            <a href="#share_personal" className="text-black font-bold">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>,
            <a href="#third_party_website" className="text-black font-bold">
              5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
            </a>,
            <a href="#use_cookies" className="text-black font-bold">
              6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>,
            <a href="#social_logins" className="text-black font-bold">
              7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>,
            <a href="#how_long_keep_info" className="text-black font-bold">
              8. HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>,
            <a href="#keep_info_sage" className="text-black font-bold">
              9. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </a>,
            <a href="#info_minors" className="text-black font-bold">
              10. DO WE COLLECT INFORMATION FROM MINORS?
            </a>,
            <a href="#privacy_rights" className="text-black font-bold">
              11. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>,
            <a href="#dn_track_features" className="text-black font-bold">
              12. CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>,
            <a href="#un_specific" className="text-black font-bold">
              13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>,
            <a href="#14" className="text-black font-bold">
              14. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>,
            <a href="#15" className="text-black font-bold">
              15. DO WE MAKE UPDATES TO THIS NOTICE?
            </a>,
            <a href="#16" className="text-black font-bold">
              16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>,
            <a href="#17" className="text-black font-bold">
              17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>,
          ]}
        />
        <section id="personalinfo" className="">
          <Section
            title={"1. WHAT INFORMATION DO WE COLLECT?"}
            items={[
              <p className="font-bold">
                Personal Information you disclose to us
              </p>,
              <p className="">
                In Short: We collect personal information that you provide to
                us.
              </p>,
              <p className="">
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </p>,
              <p className="">
                <span className="font-semibold">
                  Personal Information Provided by You.
                </span>{" "}
                The personal information that we collect depends on the context
                of your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
              </p>,
              <ul>
                <li> names </li>
                <li> phone numbers </li>
                <li> email addresses </li>
                <li> usernames </li>
                <li> passwords </li>
                <li> contact or authentication data </li>
                <li> contact preferences </li>
                <li> debit/credit card numbers </li>
                <li> billing addresses </li>
                <li> term of business </li>
                <li> identity verification </li>
                <li> training and certificate </li>
              </ul>,
              <p id="sensitive_info">
                <span className="font-semibold">Sensitive Information.</span>{" "}
                When necessary, with your consent or as otherwise permitted by
                applicable law, we process the following categories of sensitive
                information:
              </p>,
              <ul>
                <li> health data </li>
              </ul>,
              <p>
                <span className="font-semibold">Payment Data.</span> We may
                collect data necessary to process your payment if you make
                purchases, such as your payment instrument number, and the
                security code associated with your payment instrument. All
                payment data is stored by Stripe. You may find their privacy
                notice link(s) here:{" "}
                <a
                  href="https://stripe.com/privacy"
                  className="font-bold text-primary-100"
                >
                  https://stripe.com/privacy.
                </a>
              </p>,
              <p>
                We also use Paypal -
                https://www.paypal.com/myaccount/privacy/privacyhub Paystack -{" "}
                <a
                  href="https://paystack.com/terms"
                  className="font-bold text-primary-100"
                >
                  https://paystack.com/terms
                </a>
              </p>,
              <p>
                <span className="font-semibold">Social Media Login Data.</span>{" "}
                We may provide you with the option to register with us using
                your existing social media account details, like your Facebook,
                Twitter, or other social media account. If you choose to
                register in this way, we will collect the information described
                in the section called{" "}
                <a href="#socialmedia" className="font-bold text-primary-100">
                  'HOW DO WE HANDLE YOUR SOCIAL LOGINS?'
                </a>{" "}
                below.
              </p>,
              <p>
                <span className="font-semibold">Application Data.</span> If you
                use our application(s), we also may collect the following
                information if you choose to provide us with access or
                permission:
              </p>,
              <ul>
                <li>
                  Geolocation Information. We may request access or permission
                  to track location-based information from your mobile device,
                  either continuously or while you are using our mobile
                  application(s), to provide certain location-based services. If
                  you wish to change our access or permissions, you may do so in
                  your device's settings.
                </li>
                <li>
                  Push Notifications. We may request to send you push
                  notifications regarding your account or certain features of
                  the application(s). If you wish to opt out from receiving
                  these types of communications, you may turn them off in your
                  device's settings.
                </li>
              </ul>,
              <p>
                This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.
              </p>,
              <p>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>,
              <p id="infothirdparty" className="text-lg font-bold">
                Information collected from other sources
              </p>,
              <p className="">
                In Short: We may collect limited data from public databases,
                marketing partners, social media platforms, and other outside
                sources
              </p>,
              <p className="">
                In order to enhance our ability to provide relevant marketing,
                offers, and services to you and update our records, we may
                obtain information about you from other sources, such as public
                databases, joint marketing partners, affiliate programs, data
                providers, social media platforms, and from other third parties.
                This information includes mailing addresses, job titles, email
                addresses, phone numbers, intent data (or user behaviour data),
                Internet Protocol (IP) addresses, social media profiles, social
                media URLs, and custom profiles, for purposes of targeted
                advertising and event promotion. If you interact with us on a
                social media platform using your social media account (e.g.
                Facebook or Twitter), we receive personal information about you
                such as your name, email address, and gender. Any personal
                information that we collect from your social media account
                depends on your social media account's privacy settings.
              </p>,
            ]}
          />
        </section>
        ,
        <section id="process_your_info" className="">
          <Section
            title={"2. HOW DO WE PROCESS YOUR INFORMATION?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We process your information to provide, improve, and
                  administer our Services, communicate with you, for security
                  and fraud prevention, and to comply with law. We may also
                  process your information for other purposes with your consent.
                </span>
              </p>,
              <p className="font-semibold">
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </p>,
              <ul>
                <li>
                  <span className="font-semibold">
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </span>{" "}
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </li>
                <li>
                  <span className="font-semibold">
                    To deliver and facilitate delivery of services to the user.{" "}
                  </span>{" "}
                  We may process your information to provide you with the
                  requested service.
                </li>
                ,
                <li>
                  <span className="font-semibold">
                    To respond to user inquiries/offer support to users.
                  </span>{" "}
                  We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.
                </li>
                ,
                <li>
                  <span className="font-semibold">
                    To send administrative information to you.{" "}
                  </span>{" "}
                  We may process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                </li>
                ,
                <li>
                  <span className="font-semibold">
                    To fulfil and manage your orders.
                  </span>{" "}
                  We may process your information to fulfil and manage your
                  orders, payments, returns, and exchanges made through the
                  Services.
                </li>
                ,
                <li>
                  <span className="font-semibold">
                    To save or protect an individual's vital interest.
                  </span>{" "}
                  We may process your information when necessary to save or
                  protect an individual’s vital interest, such as to prevent
                  harm.
                </li>
                ,
              </ul>,
            ]}
          />
        </section>
        ,
        <section id="legal_bases" className="">
          <Section
            title={
              "3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?"
            }
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We only process your personal information when we believe it
                  is necessary and we have a valid legal reason (i.e. legal
                  basis) to do so under applicable law, like with your consent,
                  to comply with laws, to provide you with services to enter
                  into or fulfil our contractual obligations, to protect your
                  rights, or to fulfil our legitimate business interests.
                </span>
              </p>,
              <p className="font-semibold italic underline">
                If you are located in the EU or UK, this section applies to you.
              </p>,
              <p>
                The General Data Protection Regulation (GDPR) and UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
              </p>,
              <ul>
                <li>
                  <span className="font-bold">Consent.</span> We may process
                  your information if you have given us permission (i.e.
                  consent) to use your personal information for a specific
                  purpose. You can withdraw your consent at any time. Learn more
                  about{" "}
                  <a
                    href="#withdraw_consent"
                    className="text-primary-100 font-bold"
                  >
                    withdrawing your consent.
                  </a>
                </li>
                ,
                <li>
                  <span className="font-bold">Performance of a Contract.</span>{" "}
                  We may process your personal information when we believe it is
                  necessary to fulfil our contractual obligations to you,
                  including providing our Services or at your request prior to
                  entering into a contract with you.
                </li>
                ,
                <li>
                  <span className="font-bold">Legal Obligations. </span> We may
                  process your information where we believe it is necessary for
                  compliance with our legal obligations, such as to cooperate
                  with a law enforcement body or regulatory agency, exercise or
                  defend our legal rights, or disclose your information as
                  evidence in litigation in which we are involved
                </li>
                ,
                <li>
                  <span className="font-bold">Vital Interests. </span> . We may
                  process your information where we believe it is necessary to
                  protect your vital interests or the vital interests of a third
                  party, such as situations involving potential threats to the
                  safety of any person
                </li>
                ,
              </ul>,
              <p>
                In legal terms, we are generally the 'data controller' under
                European data protection laws of the personal information
                described in this privacy notice, since we determine the means
                and/or purposes of the data processing we perform. This privacy
                notice does not apply to the personal information we process as
                a 'data processor' on behalf of our customers. In those
                situations, the customer that we provide services to and with
                whom we have entered into a data processing agreement is the
                'data controller' responsible for your personal information, and
                we merely process your information on their behalf in accordance
                with your instructions. If you want to know more about our
                customers' privacy practices, you should read their privacy
                policies and direct any questions you have to them.
              </p>,
              <p className="font-bold underline italic">
                If you are located in Canada, this section applies to you.
              </p>,
              <p>
                We may process your information if you have given us specific
                permission (i.e. express consent) to use your personal
                information for a specific purpose, or in situations where your
                permission can be inferred (i.e. implied consent). You can{" "}
                <a
                  href="#withdraw_consent"
                  className="text-primary-100 font-bold"
                >
                  withdraw your consent.
                </a>{" "}
                at any time.
              </p>,
              <p>
                In some exceptional cases, we may be legally permitted under
                applicable law to process your information without your consent,
                including, for example:
              </p>,
              <ul>
                <li>
                  If collection is clearly in the interests of an individual and
                  consent cannot be obtained in a timely way
                </li>
                ,<li>For investigations and fraud detection and prevention</li>,
                <li>
                  For business transactions provided certain conditions are met
                </li>
                ,
                <li>
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </li>
                ,
                <li>
                  For identifying injured, ill, or deceased persons and
                  communicating with next of kin
                </li>
                ,
                <li>
                  If we have reasonable grounds to believe an individual has
                  been, is, or may be victim of financial abuse
                </li>
                ,
                <li>
                  If it is reasonable to expect collection and use with consent
                  would compromise the availability or the accuracy of the
                  information and the collection is reasonable for purposes
                  related to investigating a breach of an agreement or a
                  contravention of the laws of Canada or a province
                </li>
                ,
                <li>
                  If disclosure is required to comply with a subpoena, warrant,
                  court order, or rules of the court relating to the production
                  of records
                </li>
                ,
                <li>
                  If it was produced by an individual in the course of their
                  employment, business, or profession and the collection is
                  consistent with the purposes for which the information was
                  produced
                </li>
                ,
                <li>
                  If the collection is solely for journalistic, artistic, or
                  literary purposes
                </li>
                ,
                <li>
                  If the information is publicly available and is specified by
                  the regulations
                </li>
                ,
              </ul>,
            ]}
          />
        </section>
        <section id="share_personal" className="">
          <Section
            title={
              "4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
            }
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We may share information in specific situations described in
                  this section and/or with the following third parties.
                </span>
              </p>,
              <p>
                We may need to share your personal information in the following
                situations:
              </p>,
              <ul>
                <li>
                  <p>
                    <span>Business Transfers. </span> We may share or transfer
                    your information in connection with, or during negotiations
                    of, any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.{" "}
                  </p>
                  <p>
                    <span>When we use Google Analytics. </span> We may share
                    your information with Google Analytics to track and analyse
                    the use of the Services. The Google Analytics Advertising
                    Features that we may use include: Google Analytics
                    Demographics and Interests Reporting. To opt out of being
                    tracked by Google Analytics across the Services, visit
                    https://tools.google.com/dlpage/gaoptout. You can opt out of
                    Google Analytics Advertising Features through Ads Settings
                    and Ad Settings for mobile apps. Other opt out means include
                    http://optout.networkadvertising.org/ and
                    http://www.networkadvertising.org/mobile-choice. For more
                    information on the privacy practices of Google, please visit
                    the Google Privacy & Terms page.
                  </p>
                  ,
                  <p>
                    <span>When we use Google Maps Platform APIs </span> We may
                    share your information with certain Google Maps Platform
                    APIs (e.g. Google Maps API, Places API).
                  </p>
                  <p>
                    <span>Business Partners </span> We may share your
                    information with our business partners to offer you certain
                    products, services, or promotions
                  </p>
                  <p>
                    <span>Offer Wall. </span> Our application(s) may display a
                    third-party hosted 'offer wall'. Such an offer wall allows
                    third-party advertisers to offer virtual currency, gifts, or
                    other items to users in return for the acceptance and
                    completion of an advertisement offer. Such an offer wall may
                    appear in our application(s) and be displayed to you based
                    on certain data, such as your geographic area or demographic
                    information. When you click on an offer wall, you will be
                    brought to an external website belonging to other persons
                    and will leave our application(s). A unique identifier, such
                    as your user ID, will be shared with the offer wall provider
                    in order to prevent fraud and properly credit your account
                    with the relevant reward.
                  </p>
                </li>
              </ul>,
            ]}
          />
        </section>
        ,
        <section id="third_party_website" className="">
          <Section
            title={"5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We are not responsible for the safety of any information that
                  you share with third parties that we may link to or who
                  advertise on our Services, but are not affiliated with, our
                  Services.
                </span>
              </p>,
              <p>
                The Services, including our offer wall, may link to third-party
                websites, online services, or mobile applications and/or contain
                advertisements from third parties that are not affiliated with
                us and which may link to other websites, services, or
                applications. Accordingly, we do not make any guarantee
                regarding any such third parties, and we will not be liable for
                any loss or damage caused by the use of such third-party
                websites, services, or applications. The inclusion of a link
                towards a third-party website, service, or application does not
                imply an endorsement by us. We cannot guarantee the safety and
                privacy of data you provide to any third parties. Any data
                collected by third parties is not covered by this privacy
                notice. We are not responsible for the content or privacy and
                security practices and policies of any third parties, including
                other websites, services, or applications that may be linked to
                or from the Services. You should review the policies of such
                third parties and contact them directly to respond to your
                questions.
              </p>,
            ]}
          />
        </section>
        ,
        <section id="use_cookies" className="">
          <Section
            title={"6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We may use cookies and other tracking technologies to collect
                  and store your information.
                </span>
              </p>,
              <p>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice
              </p>,
            ]}
          />
        </section>
        <section id="social_logins" className="">
          <Section
            title={"7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  If you choose to register or log in to our Services using a
                  social media account, we may have access to certain
                  information about you.
                </span>
              </p>,
              <p>
                Our Services offer you the ability to register and log in using
                your third-party social media account details (like your
                Facebook or Twitter logins). Where you choose to do this, we
                will receive certain profile information about you from your
                social media provider. The profile information we receive may
                vary depending on the social media provider concerned, but will
                often include your name, email address, friends list, and
                profile picture, as well as other information you choose to make
                public on such a social media platform.
                <br />
                We will use the information we receive only for the purposes
                that are described in this privacy notice or that are otherwise
                made clear to you on the relevant Services. Please note that we
                do not control, and are not responsible for, other uses of your
                personal information by your third-party social media provider.
                We recommend that you review their privacy notice to understand
                how they collect, use, and share your personal information, and
                how you can set your privacy preferences on their sites and
                apps.
              </p>,
            ]}
          />
        </section>
        <section id="how_long_keep_info" className="">
          <Section
            title={"8. HOW LONG DO WE KEEP YOUR INFORMATION?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We keep your information for as long as necessary to fulfil
                  the purposes outlined in this privacy notice unless otherwise
                  required by law.
                </span>
              </p>,
              <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us. When we have no ongoing legitimate
                business need to process your personal information, we will
                either delete or anonymise such information, or, if this is not
                possible (for example, because your personal information has
                been stored in backup archives), then we will securely store
                your personal information and isolate it from any further
                processing until deletion is possible
              </p>,
            ]}
          />
        </section>
        <section id="keep_info_sage" className="">
          <Section
            title={"9. HOW DO WE KEEP YOUR INFORMATION SAFE?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We aim to protect your personal information through a system
                  of organisational and technical security measures.
                </span>
              </p>,
              <p>
                We have implemented appropriate and reasonable technical and
                organisational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorised third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
              </p>,
            ]}
          />
        </section>
        <section id="info_minors" className="">
          <Section
            title={"10. DO WE COLLECT INFORMATION FROM MINORS?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  We do not knowingly collect data from or market to children
                  under 18 years of age.
                </span>
              </p>,
              <p>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at
                Team@ikook.co.uk.
              </p>,
            ]}
          />
        </section>
        <section id="privacy_rights" className="">
          <Section
            title={"11. WHAT ARE YOUR PRIVACY RIGHTS?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  In some regions, such as the European Economic Area (EEA),
                  United Kingdom (UK), Switzerland, and Canada, you have rights
                  that allow you greater access to and control over your
                  personal information. You may review, change, or terminate
                  your account at any time
                </span>
              </p>,
              <p>
                In some regions (like the EEA, UK, Switzerland, and Canada), you
                have certain rights under applicable data protection laws. These
                may include the right (i) to request access and obtain a copy of
                your personal information, (ii) to request rectification or
                erasure; (iii) to restrict the processing of your personal
                information; (iv) if applicable, to data portability; and (v)
                not to be subject to automated decision-making. In certain
                circumstances, you may also have the right to object to the
                processing of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section{" "}
                <a href="#16" className="font-bold text-primary-100">
                  'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
                </a>{" "}
                below
              </p>,
              <p>
                We will consider and act upon any request in accordance with
                applicable data protection laws. If you are located in the EEA
                or UK and you believe we are unlawfully processing your personal
                information, you also have the right to complain to your{" "}
                <a
                  href="https://ec.europa.eu/newsroom/article29/items/612080"
                  className="font-bold text-primary-100"
                >
                  Member State data protection authority
                </a>{" "}
                or{" "}
                <a
                  href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                  className="font-bold text-primary-100"
                >
                  UK data protection authority.
                </a>
              </p>,
              <p>
                If you are located in Switzerland, you may contact the{" "}
                <a
                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  className="font-bold text-primary-100"
                >
                  Federal Data Protection and Information Commissioner.
                </a>
              </p>,
              <p id="#withdraw">
                {" "}
                <span className="font-bold underline" id="withdraw_consent">
                  Withdrawing your consent
                </span>
                If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section{" "}
                <a href="#16" className="font-bold text-primary-100">
                  HOW CAN YOU CONTACT US
                </a>{" "}
                ABOUT THIS NOTICE?' below or updating your preferences.
              </p>,
              <p>
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </p>,
              <p>
                <span className="font-bold underline">
                  {" "}
                  Opting out of marketing and promotional communications{" "}
                </span>{" "}
                You can unsubscribe from our marketing and promotional
                communications at any time by clicking on the unsubscribe link
                in the emails that we send, or by contacting us using the
                details provided in the section{" "}
                <a href="#contact_us" className="font-bold text-primary-100">
                  'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
                </a>{" "}
                below. You will then be removed from the marketing lists.
                However, we may still communicate with you — for example, to
                send you service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
              </p>,
              <p className="font-bold">Account Information</p>,
              <p>
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </p>,
              <ul>
                <li>
                  Log in to your account settings and update your user account.
                </li>
                <li>Contact us using the contact information provided</li>
              </ul>,
              <p>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </p>,
              <p>
                <span className="font-bold underline">
                  Cookies and similar technologies:{" "}
                </span>{" "}
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Services. You may also{" "}
                <a
                  href="http://www.aboutads.info/choices/"
                  className="font-bold text-primary-100"
                >
                  opt out of interest-based advertising by advertisers
                </a>{" "}
                on our Services
              </p>,

              <p>
                If you have questions or comments about your privacy rights, you
                may email us at Support@ikook.co.uk.
              </p>,
            ]}
          />
        </section>
        ,
        <section id="dn_track_features" className="">
          <Section
            title={"12. CONTROLS FOR DO-NOT-TRACK FEATURES"}
            items={[
              <p>
                {" "}
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ('DNT') feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognising and implementing DNT signals has been finalised. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.{" "}
              </p>,
            ]}
          />
        </section>
        ,
        <section id="un_specific" className="">
          <Section
            title={
              "13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
            }
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  In some regions, such as the European Economic Area (EEA),
                  United Kingdom (UK), Switzerland, and Canada, you have rights
                  that allow you greater access to and control over your
                  personal information. You may review, change, or terminate
                  your account at any time
                </span>
              </p>,
              <p className="font-bold">
                What categories of personal information do we collect?{" "}
              </p>,
              <p>
                We have collected the following categories of personal
                information in the past twelve (12) months:
              </p>,

              <div className="w-full overflow-x-auto">
                <table className="min-w-full table-auto text-sm text-left text-gray-500 shadow-md">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Examples
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Collected
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        A. Identifiers
                      </th>
                      <td className="px-6 py-4">
                        Contact details, such as real name, alias, postal
                        address, telephone or mobile contact number, unique
                        personal identifier, online identifier, Internet
                        Protocol address, email address, and account name
                      </td>
                      <td className="px-6 py-4">YES</td>
                    </tr>
                    <tr className="bg-gray-50 border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        B. Protected classification characteristics under state
                        or federal law
                      </th>
                      <td className="px-6 py-4">Gender and date of birth</td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        C. Commercial information
                      </th>
                      <td className="px-6 py-4">
                        Transaction information, purchase history, financial
                        details, and payment information
                      </td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-gray-50 border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        D. Biometric information
                      </th>
                      <td className="px-6 py-4">
                        Fingerprints and voiceprints
                      </td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        E. Internet or other similar network activity
                      </th>
                      <td className="px-6 py-4">
                        Browsing history, search history, online behaviour,
                        interest data, and interactions with our and other
                        websites, applications, systems, and advertisements
                      </td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-gray-50 border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        F. Geolocation data
                      </th>
                      <td className="px-6 py-4">Device location</td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        G. Audio, electronic, visual, thermal, olfactory, or
                        similar information
                      </th>
                      <td className="px-6 py-4">
                        Images and audio, video or call recordings created in
                        connection with our business activities
                      </td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-gray-50 border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        H. Professional or employment-related information
                      </th>
                      <td className="px-6 py-4">
                        Business contact details in order to provide you our
                        Services at a business level or job title, work history,
                        and professional qualifications if you apply for a job
                        with us
                      </td>
                      <td className="px-6 py-4">YES</td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        I. Education Information
                      </th>
                      <td className="px-6 py-4">
                        Student records and directory information
                      </td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-gray-50 border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        J. Inferences drawn from collected personal information
                      </th>
                      <td className="px-6 py-4">
                        Inferences drawn from any of the collected personal
                        information listed above to create a profile or summary
                        about, for example, an individual’s preferences and
                        characteristics
                      </td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        K. Sensitive personal Information
                      </th>
                      <td className="px-6 py-4"></td>
                      <td className="px-6 py-4">NO</td>
                    </tr>
                  </tbody>
                </table>
              </div>,
              <p>
                We will use and retain the collected personal information as
                needed to provide the Services or for:
              </p>,
              <ul>
                <li>Category A - As long as the user has an account with us</li>
                <li>Category H - As long as the user has an account with us</li>
              </ul>,
              <p>
                We may also collect other personal information outside of these
                categories through instances where you interact with us in
                person, online, or by phone or mail in the context of:
              </p>,
              <ul>
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </li>
              </ul>,
              <p className="font-semibold">
                How do we use and share your personal information?
              </p>,
              <p>
                Learn about how we use your personal information in the section,{" "}
                <a
                  href="#process_your_info"
                  className="font-bold text-primary-100"
                >
                  'HOW DO WE PROCESS YOUR INFORMATION?'
                </a>
              </p>,
              <p className="font-semibold">
                Will your information be shared with anyone else?
              </p>,
              <p>
                We may disclose your personal information with our service
                providers pursuant to a written contract between us and each
                service provider. Learn more about how we disclose personal
                information to in the section,{" "}
                <a href="#share_personal" className="font-bold text-primary-100">
                  'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
                </a>
              </p>,
              <p>
                {" "}
                We may use your personal information for our own business
                purposes, such as for undertaking internal research for
                technological development and demonstration. This is not
                considered to be 'selling' of your personal information.
                <br />
                We have not disclosed, sold, or shared any personal information
                to third parties for a business or commercial purpose in the
                preceding twelve (12) months. We will not sell or share personal
                information in the future belonging to website visitors, users,
                and other consumers.
              </p>,
            ]}
          />
        </section>
        ,
        <section id="#14" className="">
          <Section
            title={"14. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  You may have additional rights based on the country you reside
                  in.
                </span>
              </p>,
              <p className="font-bold">Australia and New Zealand</p>,
              <p>
                We collect and process your personal information under the
                obligations and conditions set by Australia's Privacy Act 1988
                and New Zealand's Privacy Act 2020 (Privacy Act).
                <br />
                This privacy notice satisfies the notice requirements defined in
                both Privacy Acts, in particular: what personal information we
                collect from you, from which sources, for which purposes, and
                other recipients of your personal information.
                <br />
                If you do not wish to provide the personal information necessary
                to fulfill their applicable purpose, it may affect our ability
                to provide our services, in particular:
              </p>,
              <ul>
                <li>offer you the products or services that you want</li>
                <li>respond to or help with your requests</li>
                <li>manage your account with us</li>
                <li>confirm your identity and protect your account</li>
              </ul>,
              <p>
                At any time, you have the right to request access to or
                correction of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section{" "}
                <a href="#17" className="font-bold text-primary-100">
                  'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?'
                </a>
              </p>,
              <p>
                If you believe we are unlawfully processing your personal
                information, you have the right to submit a complaint about a
                breach of the Australian Privacy Principles to the{" "}
                <a
                  href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
                  className="text-primary-100 font-bold"
                >
                  Office of the Australian Information Commissioner
                </a>{" "}
                and a breach of New Zealand's Privacy Principles to the{" "}
                <a
                  href="https://www.privacy.org.nz/your-rights/making-a-complaint/"
                  className="font-bold text-primary-100"
                >
                  Office of New Zealand Privacy Commissioner.
                </a>
              </p>,
              <p>
                <span className="font-bold text-xl">
                  Republic of South Africa
                </span>
                At any time, you have the right to request access to or
                correction of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section{" "}
                <a href="#17" className="font-bold text-primary-100">
                  'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?'
                </a>
                If you are unsatisfied with the manner in which we address any
                complaint with regard to our processing of personal information,
                you can contact the office of the regulator, the details of
                which are: The Information Regulator (South Africa) General
                enquiries: enquiries@inforegulator.org.za Complaints (complete
                POPIA/PAIA form 5): PAIAComplaints@inforegulator.org.za &
                POPIAComplaints@inforegulator.org.za
              </p>,
            ]}
          />
        </section>
        ,
        <section id="15" className="">
          <Section
            title={"15. DO WE MAKE UPDATES TO THIS NOTICE?"}
            items={[
              <p>
                In Short:{" "}
                <span className="italic">
                  Yes, we will update this notice as necessary to stay compliant
                  with relevant laws.
                </span>
              </p>,
              <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated 'Revised' date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>,
            ]}
          />
        </section>
        <section id="16">
          <Section
            title={"16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"}
            items={[
              <p>
                If you have questions or comments about this notice, you may
                email us at Team@ikook.co.uk or contact us by post at:
                <br />
                iKooK LTD D FRENSHAM HOUSE 4 BLAKESLEY AVENUE LONDON, LONDON W5
                2DW United Kingdom
                <br />
                If you are a resident in the United Kingdom, we are the 'data
                controller' of your personal information. We have appointed
                Iyabo Bello to be our representative in the UK. You can contact
                them directly regarding our processing of your information, by
                email at <a href="mailto:info@ikook.co.uk" className="text-primary-100 font-bold">Info@ikook.co.uk</a>, by visiting 
                <a
                  href="https://ikook.co.uk/"
                  className="font-bold text-primary-100"
                >{" "}our Website
                </a>
                , or by post to:
                <br />
                <br />
                D Frensham House 4 Blakesley Avenue London, London W5 2DW
                England
              </p>,
            ]}
          />
        </section>
        <section id="17">
          <Section
            title={
              "17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
            }
            items={[
              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please fill
                out and submit a{" "}
                <a
                  href="https://app.termly.io/notify/467ba43d-e9f6-4ddd-9b39-d707f5854f9f"
                  className="text-primary-100 font-bold"
                >
                  data subject access request.
                </a>
              </p>,
            ]}
          />
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicyNew;
