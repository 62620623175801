import React from "react";


function Textfield(props) {
  return (
    <div className={`flex flex-col ${props.customClass}`}>
      <div className="flex place-content-between w-full">
        {props.title && (
          <span className={`${props.titleStyle??"text-text-bodyColor text-body whitespace-pre-line leading-none p-0 m-0"}`}>{props.title}</span>
        )}
        {props.suffixContent}
      </div>
      <div
        className={`flex gap-x-[12px] ${props.border??"border-b border-b-border rounded-md"}  ${props.padding?props.padding:"mdw:py-[2px] py-[2px] px-[2px]"} ${props.bg??"bg-white"} w-full`}
      >
        {props.image && (
          <img
            src={props.image}
            className="w-6 h-6"
            alt="Search"
            width={24}
            height={24}
          />
        )}
        {props.CustomInput != null ? (
          <div className="w-full grow ">{props.CustomInput}</div>
        ) : (
          <input
            {...props}
            value={props.value}
            onChange={(e) => {
              props.onValueChanged(e.target.value);
            }}
            placeholder={props.placeholder}
            type={props.type}
            readOnly={props.readOnly}
            className={`text-text-bodyColor text-sm placeholder:text-sm focus:outline-none focus:border-none w-full grow noicon bg-transparent ${props.textfieldcustomClass}`}
          />
        )}
        {props.endimage && (
          <img
            src={props.endimage}
            className="w-6 h-6"
            alt="Search"
            width={24}
            height={24}
          />
        )}
      </div>
      <div className="flex place-items-center gap-2 w-full">
        {props.error && (
          <p className="text-red-500 text-body flex place-items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 fill-red-500"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
            </svg>
            {props.error}
          </p>
        )}
      </div>
    </div>
  );
}

export default Textfield;
