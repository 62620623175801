import React, { useState, useContext, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBInput,
  MDBModalBody,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../styles/index.css";
import "../../pages/user-dashboard/styles/user-index.css";
import "../auth/login/login-modal.css";
import "../../pages/chef-dashboard/styles/chef-index.css";
import AppContext from "../../context/app-context";
import "./propose.css";
import PMenuCard from "./p-menu-card";

let theMenusSelected = [];

const SubmitProposal = ({ display, setShowProposalModal, data, currency }) => {
  const [hideSubmitBtn, setHideSubmitBtn] = useState(true);
  const [yourMenus, setYourMenus] = useState([]);

  const [costProposed, setCostProposed] = useState("");
  const [proposalMessage, setProposalMessage] = useState("");

  const [stateTheMenuSelected, setStateTheMenuSelected] = useState([]);

  const { backendServer, apiKey, setIsLoading, popup, usersData, updateUsersData, convertAmount } =
    useContext(AppContext);

  useEffect(() => {
    if (!display) return;
    (async function () {
      try {
        setIsLoading(true);

        const realMenus = await Promise.all(
          usersData?.chef_details?.chef_menus?.map(async (e) => {
            const { data } = await (
              await fetch(`${backendServer}/ikook/api/v1/1680/chef/menu/get/${e}`, {
                credentials: "include",
                headers: {
                  token: localStorage.getItem("ikooktoken"),
                },
              })
            ).json();

            return data;
          })
        );

        const servicesFilteredData = realMenus.filter(
          (e) => e?.chefID?.country === data?.custom_booking_country && e?.status === "approved"
        );

        if (servicesFilteredData?.length === 0) {
          setYourMenus(
            <p className="small redText removemargin">You have no menus to make a proposal</p>
          );
          return setIsLoading(false);
        }

        const comp = servicesFilteredData.map((e, i) => (
          <PMenuCard
            key={i}
            data={e}
            setStateTheMenuSelected={setStateTheMenuSelected}
            fixTheMenuSelected={fixTheMenuSelected}
            theMenusSelected={theMenusSelected}
          />
        ));

        setYourMenus(comp);

        setIsLoading(false);
      } catch (err) {
        popup("Your Menus Could Not Be Fetched");
        setIsLoading(false);
      }
    })();
  }, [data, display]);

  useEffect(() => {
    if (
      !costProposed ||
      !proposalMessage ||
      Number(costProposed) < 1 ||
      theMenusSelected.length === 0
    )
      return setHideSubmitBtn(true);
    else setHideSubmitBtn(false);
  }, [costProposed, proposalMessage, stateTheMenuSelected]);

  const handleSubmitProposal = async function () {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/booking/proposals/add/${data._id}`, {
          method: "POST",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify({
            chef_who_proposed_id: usersData._id,
            menus_proposed_ids: theMenusSelected,
            price_proposed: costProposed,
            proposal_message: proposalMessage,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your proposal has been submitted!");

      updateUsersData();

      setIsLoading(false);
      setShowProposalModal(false);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  };

  function fixTheMenuSelected(theId) {
    if (theMenusSelected.includes(theId)) {
      theMenusSelected = theMenusSelected.filter((e) => {
        return e !== theId;
      });
    } else theMenusSelected.push(theId);

    setStateTheMenuSelected([]);
  }

  useEffect(() => {
    return () => {
      if (display) {
        theMenusSelected = [];
        setCostProposed("");
        setProposalMessage("");
        setYourMenus([]);
      }
    };
  }, [display]);

  if (!data) return;

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowProposalModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <h5 className="removemargin">Submit a Quote for Booking #{data._id}</h5>
            </MDBModalHeader>

            <MDBModalBody className="cd-page-main-cont media-submit-proposal-modal">
              <div className="propose-box-1 media-submit-proposal-modal-box-1">
                <p className="small alignTextLeft blackText removemargin">
                  Send a quote with up to 2 Menu Templates
                </p>

                <div className="flex-column align-column-left">{yourMenus}</div>

                <MDBInput
                  min="1"
                  value={costProposed}
                  onChange={(e) => setCostProposed(e.target.value)}
                  maxLength={50}
                  label={`Cost Proposed (${currency})`}
                  type="number"
                />

                <MDBTextArea
                  value={proposalMessage}
                  onChange={(e) => setProposalMessage(e.target.value)}
                  label="Quote Message (Make it Count!)"
                  type="text"
                  maxLength={500}
                  rows={5}
                />

                <MDBBtn
                  id="signupbtn-disable"
                  disabled={hideSubmitBtn}
                  style={{
                    width: "80%",
                    cursor: "pointer",
                    backgroundColor: "#fcc01c",
                    color: "black",
                  }}
                  onClick={handleSubmitProposal}
                >
                  Confirm Proposal
                </MDBBtn>
              </div>

              <div className="propose-box-2 media-submit-proposal-modal-box-2">
                <div className="box-2-booking-details">
                  <div className="box-2-booking-details">
                    <div className="menu-page-nav-cont">
                      <p className={`small boldText`}>Cost Breakdown ({currency})</p>
                    </div>

                    <div className="flex-column align-column-left">
                      <p className="tablesmall removemargin">Guests</p>

                      <p className="small removemargin alignTextLeft">
                        {data?.number_of_adults} Adult(s). {data?.number_of_teens} Teen(s).{" "}
                        {data?.number_of_children} Kid(s)
                      </p>
                    </div>

                    <p
                      className="tablesmall alignTextLeft"
                      style={{ marginTop: 10, marginBottom: 0 }}
                    >
                      iKooK will deduct 15% commission on the total amount of each booking (iKooK
                      Fee).
                    </p>

                    <div className="flex-row">
                      <hr
                        className="alignTextLeft"
                        style={{ width: "50%", color: "#ffcc00", height: 3 }}
                      ></hr>
                    </div>

                    <div className="each-order-details-cont">
                      <p className="small removemargin alignTextLeft">Amount Per Guests</p>

                      <p className="small removemargin alignTextLeft boldText">
                        {convertAmount(
                          costProposed /
                            (data?.number_of_adults +
                              data?.number_of_teens +
                              data?.number_of_children),
                          currency
                        ) || 0}
                      </p>
                    </div>

                    <div className="each-order-details-cont">
                      <p className="small removemargin alignTextLeft">iKooK Fee (15%)</p>

                      <p className="small removemargin alignTextLeft boldText">
                        {convertAmount((15 / 100) * costProposed, currency) || 0}
                      </p>
                    </div>

                    <div className="each-order-details-cont" style={{ marginBottom: 0 }}>
                      <p className="small removemargin alignTextLeft">Total</p>

                      <p className="small removemargin alignTextLeft boldText">
                        {convertAmount(costProposed - (15 / 100) * costProposed, currency) || 0}
                      </p>
                    </div>

                    <div className="flex-row">
                      <hr
                        className="alignTextLeft"
                        style={{ width: "50%", color: "#ffcc00", height: 3 }}
                      ></hr>
                    </div>

                    <p className="tablesmall alignTextLeft">
                      NOTE: For every proposal submitted, you agree to our{" "}
                      <span>
                        <a href="/tac" target="_blank">
                          Terms of Service.
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export { SubmitProposal, theMenusSelected };
