import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import "../styles/user-index.css";
import "../../../styles/index.css";
import { RiDashboardLine } from "react-icons/ri";
import { TbBrandBooking } from "react-icons/tb";
import { BiMessageSquareDots, BiSupport, BiNotification } from "react-icons/bi";
import { VscPreview } from "react-icons/vsc";
import { CiSettings } from "react-icons/ci";
import AppContext from "../../../context/app-context";
import { Tooltip } from "@mui/material";

const UserDashBoardSideNav = forwardRef(function ({ changeScreen }, ref) {
  const [activeNav, setActiveNav] = useState("dashboard");
  const { usersData, updateUsersData, setIsLoading, backendServer, apiKey } =
    useContext(AppContext);

  useImperativeHandle(
    ref,
    () => {
      return {
        changeAppScreen(screen) {
          setActiveNav(screen);
          changeScreen(screen);
          document.location.hash = screen;
        },
      };
    },
    [changeScreen]
  );

  async function markAllNotificationsAsRead() {
    try {
      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/user/notification/read/${usersData._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();
    } catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <div className="ud-nav-cont media-ud-nav-cont">
      <div
        onClick={() => {
          setActiveNav("dashboard");
          changeScreen("dashboard");
          document.location.hash = "dashboard";
        }}
        className={`ud-each-nav ${activeNav === "dashboard" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Dashboard">
          <span>
            <RiDashboardLine size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">DASHBOARD</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("bookings");
          changeScreen("bookings");
          document.location.hash = "bookings";
        }}
        className={`ud-each-nav ${activeNav === "bookings" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Bookings">
          <span>
            <TbBrandBooking size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">BOOKINGS</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("notifications");
          changeScreen("notifications");
          document.location.hash = "notifications";
          setTimeout(() => {
            markAllNotificationsAsRead();
          }, 3000);
        }}
        className={`ud-each-nav ${activeNav === "notifications" ? "ud-each-nav-active" : "hide"}
        `}
      >
        <Tooltip
          disableHoverListener
          placement="right"
          arrow
          enterTouchDelay={0}
          title="Notifications"
        >
          <span>
            <BiNotification size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">NOTIFICATIONS</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("messages");
          changeScreen("messages");
          document.location.hash = "messages";
        }}
        className={`ud-each-nav ${activeNav === "messages" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Messages">
          <span>
            <BiMessageSquareDots size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">MESSAGES</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("review");
          changeScreen("review");
          document.location.hash = "review";
        }}
        className={`ud-each-nav ${activeNav === "review" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Review">
          <span>
            <VscPreview size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">REVIEW</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("support");
          changeScreen("support");
          document.location.hash = "support";
        }}
        className={`ud-each-nav ${activeNav === "support" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Support">
          <span>
            <BiSupport size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">SUPPORT</p>
      </div>

      <div
        onClick={() => {
          setActiveNav("settings");
          changeScreen("settings");
          document.location.hash = "settings";
        }}
        className={`ud-each-nav ${activeNav === "settings" ? "ud-each-nav-active" : "hide"}`}
      >
        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Settings">
          <span>
            <CiSettings size={20} />
          </span>
        </Tooltip>

        <p className="small blackText removemargin media-ud-nav-text">SETTINGS</p>
      </div>

      <div className="flex-row">
        <p style={{ marginTop: 70 }} className="xsmall media-ud-nav-text">
          Copyright © 2022 iKooK, All Right Reserved.
        </p>
      </div>
    </div>
  );
});

export default UserDashBoardSideNav;
