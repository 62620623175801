import React, { useContext, useEffect, useRef } from "react";
import "./extensive-meal-prep-day.css";
import "../../../styles/index.css";
import AppContext from "../../../context/app-context.js";
import { menuDetailObjs } from "../../../pages/meal-prep-menu-page/meal-prep-menu-page";

const EachOfInnerMenu = ({ data, currency, day, sortOutMenuSelected }) => {
  const { convertAmount } = useContext(AppContext);
  const menuRef = useRef(null);

  const theMenu = { ...data };

  useEffect(() => {
    theMenu.day = day;
    theMenu.type = menuRef.current?.closest(".where-each-inner-menu-are")?.dataset?.type;
    theMenu.unique_id = `${theMenu.description}-${theMenu.day}-${theMenu.type}`;

    if (menuDetailObjs.find((e) => e.unique_id === theMenu.unique_id))
      menuRef.current?.classList?.add("extensive-drbtn-active");
    else menuRef.current?.classList?.remove("extensive-drbtn-active");
  });

  return (
    <button
    id="sortmenu"
      ref={menuRef}
      className="extensive-drbtn"
      onClick={(e) => {
        e.stopPropagation();

        sortOutMenuSelected(theMenu);

        e.target.classList.toggle("extensive-drbtn-active");
      }}
    >
      {data?.description} - {convertAmount(data?.price, currency)}
    </button>
  );
};

export default EachOfInnerMenu;
