const formatDate = (blogInfo) => {
  const date = new Date(blogInfo?.pub_date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
};

export default formatDate;
