import React, { useState } from "react";
import "../../pages/explore-menus/explore.css";
import SupportModal from "../support-modal/support-modal";
import "./for-pages-action.css";
import AppContext from "../../context/app-context";
import { useContext } from "react";

const ForPageActions = function ({ margin }) {
  const [showSupportModal, setShowSupportModal] = useState(false);

  const { popup, loggedIn, universalShowLoginObject } = useContext(AppContext);

  return (
    <>
      <div
        className="explore-continue"
        style={{ marginTop: margin || 50, justifyContent: "center" }}
      >
        <div className="each-explore-continue yellow-bg">
          <p className="big boldText blackText" style={{ marginBottom: 5 }}>
            Can't find what you are looking for?
          </p>

          <p className="small" style={{ color: "black" }}>
            OR not satisfied with the Menus, use the custom booking to get exactly what you want.
          </p>

          <button id="find_a_chef"
            onClick={() => (document.location.href = "/cb")}
            className="themeBtn responsiveBtn whiteBg"
          >
            Find a Chef
          </button>
        </div>

        <div className="each-explore-continue yellow-bg">
          <p className="big boldText blackText" style={{ marginBottom: 5 }}>
            The iKooK Experience!
          </p>

          <p className="small" style={{ color: "black", paddingLeft: 20, paddingRight: 20 }}>
            Experience a culinary adventure with our talented chefs.
          </p>

          <button id="explore_our_chefs"
            onClick={(e) => {
              if (!loggedIn || loggedIn === "none") {
                e.preventDefault();
                universalShowLoginObject?.showLogin(true);
                return popup("Login to see our chefs!");
              } else document.location.href = "/users/";
            }}
            className="themeBtn responsiveBtn whiteBg"
          >
            Explore Our Chefs
          </button>
        </div>
      </div>

      <SupportModal display={showSupportModal} setShowSupportModal={setShowSupportModal} />
    </>
  );
};

export default ForPageActions;
