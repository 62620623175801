import React, { useState, useEffect, useContext } from "react";
import "../../pages/menu-page/menu-page.css";
import MenuCardSmall from "../menu-card-small/menu-card-small";
import AppContext from "../../context/app-context";
import "../../pages/user-page/user-page.css";

const UserDetailsMenuList = function ({ activeNav, data }) {
  const [comps, setComps] = useState([]);

  const { backendServer, apiKey, setIsLoading } = useContext(AppContext);

  const getMenus = async () => {
    if (!data._id) return;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get?chefID=${data?._id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const servicesFilteredData = returned.data.filter((e) => e?.status === "approved");

      const compss = servicesFilteredData.map((e, i) => {
        return <MenuCardSmall key={i} data={e} />;
      });

      setComps(compss);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!data) return;

    getMenus();
  }, [data]);

  if (activeNav !== "menu") return;

  return (
    <div className="box-1-in">
      <div className="flex-column align-column-left">
        <p className="small">Showing {comps.length} Active Menus</p>
      </div>

      <div className="flex-row align-row-left the-review-cont upp-menu-list">{comps}</div>
    </div>
  );
};

export default UserDetailsMenuList;
