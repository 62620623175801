import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
import "../../styles/index.css";
import "./meal-prep-explore.css";
import { AiOutlineSearch, AiFillStar } from "react-icons/ai";
import UserCardMealPrep from "../../components/user-card-meal-prep/user-card-meal-prep";
import "../../pages/home/home.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import AppContext from "../../context/app-context";
import { useParams } from "react-router-dom";
import { GiCookingPot } from "react-icons/gi";
import { usePlacesWidget } from "react-google-autocomplete";
import country from "../../context/country";
import restrictCountry from "../../context/country-restrictions";
import { Pagination } from "@mui/material";

let unvailableChefsForTheFilteredDate = [];
let page = 0;

const ExploreMealPrepChefs = function () {
  const [compsOnShow, setCompsOnShow] = useState([]);
  const [splittedArrWorked, setSplittedArrWorked] = useState([]);

  const {
    backendServer,
    apiKey,
    setIsLoading,
    popup,
    calculateChefRating,
    googleMapsApiKey,
    pyBackendServer,
  } = useContext(AppContext);

  const [queryLocation, setQueryLocation] = useState("");

  const queryRef = useRef(null);

  const topRef = useRef(null);

  const { query, filterdate } = useParams();

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setQueryLocation(place?.formatted_address);
    },
    options: {
      componentRestrictions: { country: restrictCountry },
    },
  });

  useEffect(() => {
    document.title = "iKooK - Meal Prep Chefs";
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (filterdate) {
          setIsLoading(true);

          const returned = await (
            await fetch(
              `${pyBackendServer}/accounts/chef/unavailability/get/date?date=${filterdate}`
            )
          ).json();

          unvailableChefsForTheFilteredDate = returned.data;
        }

        if (query) {
          findChefByLocation(query);
        } else {
          findChef();
        }
      } catch {
        if (query) {
          findChefByLocation(query);
        } else {
          findChef();
        }
      }
    })();
  }, []);

  useLayoutEffect(() => {
    if (query) setQueryLocation(query);
  }, []);

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }

  const findChef = async (query) => {
    try {
      setIsLoading(true);

      let url;

      if (query) {
        url = `${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?query=only-chefs`;

        const { data } = await (
          await fetch(url, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const servicesFilteredData = data.filter(
          (e) =>
            e?.availableForMealPrep !== "no" &&
            !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
            e?.country === country &&
            e?.chef_details?.chef_verification_details?.is_documents_verified &&
            e?.chef_details?.chef_menus?.filter((e) => e?.status === "approved").length > 0
        );

        const gotten = servicesFilteredData.find((e) => {
          return (
            e.first_name === capitalizeEachWord(query) ||
            e.username === query ||
            capitalizeEachWord(e.username) === capitalizeEachWord(query) ||
            capitalizeEachWord(e.username).split(" ")[0] === capitalizeEachWord(query) ||
            capitalizeEachWord(e.username).split(" ")[1] === capitalizeEachWord(query) ||
            e.last_name === capitalizeEachWord(query) ||
            e.first_name === capitalizeEachWord(query?.split(" ")[0]) ||
            e.last_name === capitalizeEachWord(query?.split(" ")[1] || query?.split(" ")[0]) ||
            e.last_name === capitalizeEachWord(query?.split(" ")[0]) ||
            e.first_name === capitalizeEachWord(query?.split(" ")[1] || query?.split(" ")[0])
          );
        });

        if (!gotten?.first_name) return handleEmptyState();

        const comp = [gotten].map((e, i) => {
          return <UserCardMealPrep key={i} data={e} />;
        });

        runPaginationOnComponents(comp, 20, setCompsOnShow, setSplittedArrWorked);
      } else {
        url = `${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?query=only-chefs`;

        const { data } = await (
          await fetch(url, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const servicesFilteredData = data.filter(
          (e) =>
            e?.availableForMealPrep !== "no" &&
            !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
            e?.country === country &&
            e?.chef_details?.chef_verification_details?.is_documents_verified &&
            e?.chef_details?.chef_menus?.filter((e) => e?.status === "approved").length > 0
        );

        const comp = servicesFilteredData.map((e, i) => {
          return <UserCardMealPrep key={i} data={e} />;
        });

        if (comp.length === 0) handleEmptyState();
        else runPaginationOnComponents(comp, 20, setCompsOnShow, setSplittedArrWorked);
      }

      setIsLoading(false);
    } catch (err) {
      popup("Something went wrong...");
      setIsLoading(false);
    }
  };

  async function findChefByLocation(query) {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?location=${query}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const servicesFilteredData = data.filter(
        (e) =>
          e?.availableForMealPrep !== "no" &&
          !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
          e?.country === country &&
          e?.chef_details?.chef_verification_details?.is_documents_verified &&
          e?.chef_details?.chef_menus?.filter((e) => e?.status === "approved").length > 0
      );

      if (servicesFilteredData?.length === 0) handleEmptyState();
      else {
        const comp = servicesFilteredData.map((e, i) => {
          return <UserCardMealPrep key={i} data={e} />;
        });

        runPaginationOnComponents(comp, 20, setCompsOnShow, setSplittedArrWorked);
      }

      setIsLoading(false);
    } catch (err) {
      popup("Something went wrong...");
      setIsLoading(false);
    }
  }

  async function sortBy(paramss) {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?query=only-chefs`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const servicesFilteredData = data.filter(
        (e) =>
          e?.availableForMealPrep !== "no" &&
          !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
          e?.country === country &&
          e?.chef_details?.chef_verification_details?.is_documents_verified &&
          e?.chef_details?.chef_menus?.filter((e) => e?.status === "approved").length > 0
      );

      const filtered = servicesFilteredData.filter((e) => {
        if (paramss === "reviews") return calculateChefRating(e.chef_details.reviews) >= 3;
        if (paramss === "menus") return e.chef_details.chef_menus.length > 3;
      });

      if (filtered.length === 0) return handleEmptyState();

      const comp = filtered.map((e, i) => {
        return <UserCardMealPrep key={i} data={e} />;
      });

      runPaginationOnComponents(comp, 20, setCompsOnShow, setSplittedArrWorked);

      setIsLoading(false);
    } catch (err) {
      popup(err);
      setIsLoading(false);
    }
  }

  function runPaginationOnComponents(componentsArr, pageLimit, setOnShowState, saveSplittedState) {
    try {
      //ensure page variable is outside the component of this function

      page = 0;

      let firstStart = 0;
      let firstEnd = pageLimit;

      const splittedArr = [];

      for (let loop = 1; loop <= Math.ceil(componentsArr.length / pageLimit); loop++) {
        splittedArr.push([...componentsArr.slice(firstStart, firstEnd)]);

        firstStart = firstStart + pageLimit;
        firstEnd = firstEnd + pageLimit;
      }

      saveSplittedState(splittedArr);

      setOnShowState(splittedArr[0]);
    } catch {}
  }

  function navigate(command, theTopOfThepage, setOnShowState, theSplittedArr) {
    setOnShowState([]);

    theTopOfThepage?.current?.scrollIntoView({
      behaviour: "smooth",
    });

    page = command - 1;

    setOnShowState(theSplittedArr[page]);
  }

  function handleEmptyState() {
    page = 0;
    setSplittedArrWorked([]);
    setCompsOnShow(
      <div className="is-empty-cont" style={{ marginBottom: 40 }}>
        <img src="/images/no-booking.png" alt="ikook" />
        <p className="small boldText">No Chef Found 😞</p>
        <p className="small">Try another query</p>
      </div>
    );
    setIsLoading(false);
  }

  return (
    <div className="explore-cont" style={{ paddingTop: 0 }}>
      <div
        ref={topRef}
        className="flex-row filter-and-rest"
        style={{ backgroundColor: "#fcc01c", marginBottom: 20 }}
      >
        <div className="lineafter mp-line-after">
          <p className="small">{compsOnShow.length} Chef(s) Available</p>
        </div>

        <div className="lineafter mp-line-after" style={{ gap: 15 }}>
          <p className="small">Filter By:</p>

          <button
            onClick={() => sortBy("menus")}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-price-btn"
          >
            <GiCookingPot />
            &nbsp; Cuisine
          </button>

          <button
            onClick={() => sortBy("reviews")}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-cuisines-btn"
          >
            <AiFillStar />
            &nbsp; High Rating
          </button>
        </div>

        <div className="lineafter mp-line-after" style={{ gap: 15 }}>
          <input
            ref={queryRef}
            className="explore-location in"
            placeholder="chef-name; e.g. esther"
          />

          <button
            onClick={(_e) => findChef(queryRef.current.value)}
            className="themeBtn home-search-explore whiteBg"
          >
            <AiOutlineSearch />
          </button>
        </div>

        <div className="lineafter explore-line-after" style={{ gap: 15, borderRightWidth: 0 }}>
          <input
            value={queryLocation}
            onChange={(e) => {
              setQueryLocation(e.target.value);
            }}
            ref={ref}
            className="explore-location in"
            placeholder={"Location; state/city"}
          />

          <button
            onClick={() => {
              if (queryLocation) findChefByLocation(queryLocation.split(",")[0].split(" ")[0]);
              else findChef();
            }}
            className="themeBtn home-search-explore whiteBg"
          >
            <AiOutlineSearch />
          </button>
        </div>
      </div>

      <div className="our-services-large-explore">{compsOnShow}</div>

      <div className="flex-row">
        <Pagination
          page={page + 1}
          count={splittedArrWorked.length}
          variant="outlined"
          shape="rounded"
          onChange={(_event, page) => {
            navigate(page, topRef, setCompsOnShow, splittedArrWorked);
          }}
        />
      </div>

      <ForPageActions />
    </div>
  );
};

export default ExploreMealPrepChefs;
