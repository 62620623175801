import React, { useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { MdNotificationsActive } from "react-icons/md";
import "../../styles/index.css";

export default function AdvancedPopup({
  display,
  setShowAdvancedPopUp,
  advancedPopUpConfig,
  setAdvancedPopUpConfig,
}) {
  useEffect(() => {
    return () =>
      setAdvancedPopUpConfig({
        showButton: false,
        buttonText: "",
        whatToDoWithButton: null,
        message: "",
      });
  }, []);

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowAdvancedPopUp}>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <MdNotificationsActive size={30} />
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setShowAdvancedPopUp(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ textAlign: "left" }}>
              <p className="small">{advancedPopUpConfig?.message}</p>

              {advancedPopUpConfig?.showButton && (
                <button
                  onClick={() => {
                    setShowAdvancedPopUp(false);
                    advancedPopUpConfig?.whatToDoWithButton();
                  }}
                  style={{ height: 30, fontSize: "small", marginTop: 30, padding: 20 }}
                  className="themeBtn"
                >
                  {advancedPopUpConfig?.buttonText}
                </button>
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
