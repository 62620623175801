import React, { useEffect, useState, useContext } from "react";
import "./IC.css";
import "../../../styles/index.css";
import { TbAlertTriangle } from "react-icons/tb";
import "../../lightening-list/lightening-list.css";
import AppContext from "../../../context/app-context";

const MenuGuidelines = ({ menuData, activeNav }) => {
  const [numberOfDishesRequired, setNumberOfDishesRequired] = useState(0);
  const [dishAdditionalComps, setDishAdditionalComps] = useState([]);

  const { convertAmount } = useContext(AppContext);

  useEffect(() => {
    if (!menuData?.boundaries) return;

    let count = 0;

    for (let loop of Object.entries(menuData?.boundaries)) {
      if (Number(loop[1]?.value) > 0) count = count + 1;
    }
    setNumberOfDishesRequired(count);
  }, [menuData, activeNav]);

  useEffect(() => {
    if (numberOfDishesRequired < 1) return;

    let str = "Additional cost for ";
    const arr = [];

    if (
      Number(menuData?.boundaries?.min_starter?.value) > 0 &&
      menuData?.boundaries?.min_starter?.additional_charge
    ) {
      str =
        str +
        `Starter Dish is ${convertAmount(
          menuData?.boundaries?.min_starter?.additional_charge,
          menuData?.currency
        )}, `;
    }

    if (
      Number(menuData?.boundaries?.min_main?.value) > 0 &&
      menuData?.boundaries?.min_main?.additional_charge
    ) {
      str =
        str +
        `Main Dish is ${convertAmount(
          menuData?.boundaries?.min_main?.additional_charge,
          menuData?.currency
        )}, `;
    }

    if (
      Number(menuData?.boundaries?.min_dessert?.value) > 0 &&
      menuData?.boundaries?.min_dessert?.additional_charge
    ) {
      str =
        str +
        `Dessert Dish is ${convertAmount(
          menuData?.boundaries?.min_dessert?.additional_charge,
          menuData?.currency
        )}, `;
    }

    if (
      Number(menuData?.boundaries?.min_side?.value) > 0 &&
      menuData?.boundaries?.min_side?.additional_charge
    ) {
      str =
        str +
        `Side Dish is ${convertAmount(
          menuData?.boundaries?.min_side?.additional_charge,
          menuData?.currency
        )}, `;
    }

    str = str.slice(0, str.length - 2);

    arr.push(
      <div key={arr.length - 1} className="m-ligthning-title">
        <span style={{ color: "#fcc01c", fontWeight: "bold" }}>-</span>
        <p className="small alignTextLeft mg-text">{str}</p>
      </div>
    );

    setDishAdditionalComps([...arr]);
  }, [menuData, activeNav, numberOfDishesRequired]);

  if (numberOfDishesRequired < 1) return;

  return (
    <div className="IC-cont">
      <div className="mg-icon-flex-title">
        <TbAlertTriangle color="#f8d134" size={40} />

        <p style={{ marginTop: 15 }} className="small boldText the-mg-bold-title">
          Please take note of the following:
        </p>
      </div>

      <div className="m-ligthning-title">
        <span style={{ color: "#fcc01c", fontWeight: "bold" }}>-</span>
        <p className="small alignTextLeft mg-text">
          Select {numberOfDishesRequired} dish(es) from this menu (at least{" "}
          {menuData?.boundaries?.min_starter?.value
            ? `${menuData?.boundaries?.min_starter?.value} Starter Dish`
            : ""}
          {menuData?.boundaries?.min_main?.value
            ? `, ${menuData?.boundaries?.min_main?.value} Main Dish`
            : ""}
          {menuData?.boundaries?.min_dessert?.value
            ? `, ${menuData?.boundaries?.min_dessert?.value} Dessert Dish`
            : ""}
          {menuData?.boundaries?.min_side?.value
            ? `, ${menuData?.boundaries?.min_side?.value} Side Dish`
            : ""}
          )
        </p>
      </div>

      {dishAdditionalComps}

      {menuData?.minimum_number_of_guest ? (
        <div className="m-ligthning-title">
          <span style={{ color: "#fcc01c", fontWeight: "bold" }}>-</span>
          <p className="small alignTextLeft mg-text">
            The minimum number of guest for this menu is {menuData?.minimum_number_of_guest}
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MenuGuidelines;
