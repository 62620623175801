import React, { useContext } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { MdNotificationsActive } from "react-icons/md";
import "../../styles/index.css";
import AppContext from "../../context/app-context";

export default function EmailVer({ display, setShowEmailVerModal }) {
  const { backendServer, apiKey, setIsLoading, popup, usersData } = useContext(AppContext);

  async function getNewEmailVer() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/resend/ver/email`, {
          method: "POST",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify({
            email: usersData.email,
            first_name: usersData.first_name,
            username: usersData.username,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setShowEmailVerModal(false);

      popup("Check your email for your verification link!");

      setIsLoading(false);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowEmailVerModal}>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <MdNotificationsActive size={30} />
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setShowEmailVerModal(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ textAlign: "left" }}>
              <p className="small">
                To access this, you'll need to verify your email. If you didn't get the email,
                please click the button below to get a new verification email.
              </p>
              <button
              id="resend_verification_email"
                onClick={getNewEmailVer}
                style={{ height: 30, fontSize: "small", marginTop: 30, padding: 20 }}
                className="themeBtn"
              >
                Resend Verification Email
              </button>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
