import React from "react";
import "../communication-guideline/communication-guideline.css";
import "../../styles/index.css";
import { TbAlertOctagon } from "react-icons/tb";
import "../lightening-list/lightening-list.css";
import "./create-menu-prompt.css";

const CreateMenuPrompt = () => {
  return (
    <div className="menuPromotCont">
      <div
        className="flex-row align-row-left"
        style={{ justifyContent: "flex-start", gap: 15, padding: 0 }}
      >
        <TbAlertOctagon color="#f8d134" size={30} />

        <p style={{ marginTop: 15 }} className="small boldText">
          PLEASE NOTE
        </p>
      </div>

      <div className="l-lightening-title" style={{ marginLeft: 15, gap: 10, width: "90%" }}>
        <img src="/images/list-item.png" alt="iKooK" />
        <p className="small alignTextLeft cmgl-text">
          Please remember to save your progress by hitting the "save" button after creating a
          menu/dish. You are also required to set a Menu Name and Menu Description for each
          menu/dish.
        </p>
      </div>
    </div>
  );
};

export default CreateMenuPrompt;
