import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./styles/App.css";
import AllRoutes from "./routers/routers";
import TopNav from "./components/header/header";
import Footer from "./components/footer/Footer";
import Loading from "./components/loading/loading";
import EmailVer from "./components/email-ver/email-ver";
import AdvancedPopup from "./components/advanced-pop-up/advanced-popup";
import AppContext from "./context/app-context";
import { popup } from "./vanilla-functions/model";
import { GoogleOAuthProvider } from "@react-oauth/google";
import socket from "./socket/socket";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ProgressBarLoading from "./components/progress-bar-loading/progress-bar-loading";
import country from "./context/country";

const stripePromise = loadStripe(
  "pk_live_51KSV6rIc3d5YOr7YStGAqitOOgOEWGSVX5f6IuITQ2ZQbtsPaqvAqQGwHENrj7G6sDY1BEljIgS3C7FrbBomsMGs00CkkzxxRv"
);

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [showProgressLoading, setShowProgressLoading] = useState(false);
  const [progressLoadingValue, setProgressLoadingValue] = useState(0);
  const [progressLoadingWhatAreYouDoing, setProgressLoadingWhatAreYouDoing] = useState("");

  const [showFooter, setShowFooter] = useState(true);
  const [showAppbar, setShowAppbar] = useState(true);
  const [loggedIn, setLoggedIn] = useState("none");
  const [usersData, setUsersData] = useState({});
  const [usersPhoto, setUsersPhoto] = useState("/images/user_placeholder.png");
  const [edition, setEdition] = useState(localStorage.getItem("edition") || "");

  const [cadExchangeRate, setCadExchangeRate] = useState(0);
  const [gbpExchangeRate, setGpbExchangeRate] = useState(0);

  const [msgCount, setMsgCount] = useState(0);
  const [notCount, setNotCount] = useState(0);

  const [showEmailVerModal, setShowEmailVerModal] = useState(false);

  const [showAdvancedPopup, setShowAdvancedPopUp] = useState(false);

  const [advancedPopUpConfig, setAdvancedPopUpConfig] = useState({
    showButton: false,
    buttonText: "",
    whatToDoWithButton: null,
    message: "",
  });

  const universalShowLoginObject = {};

  const backendServer = process.env.NODE_ENV=="STAGING"?"https://ikook-master-development-f2fb75062cbd.herokuapp.com":"https://ikook-master-api.herokuapp.com";
  const pyBackendServer = "https://ikook.herokuapp.com";
  const apiKey = "1680";

  const identityPassId = "048ec68d-92d8-454b-b2c8-fe7f458cdffc";
  const identityPassKey = "live_sk_YmeV1PIplgkn9OZDWpj8OKu0ZpjH2fh8dn4bNsx";

  const googleMapsApiKey = "AIzaSyCMEEkI6EjXIl4RiLd_Bz-McWPA9Hda_WM";

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.getItem("edition")) return;

        const response = await fetch("https://api.ipify.org?format=json");
        const { ip } = await response.json();

        const responseCountry = await fetch(
          `https://api.ipgeolocation.io/ipgeo?apiKey=ed8db69751a544dbbe02545ba3f9a84f&ip=${ip}`
        );
        const data = await responseCountry.json();

        if (data?.country_name === "Nigeria") {
          setEdition("NGN");
        } else if (data?.country_name === "United Kingdom") {
          setEdition("GBP");
        } else if (data?.country_name === "Canada") {
          setEdition("CAD");
        } else setEdition("NGN");
      } catch {
        setEdition("NGN");
      }
    })();
  }, []);

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "f83192afbamsh0567bbad6fce23fp183ae0jsn94535616ca9f",
        "X-RapidAPI-Host": "exchangerate-api.p.rapidapi.com",
      },
    };

    fetch("https://exchangerate-api.p.rapidapi.com/rapid/latest/NGN", options)
      .then((response) => {
        if (response?.status !== 200) {
          // setEdition("NGN")
          // localStorage.setItem("edition", "NGN")
          // throw new Error()
        } else return response.json();
      })
      .then((response) => {
        if (!response?.rates?.CAD || !response?.rates?.GBP) {
          // setEdition("NGN")
          // return localStorage.setItem("edition", "NGN")
        }
        setCadExchangeRate(response?.rates?.CAD);
        setGpbExchangeRate(response?.rates?.GBP);
      });
  }, [loggedIn]);

  useEffect(() => {
    socket?.on("notify", (type, message) => {
      if (type === "message") {
        popup(message);
        const audio = new Audio(
          "https://oscarmini.com/wp-content/uploads/2019/08/Waterdrop_Drop.mp3"
        );
        audio.play();
      } else {
        popup(message);
        const audio = new Audio(
          "https://oscarmini.com/wp-content/uploads/2019/08/Waterdrop_Drop.mp3"
        );
        audio.play();

        backgroundUpdateUsersData();
      }
    });
  }, []);

  useEffect(() => {
    setNotCount(usersData?.notifications?.filter((e) => e.isNotificationRead === false)?.length);

    setMsgCount(usersData?.unReadRooms?.length);
  }, [usersData]);

  const getSignedAwsUrl = async function (filename, bucketname) {
    if (!filename) return;
    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;
    const { data } = await (
      await fetch(`${backendServer}/ikook/api/v1/${apiKey}/file/get/${filename}/${bucketname}`, {
        credentials: "include",
        headers: {
          token: localStorage.getItem("ikooktoken"),
        },
      })
    ).json();

    return data;
  };

  function calculateChefRating(arrayOfReviews) {
    if (!arrayOfReviews || arrayOfReviews.length === 0) return;

    const maxRating = 5;

    const allRatingsArr = arrayOfReviews.map((e) => e.rating);

    const decimals = allRatingsArr.map((e) => e / maxRating);

    let totalDecimals = 0;

    decimals.forEach((e) => {
      totalDecimals = totalDecimals + e;
    });

    let scoreDec = totalDecimals / arrayOfReviews.length;

    let finalScore = scoreDec * maxRating;

    return Math.trunc(finalScore);
  }

  function emitRealDate(date) {
    if (!date) return;
    return new Intl.DateTimeFormat(navigator.language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(new Date(date));
  }

  async function updateUsersData() {
    setIsLoading(true);
    const data = await (
      await fetch(
        `${backendServer}/ikook/api/v1/${apiKey}/user/get/${localStorage.getItem("ikookusername")}`,
        {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        }
      )
    ).json();
    setUsersData(data.data);
    setIsLoading(false);
  }

  async function backgroundUpdateUsersData() {
    const data = await (
      await fetch(
        `${backendServer}/ikook/api/v1/${apiKey}/user/get/${localStorage.getItem("ikookusername")}`,
        {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        }
      )
    ).json();
    setUsersData(data.data);

    return data.data;
  }

  function formatNumber(amount) {
    return new Intl.NumberFormat("en-us").format(amount);
  }

  function convertAmount(amount, currency) {
    if (!amount) return null;
    if (
      currency !== "NGN" &&
      currency !== "GBP" &&
      currency !== "CAD" &&
      currency !== "Nigeria" &&
      currency !== "UK" &&
      currency !== "Canada"
    )
      return null;

    if (currency === "NGN" || currency === "Nigeria")
      return `₦${new Intl.NumberFormat("en-us").format(amount)}`;

    if (currency === "GBP" || currency === "UK")
      return `£${new Intl.NumberFormat("en-us").format(amount)}`;

    if (currency === "CAD" || currency === "Canada")
      return `$${new Intl.NumberFormat("en-us").format(amount)}`;
  }

  function renderAdvancedPopUp(display, showButton, buttonText, whatToDoWithButton, message) {
    setShowAdvancedPopUp(display);

    setAdvancedPopUpConfig({
      showButton,
      buttonText,
      whatToDoWithButton,
      message,
    });
  }

  function actOnProgressIsLoading(show, value, text) {
    if (!show) {
      setShowProgressLoading(show);
      setProgressLoadingValue(0);
      return setProgressLoadingWhatAreYouDoing("");
    }

    setShowProgressLoading(show);
    setProgressLoadingValue(value);
    setProgressLoadingWhatAreYouDoing(text);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChefInactiveState = () => {
    return (
      <div className="is-empty-cont" style={{ marginBottom: 40 }}>
        <img src="/images/no-booking.png" alt="ikook" />
        <p className="small boldText">Chef Not Found</p>
        <p className="small">This chef is either inactive or unavailable</p>
      </div>
    );
  };

  useEffect(() => {
    if (document.location.pathname === "/tac" || document.location.pathname === "/tac/")
      document.title = "iKooK - Terms and Conditions";
    if (document.location.pathname === "/policy" || document.location.pathname === "/policy/")
      document.title = "iKooK - Privacy Policy";
    if (document.location.pathname === "/about" || document.location.pathname === "/about/")
      document.title = "iKooK - About Us";
    if (
      document.location.pathname === "/how-it-works/user" ||
      document.location.pathname === "/how-it-works/user/"
    )
      document.title = "iKooK - How it Works for Hosts";
    if (
      document.location.pathname === "/how-it-works/chef" ||
      document.location.pathname === "/how-it-works/chef/"
    )
      document.title = "iKooK - How it Works for Chefs";
  }, []);

  if (!country) return;

  return (
    <GoogleOAuthProvider clientId="899835567208-9au1hcmob8lafpouuttsakmgi5bknd3o.apps.googleusercontent.com">
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <AppContext.Provider
            value={{
              setIsLoading,
              setShowFooter,
              backendServer,
              apiKey,
              setUsersData,
              usersData,
              popup,
              setLoggedIn,
              loggedIn,
              getSignedAwsUrl,
              setUsersPhoto,
              usersPhoto,
              calculateChefRating,
              emitRealDate,
              updateUsersData,
              identityPassId,
              identityPassKey,
              universalShowLoginObject,
              backgroundUpdateUsersData,
              msgCount,
              notCount,
              setNotCount,
              setMsgCount,
              edition,
              setEdition,
              cadExchangeRate,
              gbpExchangeRate,
              convertAmount,
              setShowEmailVerModal,
              googleMapsApiKey,
              renderAdvancedPopUp,
              pyBackendServer,
              isLoading,
              formatNumber,
              actOnProgressIsLoading,
              validateEmail,
              handleChefInactiveState,
              setShowAppbar,
            }}
          >
            <div className="App">
            {/* {showAppbar ? <TopNav universalShowLoginObject={universalShowLoginObject} /> : ""} */}
              

              <AllRoutes />

              {/* {showFooter ? <Footer /> : ""} */}

              <Loading show={isLoading} />
              <ProgressBarLoading
                value={progressLoadingValue}
                display={showProgressLoading}
                whatAreYouDoing={progressLoadingWhatAreYouDoing}
              />

              <EmailVer display={showEmailVerModal} setShowEmailVerModal={setShowEmailVerModal} />

              <AdvancedPopup
                display={showAdvancedPopup}
                advancedPopUpConfig={advancedPopUpConfig}
                setShowAdvancedPopUp={setShowAdvancedPopUp}
                setAdvancedPopUpConfig={setAdvancedPopUpConfig}
              />
            </div>
          </AppContext.Provider>
        </BrowserRouter>
      </Elements>
    </GoogleOAuthProvider>
  );
}

export default App;
