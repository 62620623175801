import React, { useContext, useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import "../../styles/index.css";
import { AiTwotoneStar } from "react-icons/ai";
import "../../pages/user-dashboard/styles/user-index.css";
import AppContext from "../../context/app-context";

const AddReviewModal = ({ display, setShowReviewModal, chefName, chefID }) => {
  const [rating, setRating] = useState(0);
  const [reveiwText, setReviewText] = useState("");

  const [disableBtn, setDisableBtn] = useState(true);

  const { backendServer, apiKey, setIsLoading, popup, usersData } = useContext(AppContext);

  useEffect(() => {
    if (rating && reveiwText) setDisableBtn(false);
    else setDisableBtn(true);
  }, [rating, reveiwText]);

  function handleRatingChange(e) {
    setRating(Number(e.target.closest(".rt-class").dataset.number));
  }

  async function setReview() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/profile/update/${chefID}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ikooktoken"),
          },
          body: JSON.stringify({
            reviews: {
              name: `${usersData.first_name} ${usersData.last_name}`,
              text: reveiwText,
              user_id: usersData._id,
              rating: rating,
              userPhoto: "",
            },
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your review has been posted!");

      setIsLoading(false);
      setShowReviewModal(false);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowReviewModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <h4 className="removemargin">Review {chefName}'s services</h4>
            </MDBModalHeader>

            <MDBModalBody>
              <div className="flex-column" style={{ marginBottom: 30, justifyContent: "center" }}>
                <p className="small removemargin">OVERALL PERFOMANCE</p>
                <br></br>
                <div>
                  <AiTwotoneStar
                    onClick={handleRatingChange}
                    data-number="1"
                    className="cursor rt-class"
                    size={20}
                    color={rating > 0 ? "yellow" : "grey"}
                  />
                  <AiTwotoneStar
                    onClick={handleRatingChange}
                    data-number="2"
                    className="cursor rt-class"
                    size={20}
                    color={rating > 1 ? "yellow" : "grey"}
                  />
                  <AiTwotoneStar
                    onClick={handleRatingChange}
                    data-number="3"
                    className="cursor rt-class"
                    size={20}
                    color={rating > 2 ? "yellow" : "grey"}
                  />
                  <AiTwotoneStar
                    onClick={handleRatingChange}
                    data-number="4"
                    className="cursor rt-class"
                    size={20}
                    color={rating > 3 ? "yellow" : "grey"}
                  />
                  <AiTwotoneStar
                    onClick={handleRatingChange}
                    data-number="5"
                    className="cursor rt-class"
                    size={20}
                    color={rating > 4 ? "yellow" : "grey"}
                  />
                </div>
              </div>

              <MDBTextArea
                onChange={(e) => setReviewText(e.target.value)}
                label="Your Review"
                type="text"
                placeholder="Your Review"
                maxLength={200}
                rows={5}
              />

              <MDBBtn
                disabled={disableBtn}
                style={{
                  width: "80%",
                  cursor: "pointer",
                  marginTop: 40,
                  backgroundColor: "#fcc01c",
                  color: "black",
                }}
                onClick={setReview}
                id="signupbtn-disable"
              >
                Confirm Review
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AddReviewModal;
