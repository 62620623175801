import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MDBCheckbox } from "mdb-react-ui-kit";
import "./home.css";
import "../../styles/index.css";
import "../../components/modal-edit-menu-details.jsx/modal-edit-menu-details.css";
import { AiOutlineSearch } from "react-icons/ai";
import MenuCardSmall from "../../components/menu-card-small/menu-card-small";
// import BlogCard from "../../components/blog-card/blog-card";
import Blogs from "../../components/Homeblogcard";
import Picture7 from "./Images/Picture7.png";
import Picture8 from "./Images/traction-logo.png";
import AppContext from "../../context/app-context";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import "./media-home.css";
import LoginModal from "../../components/auth/login/login-modal";
import { usePlacesWidget } from "react-google-autocomplete";
import restrictCountry from "../../context/country-restrictions";
import country from "../../context/country";
import Review from "../../components/homepagereviews";
import Video from "../../components/homevideo/video";
import WhyIkook from "../../components/why-ikook/why-ikook";

const Home = function () {
  const [userLocation, setUserLocation] = useState("");

  const [menusOnShow, setMenusOnShow] = useState([]);

  const [selectedService, setSelectedService] = useState("");

  const [eventDate, setEventDate] = useState("");

  const [cha, setCha] = useState(false);
  const [lg, setLg] = useState(false);
  const [mp, setMp] = useState(false);

  const [showLogin, setShowLogin] = useState(false);

  const loginModal = useRef(null);

  const { modal } = useParams();

  const { backendServer, apiKey, popup, loggedIn, googleMapsApiKey, edition } =
    useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn === "none") return;
    if (modal === "signup") {
      loginModal?.current?.showLoginScreen("signup");
      loginModal?.current?.setActiveSignupScreen("chef");
      setShowLogin(true);
    }
  }, [loggedIn]);

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setUserLocation(place?.formatted_address.replace(" ", ", "));
    },
    options: {
      componentRestrictions: { country: restrictCountry },
    },
  });

  async function fetchMenu() {
    try {
      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const servicesFilteredData = data.filter(
        (e) =>
          e?.chefID?.availableForChefAtHome !== "no" &&
          e?.chefID?.country === country &&
          e.status === "approved"
      );

      servicesFilteredData.reverse();

      const arr = servicesFilteredData.splice(0, 3);

      const comp = arr.map((e, i) => {
        return <MenuCardSmall key={i} data={e} />;
      });

      setMenusOnShow(comp);
    } catch {}
  }

  useEffect(() => {
    fetchMenu();
  }, []);

  return (
    <div className="home-cont">
      <div className="new-intro">
        <div>
          <div className="yellow-rec media-yellow-rec"></div>
          <p className="vbig bigTextHome blackText" style={{ color: "#323335" }}>
            Discover the new, easy way <br></br> to book a{" "}
            <span style={{ color: "#fcc01c" }}>private chef</span>
          </p>
        </div>

        <div className="smart-search home-smart-search" style={{ marginTop: 20 }}>
          <p className="big">EXPLORE OUR BEST SERVICES</p>

          <div className="search-cont home-search-cont">
            <div className="insearch" style={{ width: "fit-content" }}>
              <p className="big" style={{ color: "#323335", fontSize: "large" }}>
                Location
              </p>

              <div className="flex-row flex-gap" style={{ width: "100%" }}>
                <input
                  ref={ref}
                  onChange={(e) => setUserLocation(e.target.value)}
                  className="home-location in"
                  placeholder="City/State"
                  style={{ width: "200px" }}
                />
              </div>
            </div>

            <div className="insearch insearch-narrow">
              <p className="big" style={{ color: "#323335", fontSize: "large" }}>
                Event Date
              </p>

              <div className="home-check-boxes">
                <input
                  onChange={(e) => {
                    setEventDate(e.target.value);
                  }}
                  className="home-location in"
                  style={{ width: "200px" }}
                  type="date"
                />
              </div>
            </div>

            <div className="insearch insearch-narrow">
              <p className="big" style={{ color: "#323335", fontSize: "large" }}>
                Type of Service
              </p>

              <div className="home-check-boxes">
                <MDBCheckbox
                  checked={cha}
                  onChange={(e) => {
                    setLg(false);
                    setMp(false);
                    setCha(true);
                    setSelectedService(e.target.value);
                  }}
                  name="inlineCheck"
                  id="inlineCheckbox3"
                  value="explore"
                  label="Chef At Home"
                  inline
                  labelStyle={{ marginTop: 2.5 }}
                />

                <MDBCheckbox
                  onChange={(e) => {
                    setLg(true);
                    setMp(false);
                    setCha(false);
                    setSelectedService(e.target.value);
                  }}
                  checked={lg}
                  name="inlineCheck"
                  id="inlineCheckbox3"
                  value="cb"
                  label="Large Events"
                  inline
                  labelStyle={{ marginTop: 2.5 }}
                />

                <MDBCheckbox
                  onChange={(e) => {
                    setLg(false);
                    setMp(true);
                    setCha(false);
                    setSelectedService(e.target.value);
                  }}
                  checked={mp}
                  name="inlineCheck"
                  id="inlineCheckbox3"
                  value="meal-prep/chefs"
                  label="Meal Prep"
                  inline
                  labelStyle={{ marginTop: 2.5 }}
                />
              </div>
            </div>

            <button
              onClick={() => {
                if (!selectedService) return popup("Please select a service");
                if (!userLocation) return popup("Please select a location");

                if (selectedService === "cb") return navigate(`/${selectedService}/le`);

                navigate(
                  `/${selectedService}/${
                    userLocation !== "Your Location" ? userLocation.split(",")[0].split(" ")[0] : ""
                  }/${eventDate}`
                );
              }}
              className="themeBtn home-search-explore"
            >
              <AiOutlineSearch />
            </button>
          </div>
        </div>
      </div>

      <div className="intro home-intro">
        <div className="intro-intro home-intro-intro">
          <p className="big experience-home">
            Experience different cuisines and enjoy professionally prepared food, at home or any
            occasion! Our website will connect you with trained chefs who tailor to your exact
            needs.
          </p>

          <div className="home-action">
            <button
              onClick={() => {
                if (loggedIn) popup("You are logged In!");
                loginModal?.current?.showLoginScreen("signup");
                loginModal?.current?.setActiveSignupScreen("user");
                setShowLogin(true);
              }}
              className="themeBtn home-change-btn"
            >
              Get Started
            </button>
          </div>
        </div>

        <div className="intro-image home-intro-image home-video">
          <Video />
        </div>
      </div>

      <h3 className="styled-h3" style={{ marginBottom: "40px" }}>
        Our Services
      </h3>

      <div className="our-services" style={{ justifyContent: "center", gap: 30 }}>
        <div onClick={() => (document.location.href = "/cb/le")} className="each-home-services">
          <img
            className="services-image"
            src="/images/Picture2A.6fa509d4c5bd14d80323.png"
            alt="iKooK large events"
          />
        </div>

        <div onClick={() => (document.location.href = "/explore")} className="each-home-services">
          <img
            className="services-image"
            src="images/Picture2B.3941e8396bf93cab686a.png"
            alt="iKooK chef at home"
          />
        </div>

        <div
          onClick={() => {
            document.location.href = "/meal-prep/chefs";
          }}
          className="each-home-services"
        >
          <img className="services-image" src="images/new-meal-pre.png" alt="iKooK meal prep" />
        </div>
      </div>

      <ForPageActions margin={1} />

      {menusOnShow.length > 0 && (
        <div className="our-services-large home-our-services-large">
          <div className="each-top-menu-large-two media-top-menu-home">
            <h3 className="styled-h3-edit">Our Top Menu</h3>

            <div className="our-services-mini media-our-services-mini">{menusOnShow}</div>
          </div>

          <button
            onClick={() => {
              document.location.href = "/explore";
            }}
            className="themeBtn"
          >
            See All
          </button>
        </div>
      )}

      <WhyIkook />
      <Review />

      <div className="our-services-large home-our-services-large">
        <div className="each-top-menu-large-two media-top-menu-home">
          <h3 className="styled-h3-edit">Our Blog</h3>

          <Blogs homePage={true} />
          {/* <div className="our-services-mini media-our-services-mini">
            <BlogCard
              image={"https://ikook.tech/wp-content/uploads/2023/05/Photos-for-blog-post-2.png"}
              body="When planning a wedding, catering is important in ensuring a memorable experience for you and your guests. While many couples opt for traditional catering services"
              url={"https://ikook.tech/benefits-of-hiring-a-private-chef-for-your-wedding/"}
              title={"Benefits Of Hiring A Private Chef For Your Wedding"}
            />

            <BlogCard
              title={"How To Plan A Vegan Summer Wedding Menu"}
              image={"https://ikook.tech/wp-content/uploads/2023/05/Photos-for-blog-post-1.png"}
              url={"https://ikook.tech/tips-for-planning-a-vegan-vegetarian-summer-wedding-menu/"}
              body="Planning a wedding is an exciting journey, and for couples who embrace a vegan or vegetarian lifestyle, it’s an opportunity to reflect their values and create a menu that celebrates their dietary choices. Summer weddings offer a wealth of fresh, vibrant ingredients that can be transformed into delicious plant-based dishes."
            />

            <BlogCard
              title={"Gluten-Free Menu Ideas"}
              image={"https://ikook.tech/wp-content/uploads/2023/04/1-3.png"}
              url={"https://ikook.tech/gluten-free-menu-ideas/"}
              body="Are you having a dinner party and need some gluten-free menu ideas? We have compiled a list of gluten-free ideas that are simply delicious "
            />
          </div> */}
        </div>
      </div>

      {edition === "NGN" ? (
        <div className="home-b-footer">
          <div className="home-bf-topic">
            <div className="tm-line"></div>
            <div className="tm-intro blackText">Trusted by</div>
            <div className="tm-line"></div>
          </div>
          <div className="home-b-signs">
            <img style={{ width: 220 }} src={Picture8} alt="Our Patners" className="home-b-sign" />
          </div>
        </div>
      ) : (
        <div className="home-b-footer">
          <div className="home-bf-topic">
            <div className="tm-line"></div>
            <div className="tm-intro blackText">Trusted by</div>
            <div className="tm-line"></div>
          </div>
          <div className="home-b-signs">
            <img src={Picture7} alt="Our Patners" className="home-b-sign" />
          </div>
        </div>
      )}

      <LoginModal ref={loginModal} display={showLogin} setShowLogin={setShowLogin} />
    </div>
  );
};

export default Home;
