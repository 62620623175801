import React, { useContext } from "react";
import { PaystackButton } from "react-paystack";
import { MDBBtn } from "mdb-react-ui-kit";
import "../pages/menu-page/menu-page.css";
import "../styles/index.css";
import AppContext from "../context/app-context";

const PaystackHook = ({ config, disabled, setShowStripeModal, currency, pseudoValidate }) => {
  const { loggedIn, universalShowLoginObject, usersData, setShowEmailVerModal } =
    useContext(AppContext);
  if (!currency) return;

  if (!disabled && currency === "NGN" && loggedIn && usersData?.verified)
    return (
      <div>
        <PaystackButton className="btn btn-warning" {...config} />
      </div>
    );
  else if (!disabled && currency !== "NGN" && loggedIn && usersData?.verified)
    return (
      <MDBBtn
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black", width: 130 }}
        onClick={() => setShowStripeModal(true)}
      >
        Book Now
      </MDBBtn>
    );
  else
    return (
      <MDBBtn
        onClick={() => {
          if (!loggedIn || loggedIn === "none") return universalShowLoginObject.showLogin(true);
          if (!usersData?.verified) return setShowEmailVerModal(true);
          if (pseudoValidate) return pseudoValidate();
        }}
        className="mdbBtn"
        style={{ backgroundColor: "#fcc01c", color: "black", width: 130 }}
        disabled={pseudoValidate ? false : true}
      >
        Book Now
      </MDBBtn>
    );
};

export default PaystackHook;
