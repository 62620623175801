import { io } from "socket.io-client";

const socket = io("https://ikook-socket-server.herokuapp.com");

socket.on("connect", () => {
  if (!localStorage.getItem("ikookusername")) return;

  // console.log("Socket in")

  socket.emit("updateMySocketId", localStorage.getItem("ikookusername"));
});

export default socket;
