import React, { useEffect } from "react";
import Picture2A from "./Images/Picture2A.png";
import Picture2B from "./Images/Picture2B.png";
import Picture2C from "./Images/Picture2C.png";

const Join = () => {
  const startEffects = () => {
    document.querySelectorAll(".chef-ch-j-idbox").forEach((e, i) => {
      setTimeout(() => {
        e.style = "background-color: #ffd93b;";
        setTimeout(() => {
          e.style = "background-color: white;";
        }, 1000);
      }, 1000 * i);
    });
  };

  useEffect(() => {
    const theInterval = setInterval(() => {
      startEffects();

      clearInterval(theInterval);
    }, 1000);
  }, []);

  return (
    <>
      <div className="ch-j">
        <div className="ch-j-body">
          <div className="ch-j-paper">
            <div className="ch-j-topic">
              <div className="ch-j-line"></div>
              <div className="ch-j-intro ch-j-intro-c">How It Works</div>
              <div className="ch-j-line"></div>
            </div>
            <div className="ch-j-hbox">
              <div className="ch-j-hobox">
                <div className="ch-j-hibox">
                  <div className="ch-j-idbox chef-ch-j-idbox">
                    <h1 className="ch-j-id">1</h1>
                  </div>
                  <div className="ch-j-imgcont">
                    <img src={Picture2A} className="ch-j-image" alt="iKooK How It Works" />
                  </div>
                  <h2 className="ch-j-title">Sign up</h2>
                  <p className="ch-j-bdesc">
                    Sign up to create an account, you will not be available for booking until after
                    your verification step is taken. Create a listing for each service you offer.
                  </p>
                </div>
              </div>
              <div className="ch-j-hobox">
                <div className="ch-j-hibox">
                  <div className="ch-j-idbox chef-ch-j-idbox">
                    <h1 className="ch-j-id">2</h1>
                  </div>
                  <div className="ch-j-imgcont">
                    <img src={Picture2B} className="ch-j-image" alt="iKooK How It Works" />
                  </div>
                  <h2 className="ch-j-title">Get Bookings</h2>
                  <p className="ch-j-bdesc">
                    Once your services are published they will be available to book on our website -
                    this will include your pricing & availability.{" "}
                  </p>
                </div>
              </div>
              <div className="ch-j-hobox">
                <div className="ch-j-hibox">
                  <div className="ch-j-idbox chef-ch-j-idbox">
                    <h1 className="ch-j-id">3</h1>
                  </div>
                  <div className="ch-j-imgcont">
                    <img src={Picture2C} className="ch-j-image" alt="iKooK Event Day" />
                  </div>
                  <h2 className="ch-j-title">Event Day</h2>
                  <p className="ch-j-bdesc">
                    Client books your listed service and pays a deposit through iKooK. Our booking
                    fee will be taken directly from the deposit which is paid through iKooK.
                  </p>
                </div>
              </div>
              <div className="ch-j-hobox">
                <div className="ch-j-hibox">
                  <div className="ch-j-idbox chef-ch-j-idbox">
                    <h1 className="ch-j-id">4</h1>
                  </div>
                  <div className="ch-j-imgcont">
                    <img src={Picture2C} className="ch-j-image" alt="iKooK Payment" />
                  </div>
                  <h2 className="ch-j-title">Payment</h2>
                  <p className="ch-j-bdesc">
                    Finalise event details with the client and provide a memorable service. Clients
                    can then leave a review for your services.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Join;
