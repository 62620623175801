import React, { useContext, useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBFile,
} from "mdb-react-ui-kit";
import "../../styles/index.css";
import AppContext from "../../context/app-context";
import "./add-menu-img-modal.css";
import "../../pages/chef-dashboard/styles/chef-index.css";

const AddMenuImageModal = ({ display, setShowAddMenuImgModal, menuID }) => {
  const [disableBtn, setDisableBtn] = useState(true);
  const [menuData, setMenuData] = useState({});
  const [menuPic, setMenuPic] = useState("");
  const [placeholderImage, setPlaceholderImage] = useState("/images/img-placeholder.png");

  const { backendServer, apiKey, setIsLoading, popup } = useContext(AppContext);

  useEffect(() => {
    if (menuPic) setDisableBtn(false);
    else setDisableBtn(true);
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/chef/menu/get/${menuID}`, {
            method: "GET",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setMenuData(returned?.data);
        setIsLoading(false);
      } catch (err) {
        popup("Failed to get Menu Data. Please try again later...");
        setIsLoading(false);
        setShowAddMenuImgModal(false);
      }
    })();
  }, [menuID]);

  async function addImage() {
    if (menuData?.images?.length >= 10)
      return popup("Sorry, you are only allowed ten Menu Images...");

    if (!menuPic) return popup("Please attach an image");

    try {
      setIsLoading(true);

      const form = new FormData();

      form.append("image", menuPic);

      const returned = await (
        await fetch(
          `${backendServer}/ikook/api/v1/${apiKey}/chef/logistics/menu/add/image/${menuID}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              enctype: "multipart/form-data",
              token: localStorage.getItem("ikooktoken"),
            },
            body: form,
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Your menu image has been added!");

      setIsLoading(false);

      setShowAddMenuImgModal(false);

      setTimeout(() => {
        document.location.reload();
      }, 2000);
    } catch (err) {
      popup("An error occured");
      setIsLoading(false);
    }
  }

  function handleFileChange(e) {
    setMenuPic(e.target.files[0]);

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (function (_theFile) {
      return function (e) {
        setPlaceholderImage(e.target.result);
      };
    })(file);

    reader.readAsDataURL(file);
  }

  return (
    <>
      <MDBModal show={display} tabIndex="-1" setShow={setShowAddMenuImgModal}>
        <MDBModalDialog style={{ marginTop: 100 }} size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <h6 className="removemargin">
                Add Image to <span className="yellowText">{menuData?.menuName}</span>
              </h6>
            </MDBModalHeader>

            <MDBModalBody>
              <div className="flex-column" style={{ gap: 40 }}>
                <div className="flex-row flex-gap">
                  <div
                    className="chef-details-boxes-settings flex-col align-column-left"
                    style={{ height: "300px", gap: 15 }}
                  >
                    <h5 className="greyText">MAIN IMAGE CATALOGUE</h5>

                    <p className="greyText small">
                      (Recommended 1000px width, 1000px height.
                      <br /> Maximum of 1MB file size)
                    </p>

                    <MDBFile
                      accept="image/jpg,image/png,image/jpeg"
                      onChange={handleFileChange}
                      style={{ width: "350px" }}
                      placeholder="Attach Menu Image"
                    />
                  </div>

                  <img
                    src={placeholderImage}
                    alt="menu"
                    className="add-a-dish-image"
                  />
                </div>
                <MDBBtn
                  className="mdbBtn"
                  style={{ backgroundColor: "#fcc01c", color: "black" }}
                  disabled={disableBtn}
                  onClick={addImage}
                >
                  Add Image
                </MDBBtn>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AddMenuImageModal;
