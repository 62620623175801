import React, { useContext, useState, useLayoutEffect, useRef, useEffect } from "react";
import "./styles/user-index.css";
import AppContext from "../../context/app-context";
import UserDashBoardEngine from "./engine/engine";
import UserDashBoardSideNav from "./navigator/nav";
import UserDashBoardIndexContext from "./context/user-index-context";
import { useParams } from "react-router-dom";
import AlertModal from "../../components/alert-modal/alert-modal";

const UserDashBoardIndex = function () {
  const { setShowFooter, usersData, loggedIn, msgCount, notCount, popup } = useContext(AppContext);

  const [securityCheckded, setSecurityChecked] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);

  const [message, setMessage] = useState("");

  const [dashboardFunctions, setDashboardFunctions] = useState({});

  const engineRef = useRef(null);
  const sideNavRef = useRef(null);

  const { userId } = useParams();

  useEffect(() => {
    document.title = "iKooK - User Dashboard";
    document.location.hash = "dashboard";
  }, []);

  useLayoutEffect(() => {
    setShowFooter(false);
  }, []);

  useLayoutEffect(() => {
    if (usersData?.role !== "user") {
      setSecurityChecked(false);
    }

    if (!loggedIn || loggedIn === "none") return setSecurityChecked(false);

    if (!localStorage.getItem("ikooktoken")) return setSecurityChecked(false);

    if (!localStorage.getItem("ikookusername")) return setSecurityChecked(false);

    if (localStorage.getItem("ikookid") !== userId) return setSecurityChecked(false);

    if (!usersData?.verified) {
      popup("Please verify your email to access this page");
      setTimeout(() => {
        document.location.href = "/";
      }, 4000);
      return setSecurityChecked(false);
    }

    setSecurityChecked(true);
  }, [loggedIn, usersData]);

  useEffect(() => {
    const isMobile = navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500;
    if (isMobile) {
      setMessage(
        "Hi there, we recommend requesting desktop site on your browser or viewing on your PC for a better experience."
      );
      setShowAlertModal(true);
    }
  }, []);

  if (!securityCheckded) return;

  return (
    <UserDashBoardIndexContext.Provider
      value={{
        changeAppScreen: sideNavRef?.current?.changeAppScreen,
        setDashboardFunctions,
        dashboardFunctions,
      }}
    >
      <div className="user-dashboard-cont media-user-dashboard-cont">
        <UserDashBoardSideNav ref={sideNavRef} changeScreen={engineRef?.current?.changeScreen} />

        <UserDashBoardEngine
          dashboardFunctions={dashboardFunctions}
          setDashboardFunctions={setDashboardFunctions}
          msgCount={msgCount}
          notCount={notCount}
          ref={(ref) => (engineRef.current = ref)}
        />

        <AlertModal
          display={showAlertModal}
          setShowAlertModal={setShowAlertModal}
          message={message}
        />
      </div>
    </UserDashBoardIndexContext.Provider>
  );
};

export default UserDashBoardIndex;
