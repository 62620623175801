import React, { useState } from "react";
import "../../../styles/index.css";
import "../../../pages/user-dashboard/styles/user-index.css";
import "../../menu-page/menu-page.css";
import ChefFaq from "../components/child-components/settings/chef-cd-faq";
import ChefPassword from "../components/child-components/settings/chef-cd-password";
import ChefProfile from "../components/child-components/settings/chef-cd-profile";
import ChefServices from "../components/child-components/settings/chef-cd-services";
import ChefVerification from "../components/child-components/settings/chef-cd-verification";
import ChefBank from "../components/child-components/settings/ch-cd-bank";

const CdSettings = function ({ display }) {
  const [activeNav, setActiveNav] = useState("profile");

  if (!display) return;

  return (
    <div className="cd-box-1-settings media-chef-cd-box-1-settings">
      <div className="menu-page-nav-cont media-chef-menu-page-nav-cont">
        <p
          onClick={() => {
            setActiveNav("profile");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "profile" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Profile
        </p>

        <p
          onClick={() => {
            setActiveNav("services");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "services" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Services
        </p>

        {/* <p onClick={() => {
          setActiveNav("password")
        }} className={`blackText boldText each-menu-page-nav-cm ${activeNav === "password" ? "each-menu-page-nav-active" : ""}`}>Password</p> */}

        <p
          onClick={() => {
            setActiveNav("faq");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "faq" ? "each-menu-page-nav-active" : ""
          }`}
        >
          FAQ
        </p>

        <p
          onClick={() => {
            setActiveNav("verification");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "verification" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Verification
        </p>

        <p
          onClick={() => {
            setActiveNav("bank");
          }}
          className={`blackText boldText each-menu-page-nav-cm ${
            activeNav === "bank" ? "each-menu-page-nav-active" : ""
          }`}
        >
          Bank
        </p>
      </div>

      <ChefProfile activeNav={activeNav} />

      <ChefFaq activeNav={activeNav} />

      <ChefPassword activeNav={activeNav} />

      <ChefServices activeNav={activeNav} />

      <ChefVerification activeNav={activeNav} />

      <ChefBank activeNav={activeNav} />
    </div>
  );
};

export default CdSettings;
