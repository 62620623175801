import React, { useContext, useState, useEffect, useRef } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import NotificationCard from "../components/cd-noti-card";
import AppContext from "../../../context/app-context";

const UdNotifications = function ({ display }) {
  const [notArr, setNotArr] = useState([]);

  const { usersData } = useContext(AppContext);

  const notificationsContRef = useRef(null);

  function renderNotifications() {
    const comp = usersData.notifications.map((e, i) => {
      return <NotificationCard read={e?.isNotificationRead ? true : false} key={i} data={e} />;
    });

    comp.reverse();

    setNotArr(comp);
  }

  useEffect(() => {
    renderNotifications();
  }, [usersData]);

  useEffect(() => {
    if (!notificationsContRef?.current?.style) return;

    if (navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500) {
      notificationsContRef.current.style = `height: ${window?.screen?.availHeight - 160}px;`;
    }
  });

  if (!display) return;

  return (
    <div ref={notificationsContRef} className="ud-notifcations-cont media-cd-help-cont">
      <div className="flex-row ud-dash-header media-not-intro">
        <p className="big alignTextLeft removemargin">Your Notifications</p>

        <button
          onClick={() => window.open("/explore", "_blank")}
          className="themeBtn ud-btns  media-ud-not-btn"
        >
          Book A Chef
        </button>
      </div>

      {notArr.length === 0 ? (
        <div className="is-empty-cont">
          <img src="/images/no-booking.png" alt="ikook" />

          <p className="small boldText">No new Notifications at this time!</p>
        </div>
      ) : (
        notArr
      )}
    </div>
  );
};

export default UdNotifications;
