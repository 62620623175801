import React, { useState, useContext, useEffect, useRef } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../components/auth/login/login-modal.css";
import "../../../styles/chef-index.css";
import { MDBBtn, MDBInput, MDBTextArea, MDBFile } from "mdb-react-ui-kit";
import EachTagBox from "../each-tag-boxes";
import AppContext from "../../../../../context/app-context";
import { usePlacesWidget } from "react-google-autocomplete";

const ChefProfile = function ({ activeNav }) {
  const [hideSubmitBtn, setHideSubmitBtn] = useState(true);

  const [placeholderImage, setPlaceholderImage] = useState("/images/img-placeholder.png");

  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState("");
  const [mobile, setMobile] = useState("");
  const [cookingStyle, setCookingStyle] = useState("");
  const [chefExperiences, setChefExperiences] = useState("");
  const [userPic, setUserPic] = useState("");
  const [languages, setLanguages] = useState([]);

  const [languageComps, setLanguageComps] = useState([]);

  const languageListInputRef = useRef(null);
  const [hideLanguageTagInput, setHideLanguageTagsInput] = useState(false);
  const [hideLanguageTagBtn, setHideLanguageTagsBtn] = useState(false);

  const { backendServer, apiKey, setIsLoading, popup, usersData, googleMapsApiKey } =
    useContext(AppContext);

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setAddress(place?.formatted_address);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: ["ng", "gb", "ca"] },
    },
  });

  useEffect(() => {
    if (
      !address &&
      !bio &&
      !mobile &&
      !cookingStyle &&
      !chefExperiences &&
      !userPic &&
      languages.length === 0 &&
      !city
    )
      setHideSubmitBtn(true);
    else setHideSubmitBtn(false);
  }, [address, bio, mobile, cookingStyle, chefExperiences, userPic, languages, city]);

  useEffect(() => {
    if (languageComps.length >= 3) {
      setHideLanguageTagsBtn(true);
      setHideLanguageTagsInput(true);
    } else {
      setHideLanguageTagsBtn(false);
      setHideLanguageTagsInput(false);
    }
  }, [languageComps]);

  function handleFileChange(e) {
    setUserPic(e.target.files[0]);

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (function (theFile) {
      return function (e) {
        setPlaceholderImage(e.target.result);
      };
    })(file);

    reader.readAsDataURL(file);
  }

  function handleAddLanguage(e) {
    if (languageComps.length >= 3) return popup("You can only add 3 cuisine tags");

    setLanguageComps([
      ...languageComps,
      <EachTagBox key={languageComps.length - 1} text={languageListInputRef.current.value} />,
    ]);

    setLanguages([...languages, languageListInputRef.current.value]);

    languageListInputRef.current.value = "";
  }

  const handleRemoveLanguages = function () {
    setLanguageComps([]);
  };

  async function submitProfileDetails() {
    setIsLoading(true);

    const obj = {};

    if (chefExperiences) obj.experience = chefExperiences;

    if (address) obj.address = address;

    if (bio) obj.bio = bio;

    if (cookingStyle) obj.style_cooking = cookingStyle;

    if (languages.length > 0) obj.languages = languages.join(",");

    try {
      if (address || bio || mobile || cookingStyle || chefExperiences || languages.length > 0) {
        const returned = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/chef/profile/update/${usersData._id}`,
            {
              method: "PATCH",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("ikooktoken"),
              },
              body: JSON.stringify(obj),
              credentials: "include",
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
      }

      if (mobile) {
        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/update/${usersData._id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({ mobile: mobile }),
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
      }

      if (city) {
        const returned = await (
          await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/update/${usersData._id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ikooktoken"),
            },
            body: JSON.stringify({ city: city }),
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
      }

      if (userPic) {
        const form = new FormData();

        form.append("photo", userPic);

        const returned = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/user/profile/pic/update/${usersData.username}`,
            {
              method: "PATCH",
              credentials: "include",
              headers: {
                enctype: "multipart/form-data",
                token: localStorage.getItem("ikooktoken"),
              },
              body: form,
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
      }

      popup("Your profile has been updated!");
      setTimeout(() => {
        document.location.reload();
      }, 2000);
    } catch (err) {
      popup("Something went wrong");
      setIsLoading(false);
    }
  }

  if (activeNav !== "profile") return;

  return (
    <div
      className="cd-box-1-in-acc media-chef-cd-box-1-in-acc"
      style={{ width: "90%", overflowY: "scroll" }}
    >
      <div className="flex-row align-row-left flex-gap">
        <MDBInput
          disabled={true}
          style={{ width: "300px" }}
          className="ac-inputs"
          label={usersData.first_name}
          type="text"
        />

        <MDBInput
          disabled={true}
          style={{ width: "300px" }}
          className="ac-inputs"
          label={usersData.last_name}
          type="text"
        />
      </div>

      <div className="flex-row align-row-left flex-gap">
        <MDBInput
          disabled={true}
          style={{ width: "300px" }}
          className="ac-inputs"
          label={usersData.username}
          type="text"
        />

        <MDBInput
          disabled={true}
          style={{ width: "300px" }}
          className="ac-inputs"
          label={usersData.email}
          type="email"
        />
      </div>

      <div className="flex-row align-row-left flex-gap">
        <MDBInput
          ref={ref}
          onChange={(e) => setAddress(e.target.value)}
          style={{ width: "300px" }}
          className="ac-inputs"
          label="Address"
          placeholder={usersData.chef_details.address}
          value={address}
          type="text"
        />

        <MDBInput
          onChange={(e) => setCity(e.target.value)}
          style={{ width: "300px" }}
          className="ac-inputs"
          label="City"
          placeholder={usersData.city}
          value={city}
          type="text"
        />
      </div>

      <div className="flex-row align-row-left flex-gap" style={{ alignItems: "flex-start" }}>
        <MDBInput
          onChange={(e) => setMobile(e.target.value)}
          style={{ width: "300px" }}
          className="ac-inputs"
          label="Mobile"
          type="text"
          placeholder={usersData.mobile}
        />

        <MDBTextArea
          onChange={(e) => setBio(e.target.value)}
          maxLength={200}
          className="ac-inputs"
          label="Your Bio"
          type="text"
          rows={5}
          cols={32}
          placeholder={usersData.chef_details.bio}
        />
      </div>

      <div className="flex-row align-row-left flex-gap" style={{ alignItems: "flex-start" }}>
        <MDBTextArea
          onChange={(e) => setChefExperiences(e.target.value)}
          maxLength={200}
          className="ac-inputs"
          label="Your Chef Experiences"
          type="text"
          rows={5}
          cols={32}
          placeholder={usersData.chef_details.experience}
        />

        <MDBTextArea
          onChange={(e) => setCookingStyle(e.target.value)}
          maxLength={200}
          className="ac-inputs"
          label="Your Cooking Style (Describe)"
          type="text"
          rows={5}
          cols={32}
          placeholder={usersData.chef_details.style_cooking}
        />
      </div>

      <div className="implement-tag-cont">
        <p className="small boldText removemargin">Languages</p>

        <div className="allContTagBoxes">
          <div className="tag-boxes" style={{ width: "60%" }}>
            {languageComps}

            {!hideLanguageTagInput ? (
              <MDBInput
                ref={languageListInputRef}
                className="ac-inputs"
                type="text"
                maxLength={10}
              />
            ) : (
              ""
            )}
          </div>

          <MDBBtn
            disabled={hideLanguageTagBtn}
            onClick={handleAddLanguage}
            className="themeBtn"
            style={{ backgroundColor: "#ffd93b", color: "black" }}
          >
            Add
          </MDBBtn>

          <MDBBtn
            style={{ backgroundColor: "#fff", color: "black" }}
            onClick={handleRemoveLanguages}
            className="themeBtn redBg"
          >
            Clear
          </MDBBtn>
        </div>
      </div>

      <div className="flex-row flex-gap align-row-left" style={{ alignItems: "flex-start" }}>
        <div
          className="chef-details-boxes-settings flex-col align-column-left mobile-add-image-box"
          style={{ height: "250px", gap: 15 }}
        >
          <h5 className="greyText removemargin">PROFILE PICTURE</h5>

          <p className="greyText xsmall">(Clear JPEG/PNG Image)</p>

          <MDBFile
            accept="image/jpg,image/png,image/jpeg"
            onChange={handleFileChange}
            style={{ width: "350px" }}
            placeholder="Attach Profile Image"
          />
        </div>

        <img style={{ width: "200px", height: "200px" }} src={placeholderImage} alt="user" />
      </div>

      <div className="flex-row align-row-left">
        <MDBBtn
          onClick={submitProfileDetails}
          style={{ backgroundColor: "#fcc01c", color: "black" }}
          disabled={hideSubmitBtn}
          className="small themeBtn"
          id="signupbtn-disable"
        >
          Save Changes
        </MDBBtn>
      </div>
    </div>
  );
};

export default ChefProfile;
