import React, { useState } from "react";
import "../../../../menu-page/menu-page.css";
import "../../../../../styles/index.css";
import MenuImageCard from "./upload-cards/menu";

const CMImages = function ({ activeNav, menuImages, setMenuImages }) {
  const [imageCount, setImageCount] = useState(5);

  if (activeNav !== "images") return;

  return (
    <div className="cd-box-1-in-acc" style={{ width: "90%", overflowY: "scroll" }}>
      <MenuImageCard type={0} menuImages={menuImages} setMenuImages={setMenuImages} />
      <MenuImageCard type={1} menuImages={menuImages} setMenuImages={setMenuImages} />
      <MenuImageCard type={2} menuImages={menuImages} setMenuImages={setMenuImages} />
      <MenuImageCard type={3} menuImages={menuImages} setMenuImages={setMenuImages} />
      <MenuImageCard type={4} menuImages={menuImages} setMenuImages={setMenuImages} />

      {imageCount > 5 && (
        <MenuImageCard type={5} menuImages={menuImages} setMenuImages={setMenuImages} />
      )}

      {imageCount > 6 && (
        <MenuImageCard type={6} menuImages={menuImages} setMenuImages={setMenuImages} />
      )}

      {imageCount > 7 && (
        <MenuImageCard type={7} menuImages={menuImages} setMenuImages={setMenuImages} />
      )}

      {imageCount > 8 && (
        <MenuImageCard type={8} menuImages={menuImages} setMenuImages={setMenuImages} />
      )}

      {imageCount > 9 && (
        <MenuImageCard type={9} menuImages={menuImages} setMenuImages={setMenuImages} />
      )}

      <button
        onClick={() => {
          setImageCount(imageCount + 1);
        }}
        style={{ padding: 10 }}
        className={`themeBtn small ${imageCount >= 10 ? "hide" : ""}`}
      >
        Add Another Image
      </button>
    </div>
  );
};

export default CMImages;
