import React, { useEffect, useState, useContext } from "react";
import "../../../styles/index.css";
import "../styles/user-index.css";
import "../../menu-page/menu-page.css";
import { BiArrowBack } from "react-icons/bi";
import {
  TableProposalCard,
  pprosalAcceptFunction,
  pprosalAmout,
  proposalCurr,
} from "./child-components/table-proposal-card";
import AppContext from "../../../context/app-context";
import StripeModal from "../../../components/stripe-modal.jsx/stripe-modal";

const SeeProposals = function ({ display, setOnView, data }) {
  const [proposalComps, setProposalComps] = useState([]);

  const [aProposalHasBennAccepted, setAProposalHasBeenAccepted] = useState("");

  const [showStripeModal, setShowStripeModal] = useState(false);

  const { backendServer, apiKey, setIsLoading, convertAmount } = useContext(AppContext);

  useEffect(() => {
    const comps = data?.proposals?.map((e, i) => (
      <TableProposalCard
        setShowStripeModal={setShowStripeModal}
        showStripeModal={showStripeModal}
        aProposalHasBennAccepted={aProposalHasBennAccepted}
        bookingID={data._id}
        setOnView={setOnView}
        data={e}
        key={i}
      />
    ));

    if (comps?.length === 0)
      return setProposalComps(
        <div className="flex-column for-no-bookings">
          <img src="/images/no-booking.png" alt="ikook" />
          <p className="small boldText">NO PROPOSALS TO DISPLAY</p>
          <p className="small">No Proposals to display for this booking. Stay tuned.</p>
        </div>
      );
    else setProposalComps(comps);
  }, [data, aProposalHasBennAccepted]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      const check = data?.proposals?.find((e) => e.proposal_status === "accepted");

      if (check) {
        const { data } = await (
          await fetch(
            `${backendServer}/ikook/api/v1/${apiKey}/user/get?id=${check.chef_who_proposed_id}`,
            {
              credentials: "include",
              headers: {
                token: localStorage.getItem("ikooktoken"),
              },
            }
          )
        ).json();

        setAProposalHasBeenAccepted(`${data?.username}'s Proposal #${check._id} Accepted`);
      } else setAProposalHasBeenAccepted("");

      setIsLoading(false);
    })();

    return () => setAProposalHasBeenAccepted("");
  }, [data]);

  if (!display) return;

  return (
    <div>
      <div className="flex-row ud-dash-header ud-dash-header-to-have-back-btn">
        <BiArrowBack
          className="cursor"
          onClick={() => {
            setOnView("custom booking");
          }}
        />

        {aProposalHasBennAccepted ? (
          aProposalHasBennAccepted
        ) : (
          <p className="big alignTextLeft removemargin">All Proposals for Booking #{data?._id}</p>
        )}

        <div className="ud-stat media-custom-booking-ud-state media-hide">
          <button
            onClick={() => {
              window.open("/users", "_blank");
            }}
            className="themeBtn ud-btns xsmall greenBg"
          >
            See All Chefs
          </button>
        </div>
      </div>

      <div className="cd-page-main-cont media-cd-page-main-cont">
        <div className="cd-box-1 p-cd-media-box-1" style={{ gap: 10 }}>
          <div className="menu-page-nav-cont">
            <p
              style={{ width: "fit-content" }}
              className={`blackText boldText each-menu-page-nav each-menu-page-nav-active`}
            >
              All Proposals ({data?.proposals.length})
            </p>
          </div>

          <div className="cd-box-1-in" style={{ gap: 0 }}>
            <div
              className="flex-row align-row-left"
              style={{ justifyContent: "space-around", marginTop: 40, width: "98%" }}
            >
              <p className="tablesmall alignTextLeft boldText" style={{ width: "20%" }}>
                Price
              </p>

              <p className="tablesmall alignTextLeft boldText" style={{ width: "30%" }}>
                Chef Name
              </p>

              <p className="tablesmall alignTextLeft boldText" style={{ width: "20%" }}>
                Chef Rating
              </p>

              <p className="tablesmall alignTextLeft boldText" style={{ width: "20%" }}>
                Status
              </p>

              <p className="tablesmall alignTextLeft boldText" style={{ width: "10%" }}></p>
            </div>

            {proposalComps}
          </div>
        </div>

        <div className="cd-box-2 cd-media-box-2">
          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Booking Details</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Location</p>

              <p className="small blackText removemargin alignTextLeft">
                {data?.location?.addressString}
              </p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Event Date</p>

              <p className="small blackText removemargin alignTextLeft">{data?.startDate}</p>
            </div>

            <div className="flex-column align-column-left">
              <p className="tablesmall removemargin">Guests</p>

              <p className="small blackText removemargin alignTextLeft">
                {data?.number_of_children} Adult(s). {data.number_of_teens} Teen(s).{" "}
                {data.number_of_adults} Kid(s)
              </p>
            </div>
          </div>

          <div className="box-2-booking-details">
            <div className="menu-page-nav-cont">
              <p className={`small boldText yellowText`}>Proposal Details</p>
            </div>

            <div className="flex-column align-column-left">
              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Number of Proposals</p>

                <p className="small removemargin alignTextLeft">{data?.proposals?.length}</p>
              </div>

              <div className="each-order-details-cont">
                <p className="small removemargin alignTextLeft">Rejected Proposals</p>

                <p className="small removemargin alignTextLeft">
                  {data?.proposals?.filter((e) => e?.proposal_status === "rejected").length}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StripeModal
        rawAmount={convertAmount(pprosalAmout, proposalCurr)}
        amount={pprosalAmout * 100}
        display={showStripeModal}
        setShowStripeModal={setShowStripeModal}
        sendBooking={pprosalAcceptFunction}
        currency={proposalCurr}
      />
    </div>
  );
};

export default SeeProposals;
