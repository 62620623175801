import React, { useState, useEffect, useRef, useContext } from "react";
import "../explore-menus/explore.css";
import "../../styles/index.css";
import "./user-page.css";
import { AiOutlineSearch, AiFillStar } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import UserCardSmall from "../../components/user-card-small/user-card-small";
import "../../pages/home/home.css";
import ForPageActions from "../../components/for-page-action/for-pages-action";
import AppContext from "../../context/app-context";
import { useParams } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import { usePlacesWidget } from "react-google-autocomplete";
import restrictCountry from "../../context/country-restrictions";
import country from "../../context/country";
import { Pagination } from "@mui/material";

let unvailableChefsForTheFilteredDate = [];
let page = 0;

const ExploreUsers = function () {
  const [compsOnShow, setCompsOnShow] = useState([]);
  const [splittedArrWorked, setSplittedArrWorked] = useState([]);

  const {
    backendServer,
    apiKey,
    setIsLoading,
    popup,
    calculateChefRating,
    loggedIn,
    googleMapsApiKey,
    pyBackendServer,
  } = useContext(AppContext);

  const [queryLocation, setQueryLocation] = useState("");

  const [searchDate, setSearchDate] = useState("");

  const queryRef = useRef(null);

  const topRef = useRef(null);

  const { query, filterdate } = useParams();

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place) => {
      setQueryLocation(place?.formatted_address);
    },
    options: {
      componentRestrictions: { country: restrictCountry },
    },
  });

  useEffect(() => {
    document.title = "iKooK - Our Chefs";
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (filterdate) {
          setIsLoading(true);

          const returned = await (
            await fetch(
              `${pyBackendServer}/accounts/chef/unavailability/get/date?date=${filterdate}`
            )
          ).json();

          unvailableChefsForTheFilteredDate = returned.data;
        }

        if (query) {
          setQueryLocation(query);
          findChefByLocation(query);
        } else {
          findChef();
        }

        setIsLoading(false);
      } catch {
        if (query) {
          setQueryLocation(query);
          findChefByLocation(query);
        } else {
          findChef();
        }

        setIsLoading(false);
      }
    })();
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn || loggedIn === "none") {
      return setIsLoading(true);
    }
  }, [loggedIn]);

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }

  const findMe = async function () {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const data = await (
        await fetch(
          `https://geocode.xyz/${pos.coords.latitude},${pos.coords.longitude}?auth=497013854179196392715x23250&json=1`
        )
      ).json();

      setQueryLocation(`${data.city}, ${data.country}`);
    });
  };

  const findChef = async (query) => {
    try {
      setIsLoading(true);

      let url;

      if (query) {
        url = `${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?query=only-chefs`;

        const { data } = await (
          await fetch(url, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const servicesFilteredData = data.filter(
          (e) =>
            e?.country === country &&
            e?.chef_details?.chef_verification_details?.is_documents_verified &&
            !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
            e?.is_active
        );

        const gotten = servicesFilteredData.find((e) => {
          return (
            e.first_name === capitalizeEachWord(query) ||
            e.username === query ||
            capitalizeEachWord(e.username) === capitalizeEachWord(query) ||
            capitalizeEachWord(e.username).split(" ")[0] === capitalizeEachWord(query) ||
            capitalizeEachWord(e.username).split(" ")[1] === capitalizeEachWord(query) ||
            e.last_name === capitalizeEachWord(query) ||
            e.first_name === capitalizeEachWord(query?.split(" ")[0]) ||
            e.last_name === capitalizeEachWord(query?.split(" ")[1] || query?.split(" ")[0]) ||
            e.last_name === capitalizeEachWord(query?.split(" ")[0]) ||
            e.first_name === capitalizeEachWord(query?.split(" ")[1] || query?.split(" ")[0])
          );
        });

        if (!gotten?.first_name) {
          handleEmptyState();
          return setIsLoading(false);
        }

        const comp = [gotten].map((e, i) => {
          return <UserCardSmall key={i} data={e} />;
        });

        runPaginationOnComponents(comp, 16, setCompsOnShow, setSplittedArrWorked);
      } else {
        url = `${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?query=only-chefs`;

        const { data } = await (
          await fetch(url, {
            credentials: "include",
            headers: {
              token: localStorage.getItem("ikooktoken"),
            },
          })
        ).json();

        const servicesFilteredData = data.filter(
          (e) =>
            e?.country === country &&
            e?.chef_details?.chef_verification_details?.is_documents_verified &&
            !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
            e?.is_active
        );

        const comp = servicesFilteredData.map((e, i) => {
          return <UserCardSmall key={i} data={e} />;
        });

        if (comp.length === 0) handleEmptyState();
        else runPaginationOnComponents(comp, 16, setCompsOnShow, setSplittedArrWorked);
      }

      setIsLoading(false);
    } catch (err) {
      popup(err);
      setIsLoading(false);
    }
  };

  async function findChefByLocation(query) {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?location=${query}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const servicesFilteredData = data.filter(
        (e) =>
          e?.country === country &&
          e?.chef_details?.chef_verification_details?.is_documents_verified &&
          !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
          e?.is_active
      );

      if (servicesFilteredData?.length === 0) {
        handleEmptyState();
        return setIsLoading(false);
      } else {
        const comp = servicesFilteredData.map((e, i) => {
          return <UserCardSmall key={i} data={e} />;
        });

        runPaginationOnComponents(comp, 16, setCompsOnShow, setSplittedArrWorked);
      }

      setIsLoading(false);
    } catch (err) {
      popup("Failed to fetch");
      setIsLoading(false);
    }
  }

  async function sortBy(paramss) {
    try {
      setIsLoading(true);

      const { data } = await (
        await fetch(`${backendServer}/ikook/api/v1/${apiKey}/user/mealprep/get?query=only-chefs`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ikooktoken"),
          },
        })
      ).json();

      const filtered = data.filter((e) => {
        if (paramss === "reviews") return calculateChefRating(e.chef_details.reviews) >= 3;
        if (paramss === "menus") return e.chef_details.chef_menus.length > 3;
      });

      const servicesFilteredData = filtered.filter(
        (e) =>
          e?.country === country &&
          e?.chef_details?.chef_verification_details?.is_documents_verified &&
          !unvailableChefsForTheFilteredDate.find((el) => el === e._id) &&
          e?.is_active
      );

      if (servicesFilteredData.length === 0) {
        handleEmptyState();
        return setIsLoading(false);
      }

      const comp = servicesFilteredData.map((e, i) => {
        return <UserCardSmall key={i} data={e} />;
      });

      runPaginationOnComponents(comp, 16, setCompsOnShow, setSplittedArrWorked);

      setIsLoading(false);
    } catch (err) {
      popup(err);
      setIsLoading(false);
    }
  }

  function runPaginationOnComponents(componentsArr, pageLimit, setOnShowState, saveSplittedState) {
    try {
      //ensure page variable is outside the component of this function

      page = 0;

      let firstStart = 0;
      let firstEnd = pageLimit;

      const splittedArr = [];

      for (let loop = 1; loop <= Math.ceil(componentsArr.length / pageLimit); loop++) {
        splittedArr.push([...componentsArr.slice(firstStart, firstEnd)]);

        firstStart = firstStart + pageLimit;
        firstEnd = firstEnd + pageLimit;
      }

      saveSplittedState(splittedArr);

      setOnShowState(splittedArr[0]);
    } catch {}
  }

  function navigate(command, theTopOfThepage, setOnShowState, theSplittedArr) {
    setOnShowState([]);

    theTopOfThepage?.current?.scrollIntoView({
      behaviour: "smooth",
    });

    page = command - 1;

    setOnShowState(theSplittedArr[page]);
  }

  function handleEmptyState() {
    page = 0;
    setSplittedArrWorked([]);

    setCompsOnShow(
      <div className="is-empty-cont" style={{ marginBottom: 40 }}>
        <img src="/images/no-booking.png" alt="ikook" />
        <p className="small boldText">No Chefs Found 😞</p>

        <p className="small">Try another query</p>
      </div>
    );
  }

  return (
    <div className="explore-cont">
      {/* <div className="smart-search-explore newboxShadow user-page-smart-search">
        <p className="big">Search Chef by Location</p>

        <div className="search-cont-explore user-page-search-cont-explore">
          <div className="insearch-explore">
            <p className="big" style={{ color: "#fcc01c", fontSize: "large" }}>
              Location
            </p>

            <input
              ref={ref}
              onChange={(e) => setQueryLocation(e.target.value)}
              className="explore-location in"
              value={queryLocation}
              placeholder={"Location; state/city"}
            />
          </div>

          <MDBBtn onClick={findMe} color="#ffd93b" className="small themeBtn us-findme">
            Find Me
          </MDBBtn>

          <div className="insearch-explore">
            <p className="big" style={{ color: "#fcc01c", fontSize: "large" }}>
              Event Date
            </p>

            <input
              onChange={(e) => setSearchDate(e.target.value)}
              type="date"
              className="explore-event-date in"
              placeholder="Expected Start of Event"
            />
          </div>

          <button
            onClick={() =>
              (document.location.href = `/users/${
                queryLocation.split(",")[0].split(" ")[0]
              }/${searchDate}`)
            }
            className="themeBtn home-search-explore"
          >
            <AiOutlineSearch />
          </button>
        </div>
      </div>

      <div ref={topRef} className="flex-row filter-and-rest">
        <div className="lineafter us-line-after">
          <p className="small">Showing {compsOnShow.length} Chef(s) Found</p>
        </div>

        <div className="lineafter us-line-after" style={{ gap: 15 }}>
          <p className="small">Filter:</p>

          <button
            onClick={() => sortBy("menus")}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-price-btn"
          >
            <GiMoneyStack />
            &nbsp; Menu Count
          </button>

          <button
            onClick={() => sortBy("reviews")}
            style={{ width: "fit-content" }}
            className="themeBtn whiteBg filter-cuisines-btn"
          >
            <AiFillStar />
            &nbsp; High Rating
          </button>
        </div>

        <div className="lineafter us-line-after" style={{ gap: 15, borderRightWidth: 0 }}>
          <input
            ref={queryRef}
            className="explore-location in"
            placeholder="chef-name; e.g. esther"
          />

          <button
            onClick={(e) => findChef(queryRef.current.value)}
            className="themeBtn home-search-explore whiteBg"
          >
            <AiOutlineSearch />
          </button>
        </div>
      </div> */}

      <div ref={topRef} className="our-services-large-explore">
        {compsOnShow}
      </div>

      <div className="flex-row">
        <Pagination
          page={page + 1}
          count={splittedArrWorked.length}
          variant="outlined"
          shape="rounded"
          onChange={(_event, page) => {
            navigate(page, topRef, setCompsOnShow, splittedArrWorked);
          }}
        />
      </div>

      <ForPageActions />
    </div>
  );
};

export default ExploreUsers;
