import React from "react";
import { ukreviews, ngreviews } from "./people";
import Person from "./person";
import "./styles/index.css";
import country from "../../context/country";

const Review = () => {
  return (
    <div className="review-component">
      <h3 style={{ color: "#323335" }}>What People Say</h3>
      <div className="rev-grid">
        {country === "Nigeria"
          ? ngreviews.map(({ id, name, rating, review }, i) => (
              <Person id={id} name={name} rating={rating} review={review} key={i} />
            ))
          : ukreviews.map(({ id, name, rating, review }, i) => (
              <Person id={id} name={name} rating={rating} review={review} key={i} />
            ))}
      </div>
    </div>
  );
};

export default Review;
