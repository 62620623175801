import React, { useContext, useEffect, useState } from "react";
import "../menu-card-small/menu-card-small.css";
import "../../styles/index.css";
import "./user-card-small.css";
import { AiTwotoneStar } from "react-icons/ai";
import AppContext from "../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";
import { ImLocation } from "react-icons/im";
import { FaCity } from "react-icons/fa";
import { GiMeal, GiPartyHat } from "react-icons/gi";
import { AiFillCalendar } from "react-icons/ai";

const UserCardSmall = function ({ data }) {
  const [userImage, setUserImage] = useState("");
  const [rating, setRating] = useState(0);

  const { getSignedAwsUrl, calculateChefRating } = useContext(AppContext);

  useEffect(() => {
    setRating(calculateChefRating(data.chef_details.reviews));

    return () => setRating(0);
  }, [data]);

  useEffect(() => {
    (async function () {
      if (data.photo) setUserImage(await getSignedAwsUrl(data.photo, "users"));
      else setUserImage("/images/user_placeholder.png");
    })();

    return () => setUserImage("/images/user_placeholder.png");
  }, [data]);

  return (
    <a href={`/user/${data._id}`} className="user-card-small-cont">
      {userImage ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <img
            style={{ width: 150, height: 150, borderRadius: 150 }}
            src={userImage}
            alt="iKook Chef"
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
          }}
        >
          <MDBSpinner size="sm" className="mx-2" color="primary">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      )}

      <div className="user-roww">
        <h6 className="our-font alignTextLeft" style={{ marginBottom: 0 }}>
          {(data?.username).length < 15 ? data?.username : (data?.username).slice(0, 15) + `...`}
        </h6>
      </div>

      <p className="removemargin small yellowText boldText">
        {data?.chef_details?.chef_menus?.filter((e) => e?.status === "approved")?.length} Menus(s)
      </p>

      <div className="user-roww">
        <div className="user-roww-one">
          <div className="menu-det">
            <p className="small removemargin alignTextLeft">
              <FaCity color="#f8c808" /> {data?.city?.split(" ")[0]}
            </p>

            <p className="small removemargin alignTextLeft">
              <ImLocation color="#f8c808" /> {data.country}
            </p>
          </div>
        </div>

        <div className="user-roww-two">
          <div className="menu-det-two">
            <p className="small removemargin">
              <AiTwotoneStar color={rating > 0 ? "yellow" : "grey"} />
              <AiTwotoneStar color={rating > 1 ? "yellow" : "grey"} />
              <AiTwotoneStar color={rating > 2 ? "yellow" : "grey"} />
              <AiTwotoneStar color={rating > 3 ? "yellow" : "grey"} />
              <AiTwotoneStar color={rating > 4 ? "yellow" : "grey"} />
            </p>

            <p className="small removemargin">({data.chef_details.reviews.length} Reviews)</p>
          </div>
        </div>
      </div>

      <div className="flex-row align-row-left" style={{ gap: 8 }}>
        {data?.availableForChefAtHome === "yes" && <GiMeal title="Available for Chef At Home" />}

        {data?.availableForLargeEvents === "yes" && (
          <GiPartyHat title="Available for Large Events" />
        )}

        {data?.availableForMealPrep === "yes" && <AiFillCalendar title="Available for Meal Prep" />}
      </div>
    </a>
  );
};

export default UserCardSmall;
